// components/CategoryDropdown.js
import React, { useState, useEffect } from 'react';  // Ensure useEffect is imported
import { View, Text, Picker, StyleSheet } from 'react-native';
import { educationMap } from '../models/educationMap';

// Complete list of categories and subcategories

export const careerPaths = {
  "CS Engineering": {
    // "Web Development": {
    //   title: "Web Development",
    //   description: "Learn to create stunning websites and web applications.",
    //   image: require('../assets/web-development.jpg'),
    //   subPaths: {
    //     "Frontend Development": {
    //       title: "Frontend Development",
    //       description: "Build the visual part of websites using HTML, CSS, and JavaScript.",
    //       image: require('../assets/frontend-development.jpg'),
    //     },
    //     "Backend Development": {
    //       title: "Backend Development",
    //       description: "Develop the server-side logic and databases for web applications.",
    //       image: require('../assets/backend-development.jpg'),
    //     },
    //     "Fullstack Development": {
    //       title: "Fullstack Development",
    //       description: "Master both frontend and backend development to build complete web applications.",
    //       image: require('../assets/fullstack-development.jpg'),
    //     },
    //   },
    // },
        //   "Game Development": {
    //     title: "Game Development",
    //     description: "Design and develop engaging games for various platforms.",
    //     image: require('../assets/game-development.jpg'),
    //     subPaths: {
    //       "Unity": {
    //         title: "Unity",
    //         description: "Create 2D and 3D games using Unity.",
    //         image: require('../assets/unity.jpg'),
    //       },
    //       "Unreal Engine": {
    //         title: "Unreal Engine",
    //         description: "Develop high-fidelity games using Unreal Engine.",
    //         image: require('../assets/unreal-engine.jpg'),
    //       },
    //       "Mobile Game Development": {
    //         title: "Mobile Game Development",
    //         description: "Design games specifically for mobile devices.",
    //         image: require('../assets/mobile-game-development.jpg'),
    //       },
    //     },
    //   },
    //   "DevOps": {
    //     title: "DevOps",
    //     description: "Automate and streamline software delivery and infrastructure management.",
    //     image: require('../assets/devops.jpg'),
    //     subPaths: {
    //       "CI/CD": {
    //         title: "CI/CD",
    //         description: "Continuous Integration and Continuous Deployment for rapid development cycles.",
    //         image: require('../assets/cicd.jpg'),
    //       },
    //       "Infrastructure as Code": {
    //         title: "Infrastructure as Code",
    //         description: "Manage infrastructure with code for consistent environments.",
    //         image: require('../assets/infrastructure-as-code.jpg'),
    //       },
    //       "Cloud DevOps": {
    //         title: "Cloud DevOps",
    //         description: "Implement DevOps practices in cloud environments like AWS, Azure, and GCP.",
    //         image: require('../assets/cloud-devops.jpg'),
    //       },
    //     },
    //   },
    //   "Cybersecurity": {
    //     title: "Cybersecurity",
    //     description: "Protect systems, networks, and programs from digital attacks.",
    //     image: require('../assets/cybersecurity.jpg'),
    //     subPaths: {
    //       "Penetration Testing": {
    //         title: "Penetration Testing",
    //         description: "Identify and exploit vulnerabilities to improve security.",
    //         image: require('../assets/penetration-testing.jpg'),
    //       },
    //       "Network Security": {
    //         title: "Network Security",
    //         description: "Defend against unauthorized access and attacks on networks.",
    //         image: require('../assets/network-security.jpg'),
    //       },
    //       "Application Security": {
    //         title: "Application Security",
    //         description: "Ensure security throughout the software development lifecycle.",
    //         image: require('../assets/application-security.jpg'),
    //       },
    //     },
    //   },
    //   "Data Science": {
    //     title: "Data Science",
    //     description: "Extract insights from data using statistical and computational techniques.",
    //     image: require('../assets/data-science.jpg'),
    //     subPaths: {
    //       "Data Analysis": {
    //         title: "Data Analysis",
    //         description: "Analyze and interpret complex datasets.",
    //         image: require('../assets/data-analysis.jpg'),
    //       },
    //       "Data Engineering": {
    //         title: "Data Engineering",
    //         description: "Design, build, and maintain systems for collecting and analyzing data.",
    //         image: require('../assets/data-engineering.jpg'),
    //       },
    //       "Big Data": {
    //         title: "Big Data",
    //         description: "Work with massive datasets to uncover patterns and insights.",
    //         image: require('../assets/big-data.jpg'),
    //       },
    //     },
    //   },
    //   "Machine Learning": {
    //     title: "Machine Learning",
    //     description: "Build algorithms that can learn from and make predictions on data.",
    //     image: require('../assets/machine-learning.jpg'),
    //     subPaths: {
    //       "Supervised Learning": {
    //         title: "Supervised Learning",
    //         description: "Learn algorithms that use labeled data to make predictions.",
    //         image: require('../assets/supervised-learning.jpg'),
    //       },
    //       "Unsupervised Learning": {
    //         title: "Unsupervised Learning",
    //         description: "Explore patterns and relationships in unlabeled data.",
    //         image: require('../assets/unsupervised-learning.jpg'),
    //       },
    //       "Deep Learning": {
    //         title: "Deep Learning",
    //         description: "Develop neural networks to solve complex problems.",
    //         image: require('../assets/deep-learning.jpg'),
    //       },
    //     },
    //   },
    //   "Artificial Intelligence": {
    //     title: "Artificial Intelligence",
    //     description: "Create systems that can simulate human intelligence.",
    //     image: require('../assets/artificial-intelligence.jpg'),
    //     subPaths: {
    //       "Natural Language Processing": {
    //         title: "Natural Language Processing",
    //         description: "Teach machines to understand and respond to human language.",
    //         image: require('../assets/nlp.jpg'),
    //       },
    //       "Computer Vision": {
    //         title: "Computer Vision",
    //         description: "Enable machines to interpret and make decisions based on visual data.",
    //         image: require('../assets/computer-vision.jpg'),
    //       },
    //       "Robotics": {
    //         title: "Robotics",
    //         description: "Design, build, and operate robots for various tasks.",
    //         image: require('../assets/robotics.jpg'),
    //       },
    //     },
    //   },
    //   "IoT (Internet of Things)": {
    //     title: "IoT (Internet of Things)",
    //     description: "Connect and control devices over the internet to automate tasks.",
    //     image: require('../assets/iot.jpg'),
    //     subPaths: {
    //       "Embedded Systems": {
    //         title: "Embedded Systems",
    //         description: "Develop hardware-software systems for specific tasks.",
    //         image: require('../assets/embedded-systems.jpg'),
    //       },
    //       "Sensor Networks": {
    //         title: "Sensor Networks",
    //         description: "Create networks of sensors to collect and analyze data.",
    //         image: require('../assets/sensor-networks.jpg'),
    //       },
    //       "Wearable Technology": {
    //         title: "Wearable Technology",
    //         description: "Design smart wearable devices for health, fitness, and more.",
    //         image: require('../assets/wearable-technology.jpg'),
    //       },
    //     },
    //   },
    // "Cloud Computing": {
    //     title: "Cloud Computing",
    //     description: "Utilize cloud resources to store and manage data and applications.",
    //     image: require('../assets/cloud-computing.jpg'),
    //     subPaths: {
    //       "AWS": {
    //         title: "AWS",
    //         description: "Build and deploy cloud solutions using Amazon Web Services.",
    //         image: require('../assets/aws.jpg'),
    //       },
    //       "Azure": {
    //         title: "Azure",
    //         description: "Implement and manage cloud services using Microsoft Azure.",
    //         image: require('../assets/azure.jpg'),
    //       },
    //       "Google Cloud": {
    //         title: "Google Cloud",
    //         description: "Leverage Google Cloud Platform for scalable cloud computing solutions.",
    //         image: require('../assets/google-cloud.jpg'),
    //       },
    //     },
    //   },
    //   "Network Administration": {
    //     title: "Network Administration",
    //     description: "Manage and maintain network systems for organizations.",
    //     image: require('../assets/network-administration.jpg'),
    //     subPaths: {
    //       "Cisco Networking": {
    //         title: "Cisco Networking",
    //         description: "Configure and troubleshoot Cisco network devices.",
    //         image: require('../assets/cisco-networking.jpg'),
    //       },
    //       "Network Security": {
    //         title: "Network Security",
    //         description: "Protect and secure networks from threats and vulnerabilities.",
    //         image: require('../assets/network-security.jpg'),
    //       },
    //       "Wireless Networking": {
    //         title: "Wireless Networking",
    //         description: "Implement and manage wireless networks.",
    //         image: require('../assets/wireless-networking.jpg'),
    //       },
    //     },
    //   },
    //   "System Administration": {
    //     title: "System Administration",
    //     description: "Oversee and maintain the IT infrastructure of organizations.",
    //     image: require('../assets/system-administration.jpg'),
    //     subPaths: {
    //       "Linux Administration": {
    //         title: "Linux Administration",
    //         description: "Manage and configure Linux-based systems.",
    //         image: require('../assets/linux-administration.jpg'),
    //       },
    //       "Windows Server Administration": {
    //         title: "Windows Server Administration",
    //         description: "Administer and support Windows servers.",
    //         image: require('../assets/windows-server-administration.jpg'),
    //       },
    //       "Virtualization": {
    //         title: "Virtualization",
    //         description: "Deploy and manage virtualized environments.",
    //         image: require('../assets/virtualization.jpg'),
    //       },
    //     },
    //   },
    //   "Product Management": {
    //     title: "Product Management",
    //     description: "Oversee the development and lifecycle of products.",
    //     image: require('../assets/product-management.jpg'),
    //     subPaths: {
    //       "Agile Methodologies": {
    //         title: "Agile Methodologies",
    //         description: "Implement Agile frameworks to manage product development.",
    //         image: require('../assets/agile-methodologies.jpg'),
    //       },
    //       "Scrum": {
    //         title: "Scrum",
    //         description: "Use Scrum practices to organize and manage projects.",
    //         image: require('../assets/scrum.jpg'),
    //       },
    //       "Product Lifecycle Management": {
    //         title: "Product Lifecycle Management",
    //         description: "Manage the entire lifecycle of a product from inception to disposal.",
    //         image: require('../assets/product-lifecycle.jpg'),
    //       },
    //     },
    //   },
  },
};


// const educationMap = {
//   // "Science": [
//   //   "Natural Sciences",
//   //   "Physics",
//   //   "Chemistry",
//   //   "Biology",
//   //   "Earth Sciences",
//   //   "Geology",
//   //   "Meteorology",
//   //   "Environmental Science",
//   //   "Life Sciences",
//   //   "Biotechnology",
//   //   "Genetics",
//   //   "Ecology",
//   //   "Space Sciences",
//   //   "Astronomy",
//   //   "Astrophysics",
//   //   "Space Exploration",
//   //   "Mathematics",
//   //   "Pure Mathematics",
//   //   "Applied Mathematics",
//   //   "Statistics"
//   // ],
//   // "Commerce": [
//   //   "Finance",
//   //   "Corporate Finance",
//   //   "Personal Finance",
//   //   "Investment Banking",
//   //   "Financial Planning",
//   //   "Accounting",
//   //   "Auditing",
//   //   "Taxation",
//   //   "Financial Reporting",
//   //   "Marketing",
//   //   "Digital Marketing",
//   //   "Content Marketing",
//   //   "Brand Management",
//   //   "Market Research",
//   //   "Economics",
//   //   "Microeconomics",
//   //   "Macroeconomics",
//   //   "Econometrics",
//   //   "Business Management",
//   //   "Entrepreneurship",
//   //   "Operations Management",
//   //   "Strategic Management"
//   // ],
//   // "Arts and Humanities": [
//   //   "Literature",
//   //   "Fiction",
//   //   "Non-Fiction",
//   //   "Poetry",
//   //   "Literary Analysis",
//   //   "Visual Arts",
//   //   "Painting",
//   //   "Sculpture",
//   //   "Photography",
//   //   "Digital Art",
//   //   "Performing Arts",
//   //   "Music",
//   //   "Dance",
//   //   "Theater",
//   //   "Film",
//   //   "History",
//   //   "Ancient History",
//   //   "Modern History",
//   //   "Cultural History",
//   //   "Philosophy",
//   //   "Ethics",
//   //   "Logic",
//   //   "Metaphysics",
//   //   "Linguistics",
//   //   "Language Learning",
//   //   "Translation",
//   //   "Phonetics"
//   // ],
//   // "Social Sciences": [
//   //   "Psychology",
//   //   "Clinical Psychology",
//   //   "Cognitive Psychology",
//   //   "Developmental Psychology",
//   //   "Sociology",
//   //   "Social Theory",
//   //   "Social Research",
//   //   "Cultural Studies",
//   //   "Political Science",
//   //   "International Relations",
//   //   "Political Theory",
//   //   "Public Policy",
//   //   "Anthropology",
//   //   "Cultural Anthropology",
//   //   "Archaeology",
//   //   "Physical Anthropology",
//   //   "Education",
//   //   "Teaching Methods",
//   //   "Educational Technology",
//   //   "Curriculum Development"
//   // ],
//   // "Health and Medicine": [
//   //   "Medicine",
//   //   "General Practice",
//   //   "Surgery",
//   //   "Pediatrics",
//   //   "Nursing",
//   //   "Clinical Nursing",
//   //   "Nursing Education",
//   //   "Nursing Management",
//   //   "Public Health",
//   //   "Epidemiology",
//   //   "Health Policy",
//   //   "Global Health",
//   //   "Pharmacy",
//   //   "Pharmacology",
//   //   "Clinical Pharmacy",
//   //   "Pharmaceutical Research",
//   //   "Nutrition",
//   //   "Dietetics",
//   //   "Sports Nutrition",
//   //   "Public Health Nutrition"
//   // ],
//   "Engineering": [
//     "CS Engineering",
//     // "Mechanical Engineering",
//     // "Automotive Engineering",
//     // "Manufacturing",
//     // "Thermal Engineering",
//     // "Electrical Engineering",
//     // "Power Systems",
//     // "Control Systems",
//     // "Renewable Energy",
//     // "Civil Engineering",
//     // "Structural Engineering",
//     // "Environmental Engineering",
//     // "Transportation Engineering",
//     // "Chemical Engineering",
//     // "Process Engineering",
//     // "Materials Science",
//     // "Biochemical Engineering",
//     // "Aerospace Engineering",
//     // "Aerodynamics",
//     // "Avionics",
//     // "Robotics Engineering",
//     // "Propulsion Systems",
//     // "Space Exploration",
//     // "Genetic Engineering",
//     // "Biotechnology Applications",
//     // "Genetic Manipulation Techniques"
//   ]
// };



export default function CategoryDropdown({
  selectedStream,
  onStreamChange,
  selectedDegree,
  onDegreeChange,
  selectedBranch,
  onBranchChange,
  disabled = false, // Added to disable dropdowns if necessary
}) {
  const [degreeCategories, setDegreeCategories] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);

  useEffect(() => {
    // When stream changes, reset degree and branch, and update degree categories
    if (selectedStream) {
      const degrees = Object.keys(educationMap[selectedStream]);
      setDegreeCategories(degrees);
      onDegreeChange(''); // Reset degree when stream changes
      onBranchChange(''); // Reset branch when stream changes
    } else {
      setDegreeCategories([]);
      setBranchOptions([]);
    }
  }, [selectedStream]);

  useEffect(() => {
    // When degree changes, reset branch and update branch options
    if (selectedStream && selectedDegree) {
      const branches = educationMap[selectedStream][selectedDegree];
      if (Array.isArray(branches)) {
        const validBranchOptions = branches.map((branch) => {
          if (typeof branch === 'object' && branch.branch) {
            return branch.branch;
          }
          return branch;
        });
        setBranchOptions(validBranchOptions);
      } else {
        setBranchOptions([]);
      }
      onBranchChange(''); // Reset branch when degree changes
    } else {
      setBranchOptions([]);
    }
  }, [selectedDegree]);

  return (
    <View style={styles.container}>
      {/* Stream Picker */}
      <View style={styles.dropdownWrapper}>
        <Text style={styles.label}>Stream:</Text>
        <Picker
          selectedValue={selectedStream}
          onValueChange={onStreamChange}
          style={styles.picker}
          enabled={!disabled} // Disable if login dialog is shown
        >
          <Picker.Item label="Select Stream" value="" />
          {Object.keys(educationMap).map((stream) => (
            <Picker.Item key={stream} label={stream} value={stream} />
          ))}
        </Picker>
      </View>

      {/* Degree Picker */}
      <View style={styles.dropdownWrapper}>
        <Text style={styles.label}>Degree:</Text>
        <Picker
          selectedValue={selectedDegree}
          onValueChange={onDegreeChange}
          style={styles.picker}
          enabled={degreeCategories.length > 0 && !disabled} // Disable if no degree or login dialog
        >
          <Picker.Item label="Select Degree" value="" />
          {degreeCategories.map((degree) => (
            <Picker.Item key={degree} label={degree} value={degree} />
          ))}
        </Picker>
      </View>

      {/* Branch Picker */}
      {branchOptions.length > 0 && (
        <View style={styles.dropdownWrapper}>
          <Text style={styles.label}>Branch:</Text>
          <Picker
            selectedValue={selectedBranch}
            onValueChange={onBranchChange}
            style={styles.picker}
            enabled={branchOptions.length > 0 && !disabled} // Disable if no branch or login dialog
          >
            <Picker.Item label="Select Branch" value="" />
            {branchOptions.map((branch, index) => (
              <Picker.Item key={index} label={branch} value={branch} />
            ))}
          </Picker>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 10,
    backgroundColor: '#F1EEE6', // Light background color
    paddingHorizontal: 15, // Extra padding for better spacing
  },
  dropdownWrapper: {
    flex: 1,
    marginHorizontal: 5,
  },
  label: {
    fontSize: 16,
    fontWeight: '600', // Slightly bold for readability
    marginBottom: 5,
    color: '#333', // Dark text color for light theme
  },
  picker: {
    height: 50,
    padding: 8,
    backgroundColor: '#fff', // Light background for dropdown
    color: '#333', // Dark text color for better contrast
    borderColor: '#007BFF', // Border to match the light theme primary color
    borderWidth: 1,
    borderRadius: 5,
  },
});
