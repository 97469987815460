// HomeScreen.js
import React from 'react';
import { View, Text, StyleSheet, Image, ScrollView, Linking, TouchableOpacity, Dimensions } from 'react-native';
import * as Animatable from 'react-native-animatable';

const { width } = Dimensions.get('window');
const isLargeScreen = width > 768;

export default function HomeScreen() {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      {/* Custom Header */}
      <View style={styles.header}>
        <Image source={require('../assets/hamtiv-logo.png')} style={styles.logoHeader} />
        <Text style={styles.headerTitle}>HamTiv</Text>
      </View>

      {/* Custom Banner */}
      <View style={styles.banner}>
        <Image 
          source={require('../assets/hamtiv-logo.png')} 
          style={styles.logo} 
        />
        <Text style={styles.companyAim}>Best Career for People</Text>
        <Text style={styles.tagline}>Explore Careers, Build Skills, Share Expertise and Get Hired</Text>
      </View>

      {/* Features Section */}
      <View style={styles.featuresSection}>
        <Text style={styles.sectionTitle}>What We Offer</Text>
        <Text style={styles.sectionSubtitle}>Your career support system</Text>
        <View style={styles.featuresGrid}>
          {features.map((feature, index) => (
            <Animatable.View 
              key={index} 
              style={[styles.featureCard, !isLargeScreen && styles.mobileCard]} 
              animation="fadeInUp" 
              delay={index * 100}
            >
              <Image source={feature.image} style={styles.featureImage} />
              <Text style={styles.featureTitle}>{feature.title} &gt;</Text>
              <Text style={styles.featureDescription}>{feature.description}</Text>
            </Animatable.View>
          ))}
        </View>
      </View>

      {/* Footer */}
      <View style={styles.footer}>
        <View style={styles.footerSection}>
          <Text style={styles.footerTitle}>About HamTiv</Text>
          <Text style={styles.footerText}>
            HamTiv is dedicated to guiding individuals towards their best career paths. By providing tailored support and resources, we ensure users are equipped for success.
          </Text>
          <TouchableOpacity onPress={() => Linking.openURL('https://www.linkedin.com/company/hamtiv')}>
            <Text style={styles.footerLink}>LinkedIn</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => Linking.openURL('https://www.instagram.com/hamtiv_official')}>
            <Text style={styles.footerLink}>Instagram</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => Linking.openURL('https://www.youtube.com/@hamtiv_official')}>
            <Text style={styles.footerLink}>YouTube</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.footerSection}>
          <Text style={styles.footerTitle}>Founder & CEO</Text>
          <Text style={styles.footerText}>Name: Mayank Shukla</Text>
          <Text style={styles.footerText}>Email: mayank@hamtiv.com</Text>
          <TouchableOpacity onPress={() => Linking.openURL('https://www.linkedin.com/in/mayankshukla1406')}>
            <Text style={styles.footerLink}>LinkedIn</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => Linking.openURL('https://www.instagram.com/mayank_dsa_android')}>
            <Text style={styles.footerLink}>Instagram</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => Linking.openURL('https://www.youtube.com/@NoobDeveloperYoutube')}>
            <Text style={styles.footerLink}>YouTube</Text>
          </TouchableOpacity>
        </View>
      </View>
    </ScrollView>
  );
}

const features = [
  {
    title: 'Career Exploration',
    description: 'Users select their career category and subcategory.\nBenefit: This feature allows users to explore diverse career fields beyond the traditional ones. It can help in raising awareness about different sectors and specializations that users might not have considered otherwise.',
    image: require('../assets/career-exploration.png'),
  },
  {
    title: 'Category-Based Content',
    description: 'Posts and discussions are shown to users based on their selected category.\nBenefit: By providing tailored content, users can engage with relevant industry trends, advice, and discussions. This can aid in making informed career choices and identifying opportunities aligned with their interests and skills.',
    image: require('../assets/skill-development.png'),
  },
  {
    title: 'Focused Discussion Forums',
    description: 'Forums for in-depth discussions on specific topics and validation of career options.\nBenefit: These forums can provide valuable insights, mentorship, and networking opportunities. They help users gain a deeper understanding of career paths, potential career shifts, and skill requirements, fostering informed decision-making.',
    image: require('../assets/networking-opportunities.jpg'),
  },
  {
    title: 'Skill Development and Learning Paths',
    description: 'Discussions and resources for skill development and learning paths.\nBenefit: This feature emphasizes continuous learning and upskilling, which is crucial for adapting to market changes and improving employability in various fields.',
    image: require('../assets/recruitment-platform.jpg'),
  },
  {
    title: 'Detailed User Profiles',
    description: 'Profiles include detailed information about a candidate\'s experience and skills.\nBenefit: Comprehensive profiles allow employers to better understand candidates’ strengths and potential fit for job roles, improving the recruitment process and increasing chances of successful job placements.',
    image: require('../assets/job-matching.jpg'),
  },
  {
    title: 'Recruiter Interface for Candidate Search',
    description: 'Recruiters can filter candidates by skills and view detailed profiles.\nBenefit: This feature streamlines the hiring process for recruiters, allowing them to quickly identify and contact suitable candidates, which can help reduce unemployment by facilitating more precise matches.',
    image: require('../assets/profile-showcase.jpg'),
  },
];

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: '#F1EEE6', // Light background
  },
  header: {
    backgroundColor: '#fff',
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    position: 'relative',
    minHeight: 80,
  },
  logoHeader: {
    width: 40,
    height: 40,
    resizeMode: 'contain',
    position: 'absolute',
    left: 20,
    top: '50%',
    transform: [{ translateY: -20 }],
  },
  headerTitle: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#007BFF',
    textAlign: 'center',
  },
  banner: {
    backgroundColor: '#ffffff',
    padding: 20,
    alignItems: 'center',
    borderRadius: 10,
    marginBottom: 30,
  },
  logo: {
    width: 100,
    height: 100,
    resizeMode: 'contain',
    marginBottom: 15,
  },
  companyAim: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#007BFF',
    marginBottom: 10,
  },
  tagline: {
    fontSize: 16,
    color: '#555',
    textAlign: 'center',
  },
  featuresSection: {
    marginBottom: 30,
  },
  sectionTitle: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center',
    color: '#007BFF',
  },
  sectionSubtitle: {
    fontSize: 16,
    color: '#7f8c8d',
    marginBottom: 20,
    textAlign: 'center',
  },
  featuresGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  featureCard: {
    backgroundColor: '#ffffff',
    margin: 10,
    borderRadius: 8,
    padding: 15,
    elevation: 3,
    flexBasis: isLargeScreen?'45%':'90%',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#ddd'
  },
  mobileCard: {
    width: '90%', // Cards will take 90% width and be centered on small screens
  },
  featureImage: {
    width: '100%',
    height: 150,
    resizeMode: 'cover',
    borderRadius: 8,
    marginBottom: 10,
  },
  featureTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 5,
    color: '#007BFF',
  },
  featureDescription: {
    fontSize: 14,
    color: '#555',
    textAlign: 'center',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 20,
    borderTopWidth: 1,
    borderTopColor: '#ecf0f1',
  },
  footerSection: {
    width: '48%',
  },
  footerTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#007BFF',
  },
  footerText: {
    fontSize: 14,
    color: '#555',
    marginBottom: 10,
  },
  footerLink: {
    fontSize: 14,
    color: '#3498db',
    marginBottom: 5,
  },
});

