// JobStack.js
import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import JobListScreen from './screens/JobListScreen';
import JobDetailScreen from './screens/JobDetailScreen';

const Stack = createStackNavigator();

export default function JobStack() {
  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
      <Stack.Screen
        name="JobListScreen"
        component={JobListScreen}
        options={{ title: 'Jobs' }}
      />
      <Stack.Screen
        name="JobDetailScreen"
        component={JobDetailScreen}
        options={{ title: 'Job Details' }}
      />
    </Stack.Navigator>
  );
}
