import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, Image, Dimensions } from 'react-native';
// import CategoryDropdown from '../components/CategoryDropdown'; // Temporarily commented out for no filtering
import { educationMap } from '../models/educationMap'; // Assuming this is where educationMap is imported from

const { width } = Dimensions.get('window');  // Get screen width to adjust layout

const isLargeScreen = width > 768; // Use large screen layout if width > 768



export default function ResourceScreen({ navigation }) {
  const [filteredCareerPaths, setFilteredCareerPaths] = useState({}); // No filtering for now
  const [selectedCareerPath, setSelectedCareerPath] = useState('');
  const [selectedSubCareerPath, setSelectedSubCareerPath] = useState('');

  // Temporarily showing all career paths (assume this from educationMap structure)
  useEffect(() => {
    const allCareerPaths = {};
    for (const stream in educationMap) {
      for (const degree in educationMap[stream]) {
        educationMap[stream][degree].forEach((branchObj) => {
          Object.assign(allCareerPaths, branchObj.careerPaths);
        });
      }
    }
    setFilteredCareerPaths(allCareerPaths);
  }, []);

  return (
    <ScrollView style={styles.container}>
      <View style={styles.header}>
        <Image source={require('../assets/hamtiv-logo.png')} style={styles.logoHeader} />
        <Text style={styles.headerTitle}>Explore Career Paths</Text>
      </View>

      {/* Commented out filtering logic */}
      {/* 
      <CategoryDropdown
        selectedStream={selectedStream}
        onStreamChange={handleStreamChange}
        selectedDegree={selectedDegree}
        onDegreeChange={handleDegreeChange}
        selectedBranch={selectedBranch}
        onBranchChange={handleBranchChange}
      />
      */}

      {/* Display Career Paths */}
      {Object.keys(filteredCareerPaths).length > 0 ? (
        <View>
          <Text style={styles.sectionTitle}>Career Paths</Text>
          <View style={styles.careerPathContainer}>
            {Object.entries(filteredCareerPaths).map(([pathKey, pathValue], index) => (
              <TouchableOpacity
                key={index}
                style={styles.careerPathCard}
                onPress={() => setSelectedCareerPath(pathKey)}
              >
                <Image source={pathValue.image} style={styles.careerPathImage} resizeMode="cover" />
                <Text style={styles.careerPathTitle}>{pathValue.title}</Text>
                <Text style={styles.careerPathDescription}>{pathValue.description}</Text>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      ) : (
        <Text style={styles.noResultsText}>
          {'No career paths available.'}
        </Text>
      )}

      {/* Display Sub-Career Paths */}
      {selectedCareerPath && filteredCareerPaths[selectedCareerPath]?.subPaths && (
        <View>
          <Text style={styles.sectionTitle}>Sub Career Paths</Text>
          <View style={styles.subCareerPathContainer}>
            {Object.entries(filteredCareerPaths[selectedCareerPath].subPaths).map(
              ([subPathKey, subPathValue], index) => (
                <TouchableOpacity
                  key={index}
                  style={styles.subCareerPathCard}
                  onPress={() => {
                    setSelectedSubCareerPath(subPathKey);
                    navigation.navigate('Resources', {
                      subPathKey: subPathKey,
                      topics: subPathValue.topics || null,
                    });
                  }}
                >
                  <Image source={subPathValue.image} style={styles.subCareerPathImage} resizeMode="cover" />
                  <Text style={styles.subCareerPathTitle}>{subPathValue.title}</Text>
                  <Text style={styles.subCareerPathDescription}>{subPathValue.description}</Text>
                </TouchableOpacity>
              )
            )}
          </View>
        </View>
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F1EEE6', // Light background
  },
  header: {
    backgroundColor: '#fff',
    paddingVertical: 10, // Reduced vertical padding
    paddingHorizontal: 20,
    flexDirection: 'column', // Stacks the title and subtitle vertically
    alignItems: 'center', // Centers content vertically
    justifyContent: 'center', // Ensures the content is centered
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    position: 'relative', // For absolutely positioning the logo
    minHeight: 80, // Ensures the header has a minimum height for good spacing
  },
  logoHeader: {
    width: 40,
    height: 40,
    resizeMode: 'contain',
    position: 'absolute', // Places the logo in the top-left corner
    left: 20, // Keeps space from the left edge
    top: '50%', // Aligns vertically
    transform: [{ translateY: -20 }], // Centers logo vertically based on its height
  },
  headerTitle: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#007BFF',
    textAlign: 'center',
  },
  sectionTitle: {
    fontSize: 24,
    color: '#007BFF', // Blue section title
    margin: 10,
    fontWeight: 'bold',
  },
  careerPathContainer: {
    margin: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  careerPathCard: {
    width: isLargeScreen?'45%':'90%',
    backgroundColor: '#ffffff', // White card background
    borderRadius: 10,
    padding: 15,
    margin: 10,
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#dddddd', // Light gray border
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 5,
    elevation: 3,
  },
  careerPathImage: {
    width: '100%', // Ensure image takes full width
    height: 150, // Adjust height for better display
    borderRadius: 10,
    marginBottom: 10, // Adjusted to ensure proper spacing
  },
  careerPathTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333333', // Dark text for title
    textAlign: 'center',
  },
  careerPathDescription: {
    fontSize: 14,
    color: '#666666', // Lighter text for description
    textAlign: 'center',
  },
  subCareerPathContainer: {
    margin: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  subCareerPathCard: {
    width: isLargeScreen?'45%':'90%',
    backgroundColor: '#ffffff',
    borderRadius: 10,
    padding: 15,
    margin: 10,
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#dddddd',
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 5,
    elevation: 3,
  },
  subCareerPathImage: {
    width: '100%', // Ensure image takes full width
    height: 150, // Adjust height for better display
    borderRadius: 10,
    marginBottom: 10, // Adjusted to ensure proper spacing
  },
  subCareerPathTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333333',
    textAlign: 'center',
  },
  subCareerPathDescription: {
    fontSize: 14,
    color: '#666666',
    textAlign: 'center',
  },
  noResultsText: {
    fontSize: 18,
    color: '#333333', // Dark text for "no results"
    textAlign: 'center',
    margin: 20,
  },
});
