import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import ResourceScreen from './screens/ResourceScreen';
import Resources from './screens/Resources';
import MCQScreen from './screens/MCQScreen';
import TopicsScreen from './screens/TopicsScreen';
import TopicDetailScreen from './screens/TopicDetailScreen';
import ResultScreen from './screens/ResultScreen';

const Stack = createStackNavigator();

export default function ResourceStack() {
  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
      <Stack.Screen name="ResourceScreen" component={ResourceScreen} options={{ title: 'ResourceScreen' }} />
      <Stack.Screen name="Resources" component={Resources} options={{ title: 'Resources' }} />
      <Stack.Screen name="MCQScreen" component={MCQScreen} options={{ title: 'MCQScreen' }} />
      <Stack.Screen name="ResultScreen" component={ResultScreen} options={{ title: 'Result' }} />
      <Stack.Screen name="TopicsScreen" component={TopicsScreen} options={{ title: 'TopicsScreen' }} />
      <Stack.Screen name="TopicDetailScreen" component={TopicDetailScreen} options={{ title: 'TopicDetailScreen' }} />
    </Stack.Navigator>
  );
}