import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, TextInput, Alert, Modal, Button } from 'react-native';
import { db, auth } from '../firebaseConfig';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from 'firebase/auth';

export default function ResultScreen({ route, navigation }) {
  const { userAnswers, questions } = route.params;

  const incorrectAnswers = userAnswers.filter(
    (answer) => answer.selected !== questions[answer.questionId].correctAnswer
  );

  const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
    } catch (error) {
      console.error('Error during Google login:', error);
    }
  };

  const submitFeedback = async () => {
    if (!user) {
      Alert.alert('You need to be logged in to submit feedback.');
      return;
    }

    const feedbackData = {
      id: user.uid,
      userName: user.displayName,
      userEmail: user.email,
      time: Timestamp.now(),
      feedbackText: feedbackText,
    };

    try {
      await addDoc(collection(db, 'feedback'), feedbackData);
      Alert.alert('Feedback submitted successfully!');
      setFeedbackModalVisible(false);
      setFeedbackText('');
    } catch (error) {
      console.error('Error submitting feedback:', error);
      Alert.alert('Error submitting feedback, please try again later.');
    }
  };

  return (
    <ScrollView style={styles.container}>
      <Text style={styles.header}>Your Test Result</Text>
      <Text style={styles.result}>
        {`You answered ${questions.length - incorrectAnswers.length} out of ${questions.length} questions correctly.`}
      </Text>
      <Text style={styles.incorrectHeader}>Questions you answered incorrectly:</Text>

      {user ? (
        incorrectAnswers.map((answer, index) => (
          <View key={index} style={styles.questionContainer}>
            <Text style={styles.question}>{questions[answer.questionId].questionText}</Text>
            <Text style={styles.answer}>Correct Answer: {questions[answer.questionId].correctAnswer}</Text>
            <Text style={styles.userAnswer}>Your Answer: {answer.selected}</Text>
            <Text style={styles.explanation}>Explanation: {questions[answer.questionId].explanation}</Text>
          </View>
        ))
      ) : (
        <>
          <Text style={styles.instructions}>Sign in to see correct answers and explanations.</Text>
          <TouchableOpacity style={styles.googleButton} onPress={handleGoogleLogin}>
            <Text style={styles.googleButtonText}>Sign In With Google</Text>
          </TouchableOpacity>
        </>
      )}

      <TouchableOpacity style={styles.navButton} onPress={() => navigation.navigate('ResourceScreen')}>
        <Text style={styles.navButtonText}>Go to Resource Screen</Text>
      </TouchableOpacity>

      {user && (
        <TouchableOpacity style={styles.feedbackButton} onPress={() => setFeedbackModalVisible(true)}>
          <Text style={styles.feedbackButtonText}>Submit Feedback</Text>
        </TouchableOpacity>
      )}

      <Modal
        visible={feedbackModalVisible}
        animationType="slide"
        transparent={true}
        onRequestClose={() => setFeedbackModalVisible(false)}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>Submit Feedback</Text>
            <TextInput
              style={styles.feedbackInput}
              placeholder="Type your feedback here..."
              value={feedbackText}
              onChangeText={setFeedbackText}
              multiline={true}
            />
            <Button title="Submit" onPress={submitFeedback} />
            <Button title="Cancel" onPress={() => setFeedbackModalVisible(false)} color="#FF6F61" />
          </View>
        </View>
      </Modal>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#ffffff',
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  result: {
    fontSize: 18,
    marginBottom: 20,
  },
  incorrectHeader: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  questionContainer: {
    marginBottom: 20,
  },
  question: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  answer: {
    fontSize: 16,
    color: 'green',
  },
  userAnswer: {
    fontSize: 16,
    color: 'red',
  },
  explanation: {
    fontSize: 16,
    color: '#555',
    marginTop: 5,
  },
  instructions: {
    fontSize: 18,
    color: '#555',
    padding: 15,
    textAlign: 'center',
    backgroundColor: '#f9f9f9',
    borderRadius: 8,
    marginBottom: 20,
  },
  googleButton: {
    alignSelf: 'center',
    paddingVertical: 10,
    paddingHorizontal: 40,
    backgroundColor: '#4285F4',
    borderRadius: 5,
    marginBottom: 20,
  },
  googleButtonText: {
    color: '#fff',
    fontSize: 18,
  },
  navButton: {
    alignSelf: 'center',
    paddingVertical: 10,
    paddingHorizontal: 40,
    backgroundColor: '#FF6F61',
    borderRadius: 5,
    marginBottom: 20,
  },
  navButtonText: {
    color: '#fff',
    fontSize: 18,
  },
  feedbackButton: {
    alignSelf: 'center',
    paddingVertical: 10,
    paddingHorizontal: 40,
    backgroundColor: '#FF6F61',
    borderRadius: 5,
    marginBottom: 20,
  },
  feedbackButtonText: {
    color: '#fff',
    fontSize: 18,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    width: '80%',
    backgroundColor: '#ffffff',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  feedbackInput: {
    width: '100%',
    height: 100,
    borderColor: '#cccccc',
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    marginBottom: 20,
    textAlignVertical: 'top',
  },
});
