import React from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, Image, Dimensions } from 'react-native';

const { width } = Dimensions.get('window'); // Get screen width to adjust layout
const isLargeScreen = width > 768; // Use large screen layout if width > 768

export default function Resources({ route, navigation }) {
  const { subPathKey, topics } = route.params;

  return (
    <ScrollView style={styles.container}>
      <View style={styles.header}>
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={styles.backButton}
        >
          <Text style={styles.backButtonText}>Back</Text>
        </TouchableOpacity>
        <Text style={styles.headerTitle}>{subPathKey}</Text>
      </View>

      {topics ? (
        <View style={styles.topicsContainer}>
          {Object.entries(topics).map(([topicKey, topicValue], index) => (
            <TouchableOpacity
              key={index}
              style={styles.topicCard}
              onPress={() => navigation.navigate('TopicsScreen', { selectedTopic: topicKey })}
            >
              <Image source={topicValue.image} style={styles.topicImage} resizeMode="cover" />
              <Text style={styles.topicTitle}>{topicValue.title}</Text>
              <Text style={styles.topicDescription}>{topicValue.description}</Text>
            </TouchableOpacity>
          ))}
        </View>
      ) : (
        <View style={styles.noResourcesContainer}>
          <Text style={styles.noResourcesText}>Currently, no resources are available for this career path.</Text>
        </View>
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F1EEE6', // Light background color
  },
  header: {
    width: '100%',
    paddingVertical: 15, // Vertical padding for spacing
    paddingHorizontal: 20, // Horizontal padding for space between back button and title
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  },
  backButton: {
    position: 'absolute',
    left: 10,  // Set the left to 0 so it takes full horizontal space
    paddingHorizontal: 20, // Increase padding to make button fully clickable
    paddingVertical: 10,
    backgroundColor: '#007BFF', // Blue background for back button
    borderRadius: 5,
    zIndex: 10,  // Ensure the back button is always on top
  },
  backButtonText: {
    color: '#FFFFFF', // White text for the back button
    fontSize: 16,
    fontWeight: 'bold',
  },
  headerTitle: {
    fontSize: isLargeScreen?24:20,
    fontWeight: 'bold',
    color: '#333',
    maxWidth: '80%',  // Ensure the title doesn't take too much space and interfere with the button
    textAlign: 'center',
  },
  topicsContainer: {
    marginTop: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingHorizontal: isLargeScreen ? 20 : 10, // Adjust padding for large screens
  },
  topicCard: {
    width: isLargeScreen ? '45%' : '100%', // Full width for small screens, 45% for large screens
    backgroundColor: '#ffffff', // White card background
    borderRadius: 10,
    marginVertical: 10, // Vertical margin between cards
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#dddddd', // Light gray border for the card
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 5,
    elevation: 3,
  },
  topicImage: {
    width: '100%', // Ensure image takes full width of the card
    height: 150, // Increased height for better visual impact
    borderRadius: 10,
    marginBottom: 10,
  },
  topicTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#333333', // Dark gray text for the title
    textAlign: 'center',
    marginBottom: 5,
  },
  topicDescription: {
    fontSize: 20,
    color: '#666666', // Gray text for the description
    textAlign: 'center',
    paddingHorizontal: 10, // Add padding for better readability
  },
  noResourcesContainer: {
    marginTop: 50,
    alignItems: 'center',
  },
  noResourcesText: {
    fontSize: 16,
    color: '#666666', // Gray text for no resources message
  },
});

