import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, TouchableOpacity, StyleSheet, Image, Dimensions, ActivityIndicator, ScrollView, Button, Linking, Modal } from 'react-native';
import { db, auth } from '../firebaseConfig';
import { collection, query, where, orderBy, limit, startAfter, getDocs, addDoc } from 'firebase/firestore';
import { onAuthStateChanged, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import CategoryDropdown from '../components/CategoryDropdown';
import CreateJobDialog from '../components/CreateJobDialog';

const PAGE_SIZE = 10;
const { width: screenWidth } = Dimensions.get('window');
const isLargeScreen = screenWidth >= 600;

export default function JobListScreen({ navigation }) {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastDoc, setLastDoc] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [dialogShownCount, setDialogShownCount] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
        setShowLoginDialog(false);
        checkAndSetProfile(user); // Only set profile if it's missing
      } else {
        setIsLoggedIn(false);
        triggerLoginDialog();
      }
    });
    return () => unsubscribe();
  }, [dialogShownCount]);

  const triggerLoginDialog = () => {
    if (dialogShownCount < 2) {
      const timer = setTimeout(() => {
        setShowLoginDialog(true);
        setDialogShownCount((prev) => prev + 1);
      }, dialogShownCount === 0 ? 5000 : 120000); // Show after 5 seconds first, then after 2 minutes
      return () => clearTimeout(timer);
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error during Google login:', error);
    }
  };

  const checkAndSetProfile = async (user) => {
    const { uid, displayName, email, photoURL } = user;
    try {
      const userDocRef = doc(db, 'users', uid);
      const userDoc = await getDocs(userDocRef);

      if (!userDoc.exists()) {
        const newUserProfile = {
          userId: uid,
          userName: displayName,
          userEmail: email,
          userProfilePicture: photoURL,
        };
        await setDoc(userDocRef, newUserProfile); // Save default profile data in Firestore
      }
    } catch (error) {
      console.error('Error saving user profile:', error);
    }
  };

  const fetchJobs = async () => {
    setLoading(true);
    const q = query(collection(db, 'jobs'), orderBy('jobPostedDate', 'desc'), limit(PAGE_SIZE));
    const querySnapshot = await getDocs(q);
    const jobsData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setJobs(jobsData);
    setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1] || null);
    setSelectedJob(jobsData[0]);
    setLoading(false);
  };

  const fetchMoreJobs = async () => {
    if (loadingMore || !lastDoc) return;
    setLoadingMore(true);
    const q = query(
      collection(db, 'jobs'),
      orderBy('jobPostedDate', 'desc'),
      startAfter(lastDoc),
      limit(PAGE_SIZE)
    );
    const querySnapshot = await getDocs(q);
    const moreJobs = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setJobs((prevJobs) => [...prevJobs, ...moreJobs]);
    setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1] || null);
    setLoadingMore(false);
  };


  // const handleCreateJob = async (jobData) => {
  //   try {
  //     await addDoc(collection(db, 'jobs'), jobData);
  //     setCreateDialogVisible(false);
  //     fetchJobs(selectedCategory); // Refresh the job list
  //   } catch (error) {
  //     console.error("Error creating job post:", error);
  //   }
  // };

  const renderJob = ({ item }) => (
    <TouchableOpacity
      style={[
        styles.jobItem,
        selectedJob && selectedJob.id === item.id && styles.selectedJobItem,
      ]}
      onPress={() => {
        setSelectedJob(item);
        if (!isLargeScreen) {
          navigation.navigate('JobDetailScreen', { job: item });
        }
      }}
    >
      <Image source={{ uri: item.companyLogo }} style={styles.logo} />
      <View style={styles.jobInfo}>
        <Text style={styles.jobTitle}>{item.jobTitle}</Text>
        <Text style={styles.companyName}>{item.companyName}</Text>
        <Text style={styles.jobText}>{item.jobType}</Text>
        <Text style={styles.jobText}>{item.jobLocation}</Text>
        <Text style={styles.jobText}>{item.jobExperience}</Text>
        <Text style={styles.jobSalary}>{item.jobSalary}</Text>
      </View>
    </TouchableOpacity>
  );

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Image source={require('../assets/hamtiv-logo.png')} style={styles.logoHeader} />
        <Text style={styles.headerTitle}>HamTiv</Text>
        <Text style={styles.headerSubtitle}>Explore Jobs</Text>
      </View>
        {loading ? (
        <ActivityIndicator size="large" color="#ff6347" />
        ) : (
          <View style={styles.content}>
        <ScrollView style={isLargeScreen ? styles.jobListPaneLarge : styles.jobListPane}>
          <FlatList
            data={jobs}
            renderItem={renderJob}
            keyExtractor={(item) => item.id}
            onEndReached={fetchMoreJobs}
            onEndReachedThreshold={0.5}
            ListFooterComponent={
              loadingMore ? <ActivityIndicator size="small" color="#ff6347" /> : null
            }
          />
        </ScrollView>
        {isLargeScreen && selectedJob && (
          <ScrollView style={styles.jobDetailPane}>
            <Text style={styles.detailTitle}>{selectedJob.jobTitle}</Text>
            <Text style={styles.detailCompany}>{selectedJob.companyName}</Text>
            <Text style={styles.detailLocation}>{selectedJob.jobLocation}</Text>
            <Text style={styles.detailType}>{selectedJob.jobType}</Text>
            <Text style={styles.detailExperience}>{selectedJob.jobExperience}</Text>
            <Text style={styles.detailSalary}>{selectedJob.jobSalary}</Text>
            <Text style={styles.detailDescription}>{selectedJob.jobDescription}</Text>
            <TouchableOpacity
              style={styles.applyButton}
              onPress={() => {
                if (selectedJob && selectedJob.jobLink) {
                  // Open job link
                  Linking.openURL(selectedJob.jobLink);
                }
              }}
            >
              <Text style={styles.applyButtonText}>Apply Now</Text>
            </TouchableOpacity>
          </ScrollView>
        )}
        </View>
        )}
      {/* <CreateJobDialog
        visible={isCreateDialogVisible}
        onClose={() => setCreateDialogVisible(false)}
        onSave={handleCreateJob}
      /> */}

      {/* Login Dialog */}
      <Modal visible={!isLoggedIn && showLoginDialog} transparent={true} animationType="fade">
        <View style={styles.dialogBox}>
          <Text style={styles.dialogText}>Please log in to explore full job details.</Text>
          <TouchableOpacity onPress={handleGoogleLogin}>
            <Image style={styles.googleLogin} source={require('../assets/google-sign-in.png')} />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setShowLoginDialog(false)}>
            <Text style={styles.dismissText}>Dismiss</Text>
          </TouchableOpacity>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#1e1e1e', // Dark background
  },
  header: {
    backgroundColor: '#272727',
    padding: 20,
    alignItems: 'center',
    borderRadius: 10,
  },
  logoHeader: {
    width: 100,
    height: 100,
    resizeMode: 'contain',
    marginBottom: 15,
  },
  headerTitle: {
    fontSize: 44,
    color: '#f9d342', // Gold color for the title
    fontWeight: 'bold',
    marginLeft: 10,
  },
  headerSubtitle: {
    fontSize: 28,
    color: '#bdc3c7', // Lighter gray for the subtitle
  },
  dropdownContainer: {
    padding: 10,
    backgroundColor: '#272727',
    borderBottomWidth: 1,
    borderColor: '#ddd',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  categoryDropdown: {
    flex: 1,
    height: 40,
    marginRight: 10,
    color: '#fff', // White text for dropdown
  },
  createJobButton: {
    backgroundColor: '#FF5722', // Vibrant orange button
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 5,
    marginLeft: 10,
  },
  createJobButtonText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  content: {
    flex: 1,
    flexDirection: isLargeScreen ? 'row' : 'column',
    backgroundColor: '#1e1e1e', // Dark background for content area
  },
  jobListPane: {
    flex: 1,
    backgroundColor: '#1e1e1e',
  },
  jobListPaneLarge: {
    flex: 1,
    backgroundColor: '#1e1e1e',
    borderRightWidth: 1,
    borderColor: '#ddd',
  },
  jobDetailPane: {
    flex: 2,
    padding: 20,
    backgroundColor: '#272727',
    paddingBottom: 100, // Add padding to prevent overlap with the Apply button
  },
  jobItem: {
    flexDirection: 'row',
    padding: 15,
    marginVertical: 5,
    backgroundColor: '#272727',
    borderBottomWidth: 1,
    borderColor: '#ddd',
    borderRadius: 8,
    marginHorizontal: 10,
  },
  selectedJobItem: {
    backgroundColor: '#333333',
  },
  logo: {
    width: 60,
    height: 60,
    borderRadius: 30,
    marginRight: 15,
    resizeMode: 'contain',
  },
  jobInfo: {
    flex: 1,
  },
  jobTitle: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#f9d342', // Gold text color
  },
  companyName: {
    fontSize: 14,
    color: '#ffffff', // White text color
  },
  jobText: {
    fontSize: 14,
    color: '#ffffff', // White text color
  },
  jobSalary: {
    marginTop: 5,
    color: '#ff6347', // Bright red for salary
    fontWeight: 'bold',
  },
  authContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1e1e1e', // Dark background
  },
  authText: {
    fontSize: 22,
    marginBottom: 20,
    color: "#f9d342", // Gold text color
  },
  googleButton: {
    backgroundColor: '#4285F4',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
  },
  googleButtonText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 22,
  },
  detailTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#f9d342', // Gold text color
  },
  detailCompany: {
    fontSize: 18,
    color: '#bdc3c7', // Light gray for company name
    marginBottom: 5,
  },
  detailLocation: {
    fontSize: 16,
    color: '#bdc3c7',
    marginBottom: 5,
  },
  detailType: {
    fontSize: 16,
    color: '#bdc3c7',
    marginBottom: 5,
  },
  detailExperience: {
    fontSize: 16,
    color: '#bdc3c7',
    marginBottom: 5,
  },
  detailSalary: {
    fontSize: 16,
    color: '#bdc3c7',
    marginBottom: 10,
  },
  detailDescription: {
    fontSize: 16,
    color: '#bdc3c7',
    lineHeight: 24,
  },
  applyButton: {
    position: 'absolute',
    bottom: -70,
    left: 20,
    right: 20,
    backgroundColor: '#FF5722', // Bright orange button
    paddingVertical: 15,
    borderRadius: 5,
    alignItems: 'center',
  },
  applyButtonText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1e1e1e', // Dark background
  },
  errorText: {
    fontSize: 18,
    color: 'red', // Red for error messages
  },
  dialogBox: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  dialogText: {
    color: '#f9d342',
    fontSize: 22,
    marginBottom: 20,
    textAlign: 'center',
  },
  googleLogin: {
    width: 300,
    height: 70,
    resizeMode: 'contain',
    marginBottom: 20,
  },
  dismissText: {
    color: '#f9d342',
    fontSize: 18,
    marginTop: 10,
  },
});
