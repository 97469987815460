import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, FlatList, Image } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';

const PostDetailScreen = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { post } = route.params;

  const handlePostClick = (subPost) => {
    navigation.push('Post Detail', { post: subPost });
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
          <Text style={styles.backText}>Back</Text>
        </TouchableOpacity>
        <View style={styles.userInfo}>
          <Image source={{ uri: post.userImage || 'https://cf-images.us-east-1.prod.boltdns.net/v1/static/5359769168001/0a823cb0-01a9-4835-a348-c64187783ccb/d37cb96c-805c-4aa2-9f2f-e62d9eb814c7/1280x720/match/image.jpg' }} style={styles.userImage} />
          <Text style={styles.userName}>John Doe</Text>
        </View>
      </View>

      <View style={styles.contentContainer}>
        <Text style={styles.postContent}>{post.content}</Text>
        {post.responses && post.responses.length > 0 && (
          <FlatList
            data={post.responses}
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => (
              <TouchableOpacity onPress={() => handlePostClick(item)} style={styles.subPostItem}>
                <Text style={styles.subPostText}>{item.content}</Text>
              </TouchableOpacity>
            )}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%', // 100% width of the screen
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#f8f8f8',
  },
  backButton: {
    marginRight: 10,
  },
  backText: {
    fontSize: 16,
    color: '#007BFF',
  },
  userInfo: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  userImage: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 10,
  },
  userName: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  contentContainer: {
    flex: 1,
    padding: 20,
  },
  postContent: {
    fontSize: 18,
    marginBottom: 20,
  },
  subPostItem: {
    padding: 10,
    backgroundColor: '#eee',
    marginBottom: 10,
  },
  subPostText: {
    fontSize: 16,
  },
});

export default PostDetailScreen;
