import React from 'react';
import { View, Text, StyleSheet, ScrollView, Dimensions, TouchableOpacity } from 'react-native';

const CareerDetailScreen = ({ route, navigation }) => {
  const { career } = route.params;
  const screenWidth = Dimensions.get('window').width;

  // Combine roadmap steps from short-term, medium-term, and long-term
  const roadmapSteps = [
    ...career.roadmap.shortTerm.map((step) => ({ ...step, type: 'Short-Term' })),
    ...career.roadmap.mediumTerm.map((step) => ({ ...step, type: 'Medium-Term' })),
    ...career.roadmap.longTerm.map((step) => ({ ...step, type: 'Long-Term' })),
  ];

  // Data for different cards
  const cardData = [
    {
      title: career.title,
      content: (
        <>
          <Text style={styles.roadmapSectionTitle}>Favorite Subjects:</Text>
          {career.favoriteSubjects.map((subject, index) => (
            <Text key={index} style={styles.favoriteItem}>{subject}</Text>
          ))}

          <Text style={styles.roadmapSectionTitle}>Favorite Hobbies:</Text>
          {career.favoriteHobbies.map((hobby, index) => (
            <Text key={index} style={styles.favoriteItem}>{hobby}</Text>
          ))}

          {/* Degree, Stream, Branch in one line */}
          <Text style={styles.singleLineInfo}>
            <Text style={styles.roadmapSectionTitle}>Degree: </Text>
            <Text style={styles.favoriteItem}>{career.degree}</Text> | 
            <Text style={styles.roadmapSectionTitle}> Stream: </Text>
            <Text style={styles.favoriteItem}>{career.stream}</Text> | 
            <Text style={styles.roadmapSectionTitle}> Branch: </Text>
            <Text style={styles.favoriteItem}>{career.branch}</Text>
          </Text>
        </>
      ),
    },
    // Map each step in the roadmap to individual cards
    ...roadmapSteps.map((step, index) => ({
      title: `${step.type}`,
      subTitle: `Step ${index + 1}: ${step.sectionTitle}`,
      content: (
        <View key={index} style={styles.stepItem}>
          <Text style={styles.stepContent}>
            {step.content.split('. ').map((sentence, i) => (
              <Text key={i}>{sentence.trim()}. {'\n\n'}</Text>
            ))}
          </Text>
        </View>
      ),
    })),
  ];

  return (
    <ScrollView style={styles.container}>
      <View style={styles.header}>
        <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
          <Text style={styles.backButtonText}>Back</Text>
        </TouchableOpacity>
        <Text style={styles.headerTitle}>Career Path Details</Text>
      </View>

      <View style={screenWidth >= 768 ? styles.gridContainer : styles.verticalContainer}>
        {cardData.map((card, index) => (
          <View
            key={index}
            style={screenWidth >= 768 ? styles.cardLarge : styles.card}
          >
            <Text style={styles.detailTitle}>{card.title}</Text>
            {card.subTitle && <Text style={styles.subTitle}>{card.subTitle}</Text>}
            <View style={styles.cardContent}>{card.content}</View>
          </View>
        ))}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F1EEE6', // Light cream background
  },
  header: {
    padding: 20,
    backgroundColor: '#ffffff', // White header
    borderBottomWidth: 1,
    borderBottomColor: '#dddddd',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative', // Needed for positioning the back button
  },
  backButton: {
    position: 'absolute',
    left: 10,
    top: 20, // Adjusting position for better visibility
    padding: 10,
    backgroundColor: '#007BFF', // Blue background
    borderRadius: 5,
  },
  backButtonText: {
    color: '#ffffff', // White text for the back button
    fontSize: 16,
    fontWeight: 'bold',
  },
  headerTitle: {
    fontSize: 32, // Larger for readability
    color: '#333333',
    fontWeight: 'bold',
  },
  verticalContainer: {
    padding: 20,
    alignItems: 'center',
    marginTop: 20, // Added top margin
  },
  gridContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingBottom: 20,
    marginTop: 40, // Added top margin for grid view
  },
  card: {
    backgroundColor: '#ffffff',
    borderRadius: 10,
    marginBottom: 20,
    padding: 20, // Reduced padding for smaller card height
    width: '90%',
    maxWidth: 700,
    borderColor: '#000000', // Black border around the cards
    borderWidth: 2,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 10,
    elevation: 4,
  },
  cardLarge: {
    backgroundColor: '#ffffff',
    borderRadius: 10,
    padding: 20,
    marginBottom: 30,
    width: '48%', // Two cards per row in grid
    borderColor: '#000000', // Black border around the cards
    borderWidth: 2,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 10,
    elevation: 4,
  },
  detailTitle: {
    fontSize: 26, // Smaller font size to match the card height
    color: '#333333',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  subTitle: {
    fontSize: 22,
    color: '#666666',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
  },
  cardContent: {
    marginBottom: 10,
  },
  roadmapSectionTitle: {
    fontSize: 20,
    color: '#007BFF',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  stepItem: {
    marginBottom: 10,
  },
  stepContent: {
    fontSize: 18,
    color: '#333333',
  },
  favoriteItem: {
    fontSize: 16,
    color: '#666666',
    marginBottom: 5,
  },
  singleLineInfo: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontSize: 18,
    color: '#666666',
    marginBottom: 10,
    textAlign: 'center',
  },
});

export default CareerDetailScreen;
