// CreateJobDialog.js
import React, { useState } from 'react';
import { View, Text, Modal, TextInput, Button, StyleSheet, ScrollView } from 'react-native';
import CategoryDropdown from './CategoryDropdown'; // Import CategoryDropdown

export default function CreateJobDialog({ visible, onClose, onSave }) {
  const [jobData, setJobData] = useState({
    jobTitle: '',
    companyName: '',
    companyLogo: '',
    jobType: '',
    jobLocation: '',
    jobExperience: '',
    jobSalary: '',
    jobDescription: '',
    jobCategory: '',
    jobSubCategory: '',
  });

  const handleChange = (field, value) => {
    setJobData({ ...jobData, [field]: value });
  };

  const handleSave = () => {

    const jobPostedDateEpoch = Math.floor(new Date().getTime());
    onSave({ ...jobData, jobPostedDate: jobPostedDateEpoch });
    setJobData({
      jobTitle: '',
      companyName: '',
      companyLogo: '',
      jobType: '',
      jobLocation: '',
      jobExperience: '',
      jobSalary: '',
      jobDescription: '',
      jobCategory: '',
      jobSubCategory: '',
    });
  };

  return (
    <Modal visible={visible} animationType="slide">
      <ScrollView contentContainerStyle={styles.container}>
        <Text style={styles.title}>Create Job Post</Text>
        <TextInput
          style={styles.input}
          value={jobData.jobTitle}
          onChangeText={(value) => handleChange('jobTitle', value)}
          placeholder="Job Title"
        />
        <TextInput
          style={styles.input}
          value={jobData.companyName}
          onChangeText={(value) => handleChange('companyName', value)}
          placeholder="Company Name"
        />
        <TextInput
          style={styles.input}
          value={jobData.companyLogo}
          onChangeText={(value) => handleChange('companyLogo', value)}
          placeholder="Company Logo URL"
        />
        <TextInput
          style={styles.input}
          value={jobData.jobType}
          onChangeText={(value) => handleChange('jobType', value)}
          placeholder="Job Type - Ex. Full Time | On-Site | Entry Level"
        />
        <TextInput
          style={styles.input}
          value={jobData.jobLocation}
          onChangeText={(value) => handleChange('jobLocation', value)}
          placeholder="Job Location"
        />
        <TextInput
          style={styles.input}
          value={jobData.jobExperience}
          onChangeText={(value) => handleChange('jobExperience', value)}
          placeholder="Job Experience"
        />
        <TextInput
          style={styles.input}
          value={jobData.jobSalary}
          onChangeText={(value) => handleChange('jobSalary', value)}
          placeholder="Job Salary"
        />
        <TextInput
          style={[styles.input, styles.textArea]}
          value={jobData.jobDescription}
          onChangeText={(value) => handleChange('jobDescription', value)}
          placeholder="Job Description"
          multiline
        />
        <CategoryDropdown
          selectedCategory={jobData.jobCategory}
          onCategoryChange={(value) => handleChange('jobCategory', value)}
          onSubCategoryChange={(value) => handleChange('jobSubCategory', value)}
          isCreatingJobPost={true}
        />
        <View style={styles.buttonContainer}>
          <Button title="Save" onPress={handleSave} color="#ff6347" />
          <Button title="Cancel" onPress={onClose} color="#333" />
        </View>
      </ScrollView>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f8f9fa',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 20,
    color: '#333',
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
    width: '100%',
  },
  textArea: {
    height: 100, // Adjustable height for text area
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20,
  },
});
