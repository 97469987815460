import React from 'react';
import { View, Text, FlatList, Image, StyleSheet, TouchableOpacity } from 'react-native';
import Header from '../components/Header';

const hubs = [
  {
    hubId: '1',
    hubTitle: 'General Topic',
    hubDescription: 'Discussions on general topics related to your Category and Subcategory',
    hubImage: 'https://blog.ibsindia.org/wp-content/uploads/2023/05/bgg-8.jpg',
  },
  {
    hubId: '2',
    hubTitle: 'Specific Topic Discussion',
    hubDescription: 'In Depth Discussions, Debates, case studies and theoretical explanations on specific topics',
    hubImage: 'https://www.proclinical.com/img/7qBKhMMiXUdakgjVfaJzHA/How+to+get+a+validation+engineer+job.jpg?v=e896583613ce28c657f520cf2aaea672',
  },
  {
    hubId: '3',
    hubTitle: 'Validate Career Options',
    hubDescription: 'Discussions on the viability and prospects of different career paths.',
    hubImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKrkuXsLyuI8op_Pj5LEtrYkYOLZgyzwzboA&s',
  },
  // Add other hubs as needed...
];

const HubsScreen = ({ navigation }) => {
  const renderItem = ({ item }) => (
    <TouchableOpacity style={styles.hubItem}>
      <Image source={{ uri: item.hubImage }} style={styles.hubImage} />
      <View style={styles.hubTextContainer}>
        <Text style={styles.hubTitle}>{item.hubTitle}</Text>
        <Text style={styles.hubDescription}>{item.hubDescription}</Text>
      </View>
    </TouchableOpacity>
  );

  return (
    <View style={styles.container}>
        <Header/>
      <FlatList
        data={hubs}
        renderItem={renderItem}
        keyExtractor={(item) => item.hubId}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: '#f8f8f8',
  },
  hubItem: {
    flexDirection: 'row',
    marginBottom: 10,
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 10,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 8,
    elevation: 3,
  },
  hubImage: {
    width: 50,
    height: 50,
    borderRadius: 10,
  },
  hubTextContainer: {
    marginLeft: 10,
    flex: 1,
  },
  hubTitle: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  hubDescription: {
    color: '#666',
    marginTop: 5,
  },
});

export default HubsScreen;
