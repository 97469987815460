export const reportData = {
    "name": "Assumed to be in 12th grade",
    "date": "Current Date",
    "sections": [
        {
            "headline": "Personality Assessment Overview",
            "content": "Your personality assessment reveals a balanced and adaptable nature, with a preference for both social interaction and independent thinking.",
            "subsections": [
                {
                    "subheadline": "Extraversion vs. Introversion",
                    "points": [
                        "You lean towards being more extroverted, enjoying time spent with others, particularly in familiar settings with friends and family.",
                        "However, you also value alone time, especially when engaging in interesting work."
                    ]
                },
                {
                    "subheadline": "Sensing vs. Intuition",
                    "points": [
                        "You are a strong intuitive thinker, preferring to focus on the big picture and possibilities rather than getting bogged down by details.",
                        "You rely heavily on your imagination and gut feelings when making decisions."
                    ]
                },
                {
                    "subheadline": "Thinking vs. Feeling",
                    "points": [
                        "While you strive to be logical and objective, your decisions are often guided by personal values and a concern for the well-being of others.",
                        "In conflicts, you prioritize fairness and justice, but you also consider the impact on people’s feelings."
                    ]
                },
                {
                    "subheadline": "Judging vs. Perceiving",
                    "points": [
                        "You value having a structured plan but remain flexible and open to changes, especially when unexpected situations arise.",
                        "You prefer to finish tasks before deadlines but acknowledge challenges with procrastination."
                    ]
                }
            ]
        },
        {
            "headline": "Holland Code (RIASEC) Assessment",
            "content": "Your interests span a range of activities that align with the following Holland Code categories:",
            "subsections": [
                {
                    "subheadline": "Investigative (I)",
                    "points": [
                        "You have a strong interest in research, analysis, and problem-solving.",
                        "You enjoy tasks that involve understanding complex issues, conducting experiments, and exploring new ideas."
                    ]
                },
                {
                    "subheadline": "Artistic (A)",
                    "points": [
                        "You have a creative side, particularly in writing, and enjoy engaging in imaginative and innovative activities.",
                        "While you’re not inclined towards visual arts, your love for writing and storytelling highlights your artistic capabilities."
                    ]
                },
                {
                    "subheadline": "Social (S)",
                    "points": [
                        "You are motivated by helping others and making a positive impact on their lives.",
                        "Teaching, community work, and leading others are activities you enjoy, which indicates a strong inclination toward social interactions."
                    ]
                },
                {
                    "subheadline": "Enterprising (E)",
                    "points": [
                        "You have a strong interest in leadership, business, and entrepreneurship.",
                        "Your desire to start your own venture and interest in politics show that you are driven to influence and manage people and situations effectively."
                    ]
                },
                {
                    "subheadline": "Conventional (C)",
                    "points": [
                        "You are comfortable with structure, organization, and working within established procedures.",
                        "This trait supports your interest in planning and working with data, which are crucial in business and management roles."
                    ]
                }
            ]
        },
        {
            "headline": "Strengths and Skills Assessment",
            "content": "Your strengths and skills reflect your varied interests and abilities:",
            "subsections": [
                {
                    "subheadline": "Analytical Skills",
                    "points": [
                        "You are very comfortable with analyzing data and solving problems, although identifying patterns and trends doesn’t always come easily to you.",
                        "This is a valuable skill set for fields like data science, research, and technology."
                    ]
                },
                {
                    "subheadline": "Creative Thinking",
                    "points": [
                        "You frequently come up with original ideas and enjoy activities that require imagination and innovation.",
                        "This makes you well-suited for careers in writing, storytelling, and creative problem-solving."
                    ]
                },
                {
                    "subheadline": "Communication Skills",
                    "points": [
                        "While you’re good at expressing your ideas, there’s room for improvement in perfecting these skills.",
                        "You enjoy engaging in discussions, which is a strong foundation for roles in teaching, public speaking, or leadership."
                    ]
                },
                {
                    "subheadline": "Leadership and Management",
                    "points": [
                        "Your experience in leadership roles during school and college demonstrates your comfort in guiding others and making decisions.",
                        "This aligns well with your interest in business and entrepreneurship."
                    ]
                },
                {
                    "subheadline": "Technical Skills",
                    "points": [
                        "Your proficiency in technology and coding is a significant asset, especially in today’s tech-driven world.",
                        "You enjoy learning new technical skills, which is crucial for staying relevant in fields like IT, software development, and engineering."
                    ]
                },
                {
                    "subheadline": "Interpersonal Skills",
                    "points": [
                        "You work well in teams and handle conflicts with a calm and thoughtful approach.",
                        "This, combined with your enjoyment of helping others, suggests that you could excel in roles that require collaboration and conflict resolution."
                    ]
                }
            ]
        },
        {
            "headline": "Interests and Career Aspirations",
            "content": "",
            "subsections": [
                {
                    "subheadline": "Fulfilling Tasks",
                    "points": [
                        "You find fulfillment in solving problems, helping others, and creating something new.",
                        "This combination of interests is ideal for roles that require both creativity and analytical thinking, such as entrepreneurship, consulting, or project management."
                    ]
                },
                {
                    "subheadline": "Fascinated Industries",
                    "points": [
                        "You are particularly drawn to Education, Technology, Electric Vehicles, and Social Networks.",
                        "These industries are dynamic and align with your passion for making a societal impact through innovative solutions."
                    ]
                },
                {
                    "subheadline": "Long-Term Vision",
                    "points": [
                        "While you haven’t thought much about where you see yourself in 10 years, your current focus on gaining work experience suggests you’re open to exploring various career paths before settling on a long-term goal."
                    ]
                },
                {
                    "subheadline": "Career Priorities",
                    "points": [
                        "Making an impact on society is your top priority, which indicates a desire to contribute positively to the world.",
                        "This aligns well with careers in education, social entrepreneurship, or roles in emerging technologies that address global challenges."
                    ]
                }
            ]
        },
        {
            "headline": "Personal and Lifestyle Considerations",
            "content": "",
            "subsections": [
                {
                    "subheadline": "Work-Life Balance",
                    "points": [
                        "At this stage in your career, work-life balance isn’t a top priority, which is common when building a career.",
                        "However, you prefer flexibility in your work hours, provided the work is meaningful and rewarding."
                    ]
                },
                {
                    "subheadline": "Relocation",
                    "points": [
                        "You are open to relocating for the right career opportunity, indicating flexibility and a willingness to pursue opportunities wherever they arise."
                    ]
                },
                {
                    "subheadline": "Financial Considerations",
                    "points": [
                        "While financial stability is important, it’s not your primary focus.",
                        "You are considering the cost of education and its return on investment, which is a practical approach to career planning."
                    ]
                }
            ]
        },
        {
            "headline": "Psychological and Emotional Factors",
            "content": "",
            "subsections": [
                {
                    "subheadline": "Stress Management",
                    "points": [
                        "You handle stress by working through it methodically, breaking tasks into manageable parts.",
                        "This proactive approach is effective in maintaining focus under pressure."
                    ]
                },
                {
                    "subheadline": "Motivation",
                    "points": [
                        "You are driven by passion and recognition, suggesting that you thrive in environments where your efforts are acknowledged and appreciated."
                    ]
                },
                {
                    "subheadline": "Decision-Making",
                    "points": [
                        "You’re not entirely confident in making decisions about your future career due to a lack of awareness about opportunities and your strengths.",
                        "However, you prefer to rely on your judgment after seeking advice, which indicates a thoughtful decision-making process."
                    ]
                }
            ]
        },
        {
            "headline": "Backup Plans and Flexibility",
            "content": "",
            "subsections": [
                {
                    "subheadline": "Backup Plans",
                    "points": [
                        "Currently, you don’t have a backup plan, but you are open to exploring alternative career paths if they align with your previous work.",
                        "This openness to new opportunities is a positive trait that will serve you well in a dynamic career landscape."
                    ]
                },
                {
                    "subheadline": "Adaptability",
                    "points": [
                        "You handle change and unexpected challenges with a calm and measured approach.",
                        "Your willingness to adapt your plans for better opportunities is crucial for long-term career success."
                    ]
                }
            ]
        },
        {
            "headline": "Hobbies and Interests",
            "content": "",
            "subsections": [
                {
                    "subheadline": "Preferred Activities",
                    "points": [
                        "You enjoy writing or storytelling, solving puzzles, helping others, and engaging in conversations.",
                        "These interests complement your analytical and creative skills, suggesting that careers involving communication, problem-solving, and social interaction would be fulfilling."
                    ]
                },
                {
                    "subheadline": "Favorite Subjects",
                    "points": [
                        "You are interested in Science and Technology, Mathematics and Logic, Business and Entrepreneurship, and Psychology.",
                        "These subjects point towards a career path that blends technical expertise with business acumen and a deep understanding of human behavior."
                    ]
                }
            ]
        },
        {
            "headline": "Career Recommendations with Roadmap",
            "content": "Based on your personality traits, interests, strengths, and aspirations, here are some career paths that align well with your profile:",
            "subsections": [
                {
                    "subheadline": "1. Entrepreneurship in Technology",
                    "points": [
                        "Why: Your interest in technology, business, and making a societal impact makes entrepreneurship in the tech sector an ideal path.",
                        "You enjoy leading and managing projects, and you have the technical skills to innovate in fields like software development, electric vehicles, or social networks.",
                        "Roadmap:",
                        "Short-Term: Pursue internships or part-time jobs in tech startups to gain hands-on experience.",
                        "Medium-Term: Consider a degree in Computer Science, Information Technology, or Engineering, coupled with courses in Business Management or Entrepreneurship.",
                        "Long-Term: Launch your own tech startup, possibly in an area like education technology, electric vehicles, or social networking platforms that address societal needs."
                    ]
                },
                {
                    "subheadline": "2. Data Science and Analysis",
                    "points": [
                        "Why: Your strong analytical skills, combined with your interest in research and problem-solving, make data science a fitting career.",
                        "This field requires the ability to analyze complex data, identify trends, and make data-driven decisions.",
                        "Roadmap:",
                        "Short-Term: Begin with online courses or certifications in data science, statistics, and programming languages like Python.",
                        "Medium-Term: Obtain a degree in Data Science, Computer Science, or a related field. Engage in research projects or internships that focus on data analysis.",
                        "Long-Term: Aim for roles like Data Scientist, Data Analyst, or Machine Learning Engineer. Consider advancing to leadership roles in data-driven decision-making."
                    ]
                },
                {
                    "subheadline": "3. Educational Technology (EdTech) Specialist",
                    "points": [
                        "Why: Your interest in education, technology, and helping others aligns well with a career in EdTech.",
                        "This field involves developing and implementing technology solutions that enhance learning experiences.",
                        "Roadmap:",
                        "Short-Term: Gain experience through volunteering, internships, or part-time work in educational institutions or EdTech companies.",
                        "Medium-Term: Pursue a degree in Education, Educational Technology, or Instructional Design. Combine this with technical courses in software development.",
                        "Long-Term: Work as an EdTech consultant, instructional designer, or product manager in an EdTech company. Eventually, you could start your own venture focused on innovative educational tools."
                    ]
                },
                {
                    "subheadline": "4. Business Consulting",
                    "points": [
                        "Why: Your leadership skills, interest in business and entrepreneurship, and ability to analyze and solve problems make business consulting a strong fit.",
                        "This career allows you to work with diverse clients, helping them to optimize their operations and strategies.",
                        "Roadmap:",
                        "Short-Term: Gain experience in a business environment through internships or entry-level positions in consulting firms.",
                        "Medium-Term: Obtain a degree in Business Administration, Management, or a related field. Consider an MBA for advanced roles.",
                        "Long-Term: Work as a consultant, focusing on industries like technology, education, or emerging markets. Build expertise in a niche area, such as sustainability or digital transformation."
                    ]
                },
                {
                    "subheadline": "5. Psychology and Counseling",
                    "points": [
                        "Why: Your interest in psychology, combined with your ability to connect with people and help them, suggests a career in psychology or counseling.",
                        "This field allows you to make a direct impact on individuals' lives through support and guidance.",
                        "Roadmap:",
                        "Short-Term: Volunteer or work in settings where you can interact with people, such as community centers, schools, or non-profits.",
                        "Medium-Term: Pursue a degree in Psychology, Counseling, or Social Work. Engage in internships or practicum experiences that provide hands-on counseling practice.",
                        "Long-Term: Aim to become a licensed counselor, therapist, or psychologist. Consider specializing in areas like educational psychology, career counseling, or organizational psychology."
                    ]
                }
            ]
        },
        {
            "headline": "Final Thoughts",
            "content": "Your diverse interests and strong skills in both analytical and creative areas open up a wide range of career possibilities. While you are still exploring your long-term goals, focusing on gaining experience in areas that resonate with your interests will help you refine your career direction. Keep building on your strengths, stay open to new opportunities, and continue seeking advice and mentorship to guide your decisions.",
            "subsections": []
        },
        {
            "headline": "Next Steps",
            "content": "",
            "subsections": [
                {
                    "subheadline": "Explore Short-Term Opportunities",
                    "points": [
                        "Start with internships, online courses, or part-time jobs in your areas of interest."
                    ]
                },
                {
                    "subheadline": "Seek Guidance",
                    "points": [
                        "Continue discussing your career aspirations with mentors, teachers, or career counselors to gain more clarity."
                    ]
                },
                {
                    "subheadline": "Set Achievable Goals",
                    "points": [
                        "Break down your long-term aspirations into smaller, actionable goals that you can work towards in the coming years."
                    ]
                }
            ]
        }
    ]
};

export const reportOceanography = {
    "title": "Personalized Career Roadmap Based on Your Interest in Oceanography",
    "name": "Example Data",
    "date": "02/09/2024",
    "sections": [
        {
            "headline": "1. Overview of Interests and Potential Careers",
            "content": "Oceanography is the study of the physical, chemical, biological, and geological aspects of the ocean. It is a multidisciplinary field that offers a range of exciting career opportunities for those interested in marine environments and the science behind them. If you have a passion for the ocean and marine life, here are some potential career pathways that align with your interest in oceanography:",
            "subsections": [
                {
                    "subheadline": "",
                    "points": [
                        "Marine Biologist",
                        "Physical Oceanographer",
                        "Marine Geologist",
                        "Chemical Oceanographer",
                        "Marine Environmental Scientist",
                        "Marine Policy and Conservation Specialist"
                    ]
                }
            ]
        },
        {
            "headline": "2. Career Pathways and Roadmap",
            "subsections": [
                {
                    "subheadline": "1. Marine Biologist",
                    "content": "Marine biology focuses on the study of marine organisms, their behaviors, and interactions with their environment. If you're fascinated by marine life and ecosystems, this career allows you to explore and protect oceanic biodiversity.",
                    "points": [
                        "Short-Term (0-2 years):\n - Education: Pursue a bachelor's degree in Marine Biology, Biology, or Environmental Science with a focus on marine ecosystems.\n - Field Experience: Participate in internships or volunteer programs with marine research organizations, aquariums, or conservation groups.\n - Skills Development: Develop skills in scuba diving, underwater photography, and GIS.",
                        "Medium-Term (3-5 years):\n - Advanced Education: Consider pursuing a master's degree in Marine Biology or a related field.\n - Research and Publication: Get involved in research projects and publish findings.\n - Networking: Attend marine biology conferences.",
                        "Long-Term (5+ years):\n - Professional Role: Aim for a position as a marine biologist with a research institution or non-profit organization.\n - Conservation and Advocacy: Work on projects aimed at conserving marine species and habitats."
                    ]
                },
                {
                    "subheadline": "2. Physical Oceanographer",
                    "content": "Physical oceanography involves studying the physical properties of the ocean, such as currents, waves, and tides. This career is ideal if you're interested in the dynamic processes that shape our oceans.",
                    "points": [
                        "Short-Term (0-2 years):\n - Education: Obtain a bachelor's degree in Oceanography, Physics, or Earth Sciences with a focus on ocean dynamics.\n - Fieldwork Experience: Participate in research cruises and data collection.\n - Technical Skills: Develop strong skills in mathematics, data analysis, and programming.",
                        "Medium-Term (3-5 years):\n - Advanced Education: Pursue a master's or Ph.D. in Physical Oceanography.\n - Research Projects: Engage in research that explores oceanic processes.\n - Collaboration: Work with interdisciplinary teams.",
                        "Long-Term (5+ years):\n - Career Advancement: Aim for a research scientist position.\n - Policy and Impact: Contribute to policy development related to climate change, sea-level rise, and coastal management."
                    ]
                },
                {
                    "subheadline": "3. Marine Geologist",
                    "content": "Marine geology focuses on the study of the Earth's ocean floors, including the processes that shape them. This career is ideal if you're interested in understanding the geological aspects of the ocean.",
                    "points": [
                        "Short-Term (0-2 years):\n - Education: Earn a bachelor's degree in Geology or Earth Sciences with a focus on marine geology.\n - Field and Lab Experience: Participate in research projects and gain experience with geophysical instruments.\n - Skills Development: Develop proficiency in GIS, remote sensing, and data interpretation.",
                        "Medium-Term (3-5 years):\n - Advanced Education: Pursue a master's or Ph.D. in Marine Geology or Geophysics.\n - Research and Publication: Contribute to geological research by publishing your findings.\n - Exploration Projects: Work on ocean exploration projects.",
                        "Long-Term (5+ years):\n - Professional Role: Aim for a career as a marine geologist with a government agency or research institution.\n - Global Impact: Contribute to global initiatives that study tectonic activity and climate change impacts."
                    ]
                },
                {
                    "subheadline": "4. Chemical Oceanographer",
                    "content": "Chemical oceanography involves studying the chemical composition of seawater and the processes that regulate its chemical properties. This career is ideal if you're interested in how chemicals interact within the ocean.",
                    "points": [
                        "Short-Term (0-2 years):\n - Education: Obtain a bachelor's degree in Chemistry, Oceanography, or Environmental Science.\n - Lab and Field Experience: Gain experience in collecting and analyzing seawater samples.\n - Skills Development: Develop skills in data analysis and chemical instrumentation.",
                        "Medium-Term (3-5 years):\n - Advanced Education: Pursue a master's or Ph.D. in Chemical Oceanography.\n - Research and Collaboration: Participate in research projects and collaborate with other scientists.\n - Networking: Attend marine science conferences and workshops.",
                        "Long-Term (5+ years):\n - Career Opportunities: Work as a chemical oceanographer for a research institution or environmental agency.\n - Global Impact: Contribute to international efforts to monitor and mitigate climate change."
                    ]
                },
                {
                    "subheadline": "5. Marine Environmental Scientist",
                    "content": "Marine environmental science focuses on understanding and addressing environmental issues that affect marine ecosystems. This career is ideal if you're passionate about protecting the ocean from threats like pollution and habitat destruction.",
                    "points": [
                        "Short-Term (0-2 years):\n - Education: Pursue a bachelor's degree in Environmental Science or Marine Biology.\n - Field Experience: Participate in environmental monitoring projects.\n - Skills Development: Develop skills in GIS and environmental impact assessment.",
                        "Medium-Term (3-5 years):\n - Advanced Education: Consider pursuing a master's degree in Marine Environmental Science.\n - Research and Advocacy: Engage in research projects and advocate for policies that protect marine environments.\n - Collaboration: Work with NGOs and government agencies.",
                        "Long-Term (5+ years):\n - Career Advancement: Aim for a leadership role in environmental management or conservation.\n - Global Conservation Efforts: Contribute to global initiatives aimed at protecting marine biodiversity."
                    ]
                },
                {
                    "subheadline": "6. Marine Policy and Conservation Specialist",
                    "content": "Marine policy and conservation specialists work at the intersection of science, policy, and advocacy to protect marine environments. This career is ideal if you're interested in influencing policy and working on conservation initiatives.",
                    "points": [
                        "Short-Term (0-2 years):\n - Education: Obtain a bachelor's degree in Environmental Policy or Marine Biology.\n - Internships and Experience: Intern with NGOs or government agencies focused on marine policy.\n - Skills Development: Develop communication and policy analysis skills.",
                        "Medium-Term (3-5 years):\n - Advanced Education: Pursue a master's degree in Marine Policy or Environmental Law.\n - Policy Development: Work on developing and implementing marine policies.\n - Networking: Build relationships with policymakers and scientists.",
                        "Long-Term (5+ years):\n - Leadership and Advocacy: Aim for leadership roles in marine conservation organizations.\n - Global Policy Impact: Contribute to the development of international agreements related to marine resource management."
                    ]
                }
            ]
        },
        {
            "headline": "3. Final Thoughts",
            "content": "Oceanography offers a wide range of career paths that allow you to explore and protect the world’s oceans. Whether you're drawn to marine biology, physical oceanography, or marine policy, your passion for the ocean can lead to a fulfilling career.",
            "subsections": [
                {
                    "subheadline": "Next Steps",
                    "points": [
                        "Pursue Relevant Education: Enroll in programs that align with your chosen career path in oceanography.",
                        "Gain Practical Experience: Engage in internships, research projects, or volunteer opportunities.",
                        "Network and Collaborate: Connect with professionals and policymakers in the field of oceanography."
                    ]
                }
            ]
        }
    ]
};

export const reportGardening = {
    "title": "Personalized Career Roadmap Based on Your Interest in Gardening",
    "name": "Example Data",
    "date": "02/09/2024",
    "sections": [
        {
            "headline": "1. Overview of Interests and Potential Careers",
            "content": "Gardening, as both a passion and a career, offers numerous pathways to explore. Whether you enjoy growing plants, creating landscapes, or working with the environment, a career related to gardening can be highly rewarding. It provides opportunities to work outdoors, contribute to environmental sustainability, and improve spaces through horticulture.",
            "subsections": [
                {
                    "subheadline": "",
                    "points": [
                        "Horticulturist",
                        "Landscape Architect",
                        "Urban Gardener or Community Garden Coordinator",
                        "Botanist",
                        "Garden Designer",
                        "Environmental Conservationist",
                        "Greenhouse Manager or Nursery Owner",
                        "Floral Designer or Floriculturist"
                    ]
                }
            ]
        },
        {
            "headline": "2. Career Pathways and Roadmap",
            "subsections": [
                {
                    "subheadline": "1. Horticulturist",
                    "content": "Horticulturists specialize in growing and maintaining plants, working in fields like crop production, landscaping, and conservation. If you're passionate about plant care and cultivation, this career offers opportunities in agriculture, research, and botanical gardens.",
                    "points": [
                        "Short-Term (0-2 years):\n - Education: Pursue a degree or certification in Horticulture, Botany, or Plant Science. Take courses in plant biology, soil science, and sustainable agriculture.\n - Practical Experience: Gain experience by working in local nurseries, farms, or botanical gardens. Learn about plant care, propagation, pest control, and plant nutrition.\n - Networking: Join horticultural societies or clubs to connect with experts and fellow enthusiasts. Attend gardening workshops or agricultural fairs.",
                        "Medium-Term (3-5 years):\n - Specialization: Consider specializing in a specific area, such as ornamental horticulture, sustainable agriculture, or organic farming.\n - Research and Development: Get involved in research projects, possibly at universities or agricultural research institutions.\n - Job Opportunities: Look for roles as a horticulturist in botanical gardens, government agencies, or commercial farms.",
                        "Long-Term (5+ years):\n - Career Advancement: Aim for leadership positions, such as managing botanical gardens, working as a senior horticulturist in large agricultural companies, or leading conservation initiatives.\n - Consulting: Offer horticultural consulting services to farms, landscaping companies, or homeowners.\n - Publications and Thought Leadership: Write books, blogs, or academic papers on horticulture."
                    ]
                },
                {
                    "subheadline": "2. Landscape Architect",
                    "content": "If you love designing outdoor spaces and integrating plants into functional, aesthetically pleasing landscapes, becoming a landscape architect is an ideal career. This field combines creativity with knowledge of plants and ecosystems.",
                    "points": [
                        "Short-Term (0-2 years):\n - Education: Pursue a degree in Landscape Architecture, Horticulture, or Environmental Design. Take courses in design, architecture, plant science, and sustainable landscaping.\n - Portfolio Building: Start building a portfolio by designing small gardens or landscape projects for friends, family, or local organizations.\n - Internships: Gain experience by working with landscaping firms, architectural companies, or local government agencies on public park or urban landscaping projects.",
                        "Medium-Term (3-5 years):\n - Licensure: Depending on your region, you may need to pass a landscape architecture licensing exam to work professionally.\n - Specialization: Focus on a particular area, such as residential landscaping, urban design, or ecological restoration.\n - Networking and Collaboration: Collaborate with architects, engineers, and environmental planners to work on large-scale projects like public parks, green roofs, or community gardens.",
                        "Long-Term (5+ years):\n - Entrepreneurship: Start your own landscape architecture firm or consultancy.\n - Leadership Roles: Lead large landscaping projects, such as urban regeneration or sustainable housing developments.\n - Innovation in Sustainable Design: Incorporate green technologies, water conservation systems, or urban farming techniques into your designs."
                    ]
                },
                {
                    "subheadline": "3. Urban Gardener or Community Garden Coordinator",
                    "content": "If you’re passionate about gardening and community work, a career as an urban gardener or community garden coordinator allows you to bring green spaces to cities, promote sustainability, and improve food security through urban agriculture.",
                    "points": [
                        "Short-Term (0-2 years):\n - Education: Study Urban Agriculture, Environmental Science, or Horticulture.\n - Volunteer Experience: Volunteer at local community gardens or urban farming initiatives.\n - Networking: Connect with local governments, environmental groups, and urban planning organizations that support urban gardening and sustainability projects.",
                        "Medium-Term (3-5 years):\n - Coordination Roles: Apply for positions as a community garden coordinator, managing garden plots, organizing volunteers, and educating the public on sustainable gardening.\n - Sustainability Projects: Lead urban gardening projects that address food security or environmental justice.\n - Outreach and Education: Teach gardening workshops and organize community events.",
                        "Long-Term (5+ years):\n - Expansion: Lead city-wide or regional urban gardening initiatives.\n - Leadership Roles: Work with nonprofits, NGOs, or government agencies on larger urban sustainability projects.\n - Public Engagement: Write about urban gardening or create content that promotes sustainable urban agriculture."
                    ]
                },
                {
                    "subheadline": "4. Botanist",
                    "content": "If you are fascinated by plant biology and ecology, becoming a botanist allows you to study plants in depth. Botanists work in research, conservation, and agriculture, focusing on understanding plant species, their habitats, and their impact on the environment.",
                    "points": [
                        "Short-Term (0-2 years):\n - Education: Pursue a degree in Botany, Plant Science, or Biology.\n - Research Projects: Participate in undergraduate research projects studying plant species or ecosystems.\n - Internships: Seek internships with botanical gardens or agricultural research centers to gain hands-on experience.",
                        "Medium-Term (3-5 years):\n - Graduate Studies: Pursue a master’s or Ph.D. in Botany or a related field.\n - Fieldwork: Engage in fieldwork to study plant species in their natural habitats.\n - Conservation Projects: Work with environmental NGOs or governmental bodies on conservation initiatives.",
                        "Long-Term (5+ years):\n - Research Leadership: Lead botanical research projects at universities, research institutions, or government agencies.\n - Publications and Thought Leadership: Publish research papers, books, or articles on botany.\n - Consulting: Offer consulting services to environmental agencies or agriculture companies."
                    ]
                },
                {
                    "subheadline": "5. Garden Designer",
                    "content": "If you enjoy combining creativity with horticulture, garden design allows you to create beautiful and functional outdoor spaces. As a garden designer, you can work with homeowners, businesses, or public spaces to plan and execute garden layouts.",
                    "points": [
                        "Short-Term (0-2 years):\n - Education: Pursue a degree or certification in Garden Design, Horticulture, or Landscape Design.\n - Hands-On Experience: Start by designing small gardens or working as an assistant for established garden designers.\n - Portfolio Building: Document your projects and create a portfolio showcasing your designs.",
                        "Medium-Term (3-5 years):\n - Client Work: Offer garden design services to homeowners, businesses, or public spaces.\n - Specialization: Consider specializing in sustainable garden design or wildlife-friendly gardens.\n - Collaborating with Contractors: Work with landscapers and contractors to bring your designs to life.",
                        "Long-Term (5+ years):\n - Establishing a Business: Start your own garden design firm or expand your client base to larger projects like public parks.\n - Teaching and Mentorship: Offer garden design workshops or mentor young designers.\n - Sustainable Innovation: Lead in developing eco-friendly garden designs."
                    ]
                },
                {
                    "subheadline": "6. Environmental Conservationist",
                    "content": "If you’re passionate about protecting natural ecosystems, a career in environmental conservation focuses on preserving biodiversity, promoting sustainability, and addressing climate change impacts on natural habitats.",
                    "points": [
                        "Short-Term (0-2 years):\n - Education: Pursue a degree in Environmental Science, Conservation Biology, or Ecology.\n - Fieldwork and Volunteering: Get involved in local conservation projects such as reforestation or habitat restoration.\n - Networking: Join environmental organizations and attend conservation conferences.",
                        "Medium-Term (3-5 years):\n - Conservation Roles: Work with environmental agencies, nonprofits, or government organizations on conservation projects.\n - Specialization: Consider specializing in wetland restoration or climate adaptation strategies.\n - Public Awareness Campaigns: Lead environmental awareness campaigns.",
                        "Long-Term (5+ years):\n - Leadership in Conservation: Lead large-scale conservation initiatives at the national or global level.\n - Consulting and Policy Development: Advise governments, corporations, or agricultural businesses on sustainable practices.\n - Innovation in Conservation: Research and implement new methods of conservation."
                    ]
                },
                {
                    "subheadline": "7. Greenhouse Manager or Nursery Owner",
                    "content": "If you’re interested in growing plants on a larger scale, managing a greenhouse or owning a nursery allows you to cultivate plants and sell them to the public, landscapers, or businesses. This career combines plant care with business management.",
                    "points": [
                        "Short-Term (0-2 years):\n - Education and Certification: Study Horticulture, Greenhouse Management, or Agricultural Science.\n - Experience: Work in a local nursery or greenhouse to gain hands-on experience.\n - Networking: Connect with other nursery owners and plant suppliers.",
                        "Medium-Term (3-5 years):\n - Management Roles: Take on a management role in an established greenhouse or nursery.\n - Specialization: Decide whether to specialize in ornamental plants, native species, or edible plants.\n - Brand Development: Build a reputation for your nursery by offering high-quality plants.",
                        "Long-Term (5+ years):\n - Business Ownership: Start your own greenhouse or nursery.\n - Expansion and Innovation: Expand your business by offering landscaping services or developing an online plant store.\n - Leadership and Mentorship: Mentor other aspiring greenhouse managers or nursery owners."
                    ]
                },
                {
                    "subheadline": "8. Floral Designer or Floriculturist",
                    "content": "If you enjoy working with flowers and creating beautiful arrangements, a career as a floral designer or floriculturist might be perfect. Floriculture focuses on growing flowers, while floral design involves arranging them for events and businesses.",
                    "points": [
                        "Short-Term (0-2 years):\n - Education: Pursue a certification or courses in Floral Design or Floriculture.\n - Experience: Start by working as an assistant at a floral shop or greenhouse specializing in floriculture.\n - Portfolio Building: Document your floral arrangements and create a portfolio showcasing your work.",
                        "Medium-Term (3-5 years):\n - Specialization: Consider specializing in wedding florals, corporate events, or sustainable floral arrangements.\n - Freelancing and Entrepreneurship: Start offering freelance floral design services or begin growing your own flowers for sale.\n - Brand Development: Build a strong brand by offering unique, creative floral designs.",
                        "Long-Term (5+ years):\n - Business Ownership: Open your own floral shop or become a full-time freelance floral designer.\n - Floriculture Expansion: Expand your floriculture business by supplying local florists with fresh flowers.\n - Mentorship and Leadership: Share your expertise by teaching floral design classes or offering workshops."
                    ]
                }
            ]
        },
        {
            "headline": "3. Final Thoughts",
            "content": "Gardening offers a wide range of fulfilling career paths, from hands-on plant care to creative design and conservation. Your passion for working with plants can lead you into roles that contribute to environmental sustainability, enhance outdoor spaces, or provide educational and therapeutic benefits.",
            "subsections": [
                {
                    "subheadline": "Next Steps",
                    "points": [
                        "Pursue Relevant Education: Choose a degree or certification program that aligns with your chosen career path.",
                        "Gain Practical Experience: Volunteer, intern, or work in local gardens, nurseries, or conservation projects to build hands-on skills.",
                        "Build Your Network: Connect with other professionals in the gardening, horticulture, and environmental fields through workshops, conferences, and professional organizations."
                    ]
                }
            ]
        }
    ]
};


