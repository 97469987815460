import React, { useState } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, Image } from 'react-native';
import HubDropdown from '../components/HubDropDown';
import { Ionicons } from '@expo/vector-icons';
import { hubs } from '../components/Hubs';
import { useNavigation } from '@react-navigation/native';

const CreatePostScreen = () => {
  const navigation = useNavigation();  
  const [selectedHub, setSelectedHub] = useState(null);
  const [postContent, setPostContent] = useState('');

  const handlePost = () => {
    // Handle the post submission
    console.log('Post submitted', { selectedHub, postContent });
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <Ionicons name="arrow-back" size={24} color="#000" />
        </TouchableOpacity>
        <Image source={require('../assets/law.png')} style={styles.profileImage} />
        <View style={styles.userInfo}>
          <Text style={styles.userName}>Mayank Shukla</Text>
          <Text style={styles.toggleAnonText}>Click on your profile picture to toggle between user and anonymous posting</Text>
        </View>
      </View>

      <HubDropdown selectedHub={selectedHub} onHubChange={setSelectedHub} hubs={hubs} />

      <Text style={styles.guidelineText}>Read Community Guidelines before posting!</Text>

      <TextInput
        style={styles.postInput}
        multiline
        placeholder="Enter your post here"
        value={postContent}
        onChangeText={setPostContent}
        maxLength={1000}
      />

      <TouchableOpacity style={styles.postButton} onPress={handlePost}>
        <Text style={styles.postButtonText}>Post</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#f8f8f8',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  profileImage: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginLeft: 10,
  },
  userInfo: {
    marginLeft: 10,
  },
  userName: {
    fontWeight: 'bold',
  },
  toggleAnonText: {
    color: '#666',
    fontSize: 12,
  },
  hubSelect: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 15,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginBottom: 10,
  },
  selectedHubContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  hubImage: {
    width: 30,
    height: 30,
    borderRadius: 15,
    marginRight: 10,
  },
  hubTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  guidelineText: {
    color: '#666',
    fontSize: 14,
    marginBottom: 10,
  },
  postInput: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 15,
    fontSize: 16,
    textAlignVertical: 'top',
    height: 150,
    marginBottom: 20,
  },
  postButton: {
    backgroundColor: '#007BFF',
    borderRadius: 10,
    paddingVertical: 15,
    alignItems: 'center',
  },
  postButtonText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: '#fff',
    marginHorizontal: 20,
    padding: 20,
    borderRadius: 10,
  },
  hubItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
  },
});

export default CreatePostScreen;
