import React from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity, FlatList, ScrollView } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import PostCard from '../components/PostCardComponent';
import ModelPost from '../components/ModelPost';

const user = {
  name: 'John Doe',
  imageUrl: 'https://cf-images.us-east-1.prod.boltdns.net/v1/static/5359769168001/0a823cb0-01a9-4835-a348-c64187783ccb/d37cb96c-805c-4aa2-9f2f-e62d9eb814c7/1280x720/match/image.jpg', // Replace with your image URL
};

const posts = [
  new ModelPost(
    '1',
    'The AI Legends #75 Days Day 34: Stephen Wolfram',
    ['https://i.pinimg.com/736x/b2/7c/4a/b27c4ad38351e60f8866fcdc5f6f984a.jpg'],
    'author1',
    'Amit Mundkar',
    'https://m.media-amazon.com/images/M/MV5BMTI5ODY5NTUzMF5BMl5BanBnXkFtZTcwOTAzNTIzMw@@._V1_.jpg',
    'Stealth',
    Date.now() - 86400000, // 1 day ago
    ['user1', 'user2'],
    []
  ),
  new ModelPost(
    '2',
    'I\'m currently laughing co-branded prepaid card for students...',
    [],
    'author2',
    'Anonymous',
    'https://cdn.britannica.com/49/182849-050-4C7FE34F/scene-Iron-Man.jpg',
    'Stealth',
    Date.now() - 172800000, // 2 days ago
    ['user3', 'user4'],
    []
  ),
  new ModelPost(
    '1',
    'The AI Legends #75 Days Day 34: Stephen Wolfram',
    ['https://i.pinimg.com/736x/b2/7c/4a/b27c4ad38351e60f8866fcdc5f6f984a.jpg'],
    'author1',
    'Amit Mundkar',
    'https://m.media-amazon.com/images/M/MV5BMTI5ODY5NTUzMF5BMl5BanBnXkFtZTcwOTAzNTIzMw@@._V1_.jpg',
    'Stealth',
    Date.now() - 86400000, // 1 day ago
    ['user1', 'user2'],
    []
  ),
  new ModelPost(
    '2',
    'I\'m currently laughing co-branded prepaid card for students...',
    [],
    'author2',
    'Anonymous',
    'https://cdn.britannica.com/49/182849-050-4C7FE34F/scene-Iron-Man.jpg',
    'Stealth',
    Date.now() - 172800000, // 2 days ago
    ['user3', 'user4'],
    []
  ),
  new ModelPost(
    '1',
    'The AI Legends #75 Days Day 34: Stephen Wolfram',
    ['https://i.pinimg.com/736x/b2/7c/4a/b27c4ad38351e60f8866fcdc5f6f984a.jpg'],
    'author1',
    'Amit Mundkar',
    'https://m.media-amazon.com/images/M/MV5BMTI5ODY5NTUzMF5BMl5BanBnXkFtZTcwOTAzNTIzMw@@._V1_.jpg',
    'Stealth',
    Date.now() - 86400000, // 1 day ago
    ['user1', 'user2'],
    []
  ),
  new ModelPost(
    '2',
    'I\'m currently laughing co-branded prepaid card for students...',
    [],
    'author2',
    'Anonymous',
    'https://cdn.britannica.com/49/182849-050-4C7FE34F/scene-Iron-Man.jpg',
    'Stealth',
    Date.now() - 172800000, // 2 days ago
    ['user3', 'user4'],
    []
  ),
  new ModelPost(
    '1',
    'The AI Legends #75 Days Day 34: Stephen Wolfram',
    ['https://i.pinimg.com/736x/b2/7c/4a/b27c4ad38351e60f8866fcdc5f6f984a.jpg'],
    'author1',
    'Amit Mundkar',
    'https://m.media-amazon.com/images/M/MV5BMTI5ODY5NTUzMF5BMl5BanBnXkFtZTcwOTAzNTIzMw@@._V1_.jpg',
    'Stealth',
    Date.now() - 86400000, // 1 day ago
    ['user1', 'user2'],
    []
  ),
  new ModelPost(
    '2',
    'I\'m currently laughing co-branded prepaid card for students...',
    [],
    'author2',
    'Anonymous',
    'https://cdn.britannica.com/49/182849-050-4C7FE34F/scene-Iron-Man.jpg',
    'Stealth',
    Date.now() - 172800000, // 2 days ago
    ['user3', 'user4'],
    []
  ),
  new ModelPost(
    '1',
    'The AI Legends #75 Days Day 34: Stephen Wolfram',
    ['https://i.pinimg.com/736x/b2/7c/4a/b27c4ad38351e60f8866fcdc5f6f984a.jpg'],
    'author1',
    'Amit Mundkar',
    'https://m.media-amazon.com/images/M/MV5BMTI5ODY5NTUzMF5BMl5BanBnXkFtZTcwOTAzNTIzMw@@._V1_.jpg',
    'Stealth',
    Date.now() - 86400000, // 1 day ago
    ['user1', 'user2'],
    []
  ),
  new ModelPost(
    '2',
    'I\'m currently laughing co-branded prepaid card for students...',
    [],
    'author2',
    'Anonymous',
    'https://cdn.britannica.com/49/182849-050-4C7FE34F/scene-Iron-Man.jpg',
    'Stealth',
    Date.now() - 172800000, // 2 days ago
    ['user3', 'user4'],
    []
  ),
];

const PostsScreen = () => {
  const navigation = useNavigation();

  const handleHubsClick = () => {
    navigation.navigate('Hubs'); // Navigate to the screen that shows hubs (to be implemented)
  };

  const handlePostClick = (post) => {
    navigation.navigate('Post Detail', { post });
  };

  return (
    <View style={styles.outerContainer}>
      <ScrollView contentContainerStyle={styles.scrollViewContainer}>
        <View style={styles.container}>
          <View style={styles.header}>
            <View style={styles.userInfo}>
              <Image source={{ uri: user.imageUrl }} style={styles.userImage} />
              <Text style={styles.userName}>{user.name}</Text>
            </View>
            <TouchableOpacity onPress={handleHubsClick} style={styles.hubsIconContainer}>
              <Image source={require('../assets/dots-horizontal.png')} style={styles.hubsIcon} />
            </TouchableOpacity>
          </View>

          <View style={styles.contentContainer}>
            <FlatList
              data={posts}
              keyExtractor={(item) => item.id}
              renderItem={({ item }) => (
                <TouchableOpacity onPress={() => handlePostClick(item)} style={styles.postItem}>
                  <PostCard post={item}/>
                </TouchableOpacity>
              )}
              ListFooterComponent={<View style={{ height: 100 }} />} // Add space at the bottom
            />
          </View>
        </View>
      </ScrollView>
      <TouchableOpacity
        style={styles.fab}
        onPress={() => navigation.navigate('CreatePost')}
      >
        <Ionicons name="add" size={24} color="#fff" />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    width: '100%',
  },
  scrollViewContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: '90%', // Fixed width for the content to keep it centered
    maxWidth: 600,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#f8f8f8',
  },
  userInfo: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  userImage: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 10,
  },
  userName: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  hubsIconContainer: {
    marginRight: 10,
  },
  hubsIcon: {
    width: 24,
    height: 24,
  },
  contentContainer: {
    flex: 1,
    width: '100%', // Ensure content container takes full width of the screen
    paddingHorizontal: 10,
  },
  postItem: {
    padding: 15,
    backgroundColor: '#eee',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    width: '100%',
  },
  postText: {
    fontSize: 16,
  },
  fab: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    backgroundColor: '#007BFF',
    width: 60,
    height: 60,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default PostsScreen;
