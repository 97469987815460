import { MobileAppDevelopment } from "../components/ComputerEngineeringPaths";

export const educationMap = {
  'Science Stream': {
    'B.Sc.': [
      { branch: 'Physics', careerPaths: {} },
      { branch: 'Chemistry', careerPaths: {} },
      { branch: 'Mathematics', careerPaths: {} },
      { branch: 'Biology', careerPaths: {} },
      { branch: 'Zoology', careerPaths: {} },
      { branch: 'Botany', careerPaths: {} },
      { branch: 'Geology', careerPaths: {} },
      { branch: 'Environmental Science', careerPaths: {} },
      { branch: 'Biotechnology', careerPaths: {} },
      { branch: 'Microbiology', careerPaths: {} },
      { branch: 'Biochemistry', careerPaths: {} },
      { branch: 'Forensic Science', careerPaths: {} },
      { branch: 'Genetics', careerPaths: {} },
      { branch: 'Food Science', careerPaths: {} },
      { branch: 'Agriculture', careerPaths: {} },
      { branch: 'Home Science', careerPaths: {} },
      { branch: 'Forestry', careerPaths: {} },
      { branch: 'Horticulture', careerPaths: {} },
      { branch: 'Aquaculture', careerPaths: {} },
      { branch: 'Fisheries Science', careerPaths: {} },
      { branch: 'Computer Science', careerPaths: {} },
      { branch: 'Information Technology (IT)', careerPaths: {} },
      { branch: 'Data Science', careerPaths: {} },
      { branch: 'Artificial Intelligence and Machine Learning', careerPaths: {} },
    ],
    'B.Tech/B.E.': [
      {
        branch: 'Computer Science and Engineering',
        careerPaths: {
          MobileAppDevelopment
        },
      },
      { branch: 'Mechanical Engineering', careerPaths: {} },
      { branch: 'Civil Engineering', careerPaths: {} },
      { branch: 'Electrical Engineering', careerPaths: {} },
      { branch: 'Electronics and Communication Engineering', careerPaths: {} },
      { branch: 'Information Technology', careerPaths: {} },
      { branch: 'Chemical Engineering', careerPaths: {} },
      { branch: 'Aerospace Engineering', careerPaths: {} },
      { branch: 'Automobile Engineering', careerPaths: {} },
      { branch: 'Mechatronics Engineering', careerPaths: {} },
      { branch: 'Industrial Engineering', careerPaths: {} },
      { branch: 'Production Engineering', careerPaths: {} },
      { branch: 'Metallurgical Engineering', careerPaths: {} },
      { branch: 'Mining Engineering', careerPaths: {} },
      { branch: 'Marine Engineering', careerPaths: {} },
      { branch: 'Petroleum Engineering', careerPaths: {} },
      { branch: 'Agricultural Engineering', careerPaths: {} },
      { branch: 'Environmental Engineering', careerPaths: {} },
      { branch: 'Biomedical Engineering', careerPaths: {} },
      { branch: 'Biotechnology Engineering', careerPaths: {} },
      { branch: 'Textile Engineering', careerPaths: {} },
      { branch: 'Robotics Engineering', careerPaths: {} },
      { branch: 'Instrumentation Engineering', careerPaths: {} },
      { branch: 'Ceramic Engineering', careerPaths: {} },
      { branch: 'Nanotechnology', careerPaths: {} },
      { branch: 'Structural Engineering', careerPaths: {} },
      { branch: 'Renewable Energy Engineering', careerPaths: {} },
      { branch: 'Plastic Engineering', careerPaths: {} },
      { branch: 'Polymer Engineering', careerPaths: {} },
    ],
    'Medical and Allied Courses': [
      { branch: 'MBBS', careerPaths: {} },
      { branch: 'BDS', careerPaths: {} },
      { branch: 'BAMS', careerPaths: {} },
      { branch: 'BHMS', careerPaths: {} },
      { branch: 'BUMS', careerPaths: {} },
      { branch: 'Physiotherapy', careerPaths: {} },
      { branch: 'Nursing', careerPaths: {} },
      { branch: 'Pharmacy', careerPaths: {} },
      { branch: 'Radiology', careerPaths: {} },
      { branch: 'Medical Laboratory Technology', careerPaths: {} },
      { branch: 'Occupational Therapy', careerPaths: {} },
      { branch: 'Optometry', careerPaths: {} },
      { branch: 'Speech Therapy', careerPaths: {} },
      { branch: 'Audiology', careerPaths: {} },
      { branch: 'Nutrition and Dietetics', careerPaths: {} },
    ],
    'Other Science Degrees': [
      { branch: 'Computer Applications', careerPaths: {} },
      { branch: 'Nautical Science', careerPaths: {} },
      { branch: 'Aviation', careerPaths: {} },
      { branch: 'Statistics', careerPaths: {} },
      { branch: 'Animation', careerPaths: {} },
      { branch: 'Multimedia', careerPaths: {} },
    ],
  },
  'Commerce Stream': {
    'B.Com.': [
      { branch: 'General', careerPaths: {} },
      { branch: 'Honours', careerPaths: {} },
      { branch: 'Accounting and Finance', careerPaths: {} },
      { branch: 'Banking and Insurance', careerPaths: {} },
      { branch: 'Financial Markets', careerPaths: {} },
      { branch: 'Business Economics', careerPaths: {} },
      { branch: 'Taxation', careerPaths: {} },
      { branch: 'E-Commerce', careerPaths: {} },
      { branch: 'Investment Management', careerPaths: {} },
      { branch: 'Cost Accounting', careerPaths: {} },
      { branch: 'Corporate Secretaryship', careerPaths: {} },
    ],
    'BBA': [
      { branch: 'General', careerPaths: {} },
      { branch: 'Honours', careerPaths: {} },
      { branch: 'Human Resource Management', careerPaths: {} },
      { branch: 'Marketing', careerPaths: {} },
      { branch: 'Finance', careerPaths: {} },
      { branch: 'International Business', careerPaths: {} },
      { branch: 'Logistics and Supply Chain Management', careerPaths: {} },
      { branch: 'Tourism Management', careerPaths: {} },
      { branch: 'Hotel Management', careerPaths: {} },
      { branch: 'Event Management', careerPaths: {} },
      { branch: 'Retail Management', careerPaths: {} },
      { branch: 'Digital Marketing', careerPaths: {} },
      { branch: 'Entrepreneurship', careerPaths: {} },
      { branch: 'Sports Management', careerPaths: {} },
      { branch: 'Aviation Management', careerPaths: {} },
      { branch: 'Healthcare Management', careerPaths: {} },
      { branch: 'Family Business Management', careerPaths: {} },
      { branch: 'Business Analytics', careerPaths: {} },
      { branch: 'Financial Services and Banking', careerPaths: {} },
    ],
    'Professional Courses': [
      { branch: 'CA', careerPaths: {} },
      { branch: 'CS', careerPaths: {} },
      { branch: 'CFA', careerPaths: {} },
      { branch: 'CMA', careerPaths: {} },
      { branch: 'BFS', careerPaths: {} },
    ],
  },
  'Arts/Humanities Stream': {
    'B.A.': [
      { branch: 'History', careerPaths: {} },
      { branch: 'Political Science', careerPaths: {} },
      { branch: 'Sociology', careerPaths: {} },
      { branch: 'Psychology', careerPaths: {} },
      { branch: 'Economics', careerPaths: {} },
      { branch: 'Geography', careerPaths: {} },
      { branch: 'Philosophy', careerPaths: {} },
      { branch: 'Anthropology', careerPaths: {} },
      { branch: 'Archaeology', careerPaths: {} },
      { branch: 'International Relations', careerPaths: {} },
      { branch: 'Public Administration', careerPaths: {} },
      { branch: 'Social Work', careerPaths: {} },
      { branch: 'Rural Development', careerPaths: {} },
      { branch: 'Women’s Studies', careerPaths: {} },
      { branch: 'Defense Studies', careerPaths: {} },
      { branch: 'Education', careerPaths: {} },
      { branch: 'Library Science', careerPaths: {} },
      { branch: 'Human Rights', careerPaths: {} },
    ],
    'Fine Arts and Others': [
      { branch: 'Fine Arts', careerPaths: {} },
      { branch: 'Journalism and Mass Communication', careerPaths: {} },
      { branch: 'Laws (LLB)', careerPaths: {} },
    ],
  },
};
