import CareerRoadMap, { Roadmap, StepDetails } from '../models/CareerRoadMap';

export const careerData = [
  new CareerRoadMap(
    "Backend Developer",  // Career Title
    "Backend developers focus on building the server-side logic of applications, managing databases, and ensuring seamless communication between the front-end and back-end of a web application. They handle requests, process data, and communicate with databases or APIs.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Start by learning backend programming languages. Python and Java are two great choices for beginners, but you can also explore JavaScript (Node.js), Ruby (Ruby on Rails), or PHP. These languages power the server side of applications, handle logic, and communicate with databases."
        ),
        new StepDetails("Databases",
          "Learn how to work with SQL databases like MySQL or PostgreSQL, which use structured data. Also, explore NoSQL databases like MongoDB and Cassandra, which are great for handling unstructured data or large datasets. Understanding how to design, query, and optimize databases is critical for efficient backend development."
        ),
        new StepDetails("API Development",
          "Learn how to build RESTful APIs, which allow different parts of a web app (or other apps) to communicate with one another. Once you’re comfortable with REST, look into GraphQL, a more modern query language for APIs that allows clients to request specific data. Experiment with building small API projects to practice."
        ),
        new StepDetails("Version Control",
          "Master Git and GitHub for version control. These tools help you track changes in your code, collaborate with others, and roll back to earlier versions of your project if needed. Familiarize yourself with Git commands (commit, push, pull, branch) and workflows (like Git flow) to work effectively in a team."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Authentication & Authorization",
          "Implement secure user authentication using systems like OAuth2 (commonly used for logins via Google or Facebook), JWT (JSON Web Tokens for stateless authentication), and session-based authentication. Ensuring secure user logins and protecting sensitive information is crucial in backend development."
        ),
        new StepDetails("Cloud Platforms",
          "Learn to deploy backends on cloud platforms like AWS, Azure, or Google Cloud. Study serverless architectures (e.g., AWS Lambda) where you can run code in response to events without managing servers. Understand load balancing, autoscaling, and managing cloud databases like DynamoDB."
        ),
        new StepDetails("Microservices",
          "Microservices architecture involves breaking down an application into smaller, independent services that communicate via APIs. Study containerization (using Docker) and orchestration tools like Kubernetes to manage and scale microservices effectively. Understanding microservices will allow you to build more modular, scalable, and maintainable systems."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles",
          "After gaining experience, you can transition into roles such as Backend Lead, Technical Architect, or System Architect. These roles involve overseeing backend architecture design, managing teams, and ensuring that systems are scalable and secure."
        ),
        new StepDetails("Consulting",
          "Offer consulting services to businesses looking to improve or scale their backend architectures. This involves advising on technology stacks, optimizing performance, and ensuring security in cloud-based applications. Specialized knowledge in specific industries (e.g., fintech, healthcare) can enhance your consulting services."
        ),
        new StepDetails("Advanced Concepts",
          "Explore more advanced topics such as distributed systems (handling data across multiple servers), message queues (RabbitMQ, Apache Kafka for handling large volumes of data), and event-driven architecture (triggering actions in response to events like user requests or system actions). These concepts are key to handling large-scale web applications."
        ),
      ]
    ),
    ["Computer Science", "Mathematics", "Database Systems"],  // Favorite subjects that align with backend development
    ["Puzzles", "Server Setup"],  // Hobbies/Activities that complement backend development
    "Science Stream",  // Educational stream
    "B.Tech/B.E.",  // Degree
    "Computer Science and Engineering"  // Branch
  ),
  // Frontend Developer Career Path
  new CareerRoadMap(
    "Frontend Developer",  // Career Title
    "Frontend developers are responsible for creating the visual elements of a website or web application that users interact with directly. They work on the user interface (UI) and ensure that the user experience (UX) is seamless, responsive, and visually appealing.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Start by learning HTML (Hypertext Markup Language), CSS (Cascading Style Sheets), and JavaScript. HTML structures content on the web, CSS styles the content, and JavaScript adds interactivity (e.g., animations, dynamic page elements). Build static web pages to practice."
        ),
        new StepDetails("Frameworks",
          "Learn popular JavaScript frameworks and libraries such as React.js, Vue.js, or Angular. These tools make it easier to build complex and interactive user interfaces. Start with React.js for a component-based architecture, which is widely used in the industry."
        ),
        new StepDetails("Version Control",
          "Master Git and GitHub to manage your codebase, track changes, and collaborate with others. Familiarize yourself with Git commands like branching and merging, which are especially important when working in teams."
        ),
        new StepDetails("Responsive Design",
          "Learn how to create responsive designs using CSS frameworks like Bootstrap or Tailwind CSS. Responsive design ensures that web applications look good on all screen sizes (desktop, tablet, mobile). Practice building websites that adapt to different devices using CSS media queries."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("State Management",
          "State management refers to how data is handled in your app. Learn tools like Redux (for React), Vuex (for Vue.js), or NgRx (for Angular) to manage complex application states across components. This helps you build more dynamic and interactive applications."
        ),
        new StepDetails("Performance Optimization",
          "Learn techniques like lazy loading (loading components only when needed), code splitting (splitting the app into smaller bundles), and optimizing images and assets to improve website performance. A fast, responsive app provides a better user experience and ranks higher on search engines."
        ),
        new StepDetails("Component Libraries",
          "Understand how to build reusable UI components using libraries like Material-UI or Ant Design. These libraries come with pre-built components like buttons, forms, and navigation elements that you can customize for faster development."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles",
          "After gaining experience, transition into roles like Frontend Lead or UI/UX Lead, where you’ll oversee the design and implementation of front-end architectures. This may involve managing teams, code reviews, and designing scalable front-end systems."
        ),
        new StepDetails("Freelancing",
          "Offer freelance services as a frontend developer. This allows you to work with multiple clients, building customized user interfaces and experiences for startups, businesses, or agencies. You can specialize in areas like eCommerce, portfolios, or single-page applications."
        ),
        new StepDetails("Design Systems",
          "Contribute to or build design systems that ensure consistency in large-scale web applications. Design systems provide reusable components and design guidelines for developers and designers, making it easier to scale the frontend across multiple teams and projects."
        ),
      ]
    ),
    ["Design", "Computer Science", "Art"],  // Favorite subjects that align with frontend development
    ["Graphic Design", "Problem-Solving Games"],  // Hobbies/Activities that complement frontend development
    "Science Stream",  // Educational stream
    "B.Tech/B.E.",  // Degree
    "Computer Science and Engineering"  // Branch
  ),

  // DevOps Engineer Career Path
  new CareerRoadMap(
    "DevOps Engineer",  // Career Title
    "DevOps engineers focus on automating and streamlining the software development lifecycle by integrating development (Dev) and IT operations (Ops). Their primary role is to automate and monitor everything from code integration to deployment, ensuring fast, reliable software delivery.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Learn Linux, as most server infrastructure runs on Linux. Get comfortable with command-line tools and Bash scripting. Also, learn basic programming (Python, Go) to write scripts for automation. Understand version control systems like Git, and start learning CI/CD tools like Jenkins, Travis CI, or CircleCI."
        ),
        new StepDetails("Containerization",
          "Study Docker to create, manage, and deploy containers—lightweight, portable, and self-sufficient environments for running applications. Containers make it easier to deploy and scale apps, especially in distributed systems."
        ),
        new StepDetails("Cloud Platforms",
          "Learn the basics of cloud infrastructure using AWS, Azure, or Google Cloud. Understand how to deploy applications on these platforms, and explore concepts like virtual machines, networking, and storage in the cloud."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Advanced Concepts",
          "Master Kubernetes for container orchestration (managing containers at scale). Learn how to configure Kubernetes clusters and deploy services in production environments. Also, get familiar with Infrastructure as Code (IaC) tools like Terraform and Ansible for automating infrastructure setup."
        ),
        new StepDetails("Monitoring & Security",
          "Set up monitoring for applications using tools like Prometheus, Grafana, or ELK Stack (Elasticsearch, Logstash, Kibana). Learn how to track system performance, detect anomalies, and ensure high uptime. Study security best practices, focusing on automating security in the DevOps pipeline."
        ),
        new StepDetails("Automation",
          "Automate the deployment and scaling of infrastructure using tools like Jenkins, Ansible, Puppet, or Chef. Focus on automating repetitive tasks like configuration management, scaling, and monitoring to enhance efficiency."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles",
          "Move into roles like DevOps Lead or Site Reliability Engineer (SRE), where you'll be responsible for the architecture, scalability, and reliability of large distributed systems. These roles often involve leading teams, setting up best practices, and ensuring a smooth software development lifecycle."
        ),
        new StepDetails("Consulting",
          "Offer consulting services in DevOps, helping organizations design automated, scalable, and secure cloud-based infrastructures. This could involve setting up CI/CD pipelines, cloud migrations, or improving deployment workflows."
        ),
        new StepDetails("Continuous Learning",
          "Stay updated on the latest trends in DevOps and cloud-native technologies. Learn about new tools, techniques, and frameworks like serverless architecture, container orchestration (Kubernetes), and CI/CD advancements. Attend DevOps conferences or contribute to open-source DevOps tools."
        ),
      ]
    ),
    ["Computer Science", "Cloud Computing", "Networking"],  // Favorite subjects that align with DevOps
    ["Home Automation Projects", "Tinkering with Servers"],  // Hobbies/Activities that complement DevOps
    "Science Stream",  // Educational stream
    "B.Tech/B.E.",  // Degree
    "Computer Science and Engineering"  // Branch
  ),

  // Mobile App Developer Career Path
  new CareerRoadMap(
    "Mobile App Developer",  // Career Title
    "Mobile App Developers create applications for mobile platforms (iOS and Android). They may specialize in native development (using Kotlin, Swift) or hybrid development (using frameworks like React Native or Flutter).",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Learn native mobile development languages such as Kotlin (for Android) and Swift (for iOS). Also, study the design principles specific to mobile platforms, like Google’s Material Design for Android and Apple’s Human Interface Guidelines for iOS. These will help you design visually appealing and user-friendly apps."
        ),
        new StepDetails("Development Environments",
          "Master development environments like Android Studio for Android apps and Xcode for iOS apps. Both platforms provide tools for testing, building, and deploying apps. Set up virtual devices (emulators) to test your applications without the need for physical hardware."
        ),
        new StepDetails("Hands-on Projects",
          "Start by building small, focused mobile apps like a calculator, weather app, or a to-do list. This will help you get a feel for mobile development. Experiment with basic UI components (buttons, text inputs, etc.) and understand the life cycle of a mobile app."
        ),
        new StepDetails("Tools",
          "Learn version control tools like Git, which is essential for tracking changes and collaborating with others. Use Firebase for backend services (authentication, databases, hosting) to speed up development without setting up your own server infrastructure."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Advanced Development",
          "Explore more advanced mobile app features like push notifications (for sending real-time updates), geolocation (for maps and navigation), camera access, and integrating payment systems (Apple Pay, Google Pay). These features can add more depth and complexity to your apps."
        ),
        new StepDetails("Testing & Debugging",
          "Learn mobile testing techniques using tools like XCTest for iOS and JUnit or Espresso for Android. Write unit tests, integration tests, and UI tests to ensure your app works smoothly under different conditions and devices."
        ),
        new StepDetails("App Store Deployment",
          "Understand the process of deploying apps to the Google Play Store (Android) and Apple App Store (iOS). Learn about app store optimization (ASO) to make sure your app gets discovered, and follow the guidelines for publishing updates and handling user feedback."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles",
          "After several years of experience, you can take on leadership roles like Senior Mobile Developer, Mobile App Architect, or Team Lead. These roles involve overseeing the entire app development process, mentoring junior developers, and designing the overall architecture of mobile apps."
        ),
        new StepDetails("Entrepreneurship",
          "Consider starting your own mobile app development business or becoming a freelance mobile developer. Focus on niche markets like mobile games, fitness apps, or business apps, and offer tailored mobile solutions to clients or consumers."
        ),
        new StepDetails("Continuous Learning",
          "Stay updated with the latest mobile development trends, such as Progressive Web Apps (PWAs), augmented reality (AR) integration, and wearable technology (smartwatches). Keep refining your skills in both native and hybrid mobile development."
        ),
      ]
    ),
    ["Computer Science", "Mobile Design", "Software Engineering"],  // Favorite subjects that align with mobile app development
    ["Mobile Gaming", "Prototyping"],  // Hobbies/Activities that complement mobile app development
    "Science Stream",  // Educational stream
    "B.Tech/B.E.",  // Degree
    "Computer Science and Engineering"  // Branch
  ),
  new CareerRoadMap(
    "Cloud Support Associate",
    "Cloud Support Associates help companies manage, troubleshoot, and optimize cloud-based infrastructure. They work closely with engineering and DevOps teams to maintain cloud services and ensure that cloud environments run smoothly.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 Years)
        new StepDetails("Education", 
          "Learn the basics of cloud computing, including public, private, and hybrid clouds. Focus on cloud service models such as IaaS, PaaS, and SaaS. Study cloud platforms like AWS, Microsoft Azure, and Google Cloud."
        ),
        new StepDetails("Certification", 
          "Earn entry-level cloud certifications such as AWS Certified Cloud Practitioner, Microsoft Azure Fundamentals (AZ-900), or Google Associate Cloud Engineer. These provide a solid foundation in cloud infrastructure."
        ),
        new StepDetails("Internships or Freelance Projects", 
          "Gain hands-on experience by interning with companies or working on freelance projects related to cloud infrastructure. Learn tasks such as deploying virtual machines, managing storage, or configuring cloud applications."
        ),
      ],
      [
        // Medium-Term Goals (2-4 Years)
        new StepDetails("Work as a Cloud Support Associate", 
          "Start working as a Cloud Support Associate in a tech company, IT service provider, or startup. Handle tasks such as troubleshooting cloud services, monitoring infrastructure, and resolving outages."
        ),
        new StepDetails("Learn Scripting and Automation", 
          "Develop scripting skills using Python or Bash to automate cloud-related tasks. Learn to work with cloud automation tools like AWS Lambda, Azure Automation, or Google Cloud Functions."
        ),
        new StepDetails("Cloud Support Expertise", 
          "Focus on improving cloud troubleshooting skills. Specialize in handling issues like downtime, performance tuning, and security in cloud environments, focusing on cloud databases, storage, and networking."
        ),
      ],
      [
        // Long-Term Goals (5+ Years)
        new StepDetails("Specialization", 
          "Transition to specialized roles such as Cloud Engineer or Cloud DevOps Engineer, focusing on managing large-scale cloud infrastructures. Learn advanced tools like Kubernetes or Docker for cloud container management."
        ),
        new StepDetails("Advanced Certifications", 
          "Pursue higher-level certifications such as AWS Certified Solutions Architect – Associate, Microsoft Certified: Azure Administrator Associate, or Google Professional Cloud Architect to qualify for senior cloud roles."
        ),
        new StepDetails("Consulting or Cloud Architecture", 
          "Consider moving into Cloud Consulting or Cloud Architecture roles, designing and implementing cloud solutions for businesses. Provide advisory services on optimizing cloud environments and reducing operational costs."
        ),
      ]
    ),
    ["Cloud Computing", "Networking", "Operating Systems"],
    ["Learning New Technologies", "Solving Technical Problems", "Setting Up Virtual Machines"],
    "Science Stream", // Stream
    "B.Tech/B.E.", // Degree
    "Computer Science and Engineering" // Branch/Field
  ),
  // Data Scientist Career Path
  new CareerRoadMap(
    "Data Scientist",
    "Data Scientists analyze complex data to help organizations make data-driven decisions. They use programming, statistics, and machine learning techniques to uncover insights from large datasets.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Learn the basics of programming with Python or R. These are the most commonly used languages in data science due to their simplicity and vast ecosystem of libraries (Pandas, NumPy, etc.). Study basic statistics, probability, and linear algebra as they form the foundation for analyzing and interpreting data."
        ),
        new StepDetails("Data Manipulation",
          "Master data manipulation and analysis libraries such as Pandas (Python) or dplyr (R). Learn how to clean, filter, aggregate, and visualize data to find meaningful insights."
        ),
        new StepDetails("Data Visualization",
          "Learn data visualization techniques using libraries such as Matplotlib, Seaborn, or Plotly in Python. Understanding how to represent data graphically helps convey insights effectively to stakeholders."
        ),
        new StepDetails("Version Control",
          "Familiarize yourself with Git and GitHub to manage your code, track changes, and collaborate on data science projects. This will help you keep your analyses reproducible and organized."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Machine Learning",
          "Dive into machine learning by studying supervised and unsupervised learning algorithms. Use libraries like Scikit-learn or TensorFlow for building models. Understand core algorithms like linear regression, decision trees, and clustering."
        ),
        new StepDetails("Big Data Tools",
          "Learn to handle large datasets using big data tools like Apache Hadoop, Apache Spark, or Google BigQuery. Big data tools help in processing massive amounts of data efficiently."
        ),
        new StepDetails("Model Deployment",
          "Learn how to deploy machine learning models into production environments using tools like Flask, Docker, and Kubernetes. Study MLOps (Machine Learning Operations) to automate the process of deploying and monitoring models."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Advanced Machine Learning",
          "Explore deep learning techniques (e.g., neural networks, CNNs, RNNs) using frameworks like TensorFlow or PyTorch. Apply these techniques to more complex problems like image recognition, natural language processing, or reinforcement learning."
        ),
        new StepDetails("Data Science Leadership",
          "Transition into roles like Senior Data Scientist or Data Science Team Lead, where you’ll lead the development of data models, guide junior data scientists, and make strategic decisions based on data insights."
        ),
        new StepDetails("Consulting",
          "Offer data science consulting services to organizations, helping them leverage data to optimize business strategies. Specializing in industries such as finance, healthcare, or marketing can further enhance your consulting services."
        ),
      ]
    ),
    ["Mathematics", "Statistics", "Computer Science"],
    ["Data Analysis", "Problem-Solving", "Puzzles"],
    "Science Stream",
    "B.Tech/B.E.",
    "Computer Science and Engineering"
  ),

  // Machine Learning Engineer Career Path
  new CareerRoadMap(
    "Machine Learning Engineer",
    "Machine Learning Engineers develop systems that use algorithms to analyze and make decisions based on data. They focus on building scalable models and deploying them to production environments.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Learn programming languages like Python and Java, which are widely used for machine learning development. Study basic machine learning concepts such as supervised learning, unsupervised learning, and reinforcement learning."
        ),
        new StepDetails("Libraries and Tools",
          "Get hands-on experience with machine learning libraries such as Scikit-learn, TensorFlow, and PyTorch. Start with small datasets to practice building models like linear regression, decision trees, and k-means clustering."
        ),
        new StepDetails("Mathematics",
          "Deepen your understanding of mathematics, focusing on linear algebra, calculus, probability, and statistics. These are essential for grasping the mechanics of machine learning algorithms and optimizing model performance."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Advanced Algorithms",
          "Learn about advanced algorithms such as support vector machines, random forests, and neural networks. Study deep learning techniques, including convolutional neural networks (CNNs) for image data and recurrent neural networks (RNNs) for sequential data."
        ),
        new StepDetails("Natural Language Processing (NLP)",
          "Study natural language processing (NLP) to build applications that process and analyze human language, such as chatbots, sentiment analysis tools, and machine translation systems."
        ),
        new StepDetails("Deployment and MLOps",
          "Learn to deploy machine learning models to production using tools like Flask, Docker, and Kubernetes. Automate workflows and monitor models in production using MLOps frameworks to ensure scalability and reliability."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("AI Specialization",
          "Explore AI techniques like reinforcement learning, transfer learning, and generative adversarial networks (GANs). Apply them to cutting-edge fields such as autonomous vehicles, robotics, or advanced medical diagnostics."
        ),
        new StepDetails("Leadership and Research",
          "Transition to leadership roles such as Machine Learning Team Lead or Research Scientist. Lead machine learning initiatives in organizations, contribute to AI research, and publish papers on new algorithms or methodologies."
        ),
        new StepDetails("Consulting",
          "Provide AI and machine learning consulting services, helping companies integrate AI into their business processes. Specialize in fields such as healthcare AI, financial modeling, or autonomous systems."
        ),
      ]
    ),
    ["Mathematics", "Computer Science", "Artificial Intelligence"],
    ["Problem Solving", "Mathematical Puzzles"],
    "Science Stream",
    "B.Tech/B.E.",
    "Computer Science and Engineering"
  ),

  // Cybersecurity Specialist Career Path
  new CareerRoadMap(
    "Cybersecurity Specialist",
    "Cybersecurity Specialists focus on protecting computer systems, networks, and data from cyberattacks. They implement security measures, monitor systems for vulnerabilities, and respond to security breaches.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Learn the basics of networking (TCP/IP, DNS, HTTP), and understand how computers communicate over the internet. Study cybersecurity fundamentals such as encryption, firewalls, and access control."
        ),
        new StepDetails("Ethical Hacking",
          "Learn ethical hacking and penetration testing techniques. Tools like Wireshark, Nmap, and Metasploit will help you test systems for vulnerabilities, which is crucial for finding and fixing security flaws."
        ),
        new StepDetails("Certifications",
          "Consider entry-level cybersecurity certifications such as CompTIA Security+, Certified Ethical Hacker (CEH), or Cisco Certified CyberOps Associate. These certifications validate your understanding of cybersecurity concepts."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Security Architecture",
          "Learn how to design secure systems and networks. Study how to implement security policies, firewalls, intrusion detection systems (IDS), and intrusion prevention systems (IPS). Understand security architecture best practices."
        ),
        new StepDetails("Incident Response",
          "Develop expertise in incident response and forensics. Learn how to detect, investigate, and mitigate security breaches, as well as how to gather forensic evidence from compromised systems."
        ),
        new StepDetails("Cloud Security",
          "Study cloud security, focusing on securing cloud-based infrastructure. Learn cloud security tools and best practices for AWS, Google Cloud, and Azure. This is critical as more companies migrate their data and services to the cloud."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles",
          "Become a Chief Information Security Officer (CISO) or Security Operations Center (SOC) Manager, where you'll lead teams of security professionals and define the security strategy for an organization."
        ),
        new StepDetails("Consulting",
          "Offer cybersecurity consulting services to companies, helping them design secure systems, assess vulnerabilities, and comply with regulatory standards (GDPR, HIPAA, PCI-DSS)."
        ),
        new StepDetails("Advanced Certifications",
          "Pursue advanced certifications such as CISSP (Certified Information Systems Security Professional) or CISM (Certified Information Security Manager) to further validate your expertise and open up more senior roles."
        ),
      ]
    ),
    ["Computer Science", "Networking", "Cybersecurity"],
    ["Solving Security Challenges", "Ethical Hacking"],
    "Science Stream",
    "B.Tech/B.E.",
    "Computer Science and Engineering"
  ),

  // Game Developer Career Path
  new CareerRoadMap(
    "Game Developer",
    "Game Developers design, code, and test video games for consoles, PCs, and mobile devices. They use game engines like Unity or Unreal Engine to create engaging, interactive experiences for users.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Learn programming languages commonly used in game development, such as C# (for Unity) or C++ (for Unreal Engine). Study game design principles and the basics of creating 2D and 3D games."
        ),
        new StepDetails("Game Engines",
          "Get familiar with game engines like Unity (for mobile and indie games) or Unreal Engine (for AAA games). These engines provide tools for building complex game environments, physics simulations, and animations."
        ),
        new StepDetails("Graphics Programming",
          "Learn the basics of computer graphics and rendering. Understand how to create textures, shaders, lighting effects, and physics-based animations in your games."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Advanced Game Development",
          "Learn advanced game development techniques, including multiplayer networking (e.g., Photon), procedural generation (creating levels or environments dynamically), and AI programming (e.g., enemy behavior)."
        ),
        new StepDetails("Game Optimization",
          "Understand how to optimize games for performance, ensuring smooth frame rates and efficient memory usage, especially on mobile devices. Learn techniques like level of detail (LOD) and texture compression."
        ),
        new StepDetails("VR/AR Development",
          "Study Virtual Reality (VR) and Augmented Reality (AR) game development. Learn to build immersive experiences using platforms like Oculus SDK or ARCore/ARKit. These fields are rapidly expanding in the gaming industry."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Game Design Leadership",
          "Transition into roles like Lead Game Developer or Game Designer, where you'll oversee the entire development process, from concept to final product. Lead teams and manage game projects for studios or as an independent developer."
        ),
        new StepDetails("Entrepreneurship",
          "Consider starting your own game development studio, focusing on indie games or mobile games. Indie games often allow for more creative freedom and direct publishing to platforms like Steam or Google Play."
        ),
        new StepDetails("Specialization",
          "Specialize in fields like mobile game development, online multiplayer systems, or niche game genres. This can help you stand out in the competitive gaming industry."
        ),
      ]
    ),
    ["Computer Science", "Art", "Physics"],
    ["Video Gaming", "3D Modeling", "Storytelling"],
    "Science Stream",
    "B.Tech/B.E.",
    "Computer Science and Engineering"
  ),

  // Blockchain Developer Career Path
  new CareerRoadMap(
    "Blockchain Developer",
    "Blockchain Developers build decentralized applications (dApps) and smart contracts on blockchain platforms like Ethereum, Hyperledger, or Solana. They focus on creating secure, immutable digital records for industries like finance, supply chain, and healthcare.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Learn the fundamentals of blockchain technology, including how decentralized ledgers work and how blocks are cryptographically secured. Study programming languages like Solidity (for Ethereum) or Rust (for Solana)."
        ),
        new StepDetails("Smart Contracts",
          "Understand how to write smart contracts, which are self-executing agreements running on blockchain platforms. Study Ethereum’s Solidity language to create contracts for DeFi (Decentralized Finance) applications, NFTs, or DAOs."
        ),
        new StepDetails("Cryptography",
          "Learn cryptographic techniques like public-key cryptography, hashing algorithms, and digital signatures. These techniques are critical for securing blockchain transactions and ensuring data integrity."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Blockchain Platforms",
          "Deepen your understanding of blockchain platforms like Ethereum, Hyperledger, or Solana. Learn to develop dApps (decentralized applications) on these platforms, focusing on industries like finance, supply chain, or healthcare."
        ),
        new StepDetails("Consensus Algorithms",
          "Study consensus mechanisms like Proof of Work (PoW), Proof of Stake (PoS), and newer algorithms like Proof of Authority (PoA) or Delegated Proof of Stake (DPoS). These mechanisms ensure that all nodes in a blockchain network agree on the current state of the ledger."
        ),
        new StepDetails("Interoperability",
          "Learn about blockchain interoperability, which allows different blockchains to communicate and transfer assets. This is becoming increasingly important as companies use multiple blockchain networks to manage their operations."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles",
          "Become a Blockchain Architect or Lead Developer, overseeing blockchain projects and ensuring that smart contracts, consensus mechanisms, and security protocols are implemented correctly."
        ),
        new StepDetails("Consulting",
          "Provide consulting services for businesses looking to adopt blockchain technology. This could involve designing secure blockchain architectures, developing custom dApps, or advising on blockchain integration."
        ),
        new StepDetails("Research and Innovation",
          "Contribute to blockchain research by developing new consensus algorithms, improving scalability, or enhancing the security of blockchain systems. Publish research papers or work with organizations on blockchain innovation."
        ),
      ]
    ),
    ["Cryptography", "Computer Science", "Mathematics"],
    ["Investing in Cryptocurrencies", "Programming", "Solving Complex Problems"],
    "Science Stream",
    "B.Tech/B.E.",
    "Computer Science and Engineering"
  ),

  // Cloud Architect Career Path
  new CareerRoadMap(
    "Cloud Architect",
    "Cloud Architects design and manage cloud infrastructure for organizations, ensuring scalability, security, and cost-efficiency. They work with cloud platforms like AWS, Azure, and Google Cloud to build and deploy applications.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Learn the basics of cloud computing and how cloud infrastructure works. Study platforms like Amazon Web Services (AWS), Microsoft Azure, and Google Cloud, focusing on services like virtual machines (EC2), storage (S3), and networking (VPC)."
        ),
        new StepDetails("Cloud Certifications",
          "Consider entry-level cloud certifications like AWS Certified Solutions Architect Associate, Microsoft Certified Azure Fundamentals, or Google Associate Cloud Engineer. These certifications validate your cloud knowledge and open up entry-level roles."
        ),
        new StepDetails("Infrastructure as Code (IaC)",
          "Learn to automate cloud infrastructure provisioning using tools like Terraform or AWS CloudFormation. Infrastructure as Code allows you to manage cloud resources programmatically, improving scalability and consistency."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Cloud Security",
          "Understand cloud security best practices, including encryption, identity and access management (IAM), and network security. Ensure that data and applications in the cloud are protected from unauthorized access and breaches."
        ),
        new StepDetails("Microservices and Serverless",
          "Study microservices architecture and serverless computing (e.g., AWS Lambda or Azure Functions). These architectures help break down applications into smaller, independent components that are easier to scale and maintain."
        ),
        new StepDetails("Hybrid and Multi-Cloud Solutions",
          "Learn to design hybrid cloud solutions that integrate on-premises infrastructure with cloud services. Study multi-cloud strategies, where organizations use multiple cloud providers for different workloads."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Cloud Architecture Leadership",
          "Transition into roles like Cloud Architect Lead or Solutions Architect. Lead teams responsible for building, deploying, and optimizing cloud-based infrastructures for enterprises, ensuring scalability and security."
        ),
        new StepDetails("Consulting",
          "Offer cloud architecture consulting services, helping organizations migrate to the cloud, optimize their infrastructure, and reduce costs. Specializing in areas like cloud security or cloud-native development can enhance your consulting business."
        ),
        new StepDetails("Specialization",
          "Specialize in specific cloud services like machine learning on the cloud, high-performance computing (HPC), or cloud-based DevOps (DevSecOps). Specialization can open doors to high-level consulting or R&D roles."
        ),
      ]
    ),
    ["Networking", "Cloud Computing", "Computer Science"],
    ["Experimenting with Cloud Services", "Automating Tasks"],
    "Science Stream",
    "B.Tech/B.E.",
    "Computer Science and Engineering"
  ),

  // AI Researcher Career Path
  new CareerRoadMap(
    "AI Researcher",
    "AI Researchers focus on developing new artificial intelligence techniques and algorithms, pushing the boundaries of what AI can achieve. They often work on areas such as computer vision, natural language processing, or reinforcement learning.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Study artificial intelligence concepts such as supervised learning, unsupervised learning, and reinforcement learning. Learn Python and AI libraries like TensorFlow, PyTorch, and Keras, which are widely used for AI research."
        ),
        new StepDetails("Mathematics and Algorithms",
          "Develop a strong understanding of linear algebra, calculus, probability, and optimization algorithms. These mathematical concepts are crucial for understanding AI models and improving their performance."
        ),
        new StepDetails("Hands-on Projects",
          "Work on AI projects such as image classification, text generation, or recommendation systems. These projects will help you apply theoretical knowledge to practical AI problems."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Advanced AI Techniques",
          "Explore more advanced AI techniques like reinforcement learning (e.g., Q-learning), generative adversarial networks (GANs), and transfer learning. These cutting-edge techniques are used in fields such as robotics, autonomous systems, and creative AI."
        ),
        new StepDetails("AI Research Papers",
          "Start contributing to AI research by publishing papers on new algorithms, models, or techniques. Research conferences like NeurIPS, ICML, or CVPR are great platforms to showcase your work to the AI community."
        ),
        new StepDetails("Collaborate on AI Projects",
          "Collaborate with research institutions or AI-focused companies to work on AI research projects. These collaborations can provide funding, resources, and a network for advancing your AI research."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("AI Leadership and Innovation",
          "Take on leadership roles in AI research teams or departments. Drive innovation in AI by leading projects on advanced AI applications such as autonomous driving, healthcare AI, or AI-powered robotics."
        ),
        new StepDetails("Consulting and Advisory Roles",
          "Provide AI advisory services to companies looking to adopt cutting-edge AI technologies. This could involve helping them design AI strategies, develop AI solutions, or navigate ethical AI considerations."
        ),
        new StepDetails("AI Ethics and Policy",
          "Contribute to the growing field of AI ethics and policy. As AI becomes more widespread, ethical considerations such as algorithmic bias, privacy, and transparency are becoming critical topics for researchers and policymakers."
        ),
      ]
    ),
    ["Mathematics", "Artificial Intelligence", "Computer Science"],
    ["AI Experiments", "Researching AI Trends", "Programming"],
    "Science Stream",
    "B.Tech/B.E.",
    "Computer Science and Engineering"
  ),
  // Full Stack Developer Career Path
  new CareerRoadMap(
    "Full Stack Developer",
    "Full Stack Developers work on both the front-end (user interface) and back-end (server-side logic) of web applications. They are proficient in various layers of technology and can build complete applications from scratch.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Start by learning front-end languages such as HTML, CSS, and JavaScript, alongside back-end programming languages like Python, Java, or Node.js. This forms the foundation for building both client-side and server-side applications."
        ),
        new StepDetails("Databases",
          "Learn about relational databases (SQL - MySQL, PostgreSQL) and NoSQL databases (MongoDB). Understand how to design and query databases, ensuring proper data storage and retrieval for web applications."
        ),
        new StepDetails("Version Control",
          "Master Git and GitHub for version control. Learning branching, merging, and collaborative workflows is crucial for managing both the front-end and back-end code in full-stack applications."
        ),
        new StepDetails("API Integration",
          "Understand how to build and integrate REST APIs and GraphQL APIs into your applications. This allows the front-end to communicate with the back-end and fetch data effectively."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Advanced JavaScript Frameworks",
          "Master advanced front-end frameworks such as React.js, Vue.js, or Angular. These frameworks make it easier to build complex, responsive web applications."
        ),
        new StepDetails("State Management",
          "Learn how to manage application state using tools like Redux (React), Vuex (Vue.js), or MobX. Proper state management ensures that data flows smoothly between different components in your application."
        ),
        new StepDetails("Microservices Architecture",
          "Explore microservices architecture on the back-end. Break your application into smaller, independent services that communicate through APIs. Use Docker to containerize each service, and Kubernetes for orchestration."
        ),
        new StepDetails("Authentication & Authorization",
          "Implement secure authentication and authorization systems using OAuth2, JWT, or session-based authentication. These are essential for protecting user data and access control in full-stack applications."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles",
          "Move into leadership roles such as Full Stack Lead or Full Stack Architect. These roles involve overseeing the architecture of entire applications, managing development teams, and ensuring that both front-end and back-end components integrate seamlessly."
        ),
        new StepDetails("Freelancing and Consulting",
          "Offer freelance or consulting services to businesses needing end-to-end development solutions. Specialize in industries like e-commerce, SaaS applications, or fintech, where full-stack expertise is highly valuable."
        ),
        new StepDetails("Continuous Learning",
          "Stay updated on the latest full-stack trends like serverless architecture (AWS Lambda), Progressive Web Apps (PWAs), and WebAssembly. These technologies can enhance your full-stack capabilities."
        ),
      ]
    ),
    ["Computer Science", "Web Development", "Software Engineering"],
    ["Building Web Apps", "Solving Puzzles", "Learning New Frameworks"],
    "Science Stream",
    "B.Tech/B.E.",
    "Computer Science and Engineering"
  ),

  // DevSecOps Engineer Career Path
  new CareerRoadMap(
    "DevSecOps Engineer",
    "DevSecOps Engineers integrate security into the DevOps process, ensuring that security is automated and continuously maintained throughout the software development lifecycle.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Learn Linux and basic scripting (Bash, Python) to automate tasks. Study the DevOps basics including CI/CD pipelines, version control (Git), and cloud platforms (AWS, Google Cloud)."
        ),
        new StepDetails("Security Fundamentals",
          "Learn cybersecurity basics, including encryption, access control, and network security. Study security tools like firewalls and intrusion detection systems (IDS)."
        ),
        new StepDetails("Cloud Platforms",
          "Gain familiarity with deploying applications to cloud platforms like AWS, Azure, or Google Cloud. Learn cloud security practices and basic cloud infrastructure management."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Automation & Infrastructure Security",
          "Use automation tools like Jenkins, Ansible, or Terraform to automate security checks in the deployment process. Focus on Infrastructure as Code (IaC) to ensure security is built into the cloud infrastructure from the beginning."
        ),
        new StepDetails("Security Tools",
          "Learn to integrate security tools such as OWASP ZAP (for vulnerability scanning) and HashiCorp Vault (for managing secrets) into CI/CD pipelines. Secure the software delivery process by automating these checks."
        ),
        new StepDetails("Container Security",
          "Understand how to secure containerized environments (Docker) and orchestrated systems (Kubernetes). Implement container scanning tools (Clair, Aqua) to find vulnerabilities before deployment."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles",
          "Transition into roles like DevSecOps Lead or Security Automation Architect. Lead security automation efforts across the development lifecycle and work closely with development teams to ensure secure code delivery."
        ),
        new StepDetails("Security Audits and Compliance",
          "Offer consulting services for security audits and help organizations comply with regulations such as GDPR, HIPAA, and PCI-DSS. Work on creating secure, compliant, and scalable cloud architectures."
        ),
        new StepDetails("Advanced Certifications",
          "Pursue advanced security certifications like Certified Information Systems Security Professional (CISSP) or Certified Cloud Security Professional (CCSP) to solidify your expertise in cloud security."
        ),
      ]
    ),
    ["Cybersecurity", "Cloud Computing", "Networking"],
    ["Solving Security Challenges", "Automating Tasks"],
    "Science Stream",
    "B.Tech/B.E.",
    "Computer Science and Engineering"
  ),

  // UX/UI Designer Career Path
  new CareerRoadMap(
    "UX/UI Designer",
    "UX/UI Designers focus on creating user-friendly, aesthetically pleasing interfaces and ensuring an excellent user experience across digital products such as websites, apps, and software.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Start by learning the fundamentals of design, including typography, color theory, and layout. Use tools like Adobe XD, Sketch, or Figma to practice creating wireframes and prototypes."
        ),
        new StepDetails("User Experience (UX) Design",
          "Study UX principles, including user research, persona creation, and usability testing. Understand how to create user flows and wireframes to guide the user experience across applications."
        ),
        new StepDetails("User Interface (UI) Design",
          "Learn UI design patterns and how to create responsive designs for various screen sizes. Practice building high-fidelity mockups and user interfaces that are both functional and visually appealing."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Interaction Design",
          "Study how users interact with apps and websites by understanding user behavior and feedback. Create interactive prototypes using tools like InVision or Framer to test and refine your designs."
        ),
        new StepDetails("UX Research",
          "Conduct in-depth UX research by gathering user feedback, running A/B tests, and analyzing heatmaps. Use these insights to inform design decisions and improve the overall user experience."
        ),
        new StepDetails("Design Systems",
          "Build or contribute to design systems that ensure consistency across large-scale projects. Learn how to create reusable components and standardized guidelines for developers and designers to follow."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles",
          "Move into roles like UX/UI Lead or Product Design Manager. Lead design teams and work closely with product managers and developers to deliver consistent, high-quality designs for digital products."
        ),
        new StepDetails("Freelancing or Consulting",
          "Offer freelance UX/UI design services to startups, agencies, or companies. Specialize in fields like mobile design, enterprise UX, or SaaS applications."
        ),
        new StepDetails("Continuous Learning",
          "Stay updated with the latest design trends and tools like micro-interactions, motion design, and 3D interfaces. Experiment with new technologies like AR/VR to push the boundaries of digital design."
        ),
      ]
    ),
    ["Art", "Design", "Psychology"],
    ["Sketching", "Wireframing", "Solving User Problems"],
    "Science Stream",
    "B.Tech/B.E.",
    "Computer Science and Engineering"
  ),

  // Network Engineer Career Path
  new CareerRoadMap(
    "Network Engineer",
    "Network Engineers design, implement, and manage the networks that allow data to flow between computers. They ensure that communication networks like LAN, WAN, and VPNs are secure, reliable, and fast.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Study computer networking basics, including TCP/IP, DNS, DHCP, and how data packets move across networks. Learn how to configure basic network equipment such as routers, switches, and firewalls."
        ),
        new StepDetails("Network Security",
          "Understand basic network security concepts, including encryption, firewalls, VPNs, and access control lists (ACLs). Learn how to secure communication channels and prevent unauthorized access to networks."
        ),
        new StepDetails("Certifications",
          "Consider entry-level certifications like Cisco’s CCNA (Cisco Certified Network Associate) or CompTIA Network+ to validate your networking skills. These certifications are recognized industry-wide."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Network Management",
          "Learn to manage large-scale networks using network management tools like SolarWinds or Nagios. These tools help monitor network performance, detect bottlenecks, and ensure high availability."
        ),
        new StepDetails("Advanced Networking",
          "Study advanced networking topics like Software-Defined Networking (SDN), network automation (Ansible), and cloud networking (AWS VPC). These skills are in high demand as companies scale their IT infrastructure."
        ),
        new StepDetails("Wireless Networks",
          "Gain experience configuring and managing wireless networks (Wi-Fi). Learn how to optimize signal strength, troubleshoot connectivity issues, and secure wireless access points."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles",
          "Move into roles like Network Architect or IT Infrastructure Manager. These positions involve designing large-scale networks for organizations, managing teams, and ensuring network security and reliability."
        ),
        new StepDetails("Consulting",
          "Offer consulting services for network design and security. Specialize in fields such as data centers, corporate networks, or ISP-level networking. Provide solutions to enhance network performance and scalability."
        ),
        new StepDetails("Specialization",
          "Specialize in specific areas like SDN, cloud networking, or cybersecurity. These fields are rapidly growing and offer opportunities to work on cutting-edge network technologies."
        ),
      ]
    ),
    ["Networking", "Computer Science", "Electronics"],
    ["Solving Network Issues", "Tinkering with Routers"],
    "Science Stream",
    "B.Tech/B.E.",
    "Computer Science and Engineering"
  ),
  // Internet of Things (IoT) Engineer Career Path
  new CareerRoadMap(
    "IoT Engineer",
    "IoT Engineers design, develop, and implement solutions that connect physical devices (e.g., sensors, home automation) to the internet. They work on both hardware (sensors, actuators) and software (cloud platforms, protocols) to enable seamless data exchange between devices.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Learn basic programming languages like Python, C, and C++, as these are essential for embedded systems programming. Study electronics to understand how sensors, microcontrollers, and actuators work."
        ),
        new StepDetails("Microcontrollers",
          "Get hands-on experience with microcontrollers like Arduino and Raspberry Pi, which are commonly used in IoT projects. These devices help you control sensors and devices in real-world applications."
        ),
        new StepDetails("Networking",
          "Understand the basics of networking, including TCP/IP, MQTT, HTTP, and WebSockets. These protocols help IoT devices communicate with the cloud or other devices in a network."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("IoT Platforms",
          "Learn to work with IoT platforms like AWS IoT, Google Cloud IoT Core, or Microsoft Azure IoT Hub. These platforms allow you to connect, monitor, and manage IoT devices at scale."
        ),
        new StepDetails("Security",
          "Study IoT security protocols, focusing on securing devices, encrypting data, and managing identities. This is crucial in protecting IoT networks from cyberattacks."
        ),
        new StepDetails("Data Analysis",
          "Learn how to collect and analyze data from IoT devices. Use data analytics tools like Apache Kafka or cloud-based analytics platforms to process sensor data in real time."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles",
          "Transition into roles like IoT Architect or IoT System Designer. These roles involve designing IoT systems at scale for industries like healthcare, smart cities, and industrial automation."
        ),
        new StepDetails("Consulting",
          "Provide IoT consulting services for companies in industries like agriculture, healthcare, or smart home technologies. Help businesses implement and scale IoT solutions for operational efficiency."
        ),
        new StepDetails("IoT Innovation",
          "Work on emerging IoT fields such as edge computing, where data processing happens closer to devices, reducing latency. Explore applications in autonomous vehicles, wearable technology, and smart grids."
        ),
      ]
    ),
    ["Electronics", "Computer Science", "Networking"],
    ["Building Gadgets", "Tinkering with Smart Devices", "Programming"],
    "Science Stream",
    "B.Tech/B.E.",
    "Computer Science and Engineering"
  ),

  // Data Engineer Career Path
  new CareerRoadMap(
    "Data Engineer",
    "Data Engineers build and maintain the infrastructure required to store, process, and analyze large volumes of data. They design data pipelines, databases, and systems that help organizations gather actionable insights from raw data.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Start with learning programming languages like Python, Java, and SQL, which are commonly used for data engineering. Study basic database concepts, including how relational (SQL) and non-relational (NoSQL) databases work."
        ),
        new StepDetails("Data Pipelines",
          "Learn how to build data pipelines using tools like Apache Airflow or AWS Glue. Data pipelines are essential for automating the extraction, transformation, and loading (ETL) of data from different sources into databases or data warehouses."
        ),
        new StepDetails("Cloud Storage",
          "Study cloud storage solutions like AWS S3, Google Cloud Storage, or Azure Blob Storage. These cloud platforms provide scalable, secure storage for large datasets."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Big Data Tools",
          "Master big data tools like Apache Hadoop and Apache Spark for processing and analyzing large datasets. Learn distributed computing concepts, which are key for working with massive data volumes."
        ),
        new StepDetails("Data Warehousing",
          "Learn how to design and manage data warehouses using services like AWS Redshift, Google BigQuery, or Snowflake. Data warehouses allow for complex querying and analysis of structured data."
        ),
        new StepDetails("Data Lake Architecture",
          "Study the architecture of data lakes, which store unstructured, semi-structured, and structured data in raw form. Learn tools like Apache Hive and Delta Lake to manage data lakes efficiently."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles",
          "Transition into roles like Data Engineering Lead or Chief Data Officer (CDO). These roles involve managing teams, overseeing data infrastructure, and ensuring data quality across an organization."
        ),
        new StepDetails("Data Governance",
          "Focus on data governance policies and compliance, ensuring that data is managed securely and in compliance with regulations like GDPR and HIPAA. Work with organizations to develop data strategies that align with business goals."
        ),
        new StepDetails("Continuous Learning",
          "Stay updated with the latest advancements in data engineering, such as real-time data streaming, serverless data processing, and automated data pipelines. Experiment with new tools like Databricks and stream processing with Apache Kafka."
        ),
      ]
    ),
    ["Mathematics", "Database Systems", "Computer Science"],
    ["Solving Data Challenges", "Cloud Computing"],
    "Science Stream",
    "B.Tech/B.E.",
    "Computer Science and Engineering"
  ),

  // Cloud Security Specialist Career Path
  new CareerRoadMap(
    "Cloud Security Specialist",
    "Cloud Security Specialists focus on ensuring that cloud-based systems and data are secure. They implement best practices to protect cloud infrastructure from cyberattacks and data breaches.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Learn the basics of cloud computing platforms such as AWS, Microsoft Azure, or Google Cloud. Study networking and cloud infrastructure security concepts like virtual private clouds (VPCs), firewalls, and encryption."
        ),
        new StepDetails("Cloud Certifications",
          "Consider entry-level cloud certifications such as AWS Certified Security – Specialty, Microsoft Certified Azure Security Engineer, or Google Professional Cloud Security Engineer to validate your cloud security knowledge."
        ),
        new StepDetails("Cloud Identity Management",
          "Study identity and access management (IAM) tools to control who has access to cloud resources. Learn to implement multi-factor authentication (MFA) and role-based access control (RBAC) for enhanced security."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Cloud Security Best Practices",
          "Learn to implement cloud security best practices, including encryption at rest and in transit, regular audits, and continuous monitoring. Use security services like AWS GuardDuty or Azure Security Center to automate monitoring."
        ),
        new StepDetails("Cloud Incident Response",
          "Develop expertise in cloud incident response. Learn how to detect and respond to security breaches or vulnerabilities in real time. Study forensics to trace the source of breaches and recover compromised data."
        ),
        new StepDetails("Compliance",
          "Understand cloud compliance regulations such as GDPR, HIPAA, and SOC 2. Learn how to ensure cloud infrastructures meet these regulations and implement automated tools for auditing compliance in cloud environments."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles",
          "Transition into roles like Cloud Security Architect or Cloud Security Lead, overseeing cloud security operations and ensuring that all cloud infrastructure is secure and compliant with regulations."
        ),
        new StepDetails("Consulting",
          "Provide cloud security consulting services to organizations looking to move to the cloud or strengthen their existing cloud infrastructure. Help design secure cloud architectures and improve security postures."
        ),
        new StepDetails("Continuous Learning",
          "Stay up-to-date with the latest cloud security trends such as zero-trust architecture, cloud-native security tools, and AI-driven threat detection. Cloud security is an ever-evolving field, so continuous learning is crucial."
        ),
      ]
    ),
    ["Cybersecurity", "Cloud Computing", "Networking"],
    ["Building Secure Systems", "Cloud Services"],
    "Science Stream",
    "B.Tech/B.E.",
    "Computer Science and Engineering"
  ),

  // Quantum Computing Researcher Career Path
  new CareerRoadMap(
    "Quantum Computing Researcher",
    "Quantum Computing Researchers focus on developing new quantum algorithms and applications that leverage the principles of quantum mechanics to solve complex computational problems beyond the reach of classical computers.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Start by learning the basics of quantum mechanics and quantum computing concepts such as qubits, superposition, and entanglement. Use online platforms like IBM’s Quantum Experience to experiment with simple quantum algorithms."
        ),
        new StepDetails("Quantum Programming",
          "Learn quantum programming languages such as Qiskit (Python-based), Microsoft’s Q# (Q-sharp), or Google's Cirq. These languages allow you to write and simulate quantum algorithms on quantum hardware."
        ),
        new StepDetails("Linear Algebra",
          "Develop a strong understanding of linear algebra, as it is the mathematical foundation of quantum computing. Study matrix operations, eigenvalues, eigenvectors, and tensor products, which are essential for quantum algorithms."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Quantum Algorithms",
          "Study quantum algorithms such as Grover’s search algorithm and Shor’s factoring algorithm. These algorithms demonstrate the potential of quantum computing to solve certain problems exponentially faster than classical algorithms."
        ),
        new StepDetails("Quantum Hardware",
          "Learn about quantum hardware platforms such as IBM’s quantum processors, Google’s Sycamore, or D-Wave’s quantum annealing systems. Understand how quantum bits (qubits) are physically implemented and controlled."
        ),
        new StepDetails("Research Collaborations",
          "Collaborate with research institutions or join academic research groups focused on quantum computing. Contribute to research papers and projects that advance the state of quantum algorithms, error correction, and quantum cryptography."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Quantum Innovation",
          "Lead cutting-edge quantum research initiatives in industries such as finance, pharmaceuticals, and materials science. Explore how quantum computing can revolutionize fields like cryptography, drug discovery, or supply chain optimization."
        ),
        new StepDetails("Quantum Computing Leadership",
          "Transition into roles like Quantum Computing Research Lead or Director of Quantum Research. These roles involve managing research teams, driving innovation, and building quantum computing solutions for commercial applications."
        ),
        new StepDetails("Consulting and Academia",
          "Provide consulting services for companies looking to explore quantum computing applications, or become a professor in quantum computing to mentor future researchers. Quantum computing is still in its infancy, and there are numerous opportunities to contribute to its growth."
        ),
      ]
    ),
    ["Physics", "Mathematics", "Computer Science"],
    ["Solving Complex Problems", "Experimenting with Quantum Systems"],
    "Science Stream",
    "B.Tech/B.E.",
    "Computer Science and Engineering"
  ),

  // Artificial Intelligence Ethicist Career Path
  new CareerRoadMap(
    "Artificial Intelligence Ethicist",
    "AI Ethicists focus on ensuring that artificial intelligence systems are developed and deployed ethically. They assess the impact of AI on society, including issues like bias, privacy, transparency, and accountability.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Start by learning the basics of artificial intelligence and machine learning. Understand how AI systems work, and study ethical frameworks that guide the responsible development of technology."
        ),
        new StepDetails("AI Bias",
          "Study AI bias and fairness, focusing on how bias can enter AI systems and how it can affect decision-making. Learn techniques for detecting and mitigating bias in data and algorithms."
        ),
        new StepDetails("Privacy and Ethics",
          "Understand the legal and ethical considerations of data privacy, particularly with respect to AI. Study privacy-preserving AI techniques like differential privacy and federated learning, which help protect user data while still enabling machine learning."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("AI Ethics Policies",
          "Work with organizations to create and implement AI ethics policies that promote transparency, fairness, and accountability. Develop guidelines that ensure AI systems are aligned with human rights and ethical principles."
        ),
        new StepDetails("Regulatory Frameworks",
          "Study AI regulations like the European Union’s General Data Protection Regulation (GDPR) and proposed AI regulatory frameworks. Understand how these laws affect AI development and deployment in various industries."
        ),
        new StepDetails("AI Auditing",
          "Learn how to audit AI systems for ethical compliance. This includes assessing the impact of AI on marginalized communities, ensuring explainability, and verifying that AI systems are free from harmful biases."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership in AI Ethics",
          "Transition into leadership roles such as AI Ethics Lead or Chief AI Ethics Officer. These roles involve guiding the ethical development of AI systems across organizations and industries, ensuring that AI technologies benefit society as a whole."
        ),
        new StepDetails("Consulting and Advocacy",
          "Provide AI ethics consulting services, helping organizations navigate the ethical challenges of deploying AI systems. Work with NGOs and advocacy groups to promote ethical AI practices on a global scale."
        ),
        new StepDetails("Research and Innovation",
          "Contribute to AI ethics research by exploring new methods for ensuring fairness, transparency, and accountability in AI systems. Publish papers, participate in ethics-focused conferences, and collaborate with policymakers."
        ),
      ]
    ),
    ["Philosophy", "Computer Science", "Law"],
    ["Ethical Debates", "Reading on Social Impacts of Technology"],
    "Science Stream",
    "B.Tech/B.E.",
    "Computer Science and Engineering"
  ),
  // Mechanical Engineering Career Paths

  // Automotive Engineer Career Path
  new CareerRoadMap(
    "Automotive Engineer",
    "Automotive Engineers design, develop, and test vehicles, including cars, trucks, motorcycles, and their subsystems. They work on improving performance, fuel efficiency, safety, and the overall experience of the vehicle.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Start with studying the basics of automotive engineering, which includes vehicle dynamics, thermodynamics, fluid mechanics, and materials science. Learn about powertrains, combustion engines, and electric vehicles (EVs)."
        ),
        new StepDetails("CAD and FEA Tools",
          "Learn Computer-Aided Design (CAD) software such as CATIA, SolidWorks, or AutoCAD for designing vehicle components. Study Finite Element Analysis (FEA) tools like ANSYS to simulate how parts will behave under stress, heat, and vibration."
        ),
        new StepDetails("Vehicle Systems",
          "Study vehicle subsystems such as braking, transmission, and steering systems. Learn about the interaction between mechanical, electrical, and electronic components in modern vehicles."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Advanced Automotive Technologies",
          "Study advanced vehicle technologies such as hybrid vehicles, electric drivetrains, and autonomous driving systems. Learn about the integration of sensors, cameras, and LIDAR in autonomous vehicles."
        ),
        new StepDetails("Vehicle Testing and Simulation",
          "Learn vehicle testing techniques such as crash testing, durability testing, and performance analysis. Use tools like MATLAB/Simulink to simulate vehicle dynamics, fuel efficiency, and control systems."
        ),
        new StepDetails("Industry Certifications",
          "Consider industry certifications like SAE (Society of Automotive Engineers) certifications or ISO certifications related to automotive design and manufacturing to boost your credibility."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles",
          "Transition into roles like Automotive Engineering Lead, Project Manager, or Chief Engineer, where you'll oversee vehicle design, development, and testing projects."
        ),
        new StepDetails("Specialization",
          "Specialize in emerging fields like electric vehicles (EVs), autonomous vehicle systems, or motorsports engineering. These areas are growing rapidly and offer opportunities for innovation."
        ),
        new StepDetails("Consulting and Entrepreneurship",
          "Start your own consulting firm focusing on automotive design and development or electric vehicle systems. Alternatively, consider starting a business in vehicle modification, performance tuning, or electric vehicle retrofitting."
        ),
      ]
    ),
    ["Physics", "Thermodynamics", "Mathematics", "Fluid Mechanics"],
    ["Car Modifications", "Tinkering with Engines", "Racing"],
    "Science Stream",
    "B.Tech/B.E.",
    "Mechanical Engineering"
  ),

  // Aerospace Engineer Career Path
  new CareerRoadMap(
    "Aerospace Engineer",
    "Aerospace Engineers design, develop, and test aircraft, spacecraft, satellites, and missiles. They focus on improving flight safety, fuel efficiency, and the structural integrity of aerospace vehicles.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Learn the fundamentals of aerospace engineering, including aerodynamics, propulsion systems, thermodynamics, and fluid dynamics. Study materials used in aerospace applications, such as composites and high-strength alloys."
        ),
        new StepDetails("CAD and Simulation Tools",
          "Master CAD software like CATIA, SolidWorks, or Siemens NX for designing aerospace components. Learn simulation tools like ANSYS or MATLAB/Simulink for structural analysis, aerodynamics, and flight simulations."
        ),
        new StepDetails("Propulsion Systems",
          "Understand how jet engines, rocket engines, and other propulsion systems work. Study the principles of combustion and propulsion, as well as the design and optimization of these systems."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Aircraft and Spacecraft Design",
          "Work on the design of aircraft, satellites, and spacecraft. Focus on aerodynamics, structural analysis, and propulsion systems. Learn about drag reduction, weight optimization, and fuel efficiency."
        ),
        new StepDetails("Flight Testing",
          "Learn how to conduct flight testing and performance evaluations. Use data from flight tests to refine designs, improve safety, and enhance performance."
        ),
        new StepDetails("Industry Certifications",
          "Consider obtaining industry certifications such as those from the FAA (Federal Aviation Administration) or EASA (European Union Aviation Safety Agency) to enhance your credentials in aerospace design and safety."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles",
          "Transition into roles like Aerospace Engineering Lead, Chief Engineer, or Aerospace Project Manager, overseeing the design and testing of aircraft or spacecraft."
        ),
        new StepDetails("Specialization",
          "Specialize in areas like space exploration, satellite design, or unmanned aerial vehicles (UAVs). The demand for innovative solutions in space and defense sectors is growing."
        ),
        new StepDetails("Entrepreneurship and Consulting",
          "Start your own aerospace consulting firm or launch a startup focused on emerging technologies like space tourism, satellite deployment, or UAVs. The private space sector is expanding rapidly, offering new opportunities for innovation."
        ),
      ]
    ),
    ["Physics", "Aerodynamics", "Fluid Mechanics", "Mathematics"],
    ["Building Model Planes", "Space Exploration", "Rocketry"],
    "Science Stream",
    "B.Tech/B.E.",
    "Mechanical Engineering"
  ),

  // Robotics Engineer Career Path
  new CareerRoadMap(
    "Robotics Engineer",
    "Robotics Engineers design, build, and integrate mechanical systems for robots used in industries like manufacturing, healthcare, and defense. They focus on the mechanical structure, sensors, actuators, and control systems of robots.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Study the fundamentals of mechanical systems used in robotics, including kinematics, dynamics, and thermodynamics. Learn programming basics in C/C++ to control robotic systems, but with a focus on hardware integration."
        ),
        new StepDetails("CAD and Simulation",
          "Master CAD software like SolidWorks or CATIA to design mechanical parts for robots. Use tools like MATLAB or Simulink for simulating robotic movements, and Finite Element Analysis (FEA) tools to test material stress and durability."
        ),
        new StepDetails("Hands-on Projects",
          "Get experience by building simple mechanical robots using kits like Arduino or Raspberry Pi but focus on the mechanical aspects like arm articulation, joints, and movement precision."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Mechatronics and Control Systems",
          "Deepen your understanding of mechatronics by learning about sensors, actuators, and motor controllers. Study how control systems work to manage the movement and precision of robotic arms and automated systems."
        ),
        new StepDetails("Industrial Robotics",
          "Learn about industrial robots used in manufacturing. Study how robotic arms, CNC machines, and autonomous machines are used in factory automation. Gain experience in automation software like PLC programming."
        ),
        new StepDetails("Advanced Robotics Design",
          "Design and simulate complex robots for specialized industries like healthcare (robotic surgery), defense (robotic drones), or autonomous vehicles. Study control algorithms and advanced motion planning techniques."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles",
          "Move into leadership roles such as Robotics Engineering Lead or Robotics Project Manager. Manage teams building mechanical systems for robotic applications in industries such as manufacturing or defense."
        ),
        new StepDetails("Specialization",
          "Specialize in fields like medical robotics (robotic surgery systems), autonomous vehicles, or precision robotics for space exploration. These are emerging fields with increasing demand for mechanical expertise."
        ),
        new StepDetails("Entrepreneurship and Consulting",
          "Start your own consulting or robotics design firm, focusing on developing industrial robotic systems or custom robotic solutions for niche applications like automation, precision manufacturing, or medical devices."
        ),
      ]
    ),
    ["Mechanics", "Thermodynamics", "Kinematics"],
    ["Building Robots", "Mechanical Design", "Automating Systems"],
    "Science Stream",
    "B.Tech/B.E.",
    "Mechanical Engineering"
  ),

  // HVAC Engineer Career Path
  new CareerRoadMap(
    "HVAC Engineer",
    "HVAC (Heating, Ventilation, and Air Conditioning) Engineers design and implement systems to control the climate and air quality inside buildings. They focus on energy efficiency, environmental impact, and occupant comfort.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Learn the fundamentals of thermodynamics, heat transfer, and fluid mechanics, which are essential for designing HVAC systems. Study HVAC systems design and energy efficiency principles."
        ),
        new StepDetails("HVAC Software",
          "Learn to use HVAC design software like AutoCAD, Carrier HAP, or Trane TRACE. These tools help you design and simulate HVAC systems for commercial and residential buildings."
        ),
        new StepDetails("Regulations and Standards",
          "Familiarize yourself with building codes, energy standards (ASHRAE), and environmental regulations (EPA guidelines) related to HVAC design. Ensuring compliance with these standards is essential for HVAC systems design."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Energy Efficiency",
          "Focus on designing energy-efficient HVAC systems. Study renewable energy technologies like solar heating or geothermal systems, and learn how to integrate them into HVAC designs."
        ),
        new StepDetails("Building Management Systems (BMS)",
          "Learn about building management systems (BMS) that control and monitor HVAC systems. Study how to integrate HVAC systems with smart building technologies to optimize energy use and indoor comfort."
        ),
        new StepDetails("Green Building Certifications",
          "Consider obtaining certifications such as LEED (Leadership in Energy and Environmental Design) or ASHRAE certifications to demonstrate expertise in energy-efficient and environmentally friendly HVAC design."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles",
          "Move into roles like HVAC Project Manager or Chief HVAC Engineer, leading teams that design and implement HVAC systems for large-scale commercial or residential projects."
        ),
        new StepDetails("Specialization",
          "Specialize in areas like industrial HVAC systems, cleanroom environments, or energy-efficient building designs. Emerging trends like smart HVAC systems offer opportunities for innovation."
        ),
        new StepDetails("Consulting and Entrepreneurship",
          "Start an HVAC consulting firm, providing design and energy efficiency consulting services to businesses or homeowners. Focus on green building solutions and environmentally sustainable HVAC designs."
        ),
      ]
    ),
    ["Thermodynamics", "Heat Transfer", "Fluid Mechanics"],
    ["Tinkering with AC Units", "Energy Efficiency Projects"],
    "Science Stream",
    "B.Tech/B.E.",
    "Mechanical Engineering"
  ),

  // Renewable Energy Engineer Career Path
  new CareerRoadMap(
    "Renewable Energy Engineer",
    "Renewable Energy Engineers design and develop systems that generate energy from renewable sources, such as solar, wind, geothermal, and biomass. They focus on improving efficiency, sustainability, and energy production.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education",
          "Start by studying energy systems, including thermodynamics, heat transfer, and power generation. Learn about different renewable energy sources such as solar, wind, geothermal, and biomass."
        ),
        new StepDetails("Energy System Design",
          "Learn how to design renewable energy systems, including solar panels, wind turbines, and geothermal systems. Use software tools like PVsyst (for solar energy) and WindPro (for wind energy) to simulate energy production."
        ),
        new StepDetails("Energy Efficiency",
          "Study energy efficiency principles, focusing on maximizing energy output while minimizing environmental impact. Learn how to design energy-efficient buildings, transportation systems, and industrial processes."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Energy Storage Systems",
          "Study energy storage technologies such as batteries, pumped hydro, and compressed air energy storage (CAES). Energy storage is essential for balancing supply and demand in renewable energy systems."
        ),
        new StepDetails("Grid Integration",
          "Learn how to integrate renewable energy systems into the electrical grid. Study power electronics, grid stability, and smart grid technologies, which allow for efficient energy distribution and management."
        ),
        new StepDetails("Certifications",
          "Consider obtaining certifications such as NABCEP (North American Board of Certified Energy Practitioners) or LEED to enhance your expertise in renewable energy system design and implementation."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles",
          "Transition into roles like Renewable Energy Project Manager or Chief Engineer, leading large-scale renewable energy projects, such as solar farms or wind energy installations."
        ),
        new StepDetails("Specialization",
          "Specialize in emerging fields like floating solar farms, offshore wind energy, or energy storage innovation. These areas are expected to grow significantly as countries focus on reducing carbon emissions."
        ),
        new StepDetails("Entrepreneurship and Consulting",
          "Consider starting a renewable energy consulting firm or launching a company focused on providing sustainable energy solutions. Help industries, governments, or homeowners transition to clean energy."
        ),
      ]
    ),
    ["Physics", "Thermodynamics", "Energy Systems"],
    ["Solar Panel Installations", "Sustainable Projects"],
    "Science Stream",
    "B.Tech/B.E.",
    "Mechanical Engineering"
  ),
  // Structural Engineer Career Path
  new CareerRoadMap(
    "Structural Engineer",
    "Structural Engineers design and analyze structures to ensure they can withstand the loads and forces they encounter. They work on buildings, bridges, tunnels, towers, and other infrastructure projects.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education", 
          "Study the fundamentals of structural engineering, including statics, dynamics, mechanics of materials, and structural analysis. Learn to calculate loads, stresses, and how materials like concrete, steel, and wood behave under stress."
        ),
        new StepDetails("CAD and Design Tools", 
          "Learn to use design and analysis software like AutoCAD, SAP2000, and ETABS for modeling and analyzing structures. These tools help visualize structural designs and analyze their load-bearing capacity."
        ),
        new StepDetails("Material Properties", 
          "Study the properties of construction materials such as concrete, steel, and timber. Understand how different materials respond to forces like compression, tension, and shear."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Advanced Structural Design", 
          "Learn advanced structural analysis techniques such as finite element analysis (FEA) for complex structures. Work on seismic design, wind load analysis, and other critical factors affecting the stability of structures."
        ),
        new StepDetails("Industry Standards and Codes", 
          "Study building codes and regulations like IS codes (India), Eurocodes (Europe), or ACI (American Concrete Institute) standards. Ensure your designs comply with these standards for safety and reliability."
        ),
        new StepDetails("Project Management", 
          "Gain experience managing structural projects from conception through construction. Learn to coordinate with architects, contractors, and other engineers to ensure the successful completion of structural designs."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles", 
          "Move into roles like Structural Engineering Lead or Chief Structural Engineer. Lead large infrastructure projects such as bridges, high-rise buildings, or dams, ensuring they meet safety and design requirements."
        ),
        new StepDetails("Specialization", 
          "Specialize in areas like seismic design, high-rise buildings, or bridge engineering. These specializations require expertise in advanced structural dynamics and offer high-demand roles in civil infrastructure projects."
        ),
        new StepDetails("Consulting and Entrepreneurship", 
          "Start your own structural engineering consultancy or design firm. Offer services such as structural analysis, failure investigations, and retrofitting solutions for old or damaged structures."
        ),
      ]
    ),
    ["Mathematics", "Statics", "Mechanics of Materials"],
    ["Building Models", "Construction Projects", "Problem Solving"],
    "Science Stream",
    "B.Tech/B.E.",
    "Civil Engineering"
  ),

  // Geotechnical Engineer Career Path
  new CareerRoadMap(
    "Geotechnical Engineer",
    "Geotechnical Engineers analyze soil, rock, and groundwater conditions to design foundations, retaining walls, and tunnels. They ensure that structures are built on stable ground and can withstand natural forces like earthquakes and landslides.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education", 
          "Study soil mechanics, rock mechanics, and foundation engineering. Learn how to analyze soil properties such as shear strength, compressibility, and permeability to assess the stability of the ground."
        ),
        new StepDetails("Fieldwork and Sampling", 
          "Gain experience in fieldwork, including soil sampling, drilling, and performing standard tests like the Standard Penetration Test (SPT) and Cone Penetration Test (CPT). Fieldwork helps determine soil conditions for construction."
        ),
        new StepDetails("Geotechnical Software", 
          "Learn geotechnical analysis software like PLAXIS, GeoStudio, and Rocscience. These tools help model soil behavior, analyze slope stability, and design retaining walls and foundations."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Foundation Design", 
          "Learn to design deep foundations (piles) and shallow foundations (footings) for buildings and bridges. Understand how to ensure stability, bearing capacity, and settlement control in different soil conditions."
        ),
        new StepDetails("Retaining Structures", 
          "Work on the design of retaining walls, slope stabilization, and earth retention systems. Learn about lateral earth pressure theories and how to design structures that can hold back soil."
        ),
        new StepDetails("Seismic Analysis", 
          "Study the effects of earthquakes on soil and rock behavior. Learn how to design foundations and retaining systems that can withstand seismic forces, liquefaction, and other earthquake-related ground failures."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles", 
          "Move into roles like Geotechnical Project Manager or Lead Geotechnical Engineer. Oversee geotechnical investigations and designs for large projects such as dams, tunnels, or offshore structures."
        ),
        new StepDetails("Specialization", 
          "Specialize in areas like tunneling, slope stability, or seismic geotechnics. These fields offer opportunities to work on challenging projects like underground metro systems or landslide mitigation."
        ),
        new StepDetails("Consulting and Research", 
          "Offer geotechnical consulting services for infrastructure projects or work in research to develop new technologies for soil improvement, foundation design, and geotechnical disaster mitigation."
        ),
      ]
    ),
    ["Geology", "Soil Mechanics", "Statics"],
    ["Outdoor Exploration", "Soil Sampling", "Problem-Solving"],
    "Science Stream",
    "B.Tech/B.E.",
    "Civil Engineering"
  ),

  // Environmental Engineer Career Path
  new CareerRoadMap(
    "Environmental Engineer",
    "Environmental Engineers design systems to control pollution, manage waste, and protect natural resources. They work on projects related to air and water quality, wastewater treatment, and environmental sustainability.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education", 
          "Study environmental science, hydrology, and waste management. Learn how to assess environmental impacts, model water quality, and design systems for pollution control and waste treatment."
        ),
        new StepDetails("Environmental Regulations", 
          "Familiarize yourself with environmental laws and regulations, such as EPA (Environmental Protection Agency) standards, and international environmental treaties. Compliance with these regulations is essential for most projects."
        ),
        new StepDetails("Environmental Management Systems (EMS)", 
          "Learn how to implement environmental management systems (EMS) like ISO 14001 to improve environmental performance, monitor compliance, and reduce environmental impacts in construction projects."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Water and Wastewater Treatment", 
          "Design water and wastewater treatment systems. Understand how to treat industrial and domestic waste to remove contaminants and design facilities for safe disposal or reuse."
        ),
        new StepDetails("Sustainable Engineering", 
          "Focus on designing sustainable infrastructure, integrating renewable energy sources, and reducing the carbon footprint of buildings and cities. Study LEED certification for designing green buildings."
        ),
        new StepDetails("Air Quality Control", 
          "Study methods for controlling air pollution. Learn to design filtration systems, emissions control technologies, and air quality monitoring systems to minimize pollutants from industrial and construction activities."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles", 
          "Transition into roles like Environmental Project Manager or Chief Environmental Engineer. Lead large-scale environmental projects such as water treatment plants, renewable energy projects, or city-wide waste management systems."
        ),
        new StepDetails("Consulting and Policy", 
          "Work as an environmental consultant, helping companies comply with environmental regulations and adopt sustainable practices. Alternatively, focus on environmental policy and work with governments to shape environmental laws."
        ),
        new StepDetails("Research and Innovation", 
          "Focus on research and innovation in areas like climate change mitigation, renewable energy systems, and water recycling. Develop new technologies for sustainable resource management and environmental protection."
        ),
      ]
    ),
    ["Environmental Science", "Hydrology", "Chemistry"],
    ["Sustainability Projects", "Recycling", "Water Conservation"],
    "Science Stream",
    "B.Tech/B.E.",
    "Civil Engineering"
  ),

  // Transportation Engineer Career Path
  new CareerRoadMap(
    "Transportation Engineer",
    "Transportation Engineers design, develop, and maintain efficient transportation systems, including roads, highways, railways, airports, and public transit systems. They focus on improving traffic flow, safety, and sustainability.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education", 
          "Study transportation engineering fundamentals, including traffic engineering, highway design, and transportation planning. Learn to analyze traffic flow and design systems to improve mobility and reduce congestion."
        ),
        new StepDetails("Transportation Design Tools", 
          "Learn to use transportation design and analysis software like AutoCAD Civil 3D, TransCAD, and PTV VISSIM for traffic simulations and road design. These tools are essential for planning and optimizing transportation systems."
        ),
        new StepDetails("Traffic Control Systems", 
          "Study traffic control systems, including traffic signal design, traffic management, and intelligent transportation systems (ITS) that use sensors, cameras, and software to manage traffic in real-time."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Highway and Railway Design", 
          "Focus on the design of highways, railways, and mass transit systems. Learn to plan transportation networks, optimize route alignment, and design infrastructure to accommodate future growth."
        ),
        new StepDetails("Sustainable Transportation", 
          "Work on sustainable transportation solutions, such as designing bike lanes, pedestrian-friendly spaces, and public transit systems to reduce carbon emissions and promote eco-friendly transportation."
        ),
        new StepDetails("Transportation Modeling", 
          "Study transportation modeling and simulation. Use models to predict traffic demand, optimize routes, and design efficient public transit systems. Simulate future traffic scenarios to improve infrastructure design."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles", 
          "Transition into roles like Transportation Project Manager or Transportation Engineering Lead, where you'll oversee the development of large-scale transportation projects like highways, railways, or urban transit systems."
        ),
        new StepDetails("Consulting and Policy", 
          "Offer consulting services to cities, governments, or private companies to improve transportation networks and integrate new technologies like autonomous vehicles. Alternatively, work on transportation policy and urban planning."
        ),
        new StepDetails("Specialization", 
          "Specialize in areas like traffic engineering, railway systems, or intelligent transportation systems (ITS). These fields are becoming increasingly important as cities grow and the demand for efficient transportation systems increases."
        ),
      ]
    ),
    ["Mathematics", "Traffic Flow", "Urban Planning"],
    ["Solving Traffic Problems", "Exploring Transit Systems"],
    "Science Stream",
    "B.Tech/B.E.",
    "Civil Engineering"
  ),

  // Construction Manager Career Path
  new CareerRoadMap(
    "Construction Manager",
    "Construction Managers oversee construction projects from start to finish, ensuring that projects are completed on time, within budget, and according to specifications. They coordinate with engineers, architects, and contractors.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education", 
          "Learn the fundamentals of construction management, including project planning, cost estimation, and scheduling. Study construction methods, building materials, and site safety practices."
        ),
        new StepDetails("Project Management Tools", 
          "Learn project management software such as Primavera, Microsoft Project, and Procore to schedule tasks, allocate resources, and track project progress. These tools help manage large construction projects efficiently."
        ),
        new StepDetails("Site Management", 
          "Gain hands-on experience in site management, learning how to oversee construction activities, ensure compliance with building codes, and manage workers, equipment, and materials on-site."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Cost Estimation and Budgeting", 
          "Develop skills in cost estimation, budgeting, and financial planning for construction projects. Learn how to prepare bids, manage project budgets, and control costs to ensure projects stay within budget."
        ),
        new StepDetails("Contract Management", 
          "Study construction contracts, legal agreements, and tendering processes. Learn to manage contracts with subcontractors and suppliers, ensuring that all parties meet their obligations."
        ),
        new StepDetails("Sustainability in Construction", 
          "Focus on sustainable construction practices, including energy-efficient building designs, waste management, and the use of eco-friendly materials. Study green building certifications such as LEED."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles", 
          "Move into roles like Construction Project Manager or Director of Construction. Lead large construction projects such as commercial buildings, infrastructure projects, or housing developments."
        ),
        new StepDetails("Specialization", 
          "Specialize in areas like commercial construction, residential development, or heavy civil engineering projects (e.g., bridges, dams). This can help you focus your career on high-demand areas in the construction industry."
        ),
        new StepDetails("Entrepreneurship", 
          "Start your own construction management firm, providing project management services to developers and contractors. Offer specialized services such as green building consultancy or construction cost management."
        ),
      ]
    ),
    ["Project Management", "Mathematics", "Building Materials"],
    ["Organizing Projects", "Supervising Construction Sites"],
    "Science Stream",
    "B.Tech/B.E.",
    "Civil Engineering"
  ),
  // Hydraulic Engineer Career Path
  new CareerRoadMap(
    "Hydraulic Engineer",
    "Hydraulic Engineers design and manage systems that control the flow and storage of water, including dams, levees, canals, and stormwater management systems. They focus on managing water resources for flood control, irrigation, and municipal water supply.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education", 
          "Study fluid mechanics, hydrology, and water resources engineering. Learn how water flows through natural and man-made channels, and study how to design hydraulic structures like dams and spillways."
        ),
        new StepDetails("Hydraulic Modeling Tools", 
          "Learn to use hydraulic modeling software such as HEC-RAS, SWMM, and EPANET to simulate water flow and design water management systems. These tools help model river flow, drainage systems, and water distribution networks."
        ),
        new StepDetails("Fieldwork", 
          "Gain experience in fieldwork, including surveying riverbeds, measuring water flow, and assessing flood risks. Field experience is critical for understanding real-world water management challenges."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Irrigation and Flood Control Systems", 
          "Design large-scale irrigation systems for agriculture or flood control systems like levees, spillways, and floodgates. Learn how to balance water usage and flood mitigation strategies for urban and rural areas."
        ),
        new StepDetails("Hydraulic Structure Design", 
          "Focus on designing hydraulic structures like dams, canals, and water treatment plants. Study how to manage the pressure, velocity, and flow of water through these systems."
        ),
        new StepDetails("Environmental Impact and Water Conservation", 
          "Study the environmental impact of hydraulic engineering projects and work on solutions for water conservation, including rainwater harvesting and greywater recycling systems."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles", 
          "Move into roles like Hydraulic Project Manager or Chief Hydraulic Engineer. Lead large infrastructure projects such as dam construction, flood control systems, or municipal water supply systems."
        ),
        new StepDetails("Consulting and Specialization", 
          "Specialize in areas like flood risk management, coastal engineering, or sustainable water resource management. Provide consulting services to governments or industries on managing water resources sustainably."
        ),
        new StepDetails("Innovation in Water Management", 
          "Work on innovative water management solutions such as desalination, hydropower, and advanced stormwater management systems. These areas are crucial for addressing global water scarcity and environmental challenges."
        ),
      ]
    ),
    ["Fluid Mechanics", "Hydrology", "Water Resources"],
    ["Water Conservation", "Environmental Projects", "Solving Water Issues"],
    "Science Stream",
    "B.Tech/B.E.",
    "Civil Engineering"
  ),

  // Urban Planner Career Path
  new CareerRoadMap(
    "Urban Planner",
    "Urban Planners design and develop land use plans and programs that help create communities, accommodate population growth, and revitalize physical facilities in towns, cities, counties, and metropolitan areas.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education", 
          "Study urban planning, environmental design, and zoning laws. Learn how to conduct demographic studies, assess land use patterns, and understand infrastructure planning in urban areas."
        ),
        new StepDetails("GIS Tools", 
          "Learn to use Geographic Information Systems (GIS) software like ArcGIS to analyze spatial data, map out infrastructure projects, and assess the impact of urban growth. GIS is essential for urban planning."
        ),
        new StepDetails("Field Experience", 
          "Gain field experience by participating in local planning projects or internships with city planning departments. Learn how to conduct surveys, analyze transportation patterns, and assess the needs of growing communities."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Sustainable Urban Development", 
          "Focus on sustainable development strategies like smart cities, green infrastructure, and transit-oriented development. Learn how to plan cities that minimize environmental impact and support long-term growth."
        ),
        new StepDetails("Public Transit and Infrastructure Planning", 
          "Work on designing efficient public transportation systems and infrastructure projects such as roads, bridges, and utilities. Understand how to accommodate growing populations and improve mobility in urban areas."
        ),
        new StepDetails("Zoning Laws and Regulations", 
          "Develop expertise in zoning laws and land use regulations. Help communities balance commercial, residential, and industrial development while preserving open spaces and natural resources."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles", 
          "Move into roles like Urban Planning Director or City Planner, overseeing large urban development projects. Work with architects, engineers, and public officials to ensure the successful implementation of urban planning initiatives."
        ),
        new StepDetails("Consulting and Policy", 
          "Offer consulting services to municipalities, developers, or NGOs focused on sustainable urban development. Alternatively, work on urban policy and help draft laws and regulations that promote smart growth."
        ),
        new StepDetails("Specialization in Smart Cities", 
          "Specialize in the development of smart cities that integrate technology to improve infrastructure, public services, and energy efficiency. Work on projects that use data and sensors to enhance urban living."
        ),
      ]
    ),
    ["Urban Planning", "Geography", "Environmental Design"],
    ["Exploring Cities", "Solving Urban Issues", "Mapping"],
    "Science Stream",
    "B.Tech/B.E.",
    "Civil Engineering"
  ),

  // Surveying Engineer Career Path
  new CareerRoadMap(
    "Surveying Engineer",
    "Surveying Engineers measure land features, including terrain, boundaries, and elevations, to provide data for civil engineering and construction projects. They use advanced equipment and techniques to create maps, layouts, and topographic surveys.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education", 
          "Study land surveying, geomatics, and cartography. Learn how to measure and analyze land boundaries, elevations, and features that are critical for construction and civil engineering projects."
        ),
        new StepDetails("Surveying Tools and Equipment", 
          "Learn to use surveying instruments such as theodolites, GPS systems, total stations, and LiDAR scanners. These tools help create precise topographical maps and layouts for infrastructure projects."
        ),
        new StepDetails("Field Experience", 
          "Gain hands-on experience by conducting land surveys and mapping projects. Learn how to gather data in various environments, including urban, rural, and undeveloped areas, and process this data for engineering use."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Advanced Surveying Techniques", 
          "Study advanced surveying techniques such as aerial surveying (drones), laser scanning (LiDAR), and GPS-based surveys. These methods provide high-precision data for large-scale infrastructure projects."
        ),
        new StepDetails("Geospatial Data Analysis", 
          "Learn how to analyze geospatial data using Geographic Information Systems (GIS). Combine field survey data with satellite images and digital maps to create comprehensive land-use plans and infrastructure designs."
        ),
        new StepDetails("Legal Boundaries and Land Ownership", 
          "Develop expertise in land ownership laws, property boundaries, and land development regulations. Work on land subdivision, boundary disputes, and legal descriptions for real estate transactions and public works."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles", 
          "Move into roles like Senior Surveyor or Surveying Project Manager. Oversee surveying teams working on large construction or infrastructure projects, ensuring the accuracy of the data used for engineering designs."
        ),
        new StepDetails("Consulting and Specialization", 
          "Specialize in areas like geodetic surveying (Earth measurements), offshore surveying (for marine structures), or cadastral surveying (land ownership). Offer consulting services in these specialized fields."
        ),
        new StepDetails("Entrepreneurship", 
          "Start your own surveying firm, providing services like land surveying, boundary resolution, or topographic mapping for construction projects, real estate development, and public infrastructure."
        ),
      ]
    ),
    ["Geomatics", "Geography", "Mathematics"],
    ["Exploring Outdoors", "Mapping", "Working with Technology"],
    "Science Stream",
    "B.Tech/B.E.",
    "Civil Engineering"
  ),

  // Tunneling Engineer Career Path
  new CareerRoadMap(
    "Tunneling Engineer",
    "Tunneling Engineers design and construct tunnels for transportation, mining, and water supply systems. They focus on the safe and efficient excavation of tunnels through various geological conditions.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education", 
          "Study tunnel engineering, rock mechanics, and geotechnical engineering. Learn about the different methods of tunnel construction, including drill-and-blast, tunnel boring machines (TBMs), and cut-and-cover methods."
        ),
        new StepDetails("Geotechnical Surveys", 
          "Learn to conduct geotechnical surveys to analyze soil and rock conditions. These surveys help determine the stability of the ground and identify potential risks during tunnel excavation."
        ),
        new StepDetails("Tunneling Software", 
          "Get hands-on experience with software like PLAXIS, FLAC, or Rocscience for modeling and simulating tunnel stability, ground deformation, and support structures. These tools help you design safe tunnels in challenging conditions."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Tunnel Design and Structural Support", 
          "Learn how to design tunnel linings, support systems, and ventilation. Understand the use of materials like shotcrete, steel, and concrete for tunnel reinforcement, ensuring the structural integrity of tunnels."
        ),
        new StepDetails("Tunnel Boring Machines (TBM)", 
          "Gain experience working with TBMs, the primary machines used for large tunnel construction. Learn about TBM operation, maintenance, and how to optimize their use for varying geological conditions."
        ),
        new StepDetails("Project Management", 
          "Work on managing tunnel construction projects, ensuring safety standards are met, timelines are adhered to, and budget constraints are managed. Learn how to manage teams, machinery, and resources efficiently."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles", 
          "Move into leadership roles like Tunnel Project Manager or Chief Tunneling Engineer. Lead large-scale tunneling projects for transportation (subways, highways) or water management systems, ensuring safety and efficiency."
        ),
        new StepDetails("Specialization in Complex Tunneling", 
          "Specialize in areas like deep tunnels (e.g., for metro systems), underwater tunnels, or tunnels through challenging terrains. These projects demand advanced expertise and offer opportunities in cutting-edge tunneling technology."
        ),
        new StepDetails("Consulting and Entrepreneurship", 
          "Start your own consulting firm, providing tunneling expertise for construction firms and governments. Work on projects involving underground infrastructure, subway systems, or mining tunnels."
        ),
      ]
    ),
    ["Geotechnics", "Rock Mechanics", "Structural Engineering"],
    ["Solving Complex Problems", "Exploring Underground Spaces"],
    "Science Stream",
    "B.Tech/B.E.",
    "Civil Engineering"
  ),

  // Dam Engineer Career Path
  new CareerRoadMap(
    "Dam Engineer",
    "Dam Engineers design, construct, and maintain dams and reservoirs that control water flow, generate hydroelectric power, and supply water for agriculture and communities. They work on flood control, water storage, and energy production projects.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education", 
          "Study hydraulic engineering, structural engineering, and water resource management. Learn about dam types (e.g., gravity, arch, earthfill) and the principles of designing structures that hold back large water bodies."
        ),
        new StepDetails("Hydrological Surveys", 
          "Learn how to conduct hydrological surveys to assess water flow, flood risk, and watershed characteristics. These surveys are essential for designing dams that can withstand variable water levels and extreme weather."
        ),
        new StepDetails("Dam Design Software", 
          "Get familiar with software like HEC-RAS, ANSYS, and SAP2000 for simulating dam structure, water flow, and the forces exerted on dam walls. These tools help ensure the structural integrity of dams."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Hydroelectric Power", 
          "Focus on the design and implementation of dams for hydroelectric power generation. Learn how to integrate turbines, generators, and water flow systems to convert potential energy into electricity."
        ),
        new StepDetails("Environmental Impact Assessments", 
          "Study the environmental impacts of dam construction, including displacement of communities, effects on aquatic ecosystems, and changes in river dynamics. Work on minimizing negative impacts while optimizing dam functionality."
        ),
        new StepDetails("Structural Safety and Maintenance", 
          "Learn how to conduct regular safety inspections and maintenance of dams. Understand how to identify and mitigate risks such as dam erosion, cracking, or overtopping during extreme weather events."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles", 
          "Transition into roles like Dam Project Manager or Chief Dam Engineer. Oversee the construction, maintenance, and upgrading of dams for flood control, water supply, and power generation."
        ),
        new StepDetails("Specialization in Large-Scale Dams", 
          "Specialize in the construction of large-scale dams and mega infrastructure projects like the Three Gorges Dam or Hoover Dam. These projects require advanced expertise in civil engineering and hydrology."
        ),
        new StepDetails("Consulting and Entrepreneurship", 
          "Start your own dam engineering consultancy, providing services related to dam design, safety inspections, environmental impact assessments, and hydroelectric power generation."
        ),
      ]
    ),
    ["Hydrology", "Structural Engineering", "Hydraulic Engineering"],
    ["Exploring Dams", "Water Resource Management", "Environmental Protection"],
    "Science Stream",
    "B.Tech/B.E.",
    "Civil Engineering"
  ),

  // Coastal Engineer Career Path
  new CareerRoadMap(
    "Coastal Engineer",
    "Coastal Engineers work on designing and managing coastal structures and systems to protect shorelines, harbors, and coastal communities from erosion, storms, and rising sea levels. They also work on beach nourishment and marine construction projects.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education", 
          "Study coastal engineering, oceanography, and fluid mechanics. Learn how waves, tides, and currents affect coastal areas and how to design systems that can withstand these natural forces."
        ),
        new StepDetails("Coastal Surveying", 
          "Gain experience in surveying coastal areas, including beach profiles, underwater topography, and tidal patterns. Learn how to assess erosion risks and shoreline changes."
        ),
        new StepDetails("Design Software", 
          "Learn to use coastal engineering software such as DHI MIKE, Delft3D, or SWAN for simulating wave action, sediment transport, and coastal erosion. These tools help design sea defenses and breakwaters."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Coastal Structures", 
          "Work on designing coastal structures like seawalls, groynes, and breakwaters to protect shorelines from erosion and storm surges. Understand how to build durable structures that can withstand extreme weather."
        ),
        new StepDetails("Marine Construction", 
          "Learn about marine construction techniques for building ports, harbors, and offshore structures. Gain experience in underwater construction and working with materials that resist corrosion and wave impact."
        ),
        new StepDetails("Beach Nourishment and Erosion Control", 
          "Focus on beach nourishment projects, which involve replenishing sand to eroding shorelines. Learn how to assess sand availability, design nourishment strategies, and monitor their effectiveness."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles", 
          "Transition into roles like Coastal Project Manager or Chief Coastal Engineer. Oversee large-scale coastal protection projects, including beach nourishment, harbor construction, or flood defense systems."
        ),
        new StepDetails("Specialization in Climate Resilience", 
          "Specialize in climate resilience engineering, working on solutions to protect coastal communities from rising sea levels, increased storm activity, and other impacts of climate change."
        ),
        new StepDetails("Consulting and Entrepreneurship", 
          "Start a coastal engineering consultancy, offering services related to shoreline protection, marine construction, and coastal risk assessments. Work on international projects addressing coastal climate resilience."
        ),
      ]
    ),
    ["Oceanography", "Fluid Mechanics", "Geotechnical Engineering"],
    ["Exploring Beaches", "Marine Projects", "Environmental Protection"],
    "Science Stream",
    "B.Tech/B.E.",
    "Civil Engineering"
  ),
  new CareerRoadMap(
    "Analytical Chemist",
    "Analytical chemists work to determine the chemical composition of various materials, including food, drugs, and environmental samples. Their work is critical for ensuring product safety, compliance with regulations, and quality control.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education", 
          "Study courses in analytical chemistry, organic chemistry, and inorganic chemistry. Focus on learning how to identify and quantify substances using various techniques."
        ),
        new StepDetails("Laboratory Techniques", 
          "Gain hands-on experience in the lab. Learn common analytical techniques like titration, chromatography (e.g., HPLC, GC), and spectroscopy (e.g., UV-Vis, NMR, IR)."
        ),
        new StepDetails("Familiarize with Equipment", 
          "Learn how to use advanced laboratory equipment like mass spectrometers and chromatographs. Understanding how these tools function and their role in identifying chemical compounds is essential."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Industry Experience", 
          "Work as a laboratory analyst or junior analytical chemist in industries like pharmaceuticals, food safety, or environmental testing. Perform routine chemical analyses and report findings to senior scientists."
        ),
        new StepDetails("Quality Control and Compliance", 
          "Gain expertise in quality control and compliance standards (e.g., FDA, EPA, ISO). Work with regulatory guidelines to ensure that products meet safety and quality requirements."
        ),
        new StepDetails("Data Analysis and Reporting", 
          "Develop strong data analysis skills. Learn how to interpret chemical data, ensure its accuracy, and present it in clear and meaningful reports."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership Roles", 
          "Transition into senior positions such as Senior Analytical Chemist or Lab Supervisor. Lead a team of chemists, ensure quality standards, and develop new methods for chemical analysis."
        ),
        new StepDetails("Research and Method Development", 
          "Work on developing new analytical methods and improving existing techniques. This could include working on more sensitive detection methods or faster, more efficient testing procedures."
        ),
        new StepDetails("Consulting and Compliance Officer", 
          "Consider moving into consulting, offering expertise on analytical techniques and compliance standards. Alternatively, work as a compliance officer ensuring companies meet chemical safety regulations."
        ),
      ]
    ),
    ["Analytical Chemistry", "Organic Chemistry", "Inorganic Chemistry"],
    ["Lab Work", "Data Analysis", "Problem Solving"],
    "Science Stream",
    "B.Sc.",
    "Chemistry"
  ),
  new CareerRoadMap(
    "Organic Chemist",
    "Organic chemists study the properties, structure, and reactions of carbon-based compounds. They develop new molecules for use in pharmaceuticals, agriculture, and materials. Their work is key to innovation in areas such as drug development and chemical manufacturing.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education", 
          "Focus on courses in organic chemistry, including reaction mechanisms and synthesis techniques. Understand how carbon atoms form the basis of complex molecules."
        ),
        new StepDetails("Lab Experience in Organic Synthesis", 
          "Work on organic synthesis techniques in the lab. Learn how to create and purify new organic compounds, often through reactions like esterification, alkylation, or polymerization."
        ),
        new StepDetails("Basic Instrumentation Skills", 
          "Become proficient in using instruments like NMR (Nuclear Magnetic Resonance), IR (Infrared Spectroscopy), and Mass Spectrometry to analyze organic compounds."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Industrial Application", 
          "Work in industries such as pharmaceuticals, petrochemicals, or agriculture. Focus on applying organic chemistry principles to develop new drugs, materials, or agricultural chemicals."
        ),
        new StepDetails("Research and Development", 
          "Participate in R&D projects where you design and synthesize new organic molecules. Your work could contribute to the creation of new drugs, polymers, or advanced materials."
        ),
        new StepDetails("Patent and Product Development", 
          "Collaborate with patent teams and regulatory bodies to bring new products to market. Learn how to protect your intellectual property by filing patents for your molecular designs."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Lead Organic Chemistry Research", 
          "Lead a team of chemists in developing novel organic molecules. Manage research projects in industries like pharmaceuticals or materials science."
        ),
        new StepDetails("Entrepreneurship in Chemical Startups", 
          "Consider starting your own chemical company, focusing on niche markets like biodegradable plastics, specialty chemicals, or organic pharmaceuticals."
        ),
        new StepDetails("Consulting in Organic Chemistry", 
          "Transition into consulting, helping companies optimize their chemical processes or develop new organic compounds for various industries."
        ),
      ]
    ),
    ["Organic Chemistry", "Molecular Chemistry", "Chemical Reactions"],
    ["Laboratory Work", "Designing Molecules", "Researching Chemicals"],
    "Science Stream",
    "B.Sc.",
    "Chemistry"
  ),
  new CareerRoadMap(
    "Environmental Chemist",
    "Environmental chemists study how chemicals affect the environment. They work to detect and mitigate pollutants in the air, water, and soil, ensuring environmental health and sustainability. Their work is vital in creating cleaner technologies and solving environmental challenges.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education", 
          "Study environmental chemistry, including water and air chemistry, soil science, and environmental toxicology. Learn how chemicals affect different parts of the ecosystem."
        ),
        new StepDetails("Fieldwork", 
          "Gain practical experience by conducting fieldwork where you collect soil, water, or air samples to analyze for pollutants. Fieldwork provides firsthand insight into real-world environmental challenges."
        ),
        new StepDetails("Lab Techniques for Environmental Testing", 
          "Develop skills in techniques such as gas chromatography (GC) for air quality testing or liquid chromatography (LC) for water testing. Understand the principles of detecting pollutants."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Pollution Control and Remediation", 
          "Work with environmental agencies or private firms on pollution control projects. You may help design systems to reduce industrial emissions or create processes for cleaning up contaminated sites."
        ),
        new StepDetails("Regulatory Compliance", 
          "Become familiar with environmental regulations such as the Clean Air Act or the Clean Water Act. Ensure that your work adheres to these standards to prevent harmful environmental impact."
        ),
        new StepDetails("Data Analysis", 
          "Develop the ability to analyze environmental data to track pollution levels over time. Use this data to suggest mitigation strategies or to demonstrate compliance with environmental laws."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership in Environmental Projects", 
          "Lead large environmental cleanup or pollution control projects. This might involve managing a team of chemists and environmental engineers or coordinating with government agencies to solve large-scale environmental problems."
        ),
        new StepDetails("Policy and Advocacy", 
          "Work with government agencies or non-profits to develop policies that prevent environmental degradation. You could advise on the creation of new laws to regulate chemical use and protect ecosystems."
        ),
        new StepDetails("Research and Innovation in Sustainability", 
          "Focus on developing green chemistry solutions that minimize environmental impact, such as creating biodegradable materials or designing processes that produce fewer harmful byproducts."
        ),
      ]
    ),
    ["Environmental Chemistry", "Soil Science", "Water Chemistry"],
    ["Fieldwork", "Sustainability Projects", "Problem Solving"],
    "Science Stream",
    "B.Sc.",
    "Chemistry"
  ),
  new CareerRoadMap(
    "Pharmaceutical Chemist",
    "Pharmaceutical chemists work in the pharmaceutical industry to discover, develop, and test new drugs. Their work involves creating chemical compounds that have therapeutic effects and ensuring these drugs are safe and effective for treating diseases.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education", 
          "Study pharmaceutical chemistry, biochemistry, and organic chemistry. Learn how to design chemical compounds that have therapeutic effects."
        ),
        new StepDetails("Lab Experience in Drug Development", 
          "Gain lab experience working with medicinal compounds. Learn to synthesize new drug molecules and analyze their effectiveness using techniques like HPLC, NMR, and Mass Spectrometry."
        ),
        new StepDetails("Understanding Drug Mechanisms", 
          "Learn how different drugs interact with the human body, particularly in terms of absorption, distribution, metabolism, and excretion (ADME). This knowledge is essential for designing safe and effective drugs."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Preclinical Testing", 
          "Work on preclinical testing, where you’ll test drug compounds on cell cultures or animal models. Learn to evaluate the safety and efficacy of potential drug candidates before moving on to human trials."
        ),
        new StepDetails("Clinical Trials", 
          "Become involved in the process of clinical trials, helping test new drugs on human subjects. You will work with medical teams to ensure that drugs are tested for safety, efficacy, and side effects."
        ),
        new StepDetails("Regulatory Affairs", 
          "Learn about drug regulation and how to submit drugs for approval from regulatory agencies like the FDA or EMA. This involves extensive documentation and ensuring that all safety standards are met."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership in Drug Development", 
          "Lead a team of pharmaceutical chemists to develop new drugs. You’ll oversee the entire process, from drug discovery and synthesis to clinical trials and regulatory approval."
        ),
        new StepDetails("Innovating New Drug Therapies", 
          "Focus on researching and creating innovative drug therapies that target unmet medical needs, such as cancer treatments or drugs for rare diseases."
        ),
        new StepDetails("Pharmaceutical Entrepreneurship", 
          "Consider starting your own pharmaceutical company, focusing on niche drug markets or cutting-edge therapeutic areas like gene therapy or personalized medicine."
        ),
      ]
    ),
    ["Pharmaceutical Chemistry", "Organic Chemistry", "Biochemistry"],
    ["Lab Work", "Drug Development", "Healthcare"],
    "Science Stream",
    "B.Sc.",
    "Chemistry"
  ),
  new CareerRoadMap(
    "Materials Chemist",
    "Materials chemists study the properties of materials and develop new substances with unique characteristics. Their work contributes to the creation of new products in fields like electronics, aerospace, and healthcare, including polymers, nanomaterials, and composites.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education", 
          "Study materials science, physical chemistry, and polymer chemistry. Focus on understanding the properties of metals, polymers, ceramics, and other materials."
        ),
        new StepDetails("Lab Experience in Material Synthesis", 
          "Gain hands-on experience synthesizing new materials, such as lightweight metals, composites, or nanomaterials. Learn how to analyze material properties, such as strength, flexibility, and conductivity."
        ),
        new StepDetails("Characterization Techniques", 
          "Learn how to use techniques like X-ray diffraction (XRD), scanning electron microscopy (SEM), and atomic force microscopy (AFM) to characterize the structure of materials at the atomic level."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Industry Application", 
          "Work in industries like electronics, aerospace, or healthcare, applying your knowledge to develop materials for specific applications, such as lightweight composites for airplanes or biocompatible polymers for medical devices."
        ),
        new StepDetails("Research and Development", 
          "Collaborate with R&D teams to improve existing materials or develop entirely new ones. Your goal might be to create materials that are stronger, lighter, or more environmentally friendly."
        ),
        new StepDetails("Sustainability and Green Materials", 
          "Focus on developing sustainable materials that are biodegradable or have a reduced environmental impact. This might involve creating plastics that break down naturally or finding alternatives to harmful industrial chemicals."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership in Materials Research", 
          "Lead a team of materials scientists working on the development of next-generation materials. This might include creating materials for use in advanced technologies like quantum computing or space exploration."
        ),
        new StepDetails("Patent and Commercialization", 
          "Work on patenting your material discoveries and bringing them to market. You might work with manufacturing companies to commercialize materials you have developed."
        ),
        new StepDetails("Consulting in Materials Science", 
          "Move into consulting, where you provide expertise to companies looking to develop or improve their materials. This might involve advising on the selection of materials for specific industrial applications or helping develop new manufacturing processes."
        ),
      ]
    ),
    ["Materials Science", "Physical Chemistry", "Polymer Chemistry"],
    ["Lab Work", "Designing New Materials", "Research"],
    "Science Stream",
    "B.Sc.",
    "Chemistry"
  ),
  new CareerRoadMap(
    "Theoretical Physicist",
    "Theoretical physicists develop mathematical models to explain physical phenomena, such as gravity, quantum mechanics, and electromagnetism. They work on abstract problems and aim to answer fundamental questions about the universe. This career involves a lot of abstract thinking, mathematics, and long-term research, often leading to careers in academia or research institutions.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education", 
          "Begin by mastering foundational physics subjects: Classical Mechanics (Newton's laws, motion, energy), Quantum Mechanics (study of particles at the smallest scales), and Statistical Mechanics (how particles behave in large numbers). These areas will give you the baseline knowledge to tackle more advanced concepts."
        ),
        new StepDetails("Mathematical Foundations", 
          "Mathematics is essential for theoretical physics. Focus on learning calculus (used for understanding change), linear algebra (helps in manipulating vectors and matrices, used in quantum mechanics), and differential equations (which describe how physical quantities change over time). These tools are crucial to express the laws of physics rigorously."
        ),
        new StepDetails("Programming Skills", 
          "Programming is increasingly important for solving complex theoretical models. Learn Python, which is popular in scientific computing, or Mathematica, which helps visualize and solve mathematical equations. MATLAB can also be useful for simulations and modeling physics problems."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Research Experience", 
          "By this stage, start joining a research group at your university or a physics research institute. Theoretical physicists often work on complex problems over several years. Start by assisting senior researchers in their projects—this could be developing a new mathematical model, or reinterpreting existing theories with fresh data. It's also important to understand how to publish research papers and contribute to scientific knowledge."
        ),
        new StepDetails("Specialization", 
          "As you progress, narrow your focus to one area of theoretical physics. This could be Quantum Field Theory (theoretical framework for combining quantum mechanics and special relativity), General Relativity (Einstein’s theory of gravitation), or even String Theory (a highly mathematical framework that attempts to describe all fundamental forces in the universe). Specializing helps you become an expert in a specific area, and also allows you to make a more significant impact."
        ),
        new StepDetails("Conferences and Networking", 
          "Attending physics conferences is essential at this stage. Conferences like the International Conference on Theoretical Physics or APS (American Physical Society) meetings allow you to present your research, learn about cutting-edge developments, and build a network of collaborators and mentors. This network will be invaluable for future academic or research opportunities."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership in Research", 
          "Once you gain enough experience and have contributed significantly to your chosen field, you can begin leading your own research group. This could be in a university setting, a government research facility, or at large research institutions like CERN. As a leader, you would guide PhD students, manage research projects, and push the boundaries of current theoretical models."
        ),
        new StepDetails("Academic Tenure", 
          "Many theoretical physicists aim for an academic career, which often involves teaching while continuing their research. Securing tenure as a university professor offers job security and the freedom to pursue long-term research projects without the pressure of short-term funding. Tenure-track roles usually involve publishing frequently and contributing to the academic community through teaching, conferences, and collaborations."
        ),
        new StepDetails("Consulting and Advisory Roles", 
          "As an experienced physicist, you may also be sought out by government agencies, technology companies, or research organizations as a consultant or advisor on scientific and technological matters. Your deep understanding of physics can help in areas like quantum computing, aerospace, or national security projects related to nuclear physics or advanced technologies."
        ),
      ]
    ),
    ["Mathematics", "Classical Mechanics", "Quantum Physics"],
    ["Puzzles", "Reading Scientific Journals", "Abstract Problem Solving"],
    "Science Stream",
    "B.Sc.",
    "Physics"
  ),
  new CareerRoadMap(
    "Astrophysicist",
    "Astrophysicists study the physical properties of celestial objects, including stars, planets, and galaxies. They explore phenomena such as black holes, dark matter, and supernovae, trying to understand the universe's origins, evolution, and future. This is a highly research-driven field, often requiring work with observational data from telescopes or mathematical models to simulate astronomical phenomena.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education", 
          "Start by studying core subjects like classical mechanics and electromagnetism, both of which are essential for understanding the movement of celestial bodies. Take introductory courses in astrophysics and cosmology, which cover the large-scale structure of the universe."
        ),
        new StepDetails("Programming for Data Analysis", 
          "Learn programming languages like Python, MATLAB, or R, which are used to analyze astronomical data. Astrophysicists often work with large datasets from telescopes, and the ability to process and analyze this data is critical."
        ),
        new StepDetails("Learn to Use Telescopes", 
          "Familiarize yourself with how telescopes work, both optical and radio telescopes. This will give you practical experience in gathering observational data. Participating in astronomy clubs or internships at observatories can provide early exposure to real-world astrophysics work."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Specialization in Astrophysics", 
          "Begin specializing in a specific area of astrophysics, such as planetary science, stellar astrophysics, or cosmology. Your specialization could depend on what fascinates you most—whether it’s the evolution of galaxies or the life cycle of stars."
        ),
        new StepDetails("Observational Astrophysics", 
          "If you choose an observational track, you’ll work with astronomical data from telescopes like the Hubble Space Telescope or radio telescopes like the Very Large Array. Learn how to process this data using tools like IRAF (Image Reduction and Analysis Facility) or CASA (Common Astronomy Software Applications)."
        ),
        new StepDetails("Research and Publication", 
          "Conduct independent research and aim to publish your findings in reputable journals like The Astrophysical Journal or Monthly Notices of the Royal Astronomical Society (MNRAS). Publishing research is essential for establishing credibility and advancing in the academic and scientific community."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership in Research", 
          "Once you’ve gained significant research experience, you can start leading your own projects, potentially collaborating with organizations like NASA or the European Space Agency (ESA). You might manage a team of researchers who study cosmic phenomena or develop new theories based on the data from space missions."
        ),
        new StepDetails("Teaching and Mentoring", 
          "If you are in academia, you can become a professor of astrophysics, teaching courses in astronomy, planetary science, or cosmology while continuing your research. You’ll also mentor undergraduate and graduate students who are just beginning their astrophysics journey."
        ),
        new StepDetails("Public Outreach and Science Communication", 
          "Many astrophysicists engage in public outreach by writing popular science books, hosting shows, or giving public talks about astronomy. Helping the public understand astronomical phenomena builds interest in science and might attract future astrophysicists."
        ),
      ]
    ),
    ["Physics", "Astronomy", "Mathematics"],
    ["Stargazing", "Using Telescopes", "Reading About Space"],
    "Science Stream",
    "B.Sc.",
    "Physics"
  ),
  new CareerRoadMap(
    "Medical Physicist",
    "Medical physicists apply physics principles to healthcare, particularly in radiation therapy, imaging technologies, and ensuring the safety and accuracy of treatments like MRI scans, CT scans, and radiation therapies for cancer patients. Medical physicists are vital in hospitals and clinics where precision in radiation and imaging can mean the difference between successful treatment and harm.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education", 
          "Take foundational physics courses such as electromagnetism (understanding magnetic fields in MRI machines) and radiation physics (used in radiation therapy). Learn about biology and human anatomy to understand how physics principles are applied to the human body."
        ),
        new StepDetails("Radiation Safety", 
          "Study radiation safety, which is critical in ensuring that radiation doses used in therapies are accurate and safe for patients. You'll need to understand safety regulations and procedures that protect both patients and healthcare workers from unnecessary exposure."
        ),
        new StepDetails("Hands-on Experience", 
          "Seek internships or volunteer opportunities in hospital radiology departments to get early exposure to medical imaging technologies like X-rays and MRIs. This will help you understand how these machines work and the physics behind them."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Specialized Certification", 
          "After completing your education, you’ll need certification to become a practicing medical physicist. This might include exams and practical training from organizations like the American Board of Radiology (ABR) or equivalent boards in your region."
        ),
        new StepDetails("Advanced Imaging and Radiation Therapy", 
          "Specialize in areas like advanced medical imaging (e.g., PET scans, MRI) or radiation therapy for cancer treatment. You will learn to optimize radiation doses, calibrate machines, and ensure that patients receive the correct treatment without overexposure to radiation."
        ),
        new StepDetails("Clinical Research", 
          "Participate in clinical research to improve imaging techniques or develop safer, more effective radiation therapies. Many medical physicists work on improving imaging technologies to detect diseases earlier or creating radiation treatments with fewer side effects."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership in Medical Physics", 
          "As a senior medical physicist, you’ll be responsible for managing radiology departments, ensuring compliance with safety regulations, and leading new initiatives to improve imaging and radiation therapy. This role may also involve supervising other medical physicists."
        ),
        new StepDetails("Teaching and Mentorship", 
          "Many senior medical physicists also become educators, teaching new students about medical imaging, radiation therapy, and safety protocols. You may also mentor younger medical physicists as they gain hands-on experience."
        ),
        new StepDetails("Consulting and Research", 
          "Experienced medical physicists often consult for hospitals, equipment manufacturers, or government agencies. You might also continue to conduct research to develop the next generation of medical imaging technologies or improve radiation treatments."
        ),
      ]
    ),
    ["Physics", "Biology", "Radiation Safety"],
    ["Healthcare Volunteering", "Visiting Hospitals", "Learning About Medical Devices"],
    "Science Stream",
    "B.Sc.",
    "Physics"
  ),
  new CareerRoadMap(
    "Nuclear Physicist",
    "Nuclear physicists study the atomic nucleus and its interactions. Their work contributes to fields such as nuclear energy, nuclear medicine, and particle physics. This field is highly specialized and involves both theoretical and experimental physics. Nuclear physicists are also involved in research and development for energy production, medical technologies, and even national defense.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Core Physics Education", 
          "Start with core physics topics such as atomic physics (study of atoms), nuclear physics (study of atomic nuclei), and electromagnetism (the study of magnetic and electric fields). You'll also need a strong mathematical background, especially in calculus and linear algebra."
        ),
        new StepDetails("Laboratory Work", 
          "Nuclear physicists rely heavily on laboratory experiments. Start gaining hands-on experience by working in a university lab or taking part in research projects that involve nuclear reactions, particle accelerators, or radiation detectors."
        ),
        new StepDetails("Radiation Safety Training", 
          "Since nuclear physicists work with radioactive materials, it's important to understand radiation safety protocols and regulations. Learn about radiation shielding, exposure limits, and proper handling of radioactive substances."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Advanced Nuclear Research", 
          "Join a research institute or national lab that focuses on nuclear physics, energy research, or medical applications of nuclear technology. Work on projects such as nuclear fusion, nuclear fission, or isotope production for medical uses."
        ),
        new StepDetails("Publishing Research Papers", 
          "Conduct original research and publish your findings in journals such as Nuclear Physics A or the European Physical Journal. Publishing is essential for advancing in this field, particularly in academia or research institutions."
        ),
        new StepDetails("Specialization in Nuclear Applications", 
          "Choose a specific application of nuclear physics, such as nuclear medicine (using radioactive isotopes for imaging or treatment), nuclear energy (developing safe reactors), or particle physics (using particle accelerators to study subatomic particles)."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Leadership in Nuclear Research", 
          "Lead research teams at a university, government lab, or private research facility. You’ll oversee experiments, manage projects, and secure funding for further research into nuclear energy or nuclear physics applications."
        ),
        new StepDetails("Consulting and National Defense", 
          "Many experienced nuclear physicists work as consultants for government agencies, helping with nuclear safety, waste management, or even national defense projects related to nuclear weapons or deterrence."
        ),
        new StepDetails("Nuclear Policy and Advocacy", 
          "Some nuclear physicists become involved in nuclear policy or advocacy, working to improve safety regulations, nuclear disarmament efforts, or public education about nuclear technologies."
        ),
      ]
    ),
    ["Nuclear Physics", "Radiation Safety", "Quantum Mechanics"],
    ["Lab Experiments", "Studying Atoms", "Nuclear Energy Projects"],
    "Science Stream",
    "B.Sc.",
    "Physics"
  ),
  new CareerRoadMap(
    "Particle Physicist",
    "Particle physicists study the most fundamental particles that make up the universe, such as quarks, leptons, and bosons. They often work with large particle accelerators like CERN’s Large Hadron Collider, conducting experiments to uncover the nature of matter and energy. Their research can lead to significant discoveries, like the detection of new particles or forces.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education", 
          "Study classical mechanics, quantum mechanics, and electromagnetism. Focus on courses related to quantum field theory, which describes the interaction of fundamental forces with particles. You'll also need to study nuclear physics and relativity to understand the behavior of particles at extremely high energies."
        ),
        new StepDetails("Hands-On Laboratory Work", 
          "Participate in lab experiments related to particle physics. Learn to use particle detectors and accelerators in university settings. This hands-on experience will introduce you to the tools and technologies used to study subatomic particles."
        ),
        new StepDetails("Programming for Data Analysis", 
          "Learn programming languages like Python, C++, or ROOT (a data analysis framework used at CERN). Particle physics experiments generate vast amounts of data, and being able to analyze it efficiently is critical."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Research at Particle Accelerators", 
          "Join a research team at a particle accelerator facility like CERN or Fermilab. You will contribute to experiments that study high-energy collisions between particles to uncover new physics phenomena. Your role might involve setting up experiments, analyzing collision data, or interpreting results."
        ),
        new StepDetails("Publishing and Collaboration", 
          "Work on publishing your findings in top-tier journals such as Physical Review Letters. Particle physics is a highly collaborative field, so you'll work closely with physicists worldwide to conduct large-scale experiments and verify results."
        ),
        new StepDetails("Advanced Theoretical Study", 
          "Focus on theoretical work if you lean more towards the modeling side of particle physics. Learn about theories like supersymmetry (SUSY), string theory, or the Standard Model of particle physics. Theoretical physicists aim to predict outcomes that experimental physicists will then test in particle accelerators."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Lead Research Teams", 
          "After gaining experience, lead your own research team, designing and overseeing experiments at particle accelerators. This might involve proposing new experiments, securing funding, and managing the work of graduate students or postdoctoral researchers."
        ),
        new StepDetails("Breakthrough Discoveries", 
          "With experience, work towards making groundbreaking discoveries, such as identifying new particles, forces, or phenomena not accounted for in the Standard Model of particle physics. Such discoveries can have profound implications for our understanding of the universe."
        ),
        new StepDetails("Contribute to Global Collaboration", 
          "Most major particle physics experiments are global collaborations. As you gain recognition in the field, you may be invited to work on large-scale, international projects at research facilities worldwide. These collaborations are essential for pushing the boundaries of particle physics."
        ),
      ]
    ),
    ["Particle Physics", "Quantum Mechanics", "Data Analysis"],
    ["Building Models", "Analyzing Particle Collisions", "Data Interpretation"],
    "Science Stream",
    "B.Sc.",
    "Physics"
  ),
  new CareerRoadMap(
    "Visual Artist (Painter, Sculptor)",
    "Visual artists create artwork, often focusing on specific mediums like painting, drawing, sculpture, or mixed media. They use their artistic vision to produce pieces that are showcased in galleries, museums, or sold to private collectors.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Develop Basic Artistic Skills", 
          "Focus on building your foundational skills in drawing, painting, or sculpting. Enroll in art classes or workshops to learn the basics of color theory, composition, and art history."
        ),
        new StepDetails("Portfolio Building", 
          "Begin developing a portfolio of your work. A portfolio is a collection of your best pieces that showcase your style and technical skills. It will be essential for applying to exhibitions, galleries, or art school programs."
        ),
        new StepDetails("Art Exhibitions and Networking", 
          "Start participating in local art exhibitions or group shows. Building relationships with gallery owners and fellow artists will help you get exposure in the art world."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Specialization", 
          "Choose a specific medium (e.g., oil painting, abstract sculpture, or mixed media) to specialize in. Developing a unique style or niche can help distinguish you from other artists and make your work more recognizable."
        ),
        new StepDetails("Exhibit in Prominent Galleries", 
          "Expand your exhibitions to more prominent galleries in your region or internationally. Applying to art competitions and open calls for artists can help increase your visibility."
        ),
        new StepDetails("Networking with Art Collectors", 
          "Network with art collectors and curators. Building strong relationships in the art world is essential for selling your artwork, securing commissions, and participating in high-profile exhibitions."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Solo Exhibitions", 
          "Work towards having solo exhibitions at well-known galleries. Solo exhibitions showcase a larger body of work and offer more exposure to collectors and curators."
        ),
        new StepDetails("Public and Private Commissions", 
          "Establish yourself as a well-known artist and start receiving commissions for public art installations or private collections. This could include creating custom artwork for museums, public spaces, or private homes."
        ),
        new StepDetails("Art Career Expansion", 
          "Consider expanding your career by teaching art at a university, writing about art, or curating exhibitions. You can also create limited edition prints or licensing your artwork for commercial use."
        ),
      ]
    ),
    ["Fine Arts", "Art History", "Sculpture"],
    ["Painting", "Sculpting", "Sketching"],
    "Arts/Humanities Stream",
    "B.A.",
    "Fine Arts"
  ),
  new CareerRoadMap(
    "Art Curator",
    "Art curators work in museums and galleries, overseeing the selection, display, and preservation of artworks. They organize exhibitions, manage collections, and help create educational programs related to the art they curate.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Art History and Museum Studies", 
          "Develop a strong foundation in art history and museum studies. Understand the historical and cultural significance of art and how it is presented to the public in museums and galleries."
        ),
        new StepDetails("Intern at a Museum or Gallery", 
          "Gain experience by interning at a local museum or gallery. Learn about cataloging art, organizing exhibitions, and managing collections. This hands-on experience will give you insight into the day-to-day operations of curating."
        ),
        new StepDetails("Networking in the Art World", 
          "Start building relationships with artists, gallery owners, and other curators. Attend exhibitions and art fairs to expand your network and stay updated on current art trends."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as an Assistant Curator", 
          "Apply for assistant curator positions at museums or galleries. In this role, you will help with exhibition planning, cataloging art, and managing collections under the supervision of senior curators."
        ),
        new StepDetails("Organize Small Exhibitions", 
          "Start organizing small exhibitions, either within the institution you work for or as independent projects. This could involve selecting works, designing the layout of the exhibit, and writing descriptions for each piece."
        ),
        new StepDetails("Art Acquisition and Research", 
          "Develop skills in art acquisition by working with galleries or collectors to acquire new pieces for your institution. This often involves extensive research on the history and provenance of the artwork."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Head Curator Role", 
          "Work towards becoming a head curator, managing large collections and major exhibitions. You will oversee the curation team, develop long-term exhibition plans, and curate high-profile exhibitions."
        ),
        new StepDetails("Publish Research and Catalogs", 
          "Contribute to the academic and cultural understanding of art by writing exhibition catalogs, publishing research on specific artists or movements, and contributing to journals or books."
        ),
        new StepDetails("Cultural Leadership and Management", 
          "Transition into leadership roles within museums or cultural institutions, overseeing not just art curation but the management of entire museum departments or programs."
        ),
      ]
    ),
    ["Art History", "Museum Studies", "Curation"],
    ["Visiting Museums", "Art Research", "Organizing Exhibits"],
    "Arts/Humanities Stream",
    "B.A.",
    "Fine Arts"
  ),
  new CareerRoadMap(
    "Graphic Designer",
    "Graphic designers create visual concepts using digital tools and software. They work on projects like branding, advertisements, web design, and multimedia content for companies and clients. Graphic designers often work in advertising, publishing, or as freelancers.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Learn Design Software", 
          "Master software like Adobe Photoshop, Illustrator, and InDesign, which are essential tools for graphic designers. Take online courses or attend workshops to become proficient in these programs."
        ),
        new StepDetails("Build a Portfolio", 
          "Start creating a portfolio that showcases your best design work. Include a variety of projects, such as logos, posters, web design, and branding materials, to demonstrate your versatility."
        ),
        new StepDetails("Freelance or Internships", 
          "Take on freelance graphic design projects or internships at design agencies. Working with real clients will help you build experience and improve your design skills."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Specialize in a Design Field", 
          "Choose a specific area of graphic design to specialize in, such as branding, packaging design, or web design. Becoming an expert in one area can make you more competitive in the job market."
        ),
        new StepDetails("Work for a Design Agency or Company", 
          "Gain experience by working for a design agency, marketing firm, or in-house design team for a company. You'll work on larger projects, collaborate with other designers, and refine your design process."
        ),
        new StepDetails("Stay Updated on Design Trends", 
          "Follow industry trends and technological advancements in graphic design. New software tools and styles emerge frequently, so staying updated on trends like minimalism, flat design, or 3D graphics is important."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Become a Senior Designer or Art Director", 
          "Move into senior roles, such as Art Director or Senior Graphic Designer. These roles involve managing other designers, overseeing creative projects, and working closely with clients to deliver high-quality visual solutions."
        ),
        new StepDetails("Freelancing and Creative Business", 
          "Consider becoming a full-time freelance designer or starting your own design studio. This allows you to choose the projects you want to work on and have more control over your career."
        ),
        new StepDetails("Teach or Mentor", 
          "As an experienced designer, you can teach graphic design at art schools or mentor junior designers. Sharing your expertise can be rewarding and help shape the next generation of designers."
        ),
      ]
    ),
    ["Graphic Design", "Typography", "Branding"],
    ["Digital Art", "Designing Posters", "Creating Logos"],
    "Arts/Humanities Stream",
    "B.A.",
    "Fine Arts"
  ),  
  new CareerRoadMap(
    "Art Teacher (Fine Arts)",
    "Art teachers work in schools or universities, teaching students how to draw, paint, sculpt, and explore various artistic mediums. They help students develop their creativity and technical skills, while also promoting art appreciation and history.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education and Teaching Certifications", 
          "Earn a degree in Fine Arts or Art Education, followed by a teaching certification. Many regions require specific teaching credentials, especially if you plan to work in public schools."
        ),
        new StepDetails("Student Teaching and Internships", 
          "Gain practical experience by completing a student teaching program or internship. You'll learn classroom management skills, lesson planning, and how to engage students in creative activities."
        ),
        new StepDetails("Develop Teaching Skills in Multiple Mediums", 
          "Art teachers need to be proficient in a variety of artistic mediums (e.g., drawing, painting, sculpture). Learn how to teach these techniques to students of different skill levels."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Teaching Experience", 
          "Start working as an art teacher in elementary, middle, or high schools. Develop your curriculum and find creative ways to engage students in the artistic process. You can also work in private art schools or as a tutor."
        ),
        new StepDetails("Extracurricular Programs", 
          "Lead extracurricular activities like art clubs, exhibitions, or workshops. These programs give students more opportunities to explore their creativity and build their skills outside the classroom."
        ),
        new StepDetails("Integrate Art History and Theory", 
          "Incorporate art history and theory into your teaching, helping students understand the cultural and historical significance of art. This will deepen their appreciation and understanding of different art styles."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Teach at the University Level", 
          "With more experience and possibly a graduate degree (MFA), you can teach fine arts at the university level. This role also allows for more specialization in certain artistic fields, such as painting, sculpture, or mixed media."
        ),
        new StepDetails("Develop Art Curriculums", 
          "Move into curriculum development, creating art programs for schools or educational institutions. This involves designing art lessons that meet educational standards while encouraging creativity and innovation."
        ),
        new StepDetails("Public Art Workshops and Community Engagement", 
          "Expand your teaching to the public by running art workshops in community centers, galleries, or museums. You can also engage in art outreach programs, bringing art education to underserved communities."
        ),
      ]
    ),
    ["Fine Arts", "Art Education", "Art History"],
    ["Teaching Art", "Organizing Art Classes", "Inspiring Students"],
    "Arts/Humanities Stream",
    "B.A.",
    "Fine Arts"
  ),
  new CareerRoadMap(
    "Art Therapist",
    "Art therapists use creative processes like drawing, painting, and sculpture to help people explore their emotions, reduce stress, and cope with trauma. They work in hospitals, rehabilitation centers, or private practices to facilitate healing and personal growth through art.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Art Therapy", 
          "Enroll in an art therapy degree program. Learn about psychology, counseling, and therapeutic techniques, along with your artistic training, to understand how art can be used to help people express their emotions."
        ),
        new StepDetails("Learn Counseling and Psychology", 
          "Develop a foundation in counseling and mental health therapy. Art therapists need to understand how the mind works, especially in relation to trauma, anxiety, depression, or developmental disorders."
        ),
        new StepDetails("Gain Experience with Therapeutic Art Techniques", 
          "Learn how to guide clients in using different artistic mediums to express themselves. Practice therapeutic techniques that encourage people to explore their emotions and cope with difficult experiences through creative work."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work in Mental Health Facilities", 
          "Work as an art therapist in mental health clinics, hospitals, or rehabilitation centers. You will work with clients dealing with mental health issues, helping them find healing and emotional expression through creative outlets."
        ),
        new StepDetails("Specialize in a Therapeutic Field", 
          "Specialize in working with specific populations, such as children, trauma survivors, or people with disabilities. This could involve developing specific techniques that cater to their unique emotional and psychological needs."
        ),
        new StepDetails("Certification and Licensing", 
          "Earn certification and licensing as an art therapist from recognized bodies such as the American Art Therapy Association (AATA) or equivalent in your country. This ensures you can practice legally and with the proper credentials."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Private Practice", 
          "Open your own art therapy practice where you can provide individualized therapy to clients. In private practice, you can specialize in helping specific groups or work on personal development and healing through creative expression."
        ),
        new StepDetails("Supervision and Mentorship", 
          "As an experienced art therapist, mentor and supervise new therapists entering the field. This might include offering guidance, sharing techniques, and helping new therapists gain hands-on experience."
        ),
        new StepDetails("Advocacy and Research", 
          "Contribute to the growth of the field by engaging in research on the effectiveness of art therapy for different mental health conditions. Advocate for the inclusion of art therapy in healthcare settings and public health programs."
        ),
      ]
    ),
    ["Art Therapy", "Psychology", "Counseling"],
    ["Painting", "Creative Healing", "Helping People"],
    "Arts/Humanities Stream",
    "B.A.",
    "Fine Arts"
  ),
  new CareerRoadMap(
    "Corporate Lawyer",
    "Corporate lawyers specialize in business law, helping companies navigate the legalities of mergers, acquisitions, contracts, and compliance with regulatory standards. They play a key role in drafting legal documents and advising clients on corporate governance.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Complete LLB with Business Law Focus", 
          "Focus on courses like corporate law, contract law, and business ethics. Learn how legal frameworks apply to businesses and corporations."
        ),
        new StepDetails("Internship at Corporate Law Firms", 
          "Seek internships at corporate law firms or in the legal departments of companies. Gain experience in drafting contracts, working on mergers and acquisitions, and understanding corporate governance."
        ),
        new StepDetails("Study Contract Drafting and Negotiation", 
          "Learn the art of contract drafting and negotiation. Contracts are the backbone of corporate law, and mastering them early on is essential for a successful career."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Corporate Associate", 
          "Work as a corporate associate in a law firm. You'll handle tasks like drafting shareholder agreements, managing mergers, and ensuring companies comply with corporate governance regulations."
        ),
        new StepDetails("Specialize in M&A or Securities Law", 
          "Specialize in niche areas such as mergers and acquisitions (M&A) or securities law. This specialization will help you advise companies on complex transactions or regulatory compliance."
        ),
        new StepDetails("Build Client Relationships", 
          "Develop strong relationships with clients. As you gain experience, your ability to provide sound legal advice will be critical to maintaining long-term relationships with corporate clients."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Partnership at a Law Firm", 
          "Work towards becoming a partner at a law firm. As a partner, you'll have greater responsibility for bringing in clients, managing teams, and overseeing major transactions."
        ),
        new StepDetails("In-House Counsel for a Corporation", 
          "Consider transitioning to in-house counsel for a large corporation, where you'll provide legal advice on a day-to-day basis, manage legal risks, and oversee legal aspects of corporate decisions."
        ),
        new StepDetails("Corporate Law Consultancy", 
          "Set up your own corporate law consultancy to provide specialized legal advice to companies. This could include advising on corporate governance, regulatory compliance, or international business law."
        ),
      ]
    ),
    ["Business Law", "Corporate Governance", "Contracts"],
    ["Negotiating", "Analyzing Business Trends", "Drafting Contracts"],
    "Arts/Humanities Stream",
    "LLB",
    "Laws"
  ),

  // 2. Criminal Lawyer
  new CareerRoadMap(
    "Criminal Lawyer",
    "Criminal lawyers represent clients who are accused of committing crimes, ranging from minor offenses to serious felonies. They defend their clients in court, negotiate plea deals, and work to protect their rights during criminal proceedings.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Criminal Law", 
          "Focus on courses in criminal law, criminology, and constitutional law. Learn how the criminal justice system works, including the roles of prosecutors, defense attorneys, and judges."
        ),
        new StepDetails("Intern with a Criminal Defense Lawyer or Prosecutor", 
          "Gain hands-on experience by interning with a criminal defense lawyer or working at the prosecutor's office. This will help you understand courtroom procedures, legal research, and client management."
        ),
        new StepDetails("Learn Legal Research and Case Law", 
          "Master the skills of legal research and analysis. You'll need to know how to find relevant case law, statutes, and legal precedents to build a strong defense for your clients."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Public Defender or Prosecutor", 
          "Work as a public defender, representing clients who cannot afford private attorneys, or as a prosecutor, where you'll handle cases for the state. This experience builds your courtroom skills and legal strategy."
        ),
        new StepDetails("Criminal Case Specialization", 
          "Specialize in a specific area of criminal law, such as white-collar crime, drug offenses, or violent crimes. Specialization will allow you to become an expert in certain types of cases."
        ),
        new StepDetails("Plea Bargaining and Trial Experience", 
          "Develop skills in plea bargaining, working with prosecutors to negotiate deals that are beneficial to your clients. Gain trial experience by representing clients in court and building defense strategies."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Lead a Criminal Law Practice", 
          "Start your own criminal law firm or lead a practice within a larger firm. As the lead criminal lawyer, you'll oversee a team of attorneys and manage high-profile cases."
        ),
        new StepDetails("Become a Criminal Law Specialist", 
          "Gain certification as a criminal law specialist, recognized for your expertise in defending or prosecuting criminal cases. This can lead to handling more serious, high-stakes cases."
        ),
        new StepDetails("Teaching and Public Speaking", 
          "Consider teaching criminal law at law schools or giving public lectures on the criminal justice system. You can also become a media consultant for high-profile criminal cases."
        ),
      ]
    ),
    ["Criminal Law", "Constitutional Law", "Criminology"],
    ["Debating", "Researching Case Law", "Defending Clients"],
    "Arts/Humanities Stream",
    "LLB",
    "Laws"
  ),

  // 3. Human Rights Lawyer
  new CareerRoadMap(
    "Human Rights Lawyer",
    "Human rights lawyers advocate for the protection of fundamental human rights, such as freedom of speech, equality, and protection from discrimination. They work with non-profits, governments, and international organizations to defend human rights at both local and global levels.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Human Rights Law", 
          "Take specialized courses in human rights law, international law, and constitutional law. Understand the legal frameworks that protect human rights at national and international levels."
        ),
        new StepDetails("Intern at Human Rights Organizations", 
          "Gain experience by interning with NGOs, government agencies, or international organizations that focus on human rights. You will work on advocacy campaigns, legal research, and policy development."
        ),
        new StepDetails("Learn About International Human Rights Conventions", 
          "Familiarize yourself with key human rights treaties, such as the Universal Declaration of Human Rights (UDHR), and organizations like the United Nations (UN) and European Court of Human Rights."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work for NGOs or Government Agencies", 
          "Start working for human rights NGOs or government agencies that focus on protecting civil liberties. You'll be involved in legal advocacy, lobbying, and helping victims of human rights abuses."
        ),
        new StepDetails("Litigate Human Rights Cases", 
          "Begin litigating human rights cases in domestic courts or international tribunals. You will argue for the protection of rights such as freedom of expression, the right to education, or protection from torture."
        ),
        new StepDetails("Advocacy and Policy Reform", 
          "Work on advocacy projects that push for policy reforms. This could involve drafting new laws, working with governments, or engaging in public awareness campaigns to promote human rights."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("International Human Rights Work", 
          "Work for international organizations like the United Nations, the International Criminal Court, or Amnesty International. You may represent victims of human rights abuses or work on global advocacy campaigns."
        ),
        new StepDetails("Become a Human Rights Advocate", 
          "Establish yourself as a prominent human rights advocate, working with governments or global organizations to influence policy and legislation that protects human rights."
        ),
        new StepDetails("Human Rights Consultancy", 
          "Become a consultant for international organizations, advising on human rights law and policy. You can work on large-scale projects, advising on issues like refugee law, asylum, and minority rights."
        ),
      ]
    ),
    ["Human Rights Law", "International Law", "Civil Liberties"],
    ["Advocating for Justice", "Working with NGOs", "International Issues"],
    "Arts/Humanities Stream",
    "LLB",
    "Laws"
  ),

  // 4. Environmental Lawyer
  new CareerRoadMap(
    "Environmental Lawyer",
    "Environmental lawyers work to protect the environment through legal means. They represent clients in cases involving environmental regulations, land use, pollution, and conservation. Environmental lawyers often work with governments, non-profits, or corporations to ensure environmental laws are followed.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Environmental Law", 
          "Take courses in environmental law, public policy, and regulatory law. Learn about environmental protection regulations, land use laws, and international environmental treaties."
        ),
        new StepDetails("Intern at Environmental Organizations", 
          "Gain experience by working with environmental law firms, non-profits, or government agencies. You'll work on cases involving pollution control, conservation, and regulatory compliance."
        ),
        new StepDetails("Study Environmental Policy and Impact", 
          "Learn how environmental policies are formed, enforced, and regulated. This will help you understand the broader implications of legal decisions on conservation and environmental protection."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as an Environmental Lawyer", 
          "Work for law firms, NGOs, or government agencies on environmental cases. You'll handle lawsuits related to pollution, deforestation, land use, and corporate compliance with environmental laws."
        ),
        new StepDetails("Specialize in Areas like Climate Law or Energy Law", 
          "Specialize in niche areas such as climate change law, renewable energy law, or water rights. Specialization can help you become an expert in specific environmental issues and expand your career opportunities."
        ),
        new StepDetails("Advocacy for Environmental Regulations", 
          "Work on advocating for stronger environmental regulations, both at the domestic and international levels. This could involve lobbying for policy changes or working on large-scale environmental projects."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Lead Environmental Law Initiatives", 
          "Lead significant environmental law projects, representing major environmental organizations or government bodies. You might focus on issues like biodiversity conservation, renewable energy laws, or pollution control."
        ),
        new StepDetails("Become a Policy Advisor", 
          "Transition into a role as an environmental policy advisor, helping governments or international organizations create and implement environmental policies that protect ecosystems and combat climate change."
        ),
        new StepDetails("Environmental Consultancy", 
          "Offer environmental law consultancy services to corporations or governments. You might advise on compliance with environmental laws, climate change mitigation strategies, or sustainable development practices."
        ),
      ]
    ),
    ["Environmental Law", "Public Policy", "Sustainability"],
    ["Advocating for Nature", "Studying Environmental Policies", "Protecting Ecosystems"],
    "Arts/Humanities Stream",
    "LLB",
    "Laws"
  ),

  // 5. Intellectual Property (IP) Lawyer
  new CareerRoadMap(
    "Intellectual Property (IP) Lawyer",
    "IP lawyers specialize in protecting intellectual property rights, such as patents, trademarks, and copyrights. They help individuals and companies protect their creations, from inventions to artistic works, and litigate against infringement.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Intellectual Property Law", 
          "Focus on courses in intellectual property (IP) law, including patent law, copyright law, and trademark law. Learn the legal framework for protecting creative works and innovations."
        ),
        new StepDetails("Intern with IP Law Firms", 
          "Gain experience by interning with law firms specializing in intellectual property or working in the legal departments of companies that deal with patents, trademarks, or copyrights."
        ),
        new StepDetails("Learn Patent Filing and Trademark Registration", 
          "Master the process of filing patents and registering trademarks. This includes preparing and submitting the necessary documents and ensuring that all legal requirements are met."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as an IP Lawyer", 
          "Work for law firms or companies that handle intellectual property cases. You'll represent clients in patent disputes, trademark infringement cases, and copyright litigation."
        ),
        new StepDetails("Specialize in Patent Law, Copyright Law, or Trademark Law", 
          "Choose a specific area of intellectual property law to specialize in, such as patents (for inventions), copyrights (for creative works), or trademarks (for branding). Specialization will help you gain expertise in protecting intellectual property."
        ),
        new StepDetails("IP Litigation", 
          "Represent clients in IP litigation cases, where you'll work to defend their intellectual property rights in court. This could include suing for patent infringement or defending clients against accusations of IP theft."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Lead an IP Law Firm", 
          "Lead an intellectual property law firm, handling high-profile cases for corporations, inventors, and creators. You'll oversee a team of lawyers and provide strategic legal advice on protecting intellectual property."
        ),
        new StepDetails("Patent Agent or IP Consultant", 
          "Consider becoming a patent agent, working directly with inventors and companies to secure patents for new inventions. Alternatively, work as an IP consultant, advising businesses on how to protect their trademarks, designs, and other intellectual property."
        ),
        new StepDetails("Global IP Law Practice", 
          "Expand your practice to focus on international intellectual property law. You'll work on cross-border IP cases, helping clients navigate global patent laws, trademarks, and copyright regulations."
        ),
      ]
    ),
    ["IP Law", "Patent Law", "Copyright Law"],
    ["Analyzing Inventions", "Protecting Creative Works", "Studying Global IP Trends"],
    "Arts/Humanities Stream",
    "LLB",
    "Laws"
  ),
  new CareerRoadMap(
    "Broadcast Journalist",
    "Broadcast journalists work in television, radio, or digital platforms, reporting on news stories and current events. They may conduct interviews, present news segments, or act as field reporters, bringing important information to the public.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Education", 
          "Take courses in journalism, communication, and media studies. Learn about news writing, ethics in journalism, and how broadcast journalism works across TV, radio, and digital platforms."
        ),
        new StepDetails("Internship at News Stations", 
          "Intern at local news stations or radio stations to gain hands-on experience in reporting, editing, and producing news segments. Learn the basics of operating camera equipment, teleprompters, and audio equipment."
        ),
        new StepDetails("Develop On-Camera Skills", 
          "Practice speaking on camera and improve public speaking skills. Focus on clear articulation, professional demeanor, and reporting live on location."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Field Reporting Experience", 
          "Work as a field reporter, covering local news stories. This could include reporting live from events, conducting interviews, and producing investigative reports. Work on becoming comfortable with breaking news scenarios."
        ),
        new StepDetails("Specialization in Broadcast Journalism", 
          "Specialize in a specific area of news reporting, such as political journalism, sports reporting, or international news. Specialization helps build expertise and credibility in a specific domain."
        ),
        new StepDetails("Anchor and Host Roles", 
          "Move into roles as a news anchor or host of a television or radio show. This involves leading broadcasts, conducting live interviews, and managing the flow of a news program."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Senior Reporter or Lead Anchor", 
          "Progress to senior positions like Senior Broadcast Journalist or Lead Anchor. In this role, you'll cover significant national or international stories, lead a news team, and have more editorial control."
        ),
        new StepDetails("Executive Roles in Broadcasting", 
          "Move into executive roles such as News Director or Producer, overseeing the content and direction of news programming. You will manage teams of reporters and make editorial decisions for news stations."
        ),
        new StepDetails("Public Speaking and Media Expert", 
          "Use your expertise to participate in media panels, political discussions, or become a guest speaker at industry events. You can also write books or contribute opinion pieces on media and journalism."
        ),
      ]
    ),
    ["Journalism", "Media Studies", "Current Events"],
    ["Public Speaking", "Interviewing", "Researching"],
    "Arts/Humanities Stream",
    "B.A.",
    "Journalism and Mass Communication"
  ),

  // 2. Investigative Journalist
  new CareerRoadMap(
    "Investigative Journalist",
    "Investigative journalists focus on in-depth reporting and uncovering hidden information. They often work on long-term projects that expose corruption, wrongdoing, or social issues that are not widely covered in mainstream media.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Learn Investigative Techniques", 
          "Take courses in journalism with a focus on investigative reporting. Study how to gather facts, verify information, and protect sources when uncovering sensitive or controversial material."
        ),
        new StepDetails("Internship at Investigative Publications", 
          "Intern at investigative news outlets or publications that focus on long-form journalism. Learn how to work on stories that take months or even years to research, focusing on accuracy and accountability."
        ),
        new StepDetails("Learn Data Journalism", 
          "Acquire skills in data journalism, using tools like Excel, SQL, or data visualization software to analyze large datasets. This helps uncover patterns or trends in government or corporate records."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work on Long-Form Stories", 
          "Join a media outlet that values long-form journalism. Work on projects that expose corruption, human rights violations, or corporate misconduct. These stories often involve months of research, data collection, and interviews."
        ),
        new StepDetails("Collaborate with Investigative Teams", 
          "Work in investigative teams, where you will collaborate with other journalists, legal experts, and data analysts to ensure stories are well-researched and legally sound."
        ),
        new StepDetails("Develop a Specialization", 
          "Focus on a specific beat such as environmental issues, corporate fraud, or political corruption. Specializing in one area helps build a reputation and credibility within that field."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Senior Investigative Reporter", 
          "Move into senior investigative reporting positions. Lead significant investigations for national or international media outlets. Your work might have an impact on policy changes or legal outcomes."
        ),
        new StepDetails("Publishing Investigative Books", 
          "Expand your career by writing books based on your investigations. Many investigative journalists publish non-fiction books to tell stories that couldn't be fully covered in shorter media formats."
        ),
        new StepDetails("Lecturing or Teaching", 
          "Teach investigative journalism at universities or journalism schools. Use your experience to mentor the next generation of journalists and pass on critical investigative skills."
        ),
      ]
    ),
    ["Journalism", "Law", "Public Policy"],
    ["Researching", "Analyzing Data", "Interviewing Sources"],
    "Arts/Humanities Stream",
    "B.A.",
    "Journalism and Mass Communication"
  ),

  // 3. Digital Content Creator
  new CareerRoadMap(
    "Digital Content Creator",
    "Digital content creators produce multimedia content for platforms like YouTube, Instagram, blogs, or podcasts. They create engaging content to build audiences, often working independently or collaborating with brands to create sponsored content.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Learn Content Creation Tools", 
          "Master digital tools for content creation, including video editing software (e.g., Adobe Premiere, Final Cut Pro), graphic design tools (e.g., Canva, Photoshop), and audio editing tools (e.g., Audacity)."
        ),
        new StepDetails("Start a YouTube Channel or Blog", 
          "Start creating content around a niche you're passionate about. Choose a platform, whether it’s YouTube, Instagram, or a blog, and focus on building an audience by posting regularly and engaging with your followers."
        ),
        new StepDetails("Learn SEO and Social Media Strategy", 
          "Understand search engine optimization (SEO) and how to use social media effectively to reach your audience. Learn how to create content that ranks well on Google or gains traction on social media platforms."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Monetize Your Content", 
          "Work on monetizing your content through ads, sponsorships, or affiliate marketing. Build relationships with brands to create sponsored content, and focus on ways to make your platform profitable."
        ),
        new StepDetails("Collaborate with Brands", 
          "As your following grows, collaborate with brands or businesses that align with your content. Create promotional content or branded partnerships that benefit both you and the company."
        ),
        new StepDetails("Diversify Content Formats", 
          "Expand your content creation by experimenting with different formats, such as video series, podcasts, or interactive content. Diversification can help you reach new audiences and keep your current audience engaged."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Content Creation Business", 
          "Turn your content creation into a full-fledged business. Consider hiring a team to help with editing, management, and marketing, allowing you to scale your content production and earnings."
        ),
        new StepDetails("Launch Merchandise or Products", 
          "Create your own line of merchandise or products that align with your brand. Many content creators sell items like clothing, books, or digital courses to diversify their income streams."
        ),
        new StepDetails("Industry Influence and Growth", 
          "As an established digital content creator, leverage your influence to become a thought leader in your niche. Speak at industry events, launch courses or workshops, and mentor aspiring creators."
        ),
      ]
    ),
    ["Digital Media", "Visual Storytelling", "Social Media"],
    ["Filmmaking", "Video Editing", "Blogging"],
    "Arts/Humanities Stream",
    "B.A.",
    "Journalism and Mass Communication"
  ),

  // 4. Public Relations (PR) Specialist
  new CareerRoadMap(
    "Public Relations (PR) Specialist",
    "PR specialists help companies, individuals, or organizations build and maintain a positive public image. They craft press releases, organize press conferences, manage crisis communications, and build relationships with media outlets.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study PR and Communication", 
          "Take courses in public relations, communication strategy, and media relations. Learn how to create press releases, manage public messaging, and build relationships with journalists."
        ),
        new StepDetails("Internship at PR Firms", 
          "Intern at PR firms, government agencies, or corporate communication departments. Gain experience in drafting press releases, preparing media kits, and organizing media events."
        ),
        new StepDetails("Learn Crisis Management", 
          "Understand the basics of crisis communication. Learn how to handle negative media coverage or public relations crises by preparing statements, holding press conferences, and managing social media."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a PR Officer", 
          "Start working as a PR officer for a company, non-profit, or government agency. You'll be responsible for managing their media image, responding to press inquiries, and promoting positive coverage."
        ),
        new StepDetails("Build Media Relationships", 
          "Develop relationships with journalists, bloggers, and influencers. These connections are crucial for pitching stories and gaining media coverage for your clients."
        ),
        new StepDetails("Specialize in a PR Field", 
          "Choose a PR specialization, such as crisis communication, corporate PR, or social media PR. Specializing in one area can help you become a sought-after expert in that field."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Senior PR Roles", 
          "Advance to senior PR roles such as PR Manager or Head of Corporate Communications. In these roles, you'll oversee communication strategies, manage a team, and handle high-level client relationships."
        ),
        new StepDetails("PR Consultancy", 
          "Consider opening your own PR consultancy firm, offering services such as media training, crisis management, and public relations strategies to businesses and high-profile individuals."
        ),
        new StepDetails("Industry Thought Leadership", 
          "Establish yourself as an industry thought leader by speaking at PR conferences, publishing articles on public relations, or writing a book about communication strategies."
        ),
      ]
    ),
    ["Public Relations", "Communication", "Media Relations"],
    ["Writing", "Networking", "Event Planning"],
    "Arts/Humanities Stream",
    "B.A.",
    "Journalism and Mass Communication"
  ),

  // 5. Social Media Manager
  new CareerRoadMap(
    "Social Media Manager",
    "Social media managers oversee a company or individual’s social media presence, creating content, engaging with followers, and developing strategies to grow their online presence. They work to promote brands and build communities across social platforms.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Learn Social Media Platforms", 
          "Master the major social media platforms, including Instagram, Twitter, Facebook, LinkedIn, and TikTok. Learn how to create content, engage with users, and understand the nuances of each platform."
        ),
        new StepDetails("Internship at Marketing Agencies", 
          "Gain hands-on experience through internships at digital marketing or social media agencies. You’ll learn how to schedule posts, monitor engagement, and analyze social media performance."
        ),
        new StepDetails("Content Creation and Analytics", 
          "Develop skills in content creation, including graphics and videos, using tools like Canva, Adobe Photoshop, or InShot. Learn how to use analytics tools to measure engagement and growth."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Social Media Manager", 
          "Work as a social media manager for a company, agency, or influencer. You'll develop strategies to grow their online presence, manage social media campaigns, and track metrics to ensure successful engagement."
        ),
        new StepDetails("Build Engagement and Community", 
          "Focus on building strong online communities and engagement. This involves responding to comments, managing feedback, and creating interactive content that encourages user participation."
        ),
        new StepDetails("Social Media Advertising", 
          "Develop expertise in paid social media advertising. Learn how to create targeted ads on platforms like Facebook, Instagram, and LinkedIn to drive engagement, leads, or sales."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Head of Social Media", 
          "Move into senior roles such as Head of Social Media or Social Media Director. You’ll oversee the entire social media strategy for a brand or company, manage a team, and ensure that all content aligns with the company’s overall goals."
        ),
        new StepDetails("Digital Marketing Leadership", 
          "Expand your career by transitioning into a broader digital marketing leadership role, managing not just social media but also SEO, email marketing, and content strategy for a company."
        ),
        new StepDetails("Social Media Consultancy", 
          "Become a social media consultant, offering your expertise to brands, companies, or influencers looking to improve their social media presence. You can help them develop strategies, train their teams, and grow their online audiences."
        ),
      ]
    ),
    ["Social Media", "Marketing", "Communication"],
    ["Content Creation", "Engaging with Audiences", "Analyzing Social Trends"],
    "Arts/Humanities Stream",
    "B.A.",
    "Journalism and Mass Communication"
  ),
   // 1. Historian
   new CareerRoadMap(
    "Historian",
    "Historians research, analyze, and interpret historical events. They work to preserve the knowledge of the past, write about historical events, and provide insights into how history shapes modern society. Historians often work in academia, museums, or archives.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Focus on Historical Research", 
          "Study different periods of history, focusing on specific regions, events, or themes. Develop strong research skills by learning how to analyze primary and secondary historical sources."
        ),
        new StepDetails("Intern at Museums or Historical Societies", 
          "Gain hands-on experience through internships at museums, historical societies, or archives. This will give you exposure to historical preservation, archival work, and public history projects."
        ),
        new StepDetails("Master Historical Writing", 
          "Learn how to write research papers, articles, and essays on historical topics. Clear and precise writing is critical for communicating historical findings to both academic and general audiences."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Pursue a Master's Degree in History", 
          "Many historian roles, particularly in academia, require a master's degree or PhD. Specialize in a particular historical era or theme, such as ancient history, modern history, or social history."
        ),
        new StepDetails("Publish Historical Research", 
          "Begin publishing your research in academic journals or historical publications. Publishing helps build your reputation as a historian and is essential for advancing in academia."
        ),
        new StepDetails("Participate in Historical Conferences", 
          "Present your research at historical conferences or seminars. Networking with other historians and scholars will help you stay informed about the latest research and build your professional connections."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Become a University Professor or Research Historian", 
          "Work as a history professor at a university, where you'll teach history courses, mentor students, and continue your research. Alternatively, work as a research historian for think tanks, libraries, or research institutes."
        ),
        new StepDetails("Write Books or Historical Documentaries", 
          "Expand your career by writing books on historical subjects or contributing to historical documentaries. Historians often write for the public to make history accessible and engaging to a wider audience."
        ),
        new StepDetails("Lead Historical Research Projects", 
          "Lead large-scale historical research projects, managing teams of researchers. You may work on projects that involve documenting local histories, writing biographies, or preserving endangered historical records."
        ),
      ]
    ),
    ["History", "Research Methodology", "Writing"],
    ["Visiting Museums", "Reading History Books", "Archival Work"],
    "Arts/Humanities Stream",
    "B.A.",
    "History"
  ),

  // 2. Archaeologist
  new CareerRoadMap(
    "Archaeologist",
    "Archaeologists study human history through the excavation and analysis of artifacts, ruins, and other physical remains. They work to uncover and preserve historical sites, gaining insights into past civilizations, cultures, and practices.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Archaeological Methods", 
          "Take courses in archaeology, anthropology, and history. Learn excavation techniques, site surveying, and artifact preservation. Hands-on experience is key to understanding the tools and methods used in the field."
        ),
        new StepDetails("Fieldwork Internships", 
          "Participate in archaeological digs or fieldwork internships. Gaining practical experience in excavation, artifact handling, and site analysis is essential for becoming an archaeologist."
        ),
        new StepDetails("Learn Artifact Analysis", 
          "Develop skills in analyzing artifacts, including pottery, tools, and bones. Learn how to interpret these finds to understand the daily lives, economies, and cultures of ancient societies."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Field Archaeologist", 
          "Work as a field archaeologist on excavation sites. You'll collect and analyze artifacts, record site data, and help preserve historical sites. Focus on building expertise in a particular region or time period."
        ),
        new StepDetails("Specialize in a Subfield", 
          "Choose a subfield such as prehistoric archaeology, classical archaeology, or underwater archaeology. Specializing will help you gain deeper knowledge and become an expert in a specific area."
        ),
        new StepDetails("Collaborate on Research Projects", 
          "Work on research projects with universities, museums, or cultural heritage organizations. Archaeologists often collaborate on large-scale projects aimed at uncovering new historical knowledge."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Lead Excavation Projects", 
          "Become a lead archaeologist, overseeing excavation projects and field teams. You will be responsible for planning digs, analyzing findings, and publishing research on your discoveries."
        ),
        new StepDetails("Work for Government or International Organizations", 
          "Work with governmental or international organizations like UNESCO to preserve cultural heritage sites and artifacts. This could involve working on conservation projects or protecting sites from environmental or human threats."
        ),
        new StepDetails("Museum or Academic Roles", 
          "Consider transitioning into a role at a museum or university, where you can curate exhibits or teach future archaeologists. You may also publish books or articles based on your fieldwork."
        ),
      ]
    ),
    ["Archaeology", "Anthropology", "Artifact Analysis"],
    ["Excavating", "Studying Ancient Cultures", "Field Research"],
    "Arts/Humanities Stream",
    "B.A.",
    "History"
  ),

  // 3. Museum Curator
  new CareerRoadMap(
    "Museum Curator",
    "Museum curators manage collections of artifacts and historical items. They are responsible for acquiring, preserving, and displaying historical objects in museums. Curators work closely with historians and archaeologists to create exhibitions that educate the public.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Museum Studies and History", 
          "Take courses in museum studies, history, and art history. Learn the principles of curating, including collection management, artifact preservation, and exhibition design."
        ),
        new StepDetails("Internship at Museums or Cultural Institutions", 
          "Gain practical experience by interning at a museum, gallery, or historical institution. Learn how to handle artifacts, design exhibitions, and manage museum collections."
        ),
        new StepDetails("Learn Archival and Cataloging Techniques", 
          "Master the skills of cataloging and managing historical collections. Museums require detailed records of their artifacts, so understanding how to use archival software and cataloging systems is crucial."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as an Assistant Curator", 
          "Start working as an assistant curator in a museum or historical institution. In this role, you will help manage collections, research artifacts, and assist in planning exhibitions."
        ),
        new StepDetails("Curate Small Exhibitions", 
          "Take on the responsibility of curating small or temporary exhibitions. This will give you experience in exhibition design, storytelling through artifacts, and engaging the public with history."
        ),
        new StepDetails("Develop Expertise in a Historical Period or Theme", 
          "Specialize in a particular historical period, theme, or type of artifact, such as ancient Egypt, medieval Europe, or numismatics (coins and currency). Specialization will help you build a reputation in your area of interest."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Lead Curator or Museum Director", 
          "Move into senior roles such as Lead Curator or Museum Director. You'll be responsible for overseeing museum collections, designing major exhibitions, and managing the museum's overall vision."
        ),
        new StepDetails("Museum Collection Expansion", 
          "Work on acquiring new artifacts or collections for your museum. You'll need to research potential acquisitions, negotiate with collectors, and ensure that new items align with the museum's mission."
        ),
        new StepDetails("Public Outreach and Education", 
          "Expand your role by focusing on public outreach, creating educational programs for schools or community groups. You may also work on digital exhibits to reach a broader audience online."
        ),
      ]
    ),
    ["Museum Studies", "History", "Artifact Preservation"],
    ["Visiting Museums", "Curating Collections", "Organizing Exhibitions"],
    "Arts/Humanities Stream",
    "B.A.",
    "History"
  ),

  // 4. Archivist
  new CareerRoadMap(
    "Archivist",
    "Archivists are responsible for preserving historical records, documents, photographs, and other materials. They work in museums, libraries, universities, and government institutions, ensuring that important historical data is preserved for future generations.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Archival Science and History", 
          "Take courses in archival science, library science, and history. Learn how to organize, preserve, and maintain historical records and documents."
        ),
        new StepDetails("Intern at Archives or Libraries", 
          "Gain experience through internships at libraries, archives, or government agencies. Learn about record-keeping, cataloging systems, and digitization processes."
        ),
        new StepDetails("Learn Digital Archiving Techniques", 
          "As many archives move to digital formats, learn about the tools and techniques used in digital archiving. This includes scanning documents, creating metadata, and ensuring long-term digital preservation."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as an Archivist", 
          "Start working as an archivist at a university, library, or government agency. You'll be responsible for cataloging records, preserving historical documents, and assisting researchers who need access to archival material."
        ),
        new StepDetails("Specialize in a Type of Archive", 
          "Specialize in managing a specific type of archive, such as government documents, historical manuscripts, photographs, or film. Specialization will help you manage unique collections with specialized needs."
        ),
        new StepDetails("Research and Catalog Historical Records", 
          "Work on researching and cataloging historical records, ensuring that they are accessible to the public and researchers. You may also contribute to creating finding aids and guides for archival collections."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Lead Archivist or Archive Director", 
          "Become a lead archivist or director of archives, managing large collections and overseeing staff. You'll be responsible for long-term preservation strategies, acquisitions, and public access to the archives."
        ),
        new StepDetails("Conservation and Preservation Projects", 
          "Lead conservation projects to preserve fragile documents, photographs, or films. This may involve working with preservation experts to ensure that important records are protected from damage."
        ),
        new StepDetails("Digitization and Public Access", 
          "Expand public access to archives through digitization initiatives. Many archivists work on making their collections accessible online, allowing a broader audience to engage with historical records."
        ),
      ]
    ),
    ["Archival Science", "Library Science", "History"],
    ["Cataloging Records", "Preserving Documents", "Researching History"],
    "Arts/Humanities Stream",
    "B.A.",
    "History"
  ),

  // 5. History Teacher/Professor
  new CareerRoadMap(
    "History Teacher/Professor",
    "History teachers and professors educate students about historical events, cultures, and figures. They teach at the secondary or post-secondary level, helping students develop critical thinking skills and an understanding of the past's influence on the present.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Earn a Degree in History and Education", 
          "Complete a bachelor's degree in history, and if you're planning to teach at the high school level, earn a teaching certification. Courses in education theory and teaching methods are important for building teaching skills."
        ),
        new StepDetails("Student Teaching or Teaching Assistantships", 
          "Gain teaching experience by working as a student teacher in a high school or as a teaching assistant (TA) at a university. This will help you develop classroom management skills and learn how to engage students with history."
        ),
        new StepDetails("Learn to Design History Lessons", 
          "Focus on creating engaging and informative history lessons. Develop skills in lesson planning, incorporating primary sources, and encouraging critical analysis of historical events."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a History Teacher", 
          "Start working as a history teacher at the secondary level, where you'll teach students about historical events, world cultures, and critical thinking. Focus on making history engaging and relevant to students."
        ),
        new StepDetails("Pursue a Master's Degree for University Teaching", 
          "If you aim to teach at the university level, pursue a master's or PhD in history. This will allow you to teach specialized history courses and mentor students pursuing research in history."
        ),
        new StepDetails("Develop Expertise in a Specific Historical Period", 
          "Specialize in a particular historical era or theme, such as ancient civilizations, modern history, or gender history. Specialization will allow you to teach advanced courses and publish research in your area of expertise."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("University Professor", 
          "Become a full-time history professor at a university, where you'll teach courses, mentor students, and conduct historical research. Professors often publish books or articles on their areas of expertise."
        ),
        new StepDetails("Curriculum Development", 
          "Work on developing history curricula for schools or universities. You might also contribute to writing textbooks or creating online courses to help teach history to a wider audience."
        ),
        new StepDetails("Public Speaking and Outreach", 
          "Expand your role by participating in public outreach. This could involve giving lectures, writing for public history magazines, or participating in educational television programs that make history accessible to the public."
        ),
      ]
    ),
    ["History", "Education", "Research"],
    ["Teaching", "Writing Lessons", "Public Speaking"],
    "Arts/Humanities Stream",
    "B.A.",
    "History"
  ),
   // 1. Policy Analyst
   new CareerRoadMap(
    "Policy Analyst",
    "Policy analysts study and evaluate public policies to provide recommendations for government agencies, think tanks, and non-profit organizations. They focus on areas such as healthcare, education, and economic policy, providing research-based insights.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Public Policy and Political Science", 
          "Focus on courses in public policy, political theory, economics, and data analysis. Learn the basics of policy formulation and the factors that influence government decisions."
        ),
        new StepDetails("Internship at Government Agencies or Think Tanks", 
          "Gain experience by interning at government departments, think tanks, or non-profit organizations. You'll conduct research, analyze policy proposals, and assist in drafting policy reports."
        ),
        new StepDetails("Master Analytical Skills", 
          "Develop strong analytical skills using tools like Excel, R, or Stata to analyze data and evaluate the impact of public policies. Learn to present data and policy recommendations clearly."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Policy Analyst", 
          "Begin working as a policy analyst for government agencies, political organizations, or think tanks. You'll evaluate policy outcomes, analyze their social and economic impacts, and propose changes to existing policies."
        ),
        new StepDetails("Specialize in a Policy Area", 
          "Choose a specialization such as environmental policy, healthcare, education, or economic development. Specialization allows you to focus on a specific issue, building expertise in that field."
        ),
        new StepDetails("Contribute to Policy Reports and Journals", 
          "Work on drafting policy reports or contributing to academic journals. Publishing your research and policy recommendations helps establish your expertise in the field."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Senior Policy Analyst or Policy Director", 
          "Advance to roles such as Senior Policy Analyst or Policy Director, where you'll oversee policy analysis for large organizations or government agencies. You'll lead research teams and make high-level policy recommendations."
        ),
        new StepDetails("Advisory or Consulting Roles", 
          "Consider transitioning to a consulting role, where you'll advise governments, NGOs, or businesses on policy decisions. This could involve working on large-scale projects that impact public policy at national or international levels."
        ),
        new StepDetails("Government Leadership Roles", 
          "Move into leadership roles within government departments, where you’ll influence policy decisions at the highest levels. You may also consider running for public office to directly shape policy."
        ),
      ]
    ),
    ["Political Science", "Economics", "Public Policy"],
    ["Researching Policy", "Analyzing Data", "Writing Reports"],
    "Arts/Humanities Stream",
    "B.A.",
    "Political Science"
  ),

  // 2. Diplomat (Foreign Service Officer)
  new CareerRoadMap(
    "Diplomat (Foreign Service Officer)",
    "Diplomats represent their home country abroad, managing diplomatic relations and negotiating international agreements. They work on issues like international trade, peacekeeping, and human rights, often posted at embassies around the world.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study International Relations and Diplomacy", 
          "Focus on courses in international relations, political science, and diplomacy. Learn about global politics, international law, and the role of international organizations like the United Nations."
        ),
        new StepDetails("Apply for Foreign Service Exams or Diplomatic Programs", 
          "Most countries require aspiring diplomats to pass foreign service exams or complete a diplomatic training program. Prepare for these exams by improving your knowledge of international relations and government structures."
        ),
        new StepDetails("Learn Foreign Languages", 
          "Develop proficiency in one or more foreign languages. Diplomatic roles often require fluency in languages like French, Spanish, Arabic, or Mandarin, depending on the region you are posted to."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Junior Diplomat or Attaché", 
          "Begin your diplomatic career as a junior officer or attaché at an embassy or consulate. You’ll assist in managing relations between your home country and the host country, handling visas, trade relations, or cultural exchanges."
        ),
        new StepDetails("Negotiate International Agreements", 
          "As you gain experience, work on negotiating trade agreements, peace treaties, or human rights protocols. You'll be part of diplomatic teams that work on resolving international conflicts or building alliances."
        ),
        new StepDetails("Specialize in a Region or Issue", 
          "Develop expertise in a specific region (e.g., the Middle East, East Asia) or an issue like trade, human rights, or conflict resolution. Specialization helps you advance in diplomatic circles and handle more complex negotiations."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Senior Diplomatic Roles", 
          "Advance to senior diplomatic roles such as Ambassador or Consul General. You’ll be responsible for managing entire embassies, overseeing political and economic relations, and representing your country on the global stage."
        ),
        new StepDetails("Work with International Organizations", 
          "Work with international organizations like the United Nations, World Bank, or NATO, focusing on global diplomacy, conflict resolution, and humanitarian efforts."
        ),
        new StepDetails("Advisory Roles and International Leadership", 
          "Consider advisory roles in international organizations or non-profits that focus on global issues like peacebuilding, trade, or climate change. You may also take on leadership roles in these organizations."
        ),
      ]
    ),
    ["International Relations", "Diplomacy", "Political Science"],
    ["Learning Foreign Languages", "Negotiating", "Traveling"],
    "Arts/Humanities Stream",
    "B.A.",
    "Political Science"
  ),

  // 3. Political Consultant
  new CareerRoadMap(
    "Political Consultant",
    "Political consultants work with political candidates and parties to develop campaign strategies, manage public relations, and build a strong political image. They analyze public opinion, craft political messaging, and provide advice on media outreach.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Political Campaigns and Communication", 
          "Take courses in political science, public relations, and communication. Learn about campaign management, public opinion research, and how to shape political messaging."
        ),
        new StepDetails("Intern with Political Campaigns or Consulting Firms", 
          "Gain hands-on experience by working on local or national political campaigns. Learn how to organize rallies, conduct voter outreach, and develop strategies to engage with the public."
        ),
        new StepDetails("Learn Polling and Data Analysis", 
          "Develop skills in polling and analyzing voter data. Understanding public opinion is key to crafting successful political strategies, and consultants use data to shape campaigns."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Political Consultant for Campaigns", 
          "Start working as a political consultant for local or national campaigns. You’ll be responsible for advising candidates on their public image, crafting speeches, and managing media relations."
        ),
        new StepDetails("Specialize in Political Strategy or Media Relations", 
          "Choose a niche within political consulting, such as media relations, campaign finance, or voter engagement. Specialization helps you build expertise and become sought-after by political candidates."
        ),
        new StepDetails("Run Political Campaigns", 
          "Manage entire political campaigns, from strategy development to media outreach. You’ll be responsible for creating campaign ads, managing debates, and ensuring the candidate's message resonates with voters."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Lead a Political Consulting Firm", 
          "Establish or lead a political consulting firm, advising multiple candidates or political parties. You’ll work on high-profile campaigns, helping shape political landscapes at the regional or national level."
        ),
        new StepDetails("Political Advisory Roles", 
          "Move into advisory roles for government agencies or international political organizations. You may advise political leaders on strategy, public relations, and policy communication."
        ),
        new StepDetails("Public Speaking and Media Commentary", 
          "As an experienced consultant, participate in public speaking engagements or media commentary on political issues. Political consultants often become media personalities, offering analysis on elections and public opinion."
        ),
      ]
    ),
    ["Political Science", "Public Relations", "Media Studies"],
    ["Analyzing Elections", "Debating", "Campaign Management"],
    "Arts/Humanities Stream",
    "B.A.",
    "Political Science"
  ),

  // 4. Legislative Assistant
  new CareerRoadMap(
    "Legislative Assistant",
    "Legislative assistants support lawmakers by researching policy issues, drafting legislation, and communicating with constituents. They help legislators stay informed on key issues, respond to public inquiries, and ensure that proposed laws are well-crafted.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Legislative Processes and Political Science", 
          "Focus on understanding the legislative process, government structures, and political theory. Learn how laws are drafted, debated, and passed in your country's legislative body."
        ),
        new StepDetails("Intern with Lawmakers or Government Agencies", 
          "Gain experience by interning in the office of a legislator, either at the local, state, or national level. You’ll assist in policy research, drafting legislative proposals, and handling constituent communications."
        ),
        new StepDetails("Learn Policy Analysis and Legislative Drafting", 
          "Develop strong writing and research skills to help draft legislative proposals. Learn how to analyze policies and their potential impacts to assist in crafting effective and impactful laws."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Legislative Assistant", 
          "Work full-time as a legislative assistant, where you’ll be responsible for conducting policy research, drafting bills, and advising lawmakers on key issues. You'll also help respond to constituent inquiries."
        ),
        new StepDetails("Specialize in Legislative Issues", 
          "Develop expertise in specific policy areas such as healthcare, education, or environmental legislation. Specialization will allow you to provide more in-depth advice to lawmakers on these issues."
        ),
        new StepDetails("Draft Major Legislation", 
          "Assist in drafting significant legislation that addresses major policy issues. You’ll work closely with lawmakers to ensure that the laws you help create are effective and address the needs of the public."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Senior Legislative Advisor or Chief of Staff", 
          "Move into senior roles like Senior Legislative Advisor or Chief of Staff, where you’ll oversee a lawmaker's legislative agenda, manage their team, and provide high-level strategic advice on policy issues."
        ),
        new StepDetails("Run for Public Office", 
          "Use your experience to run for public office yourself. Many legislative assistants transition into political roles where they can directly shape legislation and policy at the state or national level."
        ),
        new StepDetails("Lobbying and Advocacy", 
          "Transition to a lobbying or advocacy role, where you'll work on behalf of organizations or interest groups to influence legislation and promote policy changes that align with your organization’s goals."
        ),
      ]
    ),
    ["Political Science", "Legislation", "Public Policy"],
    ["Researching Laws", "Drafting Legislation", "Communicating with Public"],
    "Arts/Humanities Stream",
    "B.A.",
    "Political Science"
  ),

  // 5. Public Affairs Specialist
  new CareerRoadMap(
    "Public Affairs Specialist",
    "Public affairs specialists work to build relationships between organizations and the government, media, and public. They manage public relations campaigns, engage with stakeholders, and help organizations influence public policy.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Public Relations and Political Science", 
          "Focus on public relations, communication, and political science. Learn how public affairs work at the intersection of media, government, and the public, and how organizations influence policy."
        ),
        new StepDetails("Intern at Public Affairs Firms or NGOs", 
          "Intern at a public affairs or lobbying firm, or work with a non-profit to learn about stakeholder engagement, media relations, and policy advocacy. This will give you hands-on experience with public affairs campaigns."
        ),
        new StepDetails("Develop Communication and Networking Skills", 
          "Work on your communication and networking skills, which are critical for building relationships with stakeholders. Public affairs specialists need to be able to clearly convey messages and build strong professional networks."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Public Affairs Specialist", 
          "Begin working as a public affairs specialist for companies, non-profits, or government agencies. You'll manage relationships with the media, lobby for policy changes, and ensure that your organization is seen positively by the public."
        ),
        new StepDetails("Media Relations and Crisis Management", 
          "Develop expertise in media relations, writing press releases, and handling crisis communication. Public affairs specialists often need to manage a company’s image during crises or public controversies."
        ),
        new StepDetails("Engage in Lobbying or Advocacy", 
          "Work with government officials, politicians, or lobbyists to advocate for policy changes that benefit your organization. Public affairs specialists often help organizations influence legislation and public policy."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Public Affairs Director", 
          "Advance to roles like Public Affairs Director, where you’ll lead a team responsible for managing your organization’s media relations, stakeholder engagement, and public advocacy efforts."
        ),
        new StepDetails("Government Relations", 
          "Work in government relations, representing your organization or company in discussions with government officials, regulators, and policymakers to ensure that their interests are considered in public policy."
        ),
        new StepDetails("Consultancy or Lobbying Firm", 
          "Consider establishing a public affairs or lobbying consultancy firm. In this role, you’ll help organizations build relationships with the government and public, influence policy, and manage their public image."
        ),
      ]
    ),
    ["Public Relations", "Political Science", "Public Policy"],
    ["Communicating", "Building Networks", "Lobbying"],
    "Arts/Humanities Stream",
    "B.A.",
    "Political Science"
  ),
  // 1. Social Worker
  new CareerRoadMap(
    "Social Worker",
    "Social workers provide support to individuals, families, and communities to improve their quality of life. They work in various settings, such as schools, hospitals, and social service agencies, helping clients overcome challenges related to poverty, abuse, mental health, and more.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Social Work and Sociology", 
          "Take courses in sociology, social work, and psychology. Learn about social inequality, community development, and the structures that impact individual well-being."
        ),
        new StepDetails("Internship at Social Service Agencies", 
          "Gain experience through internships or volunteering at social service agencies. You'll learn how to work with vulnerable populations, assess client needs, and provide practical support."
        ),
        new StepDetails("Learn Counseling and Case Management", 
          "Develop skills in counseling, crisis intervention, and case management. Social workers often help clients navigate complex systems, such as healthcare, housing, and legal services."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Social Worker", 
          "Work as a full-time social worker in areas like child welfare, healthcare, or mental health services. You'll provide direct support to clients, advocating for their needs and connecting them with resources."
        ),
        new StepDetails("Specialize in a Specific Population", 
          "Specialize in working with specific groups, such as children, the elderly, or people with mental health issues. Specialization helps build expertise and allows you to focus on the unique needs of these populations."
        ),
        new StepDetails("Obtain a Master's Degree in Social Work (MSW)", 
          "Consider pursuing a master's degree in social work (MSW), which is required for clinical social work roles. This will allow you to provide therapy and take on leadership roles in social service agencies."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Licensed Clinical Social Worker (LCSW)", 
          "Become a licensed clinical social worker (LCSW) to provide mental health counseling and therapy. You can work in private practice or in healthcare settings, helping clients address emotional and psychological issues."
        ),
        new StepDetails("Social Work Supervisor or Director", 
          "Advance to supervisory or director roles, where you'll manage a team of social workers and oversee the delivery of services. You may also be responsible for program development and funding."
        ),
        new StepDetails("Advocacy and Policy Work", 
          "Transition into advocacy roles, where you'll work to influence public policy related to social justice, poverty, healthcare, or child welfare. You may also work with non-profit organizations to promote social change."
        ),
      ]
    ),
    ["Sociology", "Social Work", "Psychology"],
    ["Volunteering", "Counseling", "Helping Others"],
    "Arts/Humanities Stream",
    "B.A.",
    "Sociology"
  ),

  // 2. Human Resources Specialist
  new CareerRoadMap(
    "Human Resources Specialist",
    "Human resources (HR) specialists manage recruitment, employee relations, and workplace policies within organizations. They are responsible for hiring new employees, ensuring workplace compliance, managing benefits, and fostering a positive work environment.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Human Resources and Sociology", 
          "Focus on courses in sociology, human resources management, and organizational behavior. Learn about workplace dynamics, labor laws, and employee rights."
        ),
        new StepDetails("Internship in Human Resources", 
          "Gain experience by interning in the HR department of a company or non-profit organization. You'll learn how to handle recruitment, employee onboarding, and managing workplace issues."
        ),
        new StepDetails("Develop Communication and Mediation Skills", 
          "Learn effective communication and mediation techniques, as HR specialists often resolve workplace conflicts and ensure a positive work environment."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Human Resources Specialist", 
          "Work as an HR specialist, managing recruitment processes, employee relations, and benefits administration. You'll also help develop workplace policies and ensure compliance with labor laws."
        ),
        new StepDetails("Specialize in Talent Management or Employee Relations", 
          "Choose a specialization within HR, such as talent acquisition, employee relations, or compensation and benefits. Specialization will allow you to focus on areas of HR that align with your skills and interests."
        ),
        new StepDetails("Obtain HR Certifications", 
          "Pursue HR certifications such as SHRM-CP or PHR to enhance your qualifications and expertise in human resources management. Certifications will help you advance in your career."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("HR Manager or Director", 
          "Move into senior roles like HR Manager or Director, where you'll oversee the entire HR department, manage talent strategy, and ensure employee satisfaction across the organization."
        ),
        new StepDetails("Specialize in Organizational Development", 
          "Specialize in organizational development, where you'll work on improving workplace culture, leadership development, and employee engagement. This role involves strategic planning to foster a productive and positive workplace."
        ),
        new StepDetails("Consulting or HR Leadership", 
          "Consider transitioning to HR consulting or starting your own HR consulting firm. You'll work with multiple organizations to improve their HR processes, recruit top talent, and resolve workplace challenges."
        ),
      ]
    ),
    ["Sociology", "Human Resources", "Organizational Behavior"],
    ["Networking", "Helping Others", "Problem-Solving"],
    "Arts/Humanities Stream",
    "B.A.",
    "Sociology"
  ),

  // 3. Market Research Analyst
  new CareerRoadMap(
    "Market Research Analyst",
    "Market research analysts study market conditions to help companies understand what products or services people want. They analyze consumer behavior, preferences, and trends to provide insights that guide business strategies and marketing efforts.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Market Research and Sociology", 
          "Take courses in sociology, statistics, and market research. Learn how to analyze consumer behavior, identify market trends, and use data to make informed business decisions."
        ),
        new StepDetails("Internship at Market Research Firms", 
          "Gain experience by interning at market research firms or in marketing departments. You'll work on data collection, survey design, and analyzing market trends to support business strategies."
        ),
        new StepDetails("Learn Data Analysis Tools", 
          "Develop proficiency in data analysis tools like SPSS, Excel, or Tableau. These tools help analyze consumer data, identify patterns, and predict market trends."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Market Research Analyst", 
          "Start working as a market research analyst for companies, advertising agencies, or market research firms. You'll analyze consumer data, conduct surveys, and provide insights into customer preferences."
        ),
        new StepDetails("Specialize in a Specific Industry", 
          "Choose a specialization within market research, such as consumer goods, healthcare, or technology. Specializing in a particular industry helps you provide more tailored insights and recommendations."
        ),
        new StepDetails("Conduct Focus Groups and Surveys", 
          "Lead focus groups and design surveys to gather in-depth consumer insights. You'll analyze qualitative data to understand why consumers behave the way they do and how businesses can respond to these behaviors."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Senior Market Research Analyst", 
          "Advance to a senior analyst position, managing market research projects and teams. You'll oversee large-scale research efforts, work with high-profile clients, and provide strategic insights to guide business decisions."
        ),
        new StepDetails("Market Research Consulting", 
          "Consider working as a market research consultant, advising businesses on how to improve their marketing strategies based on consumer behavior and market trends. Consulting allows you to work with various industries and clients."
        ),
        new StepDetails("Market Research Director", 
          "Become a Market Research Director, where you'll lead the market research division of a company. You'll oversee all research activities, guide data-driven marketing strategies, and ensure that business decisions are backed by reliable consumer data."
        ),
      ]
    ),
    ["Sociology", "Market Research", "Statistics"],
    ["Analyzing Data", "Studying Consumer Behavior", "Survey Design"],
    "Arts/Humanities Stream",
    "B.A.",
    "Sociology"
  ),

  // 5. Non-Profit Program Coordinator
  new CareerRoadMap(
    "Non-Profit Program Coordinator",
    "Non-profit program coordinators manage programs designed to address social issues, such as poverty, education, or healthcare. They work with communities, manage volunteers, and ensure that non-profit programs are run effectively to meet organizational goals.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Sociology and Non-Profit Management", 
          "Focus on courses in sociology, non-profit management, and social work. Learn how non-profits operate, including program development, fundraising, and community outreach."
        ),
        new StepDetails("Volunteer or Intern with Non-Profits", 
          "Gain experience by volunteering or interning with non-profit organizations. You'll learn how to manage programs, work with volunteers, and understand the challenges non-profits face in achieving their goals."
        ),
        new StepDetails("Develop Grant Writing Skills", 
          "Learn how to write grant proposals to secure funding for non-profit programs. Grant writing is a critical skill for non-profit program coordinators, as funding often comes from external sources."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Program Coordinator", 
          "Start working as a program coordinator at a non-profit organization. You'll manage day-to-day program activities, coordinate with volunteers, and ensure that the program meets its objectives."
        ),
        new StepDetails("Specialize in a Social Issue", 
          "Specialize in a particular social issue, such as education, healthcare, or environmental sustainability. Specialization allows you to focus your efforts on creating targeted programs that address specific community needs."
        ),
        new StepDetails("Fundraising and Outreach", 
          "Work on building relationships with donors and securing funding for your programs. You'll also engage in community outreach to ensure that your programs have a positive impact on the people you serve."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Non-Profit Program Director", 
          "Move into senior roles such as Program Director, where you'll oversee multiple programs, manage staff, and work on long-term strategies to achieve the organization's mission."
        ),
        new StepDetails("Executive Director or Non-Profit Founder", 
          "Consider becoming an Executive Director of a non-profit organization or founding your own non-profit. You'll be responsible for the overall strategy, fundraising, and ensuring that the organization remains financially sustainable."
        ),
        new StepDetails("Advocacy and Policy Work", 
          "Work on advocacy efforts to influence public policy related to your non-profit's mission. You may collaborate with governments, media, and other organizations to promote social change."
        ),
      ]
    ),
    ["Sociology", "Non-Profit Management", "Social Issues"],
    ["Volunteering", "Fundraising", "Helping Communities"],
    "Arts/Humanities Stream",
    "B.A.",
    "Sociology"
  ),
  // 1. Clinical Psychologist
  new CareerRoadMap(
    "Clinical Psychologist",
    "Clinical psychologists diagnose and treat mental, emotional, and behavioral disorders. They work in hospitals, mental health clinics, or private practice to help patients manage conditions like depression, anxiety, and trauma.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Clinical Psychology", 
          "Take courses in clinical psychology, abnormal psychology, and psychotherapy techniques. Learn how to assess and diagnose mental health conditions."
        ),
        new StepDetails("Internship at Mental Health Clinics", 
          "Gain practical experience by interning at mental health clinics or hospitals. You’ll observe therapy sessions, work with clients, and learn how clinical assessments are conducted."
        ),
        new StepDetails("Pursue a Master's Degree in Clinical Psychology", 
          "A master’s degree or higher is typically required to become a licensed clinical psychologist. Focus on advanced courses in clinical practice, psychological assessment, and therapy methods."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Complete a Doctorate (PhD or PsyD)", 
          "To practice as a clinical psychologist, you’ll need a doctorate degree. A PhD focuses on research and academic work, while a PsyD focuses more on clinical practice."
        ),
        new StepDetails("Complete Clinical Practicum", 
          "During your doctoral program, complete clinical practicums where you’ll work directly with patients under supervision. This will give you hands-on experience in treating mental health conditions."
        ),
        new StepDetails("Work as a Licensed Clinical Psychologist", 
          "Once licensed, work as a clinical psychologist in hospitals, mental health clinics, or private practice. You’ll provide therapy, conduct assessments, and develop treatment plans for patients."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Specialize in a Niche Area", 
          "Consider specializing in areas like trauma therapy, child psychology, or addiction treatment. Specialization allows you to focus your practice on specific patient populations or conditions."
        ),
        new StepDetails("Lead a Private Practice", 
          "Open your own private practice, offering therapy and psychological services to clients. You’ll manage your business, handle patient care, and potentially supervise other psychologists."
        ),
        new StepDetails("Research and Teaching", 
          "Expand into research or academia, where you can conduct psychological studies and teach at the university level. Many clinical psychologists contribute to advancing the field through research publications."
        ),
      ]
    ),
    ["Clinical Psychology", "Therapy", "Mental Health"],
    ["Helping Others", "Listening", "Solving Problems"],
    "Arts/Humanities Stream",
    "B.A.",
    "Psychology"
  ),

  // 2. Counseling Psychologist
  new CareerRoadMap(
    "Counseling Psychologist",
    "Counseling psychologists help individuals manage life stressors, relationships, and emotional well-being. They provide support for people dealing with everyday problems, such as work stress, family conflicts, or self-esteem issues.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Counseling and Psychology", 
          "Take courses in counseling psychology, human development, and therapeutic techniques. Learn about building therapeutic relationships and promoting personal growth in clients."
        ),
        new StepDetails("Internship in Counseling Centers", 
          "Intern at counseling centers, schools, or community organizations. Gain experience in providing support to clients through one-on-one or group counseling sessions."
        ),
        new StepDetails("Pursue a Master's Degree in Counseling Psychology", 
          "A master’s degree is typically required to become a licensed counseling psychologist. Focus on advanced training in therapy, interpersonal relationships, and client assessment."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Counseling Psychologist", 
          "Once licensed, work as a counseling psychologist in schools, universities, hospitals, or private practice. You’ll help clients work through issues such as stress, grief, or personal development."
        ),
        new StepDetails("Specialize in Family or Career Counseling", 
          "Choose a counseling specialization such as marriage and family therapy, career counseling, or substance abuse counseling. Specializing allows you to work with specific populations."
        ),
        new StepDetails("Develop Group Counseling Programs", 
          "Lead group counseling sessions, focusing on topics like stress management, relationships, or self-confidence. Group therapy is an effective way to address common issues among clients."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Lead a Private Counseling Practice", 
          "Open your own private counseling practice, where you’ll offer counseling services to individuals, couples, or families. Private practice offers flexibility and control over your client base."
        ),
        new StepDetails("Supervision and Training", 
          "Consider becoming a supervisor for other counselors, providing guidance on client cases and mentoring new counseling professionals. You may also offer training programs for aspiring counselors."
        ),
        new StepDetails("Community Mental Health Leadership", 
          "Work in leadership roles at community mental health organizations, developing programs and services to support emotional well-being at a community level."
        ),
      ]
    ),
    ["Counseling", "Human Development", "Psychology"],
    ["Listening", "Empathy", "Helping Others"],
    "Arts/Humanities Stream",
    "B.A.",
    "Psychology"
  ),

  // 3. Organizational Psychologist
  new CareerRoadMap(
    "Organizational Psychologist",
    "Organizational psychologists apply psychological principles to improve workplace productivity, employee satisfaction, and organizational development. They focus on hiring practices, leadership, and improving workplace culture.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Organizational Psychology", 
          "Take courses in organizational behavior, human resources management, and psychology. Learn how psychology influences employee performance and workplace culture."
        ),
        new StepDetails("Intern at Human Resources or Consulting Firms", 
          "Gain practical experience through internships in HR departments or organizational consulting firms. Learn how to conduct employee assessments, develop training programs, and improve workplace practices."
        ),
        new StepDetails("Learn Data Analysis for HR", 
          "Develop skills in data analysis and workplace metrics to measure employee performance, engagement, and job satisfaction. These skills are critical in assessing organizational effectiveness."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as an Organizational Psychologist", 
          "Work as an organizational psychologist for businesses, government agencies, or HR consulting firms. You’ll work on improving employee selection, training programs, and performance reviews."
        ),
        new StepDetails("Specialize in Leadership Development or Employee Engagement", 
          "Specialize in areas like leadership development, employee engagement, or workplace diversity. Specialization helps you develop expertise in a particular area of workplace psychology."
        ),
        new StepDetails("Design Training and Development Programs", 
          "Design and implement training programs that help employees develop skills, improve productivity, and advance in their careers. You may also develop leadership training for management teams."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Consulting and Advisory Roles", 
          "Move into organizational consulting, advising companies on how to improve their workplace culture, employee satisfaction, and business performance. Organizational psychologists are often sought after for high-level business strategy."
        ),
        new StepDetails("Workplace Mental Health Programs", 
          "Develop workplace wellness and mental health programs that promote employee well-being and prevent burnout. This could involve designing mental health support systems within organizations."
        ),
        new StepDetails("Executive Coaching", 
          "Become an executive coach, working directly with business leaders to improve their leadership skills, decision-making, and team management. Executive coaching is a growing field within organizational psychology."
        ),
      ]
    ),
    ["Organizational Behavior", "Human Resources", "Psychology"],
    ["Improving Workplaces", "Problem-Solving", "Coaching Others"],
    "Arts/Humanities Stream",
    "B.A.",
    "Psychology"
  ),

  // 4. Forensic Psychologist
  new CareerRoadMap(
    "Forensic Psychologist",
    "Forensic psychologists apply psychological principles to legal issues, including criminal investigations, court cases, and rehabilitation programs. They work with law enforcement, the courts, and correctional institutions to assess criminal behavior and support the justice system.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Forensic Psychology and Criminal Justice", 
          "Take courses in forensic psychology, criminal justice, and legal systems. Learn about the intersection of psychology and law, including how to assess criminal behavior and the psychology behind crimes."
        ),
        new StepDetails("Intern at Law Enforcement Agencies or Courts", 
          "Gain experience by interning with law enforcement agencies, correctional facilities, or courts. You’ll learn how forensic psychologists assess offenders, provide testimony, and work on rehabilitation efforts."
        ),
        new StepDetails("Develop Skills in Psychological Assessment", 
          "Learn to conduct psychological assessments of offenders, determining their mental health, risk of reoffending, and suitability for rehabilitation programs. Assessments play a critical role in legal proceedings."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Forensic Psychologist", 
          "Work as a forensic psychologist, providing expert testimony in court cases, assessing criminal behavior, and working on rehabilitation programs. You may also work with victims of crime, helping them recover emotionally."
        ),
        new StepDetails("Specialize in Criminal Profiling or Court Evaluations", 
          "Consider specializing in criminal profiling, where you work with law enforcement to profile suspects, or in court evaluations, where you assess offenders for trials and sentencing."
        ),
        new StepDetails("Conduct Research in Forensic Psychology", 
          "Engage in research on criminal behavior, mental illness, and the effectiveness of rehabilitation programs. Many forensic psychologists contribute to legal reforms and crime prevention strategies."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Expert Witness and Legal Consultant", 
          "Become an expert witness in court cases, providing psychological evaluations of defendants and advising the court on mental health issues related to crime. You may also consult on high-profile cases."
        ),
        new StepDetails("Lead Rehabilitation Programs", 
          "Work in leadership roles within correctional institutions, developing programs that rehabilitate offenders and reduce recidivism. You may also work on creating community-based support for reintegrating former offenders."
        ),
        new StepDetails("Academia and Forensic Research", 
          "Consider a career in academia, teaching forensic psychology to future practitioners. Many forensic psychologists conduct research and publish studies on criminal behavior, mental illness, and legal systems."
        ),
      ]
    ),
    ["Forensic Psychology", "Criminal Justice", "Psychology"],
    ["Understanding Behavior", "Analyzing Crime", "Working with Law Enforcement"],
    "Arts/Humanities Stream",
    "B.A.",
    "Psychology"
  ),

  // 5. School Psychologist
  new CareerRoadMap(
    "School Psychologist",
    "School psychologists work in educational settings to help children and adolescents succeed academically, socially, and emotionally. They assess students’ learning needs, provide counseling, and work with teachers and parents to create supportive learning environments.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Educational and Developmental Psychology", 
          "Take courses in educational psychology, child development, and school counseling. Learn how psychological principles can be applied to support students' learning and mental health."
        ),
        new StepDetails("Internship in Schools or Educational Centers", 
          "Gain hands-on experience by interning in schools or educational centers, working alongside school counselors and psychologists to assess students and provide support services."
        ),
        new StepDetails("Pursue a Master's Degree in School Psychology", 
          "Most school psychologists need at least a master's degree in school psychology or educational psychology. This advanced degree will provide the specialized knowledge needed to work in schools."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a School Psychologist", 
          "Work as a school psychologist in elementary, middle, or high schools. You’ll provide assessments for learning disabilities, offer counseling to students, and develop strategies for improving students’ academic and social well-being."
        ),
        new StepDetails("Specialize in Child Development or Behavioral Interventions", 
          "Specialize in areas such as child development or behavioral interventions, allowing you to work more closely with students who have special educational needs or behavioral challenges."
        ),
        new StepDetails("Work with Teachers and Parents", 
          "Collaborate with teachers and parents to create individualized education plans (IEPs) and behavioral support plans. School psychologists play a critical role in ensuring that students with learning disabilities receive the help they need."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Lead School Psychology Programs", 
          "Take on leadership roles in educational psychology, overseeing school counseling programs or working as a district-wide school psychologist. You’ll guide policy and practices to support student mental health across multiple schools."
        ),
        new StepDetails("Develop Educational Support Programs", 
          "Work on creating or improving programs that support students’ emotional and academic needs. These programs might include mental health services, anti-bullying campaigns, or peer support networks."
        ),
        new StepDetails("Research and Advocacy", 
          "Contribute to research on child psychology, learning disabilities, or mental health in schools. You may also work as an advocate for policies that improve mental health services for students at a national level."
        ),
      ]
    ),
    ["Educational Psychology", "Child Development", "School Counseling"],
    ["Working with Children", "Helping Students", "Improving Education"],
    "Arts/Humanities Stream",
    "B.A.",
    "Psychology"
  ),
   // 1. Economist
   new CareerRoadMap(
    "Economist",
    "Economists study how societies use resources and distribute goods and services. They analyze economic data, develop forecasts, and provide insights to guide public policy and business decisions.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Economics and Statistics", 
          "Focus on microeconomics, macroeconomics, and statistical analysis. Learn how to model economic behavior and interpret economic data."
        ),
        new StepDetails("Internship at Government Agencies or Research Firms", 
          "Intern with government agencies, think tanks, or research institutions. Gain experience in analyzing economic data, working with financial models, and understanding policy impacts."
        ),
        new StepDetails("Master Data Analysis Tools", 
          "Develop strong data analysis skills, using software like Stata, R, or Excel to interpret economic data. Proficiency in these tools is critical for forecasting economic trends."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as an Economist", 
          "Start working as an economist in government agencies, financial institutions, or international organizations. You'll analyze economic trends, provide policy recommendations, and prepare economic reports."
        ),
        new StepDetails("Specialize in a Sector (e.g., Health, Labor, or Environment)", 
          "Specialize in specific sectors like healthcare economics, labor markets, or environmental economics. Specialization helps you provide more targeted insights for policymaking or business strategy."
        ),
        new StepDetails("Conduct Economic Forecasting", 
          "Work on economic forecasting, predicting future economic conditions and trends. Forecasting is vital for advising businesses or governments on economic policies."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Lead Economic Research Teams", 
          "Lead teams of economists on large-scale research projects or work as a senior economist for international organizations like the World Bank or IMF."
        ),
        new StepDetails("Public Policy Advisor", 
          "Transition into policy advising, helping governments or international organizations craft economic policies that promote growth, reduce unemployment, or manage inflation."
        ),
        new StepDetails("Academic Research and Teaching", 
          "Consider an academic career, conducting research on economic theory and teaching at the university level. Many economists also publish studies that contribute to the field’s development."
        ),
      ]
    ),
    ["Economics", "Statistics", "Data Analysis"],
    ["Analyzing Data", "Predicting Trends", "Researching Policies"],
    "Arts/Humanities Stream",
    "B.A.",
    "Economics"
  ),

  // 2. Financial Analyst
  new CareerRoadMap(
    "Financial Analyst",
    "Financial analysts help businesses and individuals make investment decisions by evaluating financial data and market trends. They analyze financial reports, predict future performance, and provide investment recommendations.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Finance, Economics, and Accounting", 
          "Focus on courses in finance, economics, and accounting. Learn how to analyze financial statements, evaluate investment opportunities, and understand market trends."
        ),
        new StepDetails("Internship in Financial Firms", 
          "Gain experience by interning at investment banks, financial advisory firms, or corporate finance departments. You'll assist in evaluating investments, analyzing market trends, and preparing financial reports."
        ),
        new StepDetails("Learn Financial Modeling and Excel", 
          "Develop proficiency in financial modeling and Excel. These tools help financial analysts forecast future performance, evaluate investments, and provide recommendations."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Financial Analyst", 
          "Work as a financial analyst for investment banks, corporations, or asset management firms. You'll analyze financial data, provide investment recommendations, and assess market trends."
        ),
        new StepDetails("Specialize in Corporate Finance, Investment, or Risk Analysis", 
          "Specialize in areas like corporate finance, investment analysis, or risk management. Specialization allows you to provide deeper insights and focus on a specific aspect of financial analysis."
        ),
        new StepDetails("Work Towards CFA Certification", 
          "Consider earning the Chartered Financial Analyst (CFA) certification. This certification enhances your qualifications and opens up opportunities for higher-level financial roles."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Senior Financial Analyst or Portfolio Manager", 
          "Advance to senior roles such as Senior Financial Analyst or Portfolio Manager, where you'll oversee investment portfolios, manage financial teams, and provide high-level financial strategy."
        ),
        new StepDetails("Financial Consulting or Advisory Roles", 
          "Consider transitioning into financial consulting or working as a financial advisor, helping individuals or businesses make informed investment and financial decisions."
        ),
        new StepDetails("Lead Financial Strategy for Corporations", 
          "Work as a financial strategist or CFO for corporations, leading financial planning, risk management, and investment strategies at the executive level."
        ),
      ]
    ),
    ["Finance", "Economics", "Accounting"],
    ["Analyzing Markets", "Investment Strategies", "Financial Planning"],
    "Arts/Humanities Stream",
    "B.A.",
    "Economics"
  ),

  // 3. Policy Advisor
  new CareerRoadMap(
    "Policy Advisor",
    "Policy advisors work with government agencies, NGOs, and think tanks to develop and implement policies that address economic, social, or environmental issues. They analyze policy impacts, make recommendations, and guide decision-makers.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Public Policy and Economics", 
          "Take courses in public policy, economics, and political science. Learn how to analyze the impact of policies on different sectors of society and how economic theory informs policy decisions."
        ),
        new StepDetails("Intern with Government Agencies or Think Tanks", 
          "Intern at government agencies, policy think tanks, or NGOs. You'll gain experience in analyzing policies, writing reports, and making recommendations based on economic and social data."
        ),
        new StepDetails("Learn Policy Analysis Tools", 
          "Master policy analysis tools such as cost-benefit analysis, data modeling, and economic impact studies. These tools help assess the effectiveness and efficiency of different policy proposals."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Policy Advisor", 
          "Begin working as a policy advisor for government agencies, international organizations, or think tanks. You'll provide analysis on economic, social, or environmental issues and propose policy solutions."
        ),
        new StepDetails("Specialize in a Policy Area (e.g., Healthcare, Labor, or Environment)", 
          "Choose a specialization within policy advising, such as healthcare economics, labor policy, or environmental policy. Specializing allows you to focus on specific areas of societal impact."
        ),
        new StepDetails("Publish Policy Reports and Research", 
          "Work on publishing policy research or white papers, providing recommendations for governments or organizations. Published work helps establish your expertise and informs policy decisions."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Senior Policy Advisor or Consultant", 
          "Advance to roles like Senior Policy Advisor or Policy Consultant, where you'll work on high-level government projects, lead policy research teams, and advise top decision-makers on strategic policy initiatives."
        ),
        new StepDetails("Leadership Roles in Think Tanks or NGOs", 
          "Move into leadership roles at think tanks, research institutions, or international organizations, where you'll guide large-scale policy research and work on shaping public policy at a national or global level."
        ),
        new StepDetails("Government or International Policy Leadership", 
          "Consider transitioning into senior government roles, where you'll directly influence national or international policy on critical economic, environmental, or social issues."
        ),
      ]
    ),
    ["Public Policy", "Economics", "Political Science"],
    ["Analyzing Policies", "Writing Reports", "Researching Public Issues"],
    "Arts/Humanities Stream",
    "B.A.",
    "Economics"
  ),

  // 4. Data Analyst
  new CareerRoadMap(
    "Data Analyst",
    "Data analysts work with large datasets to uncover trends, patterns, and insights that inform business, government, or economic decisions. They use statistical tools to analyze data, generate reports, and make data-driven recommendations.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Data Science and Economics", 
          "Take courses in data science, statistics, and economics. Learn how to analyze large datasets, interpret economic data, and provide insights that drive business or policy decisions."
        ),
        new StepDetails("Learn Data Analysis Tools", 
          "Develop proficiency in data analysis tools like Excel, SQL, Python, and R. These tools help data analysts manipulate and interpret large datasets."
        ),
        new StepDetails("Intern at Data-Driven Companies", 
          "Gain experience by interning at companies that use data for decision-making, such as tech firms, market research companies, or financial institutions. You’ll assist in collecting, analyzing, and reporting data."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Data Analyst", 
          "Start working as a data analyst for corporations, government agencies, or research institutions. You'll be responsible for analyzing data, creating visualizations, and presenting your findings to inform decision-making."
        ),
        new StepDetails("Specialize in Economic or Business Data", 
          "Specialize in analyzing economic data, such as employment rates or market trends, or focus on business data, such as sales performance or customer behavior. Specialization helps you become an expert in your field."
        ),
        new StepDetails("Data Visualization and Reporting", 
          "Develop expertise in data visualization, using tools like Tableau or Power BI to create reports that communicate data insights clearly and effectively. Reporting is a key part of a data analyst's role."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Senior Data Analyst or Data Scientist", 
          "Advance to senior roles such as Senior Data Analyst or Data Scientist, where you'll manage data analysis projects, lead data teams, and work on more complex data models to solve business or economic problems."
        ),
        new StepDetails("Data Strategy and Leadership", 
          "Transition into roles where you’ll guide data strategy for corporations or government agencies. You’ll oversee how data is collected, analyzed, and used to inform high-level decisions."
        ),
        new StepDetails("Consulting and Data Analytics Advisory", 
          "Consider transitioning into data analytics consulting, where you'll advise businesses or governments on how to leverage data to improve decision-making, increase efficiency, and drive innovation."
        ),
      ]
    ),
    ["Data Science", "Economics", "Statistics"],
    ["Analyzing Data", "Creating Visualizations", "Solving Problems"],
    "Arts/Humanities Stream",
    "B.A.",
    "Economics"
  ),

  // 5. Investment Banker
  new CareerRoadMap(
    "Investment Banker",
    "Investment bankers help businesses and governments raise capital by issuing stocks or bonds, manage mergers and acquisitions, and provide financial advisory services. They work in high-pressure environments to close deals and generate revenue for clients.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Finance, Economics, and Accounting", 
          "Focus on courses in finance, economics, and accounting. Learn how financial markets work, how to evaluate investment opportunities, and how companies raise capital."
        ),
        new StepDetails("Intern at Investment Banks", 
          "Gain hands-on experience by interning at investment banks. You'll assist in preparing financial models, performing market analysis, and helping with mergers and acquisitions."
        ),
        new StepDetails("Learn Financial Modeling", 
          "Develop strong financial modeling skills using Excel. Investment bankers rely on financial models to value companies, assess risks, and predict future cash flows."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as an Investment Banker", 
          "Work as an investment banker at a major financial institution, advising clients on raising capital, managing mergers and acquisitions, and investing in stocks or bonds."
        ),
        new StepDetails("Specialize in Mergers and Acquisitions or Capital Markets", 
          "Choose a specialization in areas such as mergers and acquisitions (M&A) or capital markets. Specialization allows you to build expertise in complex financial transactions."
        ),
        new StepDetails("Prepare Initial Public Offerings (IPOs)", 
          "Work on preparing IPOs, helping companies go public by issuing shares on the stock market. This involves evaluating the company, pricing the shares, and coordinating the process with regulators."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Managing Director or Partner at Investment Bank", 
          "Advance to senior roles such as Managing Director or Partner at an investment bank. You’ll oversee large financial deals, manage client relationships, and lead a team of bankers."
        ),
        new StepDetails("Investment Banking Advisory", 
          "Transition into investment banking advisory, where you’ll provide high-level financial advice to corporations or governments on mergers, acquisitions, and capital raising strategies."
        ),
        new StepDetails("Private Equity or Venture Capital", 
          "Consider transitioning into private equity or venture capital, where you’ll invest directly in companies, helping them grow and manage their finances in exchange for ownership stakes."
        ),
      ]
    ),
    ["Finance", "Economics", "Accounting"],
    ["Analyzing Investments", "Closing Deals", "Working with Clients"],
    "Arts/Humanities Stream",
    "B.A.",
    "Economics"
  ),

  // 2. GIS Specialist
  new CareerRoadMap(
    "GIS Specialist",
    "GIS specialists use Geographic Information Systems (GIS) to collect, analyze, and visualize spatial data. They work in fields such as urban planning, environmental conservation, and transportation, using GIS technology to solve spatial problems.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Geographic Information Systems (GIS)", 
          "Focus on courses in GIS, remote sensing, and spatial analysis. Learn how to use GIS software to collect and analyze geographic data."
        ),
        new StepDetails("Learn GIS Software (ArcGIS, QGIS)", 
          "Develop proficiency in popular GIS software like ArcGIS and QGIS, as well as tools like Google Earth and satellite imagery analysis. These tools are essential for visualizing and interpreting geographic data."
        ),
        new StepDetails("Internship in GIS Applications", 
          "Intern with organizations that use GIS for urban planning, environmental monitoring, or disaster management. Gain experience in applying GIS to real-world spatial problems."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a GIS Specialist", 
          "Work as a GIS specialist for government agencies, environmental organizations, or private companies. You’ll create maps, analyze spatial data, and provide insights for decision-making in areas such as land use, transportation, and conservation."
        ),
        new StepDetails("Specialize in a GIS Application", 
          "Choose a GIS specialization, such as environmental monitoring, natural resource management, or urban planning. Specialization helps you focus your skills on specific geographic problems."
        ),
        new StepDetails("GIS Data Analysis and Mapping", 
          "Work on collecting and analyzing large datasets to produce detailed maps and reports. You'll use GIS tools to visualize trends in land use, population growth, or environmental changes."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Senior GIS Analyst", 
          "Advance to senior roles such as Senior GIS Analyst or GIS Project Manager, where you’ll lead GIS teams, manage large projects, and work on complex spatial analyses for clients."
        ),
        new StepDetails("GIS Consulting", 
          "Consider becoming a GIS consultant, advising businesses or governments on how to use spatial data to improve decision-making. Consulting allows you to work across various industries, such as environmental protection, transportation, and urban planning."
        ),
        new StepDetails("Innovative GIS Applications", 
          "Work on cutting-edge GIS applications such as climate change modeling, disaster risk management, or smart city development. These areas are at the forefront of geographic data science."
        ),
      ]
    ),
    ["GIS", "Remote Sensing", "Data Analysis"],
    ["Creating Maps", "Analyzing Geographic Data", "Problem-Solving"],
    "Arts/Humanities Stream",
    "B.A.",
    "Geography"
  ),

  // 3. Environmental Consultant
  new CareerRoadMap(
    "Environmental Consultant",
    "Environmental consultants provide advice on managing and mitigating environmental risks for businesses, governments, and organizations. They assess the impact of projects on ecosystems, develop sustainability strategies, and ensure compliance with environmental regulations.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Environmental Geography", 
          "Take courses in environmental geography, ecology, and environmental management. Learn about environmental laws, conservation practices, and the impact of human activity on natural ecosystems."
        ),
        new StepDetails("Internship at Environmental Consulting Firms", 
          "Gain practical experience by interning with environmental consulting firms, government agencies, or NGOs. You’ll assist in conducting environmental impact assessments and developing sustainability plans."
        ),
        new StepDetails("Learn Environmental Laws and Regulations", 
          "Develop an understanding of local, national, and international environmental regulations. This knowledge is crucial for helping clients navigate the legal aspects of environmental management."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as an Environmental Consultant", 
          "Start working as an environmental consultant for consulting firms, government agencies, or NGOs. You’ll assess environmental risks, conduct field surveys, and develop strategies to minimize environmental impact."
        ),
        new StepDetails("Specialize in Sustainability or Environmental Impact Assessments", 
          "Choose a specialization such as sustainability consulting, environmental impact assessments (EIAs), or climate change mitigation. Specializing helps you focus on a specific area of environmental protection."
        ),
        new StepDetails("Conduct Environmental Audits", 
          "Work on conducting environmental audits for companies or municipalities to ensure compliance with environmental laws and to promote sustainable practices."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Senior Environmental Consultant", 
          "Advance to senior roles where you’ll lead environmental consulting projects, manage teams, and advise high-profile clients on sustainability and environmental risk management."
        ),
        new StepDetails("Sustainability Leadership Roles", 
          "Consider taking on sustainability leadership roles within corporations, guiding them toward more environmentally friendly practices and helping them meet sustainability goals."
        ),
        new StepDetails("Environmental Policy Advisor", 
          "Transition into environmental policy advising, working with governments or international organizations to shape environmental laws and promote sustainable development."
        ),
      ]
    ),
    ["Environmental Science", "Geography", "Ecology"],
    ["Fieldwork", "Assessing Environmental Risks", "Developing Sustainability Plans"],
    "Arts/Humanities Stream",
    "B.A.",
    "Geography"
  ),

  // 4. Cartographer
  new CareerRoadMap(
    "Cartographer",
    "Cartographers design and create maps for a variety of purposes, including navigation, education, and scientific research. They work with geographic data to produce accurate and informative visual representations of landscapes, cities, and other geographic features.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Cartography and Geographic Data Visualization", 
          "Focus on courses in cartography, GIS, and graphic design. Learn how to collect geographic data and turn it into visually appealing maps."
        ),
        new StepDetails("Learn Cartography Software", 
          "Develop proficiency in mapping software such as ArcGIS, QGIS, and Adobe Illustrator. These tools are essential for creating detailed and accurate maps."
        ),
        new StepDetails("Internship with Mapping or Surveying Firms", 
          "Gain experience by interning with cartography firms, surveying companies, or government mapping agencies. You’ll assist in data collection, map design, and geographic analysis."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Cartographer", 
          "Start working as a cartographer, creating maps for various industries, including transportation, education, and scientific research. You’ll collect geographic data and use it to create informative maps."
        ),
        new StepDetails("Specialize in Thematic or Topographic Mapping", 
          "Choose a specialization within cartography, such as thematic mapping (maps that focus on specific data like population or climate) or topographic mapping (maps that show physical landscapes in detail)."
        ),
        new StepDetails("Work with 3D Mapping and Interactive Maps", 
          "Explore advanced mapping techniques such as 3D mapping and interactive digital maps. These methods are used in fields like urban planning, environmental science, and virtual reality."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Senior Cartographer or Mapping Specialist", 
          "Advance to senior roles in cartography, managing mapping projects, and leading cartography teams. You’ll be responsible for producing large-scale mapping projects and collaborating with geographers and GIS specialists."
        ),
        new StepDetails("Cartography Consultant", 
          "Consider becoming a cartography consultant, advising companies, government agencies, or research institutions on how to create accurate and visually appealing maps for various applications."
        ),
        new StepDetails("Geospatial Analysis and Map Design Innovation", 
          "Work on innovative cartography projects, such as using geospatial data for climate change research, disaster management, or creating immersive 3D maps for education and exploration."
        ),
      ]
    ),
    ["Cartography", "GIS", "Graphic Design"],
    ["Creating Maps", "Analyzing Geographic Data", "Graphic Design"],
    "Arts/Humanities Stream",
    "B.A.",
    "Geography"
  ),

  // 5. Environmental Educator
  new CareerRoadMap(
    "Environmental Educator",
    "Environmental educators teach students and communities about environmental conservation, sustainability, and natural resource management. They work in schools, nature centers, parks, or non-profits to raise awareness about environmental issues.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Environmental Geography and Education", 
          "Take courses in environmental geography, ecology, and education. Learn about environmental conservation, climate change, and how to communicate complex environmental issues to the public."
        ),
        new StepDetails("Volunteer or Intern at Nature Centers or Parks", 
          "Gain practical experience by volunteering or interning at nature centers, parks, or environmental non-profits. You’ll help lead educational programs, teach students about nature, and organize conservation activities."
        ),
        new StepDetails("Develop Communication and Teaching Skills", 
          "Learn how to create lesson plans, lead workshops, and engage different audiences. Effective communication is essential for educating people about environmental issues and inspiring action."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as an Environmental Educator", 
          "Begin working as an environmental educator in schools, nature centers, or non-profits. You’ll lead educational programs, organize conservation projects, and promote environmental awareness."
        ),
        new StepDetails("Develop Educational Materials and Programs", 
          "Create educational materials such as lesson plans, workbooks, and presentations to teach students and communities about environmental conservation and sustainability."
        ),
        new StepDetails("Specialize in Community Engagement or Conservation Education", 
          "Specialize in areas like community engagement, conservation education, or outdoor education. Specialization allows you to focus on specific audiences or issues, such as urban conservation or wilderness education."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Lead Environmental Education Programs", 
          "Take on leadership roles in environmental education programs, managing teams of educators and developing long-term strategies for environmental outreach."
        ),
        new StepDetails("Work as a Conservation Program Director", 
          "Transition into roles where you manage large-scale conservation programs, working with schools, government agencies, or non-profits to promote environmental stewardship."
        ),
        new StepDetails("Environmental Advocacy and Policy Education", 
          "Consider transitioning into advocacy roles, educating the public and policymakers on the importance of environmental protection and influencing environmental policy at a local or national level."
        ),
      ]
    ),
    ["Environmental Science", "Geography", "Education"],
    ["Teaching", "Leading Conservation Efforts", "Public Speaking"],
    "Arts/Humanities Stream",
    "B.A.",
    "Geography"
  ),
  // 1. Ethics Consultant
  new CareerRoadMap(
    "Ethics Consultant",
    "Ethics consultants advise businesses, governments, and organizations on ethical practices and decision-making. They help guide organizations through complex moral issues, ensuring compliance with ethical standards and fostering responsible behavior.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Ethics and Philosophy", 
          "Take courses in ethics, moral philosophy, and corporate responsibility. Learn how to apply ethical principles to real-world scenarios, focusing on business ethics, medical ethics, or environmental ethics."
        ),
        new StepDetails("Internship in Business or Policy Organizations", 
          "Gain experience by interning with companies, non-profits, or government agencies that focus on ethical practices. This will give you hands-on experience in analyzing and advising on ethical issues."
        ),
        new StepDetails("Learn Regulatory and Compliance Frameworks", 
          "Develop an understanding of legal and regulatory frameworks related to ethics. Knowing how laws intersect with ethics is crucial in advising businesses on compliance with ethical standards."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as an Ethics Consultant", 
          "Start working as an ethics consultant for businesses, non-profits, or government agencies. You’ll provide advice on ethical policies, review business practices, and ensure that organizations are operating in a responsible manner."
        ),
        new StepDetails("Specialize in a Field (e.g., Business Ethics, Medical Ethics)", 
          "Specialize in areas such as corporate ethics, medical ethics, or environmental ethics. Specialization helps you focus your practice and provide expert advice on specific ethical issues."
        ),
        new StepDetails("Develop Corporate Social Responsibility (CSR) Programs", 
          "Work on developing CSR programs for businesses that align their practices with ethical principles. You’ll help organizations balance profit with responsibility to the environment, employees, and communities."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Senior Ethics Consultant or Ethics Director", 
          "Advance to senior roles like Ethics Director, where you’ll oversee an organization’s ethics programs, provide high-level advice on ethical decision-making, and ensure compliance with legal and regulatory standards."
        ),
        new StepDetails("Ethics Consulting Firm", 
          "Consider starting your own ethics consulting firm, advising businesses, governments, and non-profits on ethical issues. You’ll provide specialized services in areas like sustainability, human rights, and corporate governance."
        ),
        new StepDetails("Academic Contributions to Ethics", 
          "Engage in academic research and teaching, contributing to the field of ethics by publishing papers and teaching future consultants. Many ethics consultants continue to engage in academic work to shape the discourse around ethics."
        ),
      ]
    ),
    ["Ethics", "Moral Philosophy", "Corporate Responsibility"],
    ["Analyzing Ethical Issues", "Developing Policies", "Advising Companies"],
    "Arts/Humanities Stream",
    "B.A.",
    "Philosophy"
  ),

  // 2. Policy Analyst
  new CareerRoadMap(
    "Policy Analyst",
    "Policy analysts research, analyze, and develop policies to address societal challenges. They work for government agencies, think tanks, or non-profits to evaluate the effectiveness of policies and propose solutions to social, political, or economic problems.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Political Philosophy and Ethics", 
          "Focus on courses in political philosophy, public policy, and ethics. Learn how philosophical principles influence policy decisions and how to apply these concepts to solve societal problems."
        ),
        new StepDetails("Internship at Government Agencies or Think Tanks", 
          "Gain experience by interning at government agencies, research organizations, or think tanks. You’ll assist in policy analysis, research, and developing recommendations for policymakers."
        ),
        new StepDetails("Develop Research and Writing Skills", 
          "Work on developing strong research and writing skills, which are essential for policy analysis. You'll need to analyze large amounts of data and create reports that present your findings clearly."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Policy Analyst", 
          "Start working as a policy analyst for governments, NGOs, or research institutions. You’ll be responsible for researching policies, evaluating their impacts, and providing recommendations for improvement."
        ),
        new StepDetails("Specialize in a Policy Area (e.g., Healthcare, Education, or Environment)", 
          "Choose a policy area to specialize in, such as healthcare policy, environmental policy, or education reform. Specialization allows you to develop deep expertise in a specific field of public policy."
        ),
        new StepDetails("Engage in Policy Advocacy", 
          "Work on advocacy efforts to influence government decisions on key policy issues. This might involve presenting research to lawmakers, participating in public debates, or collaborating with advocacy groups."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Senior Policy Advisor", 
          "Advance to roles like Senior Policy Advisor, where you’ll advise high-level decision-makers on complex policy issues, help craft legislation, and provide strategic advice to governments or organizations."
        ),
        new StepDetails("Policy Director or Think Tank Leadership", 
          "Consider taking leadership roles in policy organizations or think tanks, guiding large-scale research projects and shaping public discourse on critical policy issues."
        ),
        new StepDetails("Public Sector Leadership", 
          "Consider transitioning into government roles, where you'll work directly in the public sector to implement and oversee the policies you've helped develop."
        ),
      ]
    ),
    ["Political Philosophy", "Public Policy", "Ethics"],
    ["Researching Policies", "Advocating for Change", "Analyzing Societal Issues"],
    "Arts/Humanities Stream",
    "B.A.",
    "Philosophy"
  ),

  // 3. Legal Consultant
  new CareerRoadMap(
    "Legal Consultant",
    "Legal consultants advise clients on legal matters, providing expertise in areas such as contract law, ethics, and compliance. They help businesses and individuals navigate complex legal frameworks and ensure adherence to regulations.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Philosophy of Law and Ethics", 
          "Focus on courses in legal philosophy, ethics, and logic. Learn how philosophical principles underpin legal systems and develop your critical thinking skills."
        ),
        new StepDetails("Internship at Law Firms or Legal Departments", 
          "Gain experience by interning with law firms, corporate legal departments, or government agencies. You’ll assist with legal research, case preparation, and drafting legal documents."
        ),
        new StepDetails("Develop Legal Research Skills", 
          "Work on honing your legal research and analytical skills. Legal consultants need to be able to understand complex legal texts and regulations and provide clear advice to clients."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Legal Consultant", 
          "Start working as a legal consultant for law firms, corporations, or government agencies. You’ll provide legal advice, assist in contract negotiations, and ensure that businesses comply with laws and regulations."
        ),
        new StepDetails("Specialize in Corporate Law, Compliance, or Ethics", 
          "Choose a legal specialization such as corporate law, compliance, or ethics. Specialization allows you to develop deeper expertise and provide more tailored advice to clients."
        ),
        new StepDetails("Handle Legal Compliance and Risk Management", 
          "Work on ensuring that businesses comply with legal regulations and manage risks effectively. You’ll advise on legal liabilities and help companies avoid costly legal challenges."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Senior Legal Consultant or Compliance Officer", 
          "Advance to senior roles such as Senior Legal Consultant or Compliance Officer. You’ll oversee legal compliance for large organizations, manage risk, and provide strategic advice to executives."
        ),
        new StepDetails("Consulting Firm or Private Practice", 
          "Consider starting your own legal consulting firm, providing expert legal advice to businesses, non-profits, or individuals. You’ll help clients navigate complex legal challenges and ensure compliance with regulations."
        ),
        new StepDetails("Academic Research and Teaching in Legal Philosophy", 
          "Engage in academic work, contributing to the study of legal philosophy and teaching law students. Many legal consultants continue to research and publish papers on the intersection of law, philosophy, and ethics."
        ),
      ]
    ),
    ["Legal Philosophy", "Ethics", "Compliance"],
    ["Researching Laws", "Advising Clients", "Analyzing Legal Texts"],
    "Arts/Humanities Stream",
    "B.A.",
    "Philosophy"
  ),

  // 4. Academic Researcher/Professor
  new CareerRoadMap(
    "Academic Researcher/Professor",
    "Academic researchers and professors work in universities, teaching philosophy and conducting research in various philosophical fields. They contribute to the academic community by publishing papers, guiding students, and advancing the understanding of philosophical issues.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Pursue a Master’s Degree in Philosophy", 
          "After completing your bachelor's degree, pursue a master’s degree in philosophy. Focus on developing a deep understanding of philosophical theories and honing your research and writing skills."
        ),
        new StepDetails("Begin Research in Your Area of Interest", 
          "Start conducting research in your chosen philosophical field, whether it’s ethics, metaphysics, epistemology, or political philosophy. Work closely with professors and publish your research findings in academic journals."
        ),
        new StepDetails("Develop Teaching Skills", 
          "Gain experience as a teaching assistant or lecturer during your master’s or doctoral studies. Learn how to create lesson plans, deliver lectures, and engage students in philosophical debates."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Complete a PhD in Philosophy", 
          "Pursue a doctoral degree (PhD) in philosophy, where you’ll conduct original research and write a dissertation on a specialized philosophical topic. A PhD is typically required for academic positions."
        ),
        new StepDetails("Publish Research Papers", 
          "Work on publishing your research in academic journals and presenting at philosophical conferences. Publishing is key to establishing your reputation as a scholar and advancing your academic career."
        ),
        new StepDetails("Work as an Assistant Professor", 
          "Start working as an assistant professor at a university, teaching philosophy courses, supervising students, and continuing your research. This is a crucial stage for building your academic career."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Full Professor or Department Chair", 
          "Advance to the position of full professor, where you’ll have tenure and greater influence within your department. Consider taking on leadership roles, such as Department Chair, to guide the academic direction of your philosophy program."
        ),
        new StepDetails("Publish Books and Lead Research", 
          "Continue to publish books and academic papers on your specialized topics in philosophy. Lead research initiatives and mentor PhD students in developing their academic careers."
        ),
        new StepDetails("Public Intellectual and Media Engagement", 
          "Consider becoming a public intellectual, contributing to discussions in the media or publishing popular philosophy books that make complex philosophical issues accessible to a broader audience."
        ),
      ]
    ),
    ["Metaphysics", "Epistemology", "Ethics"],
    ["Teaching", "Conducting Research", "Writing Papers"],
    "Arts/Humanities Stream",
    "B.A.",
    "Philosophy"
  ),

  // 5. Journalist or Editorial Writer
  new CareerRoadMap(
    "Journalist or Editorial Writer",
    "Philosophy graduates with strong writing and analytical skills often pursue careers in journalism or editorial writing. They write opinion pieces, articles, and columns on social, political, and cultural issues, applying philosophical insights to public discourse.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Philosophy and Writing", 
          "Take courses in philosophy, ethics, and writing. Learn how to structure arguments, analyze complex issues, and communicate clearly to a general audience."
        ),
        new StepDetails("Start a Blog or Write for College Publications", 
          "Gain writing experience by starting a personal blog or writing for student publications. Write opinion pieces or articles that apply philosophical insights to current social, political, or cultural issues."
        ),
        new StepDetails("Internship at Media Outlets", 
          "Intern with newspapers, magazines, or online media outlets. You’ll assist in researching stories, writing articles, and learning how to produce content for print or digital platforms."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Journalist or Editorial Writer", 
          "Begin working as a journalist or editorial writer for media outlets, focusing on opinion pieces, investigative journalism, or analysis of political and social issues. Philosophy graduates often excel at writing deeply analytical articles."
        ),
        new StepDetails("Specialize in Opinion Writing or Investigative Journalism", 
          "Choose a specialization such as opinion writing, where you’ll write about ethical, philosophical, or political issues, or investigative journalism, where you’ll research and uncover in-depth stories."
        ),
        new StepDetails("Publish Freelance Articles", 
          "Contribute freelance articles to newspapers, magazines, or online platforms. Freelance work allows you to build a portfolio of published articles and reach a broader audience."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Senior Editorial Writer or Columnist", 
          "Advance to senior roles such as editorial writer or columnist, where you’ll regularly contribute thought-provoking opinion pieces or analysis on political, social, or philosophical topics."
        ),
        new StepDetails("Editorial Leadership Roles", 
          "Consider taking on leadership roles such as Editor-in-Chief or Editorial Director, guiding the editorial direction of a publication and managing teams of writers and journalists."
        ),
        new StepDetails("Author or Public Intellectual", 
          "Consider writing books on social or political philosophy, ethics, or cultural criticism. Many editorial writers also publish books, expanding their influence as public intellectuals."
        ),
      ]
    ),
    ["Ethics", "Political Philosophy", "Writing"],
    ["Writing Opinion Pieces", "Analyzing Social Issues", "Reading and Researching"],
    "Arts/Humanities Stream",
    "B.A.",
    "Philosophy"
  ),
   // 2. Actuary
   new CareerRoadMap(
    "Actuary",
    "Actuaries use mathematics, statistics, and financial theory to assess risk and uncertainty in insurance, pensions, and other financial sectors. They help companies predict future events and create strategies to minimize financial risk.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Actuarial Science and Probability", 
          "Focus on courses in actuarial science, probability, and statistics. These are the foundation for understanding risk and uncertainty in financial contexts."
        ),
        new StepDetails("Pass Actuarial Exams", 
          "Begin taking actuarial exams from recognized bodies like the Society of Actuaries (SOA) or the Institute and Faculty of Actuaries (IFoA). Passing these exams is required to become a certified actuary."
        ),
        new StepDetails("Internship with Insurance Companies", 
          "Gain experience by interning with insurance companies, pension funds, or financial firms. You'll assist in analyzing risk, calculating premiums, and assessing financial outcomes."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as an Actuary", 
          "Work as an actuary for insurance companies, consulting firms, or government agencies. You’ll assess financial risks, design insurance policies, and advise companies on pension plans and investment strategies."
        ),
        new StepDetails("Specialize in Health, Life, or Property Insurance", 
          "Choose a specialization in areas like health insurance, life insurance, or property insurance. Specialization allows you to focus on specific types of risk and develop expertise in that domain."
        ),
        new StepDetails("Continue Passing Actuarial Exams", 
          "Continue progressing through the series of actuarial exams required for full certification. Actuaries need to complete several exams to achieve professional status."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Senior Actuary or Actuarial Manager", 
          "Advance to senior roles such as Senior Actuary or Actuarial Manager, where you'll lead risk analysis teams, manage large portfolios, and advise companies on strategic financial decisions."
        ),
        new StepDetails("Consulting in Risk Management", 
          "Consider transitioning into consulting, where you'll advise businesses and government agencies on managing financial risks and ensuring the long-term stability of their financial products."
        ),
        new StepDetails("Chief Risk Officer (CRO)", 
          "Move into executive roles such as Chief Risk Officer, where you'll oversee an organization's entire risk management strategy and ensure that it is aligned with business goals and regulatory requirements."
        ),
      ]
    ),
    ["Mathematics", "Statistics", "Financial Theory"],
    ["Analyzing Risk", "Solving Problems", "Financial Planning"],
    "Science Stream",
    "B.Sc.",
    "Mathematics"
  ),

  // 3. Operations Research Analyst
  new CareerRoadMap(
    "Operations Research Analyst",
    "Operations research analysts use advanced mathematical models to help organizations solve complex problems and make better decisions. They work in industries like logistics, manufacturing, and finance, using optimization techniques to improve efficiency.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Optimization and Linear Programming", 
          "Focus on courses in optimization, linear programming, and operations research. These areas form the core of operations research, allowing you to solve complex logistical and operational problems."
        ),
        new StepDetails("Learn Programming and Simulation Tools", 
          "Develop proficiency in programming languages such as Python, R, and tools like MATLAB. Operations research analysts often use these tools to simulate scenarios and run optimization algorithms."
        ),
        new StepDetails("Internship in Logistics or Supply Chain", 
          "Gain practical experience by interning in industries like logistics, manufacturing, or finance. You'll assist in optimizing supply chains, production processes, or financial portfolios using mathematical models."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as an Operations Research Analyst", 
          "Start working as an operations research analyst for companies in logistics, transportation, or manufacturing. You'll develop models to improve operational efficiency and reduce costs."
        ),
        new StepDetails("Specialize in Supply Chain or Financial Optimization", 
          "Specialize in areas such as supply chain optimization, transportation planning, or financial portfolio management. Specialization allows you to provide deeper insights into specific operational challenges."
        ),
        new StepDetails("Work on Simulation and Risk Analysis", 
          "Develop expertise in simulation techniques and risk analysis to help companies anticipate potential disruptions and optimize their operational processes in uncertain conditions."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Senior Operations Analyst or Operations Manager", 
          "Advance to senior roles such as Senior Operations Analyst or Operations Manager, where you'll oversee optimization projects, manage teams, and advise on strategic operational decisions."
        ),
        new StepDetails("Consulting in Operational Efficiency", 
          "Consider transitioning into consulting, advising businesses on how to improve their operations, optimize their supply chains, and enhance decision-making processes."
        ),
        new StepDetails("Chief Operations Officer (COO)", 
          "Move into executive roles like Chief Operations Officer, where you'll oversee the operational strategy of a company and ensure that it is aligned with business goals and efficiency targets."
        ),
      ]
    ),
    ["Operations Research", "Optimization", "Statistics"],
    ["Solving Problems", "Improving Efficiency", "Analyzing Operations"],
    "Science Stream",
    "B.Sc.",
    "Mathematics"
  ),

  // 4. Quantitative Analyst (Quant)
  new CareerRoadMap(
    "Quantitative Analyst (Quant)",
    "Quantitative analysts, or quants, use mathematical models to analyze financial markets and make trading decisions. They work in investment banks, hedge funds, and financial firms, developing algorithms that predict market movements and optimize portfolios.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Financial Mathematics and Stochastic Processes", 
          "Focus on courses in financial mathematics, stochastic processes, and derivatives. These areas are key to understanding market movements and developing financial models."
        ),
        new StepDetails("Learn Programming for Financial Modeling", 
          "Develop proficiency in programming languages like Python, C++, and MATLAB. Quants use these tools to build algorithms that analyze market data and predict trends."
        ),
        new StepDetails("Internship at Financial Institutions", 
          "Gain experience by interning at investment banks, hedge funds, or financial firms. You'll assist in building financial models, backtesting trading strategies, and analyzing market data."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Quantitative Analyst", 
          "Work as a quantitative analyst for financial institutions, developing algorithms for trading, risk management, and portfolio optimization. You'll apply advanced mathematical techniques to financial markets."
        ),
        new StepDetails("Specialize in High-Frequency Trading or Derivatives Pricing", 
          "Specialize in areas like high-frequency trading (HFT), derivatives pricing, or risk management. Specialization allows you to focus on specific market strategies and develop highly accurate financial models."
        ),
        new StepDetails("Work on Algorithmic Trading", 
          "Develop and test algorithmic trading strategies that use mathematical models to make automated trades in financial markets. You'll work on optimizing these strategies for speed and profitability."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Senior Quantitative Analyst or Head of Quantitative Research", 
          "Advance to senior roles such as Senior Quantitative Analyst or Head of Quantitative Research, where you'll lead teams of quants, manage trading strategies, and advise on market risks."
        ),
        new StepDetails("Hedge Fund or Proprietary Trading Firm", 
          "Consider transitioning into a hedge fund or proprietary trading firm, where you'll have more autonomy in developing and executing trading strategies. Many quants find these environments offer greater earning potential and creative freedom."
        ),
        new StepDetails("Financial Consulting and Algorithm Development", 
          "Consider moving into consulting, where you’ll advise financial institutions on quantitative strategies, algorithm development, and risk management. Consulting allows you to apply your skills across multiple financial sectors."
        ),
      ]
    ),
    ["Financial Mathematics", "Programming", "Statistics"],
    ["Analyzing Markets", "Developing Algorithms", "Risk Management"],
    "Science Stream",
    "B.Sc.",
    "Mathematics"
  ),

  // 5. Cryptographer
  new CareerRoadMap(
    "Cryptographer",
    "Cryptographers design and analyze algorithms that protect information in digital communications. They work in fields like cybersecurity, government, and finance, developing encryption techniques to safeguard sensitive data and ensure secure communication.",
    new Roadmap(
      [
        // Short-Term Goals (0-2 years)
        new StepDetails("Study Cryptography and Number Theory", 
          "Focus on courses in cryptography, number theory, and algebra. These areas provide the mathematical foundation for designing secure encryption algorithms."
        ),
        new StepDetails("Learn Programming for Cryptography", 
          "Develop proficiency in programming languages like Python, C++, and Java. Cryptographers use these languages to implement encryption algorithms and analyze their security."
        ),
        new StepDetails("Internship in Cybersecurity or Cryptography Firms", 
          "Gain experience by interning at cybersecurity firms, government agencies, or companies that focus on data encryption. You'll work on designing encryption algorithms, testing security protocols, and protecting sensitive data."
        ),
      ],
      [
        // Medium-Term Goals (3-5 years)
        new StepDetails("Work as a Cryptographer", 
          "Start working as a cryptographer for government agencies, tech companies, or financial institutions. You’ll design encryption algorithms, protect sensitive data, and ensure secure communication."
        ),
        new StepDetails("Specialize in Blockchain or Quantum Cryptography", 
          "Specialize in cutting-edge areas like blockchain technology or quantum cryptography. Blockchain is crucial for cryptocurrencies, while quantum cryptography aims to protect data from quantum computer attacks."
        ),
        new StepDetails("Work on Network Security and Encryption Protocols", 
          "Develop expertise in network security and encryption protocols. Cryptographers often work with cybersecurity teams to protect sensitive information from cyberattacks and ensure data integrity."
        ),
      ],
      [
        // Long-Term Goals (5+ years)
        new StepDetails("Senior Cryptographer or Chief Information Security Officer (CISO)", 
          "Advance to senior roles like Senior Cryptographer or CISO, where you’ll oversee a company’s encryption strategies and ensure that all communication and data are protected from cyber threats."
        ),
        new StepDetails("Lead Cryptographic Research and Development", 
          "Work in research and development, creating new encryption techniques and advancing the field of cryptography. Many cryptographers contribute to academic research or develop new products for private companies."
        ),
        new StepDetails("Consulting in Data Encryption and Cybersecurity", 
          "Consider becoming a cybersecurity consultant, advising companies on how to protect their data and implement secure encryption protocols. Consulting allows you to work across industries and solve unique security challenges."
        ),
      ]
    ),
    ["Cryptography", "Number Theory", "Algebra"],
    ["Solving Puzzles", "Programming", "Cybersecurity"],
    "Science Stream",
    "B.Sc.",
    "Mathematics"
  )
];