import React, { useState, useEffect } from 'react';
import { Modal, View, Text, TextInput, Button, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';

export default function EditProfileDialog({ visible, section, data, onClose, onSave }) {
  const [inputData, setInputData] = useState(data);

  useEffect(() => {
    setInputData(data);
  }, [data]);

  const handleChange = (field, value) => {
    setInputData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleNestedChange = (field, index, subfield, value) => {
    const updatedArray = [...inputData[field]];
    updatedArray[index][subfield] = value;
    setInputData((prevData) => ({ ...prevData, [field]: updatedArray }));
  };

  const handleDelete = (field, index) => {
    const updatedArray = inputData[field].filter((_, i) => i !== index);
    setInputData((prevData) => ({ ...prevData, [field]: updatedArray }));
  };

  const handleToggleSelection = (field, value) => {
    setInputData((prevData) => {
      const currentSelection = prevData[field] || [];
      if (currentSelection.includes(value)) {
        return {
          ...prevData,
          [field]: currentSelection.filter(item => item !== value),
        };
      } else {
        return {
          ...prevData,
          [field]: [...currentSelection, value],
        };
      }
    });
  };

  const renderMultiSelectOptions = (options, field) => (
    <View style={styles.multiSelectContainer}>
      {options.map((option, index) => (
        <TouchableOpacity
          key={index}
          style={[
            styles.optionButton,
            inputData[field]?.includes(option) && styles.selectedOptionButton
          ]}
          onPress={() => handleToggleSelection(field, option)}
        >
          <Text style={styles.optionText}>{option}</Text>
        </TouchableOpacity>
      ))}
    </View>
  );

  const renderInputs = () => {
    switch (section) {
      case 'contactDetails':
        return (
          <>
            <TextInput
              style={styles.input}
              placeholder="Name"
              value={inputData.userName}
              onChangeText={(text) => handleChange('userName', text)}
            />
            <TextInput
              style={styles.input}
              placeholder="Email"
              value={inputData.userEmail}
              onChangeText={(text) => handleChange('userEmail', text)}
            />
            <TextInput
              style={styles.input}
              placeholder="LinkedIn"
              value={inputData.userLinkedInLink}
              onChangeText={(text) => handleChange('userLinkedInLink', text)}
            />
            <TextInput
              style={styles.input}
              placeholder="GitHub"
              value={inputData.userGithubLink}
              onChangeText={(text) => handleChange('userGithubLink', text)}
            />
            <TextInput
              style={styles.input}
              placeholder="Career Category"
              value={inputData.userCareerCategory}
              onChangeText={(text) => handleChange('userCareerCategory', text)}
            />
            <TextInput
              style={styles.input}
              placeholder="Career SubCategory"
              value={inputData.userCareerSubCategory}
              onChangeText={(text) => handleChange('userCareerSubCategory', text)}
            />
          </>
        );
      case 'summaryAndSkills':
        return (
          <>
            <TextInput
              style={styles.input}
              placeholder="Summary"
              multiline
              numberOfLines={4}
              value={inputData.userSummary}
              onChangeText={(text) => handleChange('userSummary', text)}
            />
            {inputData.userSkills?.map((skill, index) => (
              <View key={index} style={styles.skillContainer}>
                <TextInput
                  style={styles.input}
                  placeholder={`Skill ${index + 1}`}
                  value={skill}
                  onChangeText={(text) => {
                    const updatedSkills = [...inputData.userSkills];
                    updatedSkills[index] = text;
                    handleChange('userSkills', updatedSkills);
                  }}
                />
                <TouchableOpacity onPress={() => handleDelete('userSkills', index)}>
                  <Text style={styles.deleteText}>Delete</Text>
                </TouchableOpacity>
              </View>
            ))}
            <Button title="Add Skill" onPress={() => handleChange('userSkills', [...inputData.userSkills, ''])} />
          </>
        );
      case 'favoriteSubjectsAndActivities':
        return (
          <ScrollView>
            <Text style={styles.subheading}>Select Favorite Subjects:</Text>
            {renderMultiSelectOptions([
              'Physics', 'Chemistry', 'Biology', 'Computer Science', 'Environmental Science', 'Engineering',
              'Algebra', 'Geometry', 'Calculus', 'Statistics', 'Discrete Mathematics', 'Logic and Problem-Solving',
              'English Literature', 'World Literature', 'Creative Writing', 'Foreign Languages (e.g., Spanish, French, Mandarin)', 'Linguistics',
              'History', 'Geography', 'Political Science', 'Sociology', 'Philosophy', 'Psychology', 'Business Studies',
              'Economics', 'Entrepreneurship', 'Finance', 'Marketing', 'Management', 'Visual Arts (Drawing, Painting)',
              'Music Theory', 'Performing Arts (Theater, Dance)', 'Graphic Design', 'Fashion Design', 'Art History',
              'Human Anatomy', 'Nutrition', 'Health Sciences', 'Medicine', 'Public Health', 'Geology', 'Meteorology',
              'Oceanography', 'Ecology', 'Robotics', 'Software Engineering', 'Electrical Engineering', 'Mechanical Engineering', 'Artificial Intelligence',
              'Behavioral Science', 'Developmental Psychology', 'Clinical Psychology', 'Counseling Techniques', 'Human Development', 'Social Work'
            ], 'favoriteSubjects')}

            <Text style={styles.subheading}>Select Favorite Activities:</Text>
            {renderMultiSelectOptions([
              'Writing or Storytelling', 'Drawing or Painting', 'Playing a Musical Instrument', 'Singing or Performing Arts', 'Photography', 'Crafting or DIY Projects',
              'Playing Sports (e.g., Football, Basketball, Tennis)', 'Yoga or Pilates', 'Hiking or Running', 'Dancing', 'Martial Arts', 'Fitness Training',
              'Coding or Programming', 'Building Computers or Electronics', 'Video Gaming', 'Game Development', 'App Development', 'Robotics',
              'Solving Puzzles (e.g., Sudoku, Crosswords)', 'Playing Strategy Games (e.g., Chess)', 'Conducting Experiments', 'Research and Data Analysis', 'Mathematical Challenges', 'Financial Planning',
              'Volunteering or Community Service', 'Teaching or Tutoring', 'Mentoring or Coaching', 'Public Speaking', 'Organizing Events', 'Networking and Socializing',
              'Traveling or Exploring New Places', 'Camping or Backpacking', 'Gardening', 'Rock Climbing', 'Scuba Diving', 'Wildlife Observation',
              'Running a Small Business or Startup', 'Investing in Stocks or Real Estate', 'Marketing and Branding', 'Sales and Negotiation', 'Business Strategy Games', 'Networking with Entrepreneurs',
              'Cooking or Baking', 'Trying New Recipes', 'Food Blogging or Photography', 'Hosting Dinner Parties', 'Studying Nutrition', 'Exploring Different Cuisines',
              'Blogging or Vlogging', 'Podcasting', 'Video Editing', 'Social Media Management', 'Journalism', 'Public Relations',
              'Reading Books or Articles', 'Attending Workshops or Webinars', 'Learning New Languages', 'Mindfulness or Meditation', 'Journaling', 'Online Courses and Certifications'
            ], 'favoriteActivities')}
          </ScrollView>
        );
      // Add more cases for other sections as needed...
      default:
        return null;
    }
  };

  return (
    <Modal visible={visible} animationType="slide" onRequestClose={onClose}>
      <ScrollView contentContainerStyle={styles.dialogContainer}>
        <Text style={styles.dialogTitle}>Edit {section.replace(/([A-Z])/g, ' $1').trim()}</Text>
        {renderInputs()}
        <View style={styles.buttonContainer}>
          <Button title="Cancel" onPress={onClose} color="#007BFF" />
          <Button title="Save" onPress={() => onSave(inputData)} color="#007BFF" />
        </View>
      </ScrollView>
    </Modal>
  );
}

const styles = StyleSheet.create({
  dialogContainer: {
    padding: 20,
    backgroundColor: '#f5f5f5',
  },
  dialogTitle: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: 20,
    color: '#333',
  },
  subheading: {
    fontSize: 18,
    color: '#333',
    marginVertical: 10,
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 10,
    marginBottom: 15,
    borderRadius: 5,
    backgroundColor: '#fff',
    color: '#333',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  deleteText: {
    color: 'red',
    textAlign: 'right',
    marginBottom: 10,
  },
  itemContainer: {
    marginBottom: 20,
  },
  skillContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  optionButton: {
    backgroundColor: '#f0f0f0',
    padding: 10,
    borderRadius: 5,
    margin: 5,
  },
  selectedOptionButton: {
    backgroundColor: '#007BFF',
  },
  optionText: {
    color: '#333',
    fontSize: 16,
  },
  multiSelectContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
});
