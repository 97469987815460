import React, { useState } from 'react';
import { View, Text, Picker, TouchableOpacity, Image, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
const HubDropdown = ({ selectedHub, onHubChange, hubs }) => {
  const [showPicker, setShowPicker] = useState(false);

  return (
    <View>
      <TouchableOpacity onPress={() => setShowPicker(!showPicker)} style={styles.dropdownWrapper}>
        <View style={styles.hubInfo}>
          {selectedHub ? (
            <>
              <Image source={{ uri: selectedHub.hubImage }} style={styles.hubImage} />
              <Text style={styles.hubTitle}>{selectedHub.hubTitle}</Text>
            </>
          ) : (
            <Text style={styles.hubTitle}>Select a pod</Text>
          )}
        </View>
        <Ionicons name={showPicker ? 'chevron-up' : 'chevron-down'} size={20} color="#000" />
      </TouchableOpacity>

      {showPicker && (
        <Picker
          selectedValue={selectedHub?.hubId}
          onValueChange={(itemValue) => {
            const hub = hubs.find((h) => h.hubId === itemValue);
            onHubChange(hub);
            setShowPicker(false);
          }}
          style={styles.picker}
          itemStyle={styles.pickerItem}
        >
          {hubs.map((hub) => (
            <Picker.Item key={hub.hubId} label={hub.hubTitle} value={hub.hubId} />
          ))}
        </Picker>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  dropdownWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 15,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginBottom: 10,
  },
  hubInfo: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  hubImage: {
    width: 30,
    height: 30,
    borderRadius: 15,
    marginRight: 10,
  },
  hubTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  picker: {
    marginTop: 10,
    backgroundColor: '#fff',
  },
  pickerItem: {
    fontSize: 16,
    color: '#000',
  },
});

export default HubDropdown;
