// screens/JobDetailScreen.js
import React from 'react';
import { View, Text, StyleSheet, ScrollView, Button, Linking, Dimensions } from 'react-native';

export default function JobDetailScreen({ route }) {
  const { job } = route.params;
  const isLargeScreen = Dimensions.get('window').width >= 600;

  if (!job) {
    console.error("Job data is not available");
    return (
      <View style={styles.errorContainer}>
        <Text style={styles.errorText}>Job details not found.</Text>
      </View>
    );
  }

  return (
    <View style={styles.mainContainer}>
      <ScrollView contentContainerStyle={styles.container}>
        <Text style={styles.detailTitle}>{job.jobTitle}</Text>
        <Text style={styles.detailCompany}>{job.companyName}</Text>
        <Text style={styles.detailLocation}>{job.jobLocation}</Text>
        <Text style={styles.detailType}>{job.jobType}</Text>
        <Text style={styles.detailExperience}>{job.jobExperience}</Text>
        <Text style={styles.detailSalary}>{job.jobSalary}</Text>
        <Text style={styles.detailDescription}>{job.jobDescription}</Text>
      </ScrollView>
      <Button
        title="Apply Now"
        onPress={() => Linking.openURL(job.jobLink)}
        style={[styles.applyButton, isLargeScreen && styles.applyButton]}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    position: 'relative',
    backgroundColor: '#1e1e1e', // Dark background
  },
  container: {
    padding: 20,
    backgroundColor: '#272727', // Slightly lighter dark background
  },
  detailTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#f9d342', // Gold text color for titles
  },
  detailCompany: {
    fontSize: 18,
    color: '#bdc3c7', // Light gray for company name
    marginBottom: 5,
  },
  detailLocation: {
    fontSize: 16,
    color: '#bdc3c7',
    marginBottom: 5,
  },
  detailType: {
    fontSize: 16,
    color: '#bdc3c7',
    marginBottom: 5,
  },
  detailExperience: {
    fontSize: 16,
    color: '#bdc3c7',
    marginBottom: 5,
  },
  detailSalary: {
    fontSize: 16,
    color: '#bdc3c7',
    marginBottom: 10,
  },
  detailDescription: {
    fontSize: 16,
    color: '#bdc3c7',
    lineHeight: 24,
  },
  applyButton: {
    position: 'absolute',
    bottom: 20,
    left: 20,
    right: 20,
    backgroundColor: '#FF5722', // Bright orange button
    paddingVertical: 15,
    borderRadius: 5,
    alignItems: 'center',
  },
  applyButtonText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1e1e1e', // Dark background
  },
  errorText: {
    fontSize: 18,
    color: 'red', // Red for error messages
  },
});
