import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Dimensions, ScrollView } from 'react-native';
import Topics from '../components/KotlinTopics'; // Assuming all topics with their sections are stored here
import { useNavigation } from '@react-navigation/native';
import { MCQ_QUESTIONS } from '../components/MCQQuestions';

const { width } = Dimensions.get('window');
const isLargeScreen = width > 768;

// CodeBlock component to render code snippets
function CodeBlock({ code }) {
  return (
    <View style={styles.codeBlockContainer}>
      <Text style={styles.codeText}>{code}</Text>
    </View>
  );
}

export default function TopicDetailScreen({ route }) {
  const { selectedTopic, selectedTitle } = route.params; // Get title from route params
  const navigation = useNavigation();
  const [sectionIndex, setSectionIndex] = useState(0); // Track the section index
  const [sections, setSections] = useState([]); // State to hold fetched sections
  const [loading, setLoading] = useState(true); // Loading state

  // Fetch sections by the selected title
  useEffect(() => {
    if (selectedTopic && selectedTitle) {
      setLoading(true);
      const topic = Topics[selectedTopic]; // Retrieve topic data from the source
      const sectionData = topic.find(item => item.title === selectedTitle); // Find the section by title
      if (sectionData && sectionData.sections) {
        setSections(sectionData.sections); // Set the sections to the state
        setLoading(false);
      }
    }
  }, [selectedTopic, selectedTitle]);

  const currentSection = sections[sectionIndex] || {}; // Get the current section

  const nextSection = () => {
    if (sectionIndex < sections.length - 1) {
      setSectionIndex(sectionIndex + 1); // Move to the next section
    }
  };

  const prevSection = () => {
    if (sectionIndex > 0) {
      setSectionIndex(sectionIndex - 1); // Move to the previous section
    }
  };

  const isFirstSection = sectionIndex === 0;
  const isLastSection = sectionIndex === sections.length - 1;

  // Prepare quiz questions based on the topic
  const filteredQuestions = MCQ_QUESTIONS.filter(question => question.topic === selectedTitle);
  const timeLimit = filteredQuestions.length * 60; // 1 minute per question

  const handleStartQuiz = () => {
    navigation.navigate('MCQScreen', {
      subject: selectedTitle,
      questions: filteredQuestions,
      timeLimit,
    });
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  if (loading) {
    return (
      <View style={styles.loaderContainer}>
        <Text style={styles.loaderText}>Loading content...</Text>
      </View>
    );
  }

  if (sections.length === 0) {
    return (
      <View style={styles.container}>
        <Text style={styles.errorMessage}>No content available for this topic</Text>
        <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
          <Text style={styles.backButtonText}>Go Back</Text>
        </TouchableOpacity>
      </View>
    );
  }

  return (
    <View style={styles.fullContainer}>
      {/* Sticky Header with Back Button */}
      <View style={styles.header}>
        <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
          <Text style={styles.backButtonText}>Back</Text>
        </TouchableOpacity>
        <Text style={styles.headerTitle}>
          {truncateText(selectedTitle, isLargeScreen ? 50 : 20)} {/* Truncate based on screen size */}
        </Text>
      </View>

      <ScrollView contentContainerStyle={styles.scrollContent}>
        {/* Progress Loader */}
        <View style={styles.progressContainer}>
          <Text style={styles.progressText}>
            {sectionIndex + 1}/{sections.length} Sections Completed
          </Text>
          <View style={styles.progressBar}>
            <View
              style={[
                styles.progressIndicator,
                { width: `${((sectionIndex + 1) / sections.length) * 100}%` }
              ]}
            />
          </View>
        </View>

        {/* Display the current section inside a card */}
        <View style={[styles.card, { maxWidth: isLargeScreen ? 800 : '90%' }]}>
          <Text style={styles.sectionHeading}>{currentSection.heading}</Text>
          <Text style={styles.sectionContent}>{currentSection.content}</Text>

          {/* Render code snippet if it exists */}
          {currentSection.codeSnippet && <CodeBlock code={currentSection.codeSnippet.code} />}
        </View>

        {/* Navigation buttons */}
        <View style={styles.buttonContainer}>
          {!isFirstSection && (
            <TouchableOpacity onPress={prevSection} style={[styles.navButton, styles.centeredButton]}>
              <Text style={styles.navButtonText}>Prev</Text>
            </TouchableOpacity>
          )}
          {!isLastSection && (
            <TouchableOpacity onPress={nextSection} style={[styles.navButton, styles.centeredButton]}>
              <Text style={styles.navButtonText}>Next</Text>
            </TouchableOpacity>
          )}

          {isLastSection && (
            <View style={styles.finalButtonContainer}>
              <TouchableOpacity onPress={handleStartQuiz} style={styles.quizButton}>
                <Text style={styles.quizButtonText}>Start Quiz</Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  fullContainer: {
    flex: 1,
  },
  scrollContent: {
    alignItems: 'center',
    paddingBottom: 20,
    paddingHorizontal: 20,
  },
  container: {
    flexGrow: 1,
    backgroundColor: '#F1EEE6',
  },
  header: {
    width: '100%',
    paddingVertical: 15, // Vertical padding for spacing
    paddingHorizontal: 20, // Horizontal padding for space between back button and title
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  },
  backButton: {
    position: 'absolute',
    left: 10,  // Set the left to 0 so it takes full horizontal space
    paddingHorizontal: 20, // Increase padding to make button fully clickable
    paddingVertical: 10,
    backgroundColor: '#007BFF', // Blue background for back button
    borderRadius: 5,
    zIndex: 10,  // Ensure the back button is always on top
  },
  backButtonText: {
    color: '#FFFFFF', // White text for the back button
    fontSize: 16,
    fontWeight: 'bold',
  },
  headerTitle: {
    fontSize: isLargeScreen ? 24 : 20,
    fontWeight: 'bold',
    color: '#333',
    maxWidth: '80%',  // Ensure the title doesn't take too much space and interfere with the button
    textAlign: 'center',
  },
  progressContainer: {
    width: '100%',
    paddingHorizontal: 20,
    marginTop: 20,
    marginBottom: 10,
  },
  progressText: {
    fontSize: 16,
    color: '#007BFF',
    marginBottom: 5,
  },
  progressBar: {
    width: '100%',
    height: 10,
    backgroundColor: '#ccc',
    borderRadius: 5,
    overflow: 'hidden',
  },
  progressIndicator: {
    height: '100%',
    backgroundColor: '#007BFF',
    borderRadius: 5,
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 20,
    borderWidth: 2,
    borderColor: '#000',
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 5,
    elevation: 3,
    marginBottom: 20,
    alignSelf: 'center', // Center the card horizontally
    marginTop: 20, // Adjust the margin to start near the top
  },
  sectionHeading: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#007BFF',
    marginBottom: 10,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  sectionContent: {
    fontSize: 18,
    color: '#333',
    textAlign: 'left',
    lineHeight: 24,
    marginBottom:10
  },
  codeBlockContainer: {
    backgroundColor: '#272727',
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
  },
  codeText: {
    color: '#f8f8f2',
    fontFamily: 'Courier New',
    fontSize: 14,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    marginTop: 20,
  },
  centeredButton: {
    margin: 15,
  },
  navButton: {
    backgroundColor: '#007BFF',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    alignItems: 'center',
  },
  quizButton: {
    backgroundColor: '#FF6F61',
    paddingVertical: 12,
    paddingHorizontal: 25,
    borderRadius: 5,
    alignItems: 'center',
    marginTop: 15,
  },
  quizButtonText: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
  },
  navButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
  },
  finalButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 30,
  },
  loaderContainer: {
    flex: 1,
    justifyContent: 'flex-start', // Align the loader to the top
    alignItems: 'center',
  },
  loaderText: {
    fontSize: 18,
    color: '#007BFF',
  },
  errorMessage: {
    fontSize: 18,
    color: '#FF0000',
    textAlign: 'center',
  },
});
