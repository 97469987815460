import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, ScrollView, ActivityIndicator, Modal, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage'; // Import for mobile
import { db, auth } from '../firebaseConfig';
import { setDoc, doc } from 'firebase/firestore';

const sections = [
    {
        title: '1. Personality Assessment',
        description: 'Understand your personality type based on the MBTI framework.',
        subsections: [
            {
                subtitle: 'A. MBTI-Inspired Assessment',
                description: 'Identify your personality type based on the four dichotomies in the MBTI framework.',
                questions: [
                    { id: 1, question: 'When you have free time, do you prefer spending it with others (E) or by yourself (I)?' },
                    { id: 2, question: 'Do you feel energized after a social event (E) or do you need alone time to recharge (I)?' },
                    { id: 3, question: 'Do you prefer focusing on the details and facts (S) or looking at the big picture and possibilities (N)?' },
                    { id: 4, question: 'When making decisions, do you rely more on your experiences (S) or your imagination and gut feelings (N)?' },
                    { id: 5, question: 'Do you usually make decisions based on logic and objectivity (T) or personal values and how others might feel (F)?' },
                    { id: 6, question: 'In a conflict, do you tend to prioritize fairness and justice (T) or harmony and compassion (F)?' },
                    { id: 7, question: 'Do you prefer having a structured plan (J) or do you like to keep things flexible and open (P)?' },
                    { id: 8, question: 'When working on a project, do you feel more comfortable finishing it before the deadline (J) or working closer to the deadline with some spontaneity (P)?' },
                ],
            },
        ],
    },
    {
        title: '2. Holland Code (RIASEC) Assessment',
        description: 'Determine your interest profile across six personality types.',
        subsections: [
            {
                subtitle: 'A. Realistic (R)',
                description: 'Evaluate your preference for practical, hands-on activities and physical tasks.',
                questions: [
                    { id: 9, question: 'Do you enjoy working with your hands, using tools, or being outdoors?' },
                    { id: 10, question: 'Are you interested in mechanical, technical, or athletic tasks?' },
                ],
            },
            {
                subtitle: 'B. Investigative (I)',
                description: 'Evaluate your inclination towards analytical thinking, research, and problem-solving.',
                questions: [
                    { id: 11, question: 'Do you enjoy solving puzzles, conducting experiments, or exploring new ideas?' },
                    { id: 12, question: 'Are you drawn to research, analysis, and understanding complex issues?' },
                ],
            },
            {
                subtitle: 'C. Artistic (A)',
                description: 'Determine your interest in creative and artistic pursuits.',
                questions: [
                    { id: 13, question: 'Do you enjoy creative activities like writing, drawing, or performing?' },
                    { id: 14, question: 'Are you inclined towards self-expression and originality in your work?' },
                ],
            },
            {
                subtitle: 'D. Social (S)',
                description: 'Evaluate your inclination toward social interaction, community service, and helping professions.',
                questions: [
                    { id: 15, question: 'Do you enjoy helping others, teaching, or being involved in community work?' },
                    { id: 16, question: 'Are you motivated by making a positive impact on others’ lives?' },
                ],
            },
            {
                subtitle: 'E. Enterprising (E)',
                description: 'Identify your preference for leadership, entrepreneurship, and influencing others.',
                questions: [
                    { id: 17, question: 'Do you enjoy leading, managing, or persuading others to achieve goals?' },
                    { id: 18, question: 'Are you interested in business, politics, or starting your own venture?' },
                ],
            },
            {
                subtitle: 'F. Conventional (C)',
                description: 'Determine your preference for structured tasks, organization, and working with data.',
                questions: [
                    { id: 19, question: 'Do you enjoy organizing, planning, or working with data and details?' },
                    { id: 20, question: 'Are you comfortable following set procedures and working within a structured environment?' },
                ],
            },
        ],
    },
    {
        title: '3. Strengths and Skills Assessment',
        description: 'Identify your core strengths and skills to match you with suitable careers.',
        subsections: [
            {
                subtitle: 'A. Analytical Skills',
                questions: [
                    { id: 21, question: 'How comfortable are you with analyzing data or information to solve problems?' },
                    { id: 22, question: 'Can you easily identify patterns, trends, and anomalies in complex information?' },
                ],
            },
            {
                subtitle: 'B. Creative Thinking',
                questions: [
                    { id: 23, question: 'How often do you come up with original ideas or solutions to problems?' },
                    { id: 24, question: 'Do you enjoy activities that require imagination and innovation?' },
                ],
            },
            {
                subtitle: 'C. Communication Skills',
                questions: [
                    { id: 25, question: 'How effective are you at expressing your ideas verbally or in writing?' },
                    { id: 26, question: 'Do you enjoy public speaking, writing, or engaging in discussions?' },
                ],
            },
            {
                subtitle: 'D. Leadership and Management',
                questions: [
                    { id: 27, question: 'Have you taken on leadership roles in group settings or projects?' },
                    { id: 28, question: 'Are you comfortable making decisions and guiding others?' },
                ],
            },
            {
                subtitle: 'E. Technical Skills',
                questions: [
                    { id: 29, question: 'How proficient are you with technology, tools, or machinery relevant to your field of interest?' },
                    { id: 30, question: 'Do you enjoy learning new technical skills or working with software/hardware?' },
                ],
            },
            {
                subtitle: 'F. Interpersonal Skills',
                questions: [
                    { id: 31, question: 'How well do you work in a team, and how do you handle conflicts?' },
                    { id: 32, question: 'Do you enjoy collaborating with others or helping people with their problems?' },
                ],
            },
        ],
    },
    {
        title: '4. Interests and Career Aspirations',
        description: 'Understand your long-term career interests, goals, and aspirations.',
        subsections: [
            {
                subtitle: 'A. Career Interests',
                questions: [
                    { id: 33, question: 'What kind of tasks do you find most fulfilling (e.g., solving problems, helping others, creating something new)?' },
                    { id: 34, question: 'Are there any specific industries or fields that fascinate you? Why?' },
                ],
            },
            {
                subtitle: 'B. Long-Term Goals',
                questions: [
                    { id: 35, question: 'Where do you see yourself in 10 years? What kind of work environment would you like?' },
                    { id: 36, question: 'What are your priorities in a career (e.g., job security, salary, work-life balance, impact on society)?' },
                ],
            },
            {
                subtitle: 'C. Immediate Plans',
                questions: [
                    { id: 37, question: 'What are your next steps after completing your 12th grade (e.g., higher education, starting a business, gaining work experience)?' },
                    { id: 38, question: 'Are there any specific courses, degrees, or certifications you’re considering? What attracts you to them?' },
                ],
            },
        ],
    },
    {
        title: '5. Personal and Lifestyle Considerations',
        description: 'Assess how your personal and lifestyle preferences impact your career choices.',
        subsections: [
            {
                subtitle: 'A. Work-Life Balance',
                questions: [
                    { id: 39, question: 'How important is work-life balance to you in your career?' },
                    { id: 40, question: 'Would you prefer a job with regular hours or one that requires flexibility and possibly longer hours?' },
                ],
            },
            {
                subtitle: 'B. Location Preferences',
                questions: [
                    { id: 41, question: 'Do you have a preference for working in a particular city, state, or country? Why?' },
                    { id: 42, question: 'Are you open to relocating for the right career opportunity?' },
                ],
            },
            {
                subtitle: 'C. Financial Goals',
                questions: [
                    { id: 43, question: 'How important is financial stability or high earning potential in your career choice?' },
                    { id: 44, question: 'Are you considering the cost of education and the potential return on investment in your decision-making process?' },
                ],
            },
        ],
    },
    {
        title: '6. Psychological and Emotional Factors',
        description: 'Understand your psychological and emotional responses to stress, motivation, and decision-making.',
        subsections: [
            {
                subtitle: 'A. Stress Management',
                questions: [
                    { id: 45, question: 'How do you typically handle stress, especially in academic or work-related situations?' },
                    { id: 46, question: 'What strategies do you use to stay calm and focused under pressure?' },
                ],
            },
            {
                subtitle: 'B. Motivation',
                questions: [
                    { id: 47, question: 'What drives you to achieve your goals (e.g., passion, recognition, financial reward)?' },
                    { id: 48, question: 'How do you stay motivated when facing challenges or setbacks?' },
                ],
            },
            {
                subtitle: 'C. Confidence and Decision-Making',
                questions: [
                    { id: 49, question: 'How confident do you feel about making decisions regarding your future career?' },
                    { id: 50, question: 'Do you often seek advice from others when making important decisions, or do you prefer to rely on your own judgment?' },
                ],
            },
        ],
    },
    {
        title: '7. Backup Plans and Flexibility',
        description: 'Evaluate your adaptability and readiness to consider alternative career paths if your first choice doesn’t work out.',
        subsections: [
            {
                subtitle: 'A. Plan B',
                questions: [
                    { id: 51, question: 'Do you have a backup plan if your first career choice doesn’t work out?' },
                    { id: 52, question: 'How open are you to exploring alternative career paths or fields if new opportunities arise?' },
                ],
            },
            {
                subtitle: 'B. Adaptability',
                questions: [
                    { id: 53, question: 'How do you handle change or unexpected challenges in your academic or personal life?' },
                    { id: 54, question: 'Are you willing to adapt your plans if a better opportunity presents itself?' },
                ],
            },
        ],
    },
];


export default function CareerAssessment({ navigation }) {
    const [answers, setAnswers] = useState({});
    const [savedAnswers, setSavedAnswers] = useState({});
    const [loading, setLoading] = useState(false);
    const [isSubmitVisible, setIsSubmitVisible] = useState(false);
    const [feedbackVisible, setFeedbackVisible] = useState(false);
    const [feedback, setFeedback] = useState('');

    useEffect(() => {
        const loadAnswersFromStorage = async () => {
            try {
                let storedAnswers;
                if (Platform.OS === 'web') {
                    storedAnswers = localStorage.getItem('careerAssessmentAnswers'); // Use localStorage for web
                } else {
                    storedAnswers = await AsyncStorage.getItem('careerAssessmentAnswers'); // Use AsyncStorage for mobile
                }
                if (storedAnswers) {
                    const parsedAnswers = JSON.parse(storedAnswers);
                    setAnswers(parsedAnswers);
                    setSavedAnswers(parsedAnswers);
                    checkAllQuestionsSaved(parsedAnswers);
                }
            } catch (error) {
                console.error('Error loading saved answers:', error);
            }
        };

        loadAnswersFromStorage();
    }, []);

    const handleSaveLocally = async (questionId) => {
        const updatedAnswers = { ...answers, [questionId]: answers[questionId] || '' };
        try {
            if (Platform.OS === 'web') {
                localStorage.setItem('careerAssessmentAnswers', JSON.stringify(updatedAnswers)); // Use localStorage for web
            } else {
                await AsyncStorage.setItem('careerAssessmentAnswers', JSON.stringify(updatedAnswers)); // Use AsyncStorage for mobile
            }
            setSavedAnswers(updatedAnswers); // Update savedAnswers state after saving locally
            checkAllQuestionsSaved(updatedAnswers);
        } catch (error) {
            console.error('Error saving assessment locally:', error);
        }
    };

    const handleAnswerChange = (questionId, text) => {
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionId]: text,
        }));
    };

    const checkAllQuestionsSaved = (savedAnswersMap) => {
        const totalQuestions = sections.flatMap(section =>
            section.subsections.flatMap(subsection => subsection.questions)
        ).length;
        if (Object.keys(savedAnswersMap).length === totalQuestions) {
            setIsSubmitVisible(true);
        }
    };

    const handleSubmitAssessment = async () => {
        setLoading(true);
        try {
            const userId = auth.currentUser.uid;
            const assessmentData = {
                userId,
                answers: savedAnswers,
            };
            await setDoc(doc(db, 'assessments', userId), assessmentData);

            if (Platform.OS === 'web') {
                localStorage.removeItem('careerAssessmentAnswers'); // Clear localStorage after submission on web
            } else {
                await AsyncStorage.removeItem('careerAssessmentAnswers'); // Clear AsyncStorage after submission on mobile
            }

            navigation.navigate('ProfileScreen'); // Navigate to Profile Screen after submission
        } catch (error) {
            console.error('Error submitting assessment:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitFeedback = () => {
        console.log('User feedback:', feedback);
        setFeedbackVisible(false);
        navigation.navigate('ProfileScreen'); // Navigate to Profile Screen after feedback submission
    };

    return (
        <ScrollView style={styles.container}>
            <View style={styles.header}>
                <Text style={styles.headerTitle}>Career Assessment</Text>
                <Text style={styles.headerSubtitle}>Answer the questions below to get career guidance.</Text>
            </View>

            {sections.map((section, index) => (
                <View key={index} style={styles.section}>
                    <Text style={styles.sectionTitle}>{section.title}</Text>
                    <Text style={styles.sectionDescription}>{section.description}</Text>

                    {section.subsections.map((subsection, subIndex) => (
                        <View key={subIndex} style={styles.subsection}>
                            <Text style={styles.subsectionTitle}>{subsection.subtitle}</Text>
                            <Text style={styles.subsectionDescription}>{subsection.description}</Text>

                            {subsection.questions.map((question) => (
                                <View
                                    key={question.id}
                                    style={[
                                        styles.questionCard,
                                        savedAnswers[question.id] && styles.savedQuestionCard
                                    ]}
                                >
                                    <Text style={styles.questionText}>{question.question}</Text>
                                    <TextInput
                                        style={[
                                            styles.input,
                                            savedAnswers[question.id] && styles.savedInput
                                        ]}
                                        multiline
                                        value={answers[question.id] || ''}
                                        onChangeText={(text) => handleAnswerChange(question.id, text)}
                                    />
                                    <TouchableOpacity
                                        style={[
                                            styles.saveButton,
                                            savedAnswers[question.id] && styles.savedButton
                                        ]}
                                        onPress={() => handleSaveLocally(question.id)}
                                    >
                                        <Text style={styles.saveButtonText}>
                                            {savedAnswers[question.id] ? 'Save Again' : 'Save'}
                                        </Text>
                                    </TouchableOpacity>
                                    {savedAnswers[question.id] && (
                                        <Text style={styles.savedText}>Answer saved!</Text>
                                    )}
                                </View>
                            ))}
                        </View>
                    ))}
                </View>
            ))}

            {isSubmitVisible && (
                <TouchableOpacity style={styles.submitButton} onPress={handleSubmitAssessment}>
                    {loading ? (
                        <ActivityIndicator color="#fff" />
                    ) : (
                        <Text style={styles.submitButtonText}>Submit Assessment</Text>
                    )}
                </TouchableOpacity>
            )}

            <Modal visible={feedbackVisible} transparent={true} animationType="slide">
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Text style={styles.modalTitle}>Assessment Feedback</Text>
                        <TextInput
                            style={styles.feedbackInput}
                            multiline
                            placeholder="Please share your experience and suggestions for improvement..."
                            placeholderTextColor="#888"
                            value={feedback}
                            onChangeText={setFeedback}
                        />
                        <TouchableOpacity style={styles.submitButton} onPress={handleSubmitFeedback}>
                            <Text style={styles.submitButtonText}>Submit Feedback</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#1e1e1e',
        padding: 20,
    },
    header: {
        marginBottom: 20,
        padding: 10,
        backgroundColor: '#272727',
        borderRadius: 10,
    },
    headerTitle: {
        color: '#f9d342',
        fontSize: 28,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    headerSubtitle: {
        color: '#bdc3c7',
        fontSize: 18,
        textAlign: 'center',
    },
    section: {
        marginBottom: 30,
    },
    sectionTitle: {
        color: '#f9d342',
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    sectionDescription: {
        color: '#bdc3c7',
        fontSize: 16,
        marginBottom: 20,
    },
    subsection: {
        marginBottom: 20,
    },
    subsectionTitle: {
        color: '#FF5722',
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    subsectionDescription: {
        color: '#bdc3c7',
        fontSize: 16,
        marginBottom: 15,
    },
    questionCard: {
        backgroundColor: '#272727',
        padding: 20,
        marginBottom: 20,
        borderRadius: 10,
    },
    savedQuestionCard: {
        borderColor: '#4CAF50',
        borderWidth: 2,
    },
    questionText: {
        color: '#f9d342',
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    input: {
        backgroundColor: '#333333',
        color: '#ffffff',
        padding: 15,
        borderRadius: 5,
        borderColor: '#f9d342',
        borderWidth: 1,
        height: 150,
        marginBottom: 10,
    },
    savedInput: {
        borderColor: '#4CAF50',
    },
    saveButton: {
        backgroundColor: '#FF5722',
        padding: 12,
        borderRadius: 5,
        alignItems: 'center',
    },
    savedButton: {
        backgroundColor: '#4CAF50',
    },
    saveButtonText: {
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: 16,
    },
    savedText: {
        color: '#4CAF50',
        fontSize: 14,
        marginTop: 5,
        textAlign: 'center',
    },
    submitButton: {
        backgroundColor: '#FF5722',
        padding: 15,
        borderRadius: 10,
        alignItems: 'center',
        marginVertical: 20,
    },
    submitButtonText: {
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: 18,
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        width: '80%',
        backgroundColor: '#333333',
        padding: 20,
        borderRadius: 10,
    },
    modalTitle: {
        color: '#f9d342',
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 15,
        textAlign: 'center',
    },
    feedbackInput: {
        backgroundColor: '#272727',
        color: '#ffffff',
        padding: 15,
        borderRadius: 5,
        borderColor: '#f9d342',
        borderWidth: 1,
        height: 100,
        marginBottom: 20,
    },
});