export const MobileAppDevelopment = {
    title: "Mobile App Development",
    description: "Create mobile applications for iOS and Android platforms.",
    image: require('../assets/mobile-app-development.jpg'),
    subPaths: {
        "Native Development": {
            title: "Native Development",
            description: "Develop mobile apps using native languages like Kotlin, Swift, and Java.",
            image: require('../assets/native-development.jpg'),
            topics: {
                "Kotlin": {
                    title: "Kotlin",
                    description: "Learn Kotlin, the modern language for Android app development.",
                    image: require('../assets/kotlin.jpg'),
                },
                // "Java": {
                //   title: "Java",
                //   description: "Build Android apps with Java, a powerful and popular language.",
                //   image: require('../assets/java.jpg'),
                // },
                // "iOS": {
                //   title: "iOS",
                //   description: "Create iOS apps using Swift and Objective-C.",
                //   image: require('../assets/ios.jpg'),
                // },
                // "Swift": {
                //   title: "Swift",
                //   description: "Master Swift, Apple's programming language for iOS apps.",
                //   image: require('../assets/swift.jpg'),
                // },
                "Android": {
                    title: "Android",
                    description: "Develop apps specifically for Android devices.",
                    image: require('../assets/android.jpg'),
                },
            },
        },
        // "Hybrid Development": {
        //   title: "Hybrid Development",
        //   description: "Build mobile apps using cross-platform frameworks like React Native and Flutter.",
        //   image: require('../assets/hybrid-development.jpg'),
        //   topics: {
        //     "React Native": {
        //       title: "React Native",
        //       description: "Develop cross-platform mobile apps using React Native.",
        //       image: require('../assets/react-native.jpg'),
        //     },
        //     "JavaScript": {
        //       title: "JavaScript",
        //       description: "Use JavaScript for mobile app development with frameworks like React Native.",
        //       image: require('../assets/javascript.jpg'),
        //     },
        //     "Flutter": {
        //       title: "Flutter",
        //       description: "Create natively compiled apps for mobile using Google’s Flutter.",
        //       image: require('../assets/flutter.jpg'),
        //     },
        //     "Dart": {
        //       title: "Dart",
        //       description: "Learn Dart, the language behind Flutter.",
        //       image: require('../assets/dart.jpg'),
        //     },
        //     "Kotlin Multiplatform": {
        //       title: "Kotlin Multiplatform",
        //       description: "Share code across platforms using Kotlin Multiplatform.",
        //       image: require('../assets/kotlin-multiplatform.jpg'),
        //     },
        //   },
        // },
    },
}
