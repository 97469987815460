import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  Image,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Linking,
  Dimensions,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { auth, db } from '../firebaseConfig';
import { GoogleAuthProvider, signOut, onAuthStateChanged, signInWithPopup } from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import EditProfileDialog from '../components/EditProfileDialog';
import { reportGardening } from '../components/reportJson';


const { width } = Dimensions.get('window');  // Get screen width to adjust layout

const isLargeScreen = width > 768; // Use large screen layout if width > 768

export default function ProfileScreen({ navigation }) {
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState(null);
  const [editSection, setEditSection] = useState(null);
  const [editData, setEditData] = useState({});
  const [showReportMessage, setShowReportMessage] = useState(false);
  const [showReport, setShowReport] = useState(false); // Control report visibility


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        loadUserProfile(user);
        fetchUserReport(user.uid);
      } else {
        setLoading(false);
        setUserProfile(null);
      }
    });
    return unsubscribe;
  }, []);

  const loadUserProfile = async (user) => {
    const { uid, displayName, email, photoURL } = user;
    try {
      const userDoc = await getDoc(doc(db, 'users', uid));
      if (userDoc.exists()) {
        setUserProfile(userDoc.data());
      } else {
        const newUserProfile = {
          userId: uid,
          userName: displayName,
          userEmail: email,
          userProfilePicture: photoURL,
          userSummary: '',
          userSkills: [],
          userLinkedInLink: '',
          userGithubLink: '',
          userRole: '',
          userExperiences: [],
          userEducation: [],
          userProjects: [],
          userPosts: [],
          userCareerCategory: '',
          userCareerSubCategory: '',
          favoriteSubjects: [],
          favoriteActivities: [],
        };
        await setDoc(doc(db, 'users', uid), newUserProfile);
        setUserProfile(newUserProfile);
      }
    } catch (error) {
      console.error('Error loading user profile:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserReport = async (userId) => {
    try {
      const reportDoc = await getDoc(doc(db, 'reports', userId));
      if (reportDoc.exists()) {
        if (typeof reportDoc.data().report === 'string') {
          setReport(JSON.parse(reportDoc.data().report));
        } else {
          setReport(reportDoc.data().report);
        }
      } else {
        console.error('No report found for this user');
      }
    } catch (error) {
      console.error('Error fetching report:', error);
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      loadUserProfile(result.user);
      fetchUserReport(result.user.uid);
    } catch (error) {
      console.error('Error during Google login:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleEdit = (section, data) => {
    setEditSection(section);
    setEditData(data);
  };

  const handleSave = async (updatedData) => {
    if (!auth.currentUser) return;
    const userId = auth.currentUser.uid;
    try {
      if (updatedData.favoriteSubjects || updatedData.favoriteActivities) {
        const favoritesData = {
          userId,
          favoriteSubjects: updatedData.favoriteSubjects || [],
          favoriteActivities: updatedData.favoriteActivities || [],
        };
        await setDoc(doc(db, 'favourites', userId), favoritesData);
        setShowReportMessage(true);
      }

      await updateDoc(doc(db, 'users', userId), updatedData);
      setUserProfile((prevProfile) => ({
        ...prevProfile,
        ...updatedData,
      }));
    } catch (error) {
      console.error('Error updating profile:', error);
    } finally {
      setEditSection(null);
    }
  };
  const toggleReportVisibility = () => {
    setShowReport(!showReport);
  };

  if (loading) {
    return (
      <View style={styles.centered}>
        <ActivityIndicator size="large" color="#007bff" />
      </View>
    );
  }

  if (!userProfile) {
    return (
      <ScrollView style={styles.container}>
        <View style={styles.header}>
          <Image source={require('../assets/hamtiv-logo.png')} style={styles.logoHeader} />
          <Text style={styles.headerTitle}>Profile</Text>
          {/* <Text style={styles.headerSubtitle}>Profile</Text> */}
        </View>
        <View style={styles.centered}>
          <TouchableOpacity
            style={styles.exploreButton}
            onPress={() => navigation.navigate('ExploreCareers', { isLoggedIn: false })}
          >
            <Text style={styles.exploreButtonText}>Explore Career Paths</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => handleGoogleLogin()}>
            <Image style={styles.google_login} source={require('../assets/google-sign-in.png')} />
          </TouchableOpacity>
          <Text style={styles.loginActionText}>
            Login And Create Profile To Generate Report for your Career Recommendation and Roadmaps Like this.
          </Text>

          {/* View/Hide Report Button */}
      <TouchableOpacity style={styles.toggleButton} onPress={toggleReportVisibility}>
        <Text style={styles.toggleButtonText}>
          {showReport ? 'Hide Report' : 'View Report'}
        </Text>
      </TouchableOpacity>

          {/* Report Section in a Big Card */}
          {showReport && (<View style={styles.reportContainer}>
            {/* Report Title and Date */}
            <Text style={styles.reportTitle}>
              {reportGardening.name ? `${reportGardening.name}'s Personalized Career Assessment Report` : 'Personalized Career Assessment Report'}
            </Text>
            <Text style={styles.reportDate}>Date: {reportGardening.date || 'N/A'}</Text>

            {/* Iterate through sections */}
            {reportGardening.sections?.map((section, index) => (
              <View key={index} style={styles.sectionCard}>
                {/* Section Headline */}
                <Text style={styles.sectionTitle}>{section.headline}</Text>

                {/* Section Content */}
                {section.content ? (
                  <Text style={styles.sectionContent}>{section.content}</Text>
                ) : null}

                {/* Subsections */}
                {section.subsections?.map((sub, subIndex) => (
                  <View key={subIndex} style={styles.subsection}>
                    <Text style={styles.subsectionTitle}>{sub.subheadline}</Text>
                    {sub.points?.map((point, pointIndex) => (
                      <Text key={pointIndex} style={styles.point}>{point}</Text>
                    ))}
                  </View>
                ))}
              </View>
            ))}
          </View>)}
        </View>
      </ScrollView>
    );
  }

  const {
    userName,
    userProfilePicture,
    userEmail,
    userSummary,
    userSkills,
    userLinkedInLink,
    userGithubLink,
    userExperiences = [],
    userEducation = [],
    userProjects = [],
    favoriteSubjects = [],
    favoriteActivities = [],
  } = userProfile;

  return (
    <ScrollView style={styles.container}>
      <View style={styles.header}>
        <Image source={require('../assets/hamtiv-logo.png')} style={styles.logoHeader} />
        <Text style={styles.headerTitle}>Profile</Text>
        {/* <Text style={styles.headerSubtitle}>Profile</Text> */}
      </View>

      {showReportMessage && (
        <Text style={styles.reportMessage}>You will be able to see the report within the next 2 hours. Check back later.</Text>
      )}

      {/* Buttons Layout */}
      <View style={isLargeScreen ? styles.buttonRowLarge : styles.buttonRowMobile}>
        <TouchableOpacity style={styles.logoutButton} onPress={handleSignOut}>
          <Text style={styles.logoutButtonText}>Logout</Text>
        </TouchableOpacity>

        <TouchableOpacity style={styles.exploreButton} onPress={() => navigation.navigate('ExploreCareers')}>
          <Text style={styles.exploreButtonText}>Explore Career Paths</Text>
        </TouchableOpacity>

        <TouchableOpacity style={styles.assessmentButton} onPress={() => navigation.navigate('CareerAssessment')}>
          <Text style={styles.assessmentButtonText}>Career Assessment</Text>
        </TouchableOpacity>
      </View>

      {/* Profile Cards */}
      <View style={isLargeScreen ? styles.gridContainer : styles.listContainer}>
        {/* Contact Details Card */}
        <View style={styles.card}>
          <TouchableOpacity onPress={() => handleEdit('contactDetails', { userName, userEmail, userLinkedInLink, userGithubLink })} style={styles.editIcon}>
            <Ionicons name="pencil" size={24} color="#007BFF" />
          </TouchableOpacity>
          <Text style={styles.cardTitle}>Contact Details</Text>
          <Text style={styles.description}>{userName}</Text>
          <Text style={styles.description}>{userEmail}</Text>
          {userLinkedInLink && (
            <TouchableOpacity onPress={() => Linking.openURL(userLinkedInLink)}>
              <Text style={styles.linkText}>LinkedIn</Text>
            </TouchableOpacity>
          )}
          {userGithubLink && (
            <TouchableOpacity onPress={() => Linking.openURL(userGithubLink)}>
              <Text style={styles.linkText}>GitHub</Text>
            </TouchableOpacity>
          )}
        </View>

        {/* Favorite Subjects & Activities */}
        <View style={styles.card}>
          <Text style={styles.cardTitle}>Favorite Subjects & Activities</Text>
          <View style={styles.skillsContainer}>
            {favoriteSubjects.map((subject, index) => (
              <Text key={index} style={styles.skillChip}>
                {subject}
              </Text>
            ))}
            {favoriteActivities.map((activity, index) => (
              <Text key={index} style={styles.skillChip}>
                {activity}
              </Text>
            ))}
          </View>
          <TouchableOpacity style={styles.editIcon} onPress={() => handleEdit('favoriteSubjectsAndActivities', { favoriteSubjects, favoriteActivities })}>
            <Ionicons name="pencil" size={24} color="#007BFF" />
          </TouchableOpacity>
        </View>

        {/* Summary */}
        <View style={styles.card}>
          <Text style={styles.cardTitle}>Summary</Text>
          <Text style={styles.description}>{userSummary}</Text>
          <TouchableOpacity style={styles.editIcon} onPress={() => handleEdit('summaryAndSkills', { userSummary, userSkills })}>
            <Ionicons name="pencil" size={24} color="#007BFF" />
          </TouchableOpacity>
        </View>

        {/* Skills */}
        <View style={styles.card}>
          <Text style={styles.cardTitle}>Skills</Text>
          <View style={styles.skillsContainer}>
            {userSkills.map((skill, index) => (
              <Text key={index} style={styles.skillChip}>
                {skill}
              </Text>
            ))}
          </View>
          <TouchableOpacity style={styles.editIcon} onPress={() => handleEdit('summaryAndSkills', { userSummary, userSkills })}>
            <Ionicons name="pencil" size={24} color="#007BFF" />
          </TouchableOpacity>
        </View>

        {/* Experience */}
        <View style={styles.card}>
          <Text style={styles.cardTitle}>Experience</Text>
          {userExperiences.map((exp, index) => (
            <View key={index} style={styles.experienceItem}>
              <Text style={styles.experienceTitle}>{exp.title}</Text>
              <Text style={styles.description}>{exp.companyName}</Text>
              <Text style={styles.description}>
                {exp.startDate} - {exp.endDate}
              </Text>
              <Text style={styles.description}>{exp.location}</Text>
              <Text style={styles.description}>{exp.description}</Text>
            </View>
          ))}
          <TouchableOpacity style={styles.editIcon} onPress={() => handleEdit('userExperiences', { userExperiences })}>
            <Ionicons name="pencil" size={24} color="#007BFF" />
          </TouchableOpacity>
        </View>

        {/* Education */}
        <View style={styles.card}>
          <Text style={styles.cardTitle}>Education</Text>
          {userEducation.map((edu, index) => (
            <View key={index} style={styles.experienceItem}>
              <Text style={styles.experienceTitle}>{edu.degree}</Text>
              <Text style={styles.description}>{edu.institution}</Text>
              <Text style={styles.description}>{edu.startDate} - {edu.endDate}</Text>
            </View>
          ))}
          <TouchableOpacity style={styles.editIcon} onPress={() => handleEdit('userEducation', { userEducation })}>
            <Ionicons name="pencil" size={24} color="#007BFF" />
          </TouchableOpacity>
        </View>

        {/* Projects */}
        <View style={styles.card}>
          <Text style={styles.cardTitle}>Projects</Text>
          {userProjects.map((proj, index) => (
            <View key={index} style={styles.experienceItem}>
              <Text style={styles.experienceTitle} onPress={() => Linking.openURL(proj.projectLink)}>
                {proj.title}
              </Text>
              <Text style={styles.description}>{proj.description}</Text>
              <Text style={styles.description}>{proj.startDate} - {proj.endDate}</Text>
              {proj.projectLink && (
                <TouchableOpacity onPress={() => Linking.openURL(proj.projectLink)}>
                  <Text style={styles.linkText}>Project Link</Text>
                </TouchableOpacity>
              )}
            </View>
          ))}
          <TouchableOpacity style={styles.editIcon} onPress={() => handleEdit('userProjects', { userProjects })}>
            <Ionicons name="pencil" size={24} color="#007BFF" />
          </TouchableOpacity>
        </View>
      </View>


      {/* View/Hide Report Button */}
      <TouchableOpacity style={styles.toggleButton} onPress={toggleReportVisibility}>
        <Text style={styles.toggleButtonText}>
          {showReport ? 'Hide Report' : 'View Report'}
        </Text>
      </TouchableOpacity>

      {/* Report Section in a Big Card */}
      {showReport && (<View style={styles.reportContainer}>
        {/* Report Title and Date */}
        <Text style={styles.reportTitle}>
          {report.name ? `${report.name}'s Personalized Career Assessment Report` : 'Personalized Career Assessment Report'}
        </Text>
        <Text style={styles.reportDate}>Date: {report.date || 'N/A'}</Text>

        {/* Iterate through sections */}
        {report.sections?.map((section, index) => (
          <View key={index} style={styles.sectionCard}>
            {/* Section Headline */}
            <Text style={styles.sectionTitle}>{section.headline}</Text>

            {/* Section Content */}
            {section.content ? (
              <Text style={styles.sectionContent}>{section.content}</Text>
            ) : null}

            {/* Subsections */}
            {section.subsections?.map((sub, subIndex) => (
              <View key={subIndex} style={styles.subsection}>
                <Text style={styles.subsectionTitle}>{sub.subheadline}</Text>
                {sub.points?.map((point, pointIndex) => (
                  <Text key={pointIndex} style={styles.point}>{point}</Text>
                ))}
              </View>
            ))}
          </View>
        ))}
      </View>)}


      {editSection && (
        <EditProfileDialog
          visible={!!editSection}
          section={editSection}
          data={editData}
          onClose={() => setEditSection(null)}
          onSave={handleSave}
        />
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F1EEE6',
  },
  header: {
    backgroundColor: '#fff',
    paddingVertical: 10, // Reduced vertical padding
    paddingHorizontal: 20,
    flexDirection: 'column', // Stacks the title and subtitle vertically
    alignItems: 'center', // Centers content vertically
    justifyContent: 'center', // Ensures the content is centered
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    position: 'relative', // For absolutely positioning the logo
    minHeight: 80, // Ensures the header has a minimum height for good spacing
  },
  logoHeader: {
    width: 40,
    height: 40,
    resizeMode: 'contain',
    position: 'absolute', // Places the logo in the top-left corner
    left: 20, // Keeps space from the left edge
    top: '50%', // Aligns vertically
    transform: [{ translateY: -20 }], // Centers logo vertically based on its height
  },
  titleContainer: {
    flexDirection: 'row', // Keep title and subtitle on the same line
    alignItems: 'center', // Vertically aligns the title and subtitle
    justifyContent: 'center', // Centers horizontally
  },
  headerTitle: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#007BFF',
    textAlign: 'center',
    marginRight: 5, // Adds a little space between the title and subtitle
  },
  // headerSubtitle: {
  //   fontSize: 20, // Adjust font size
  //   color: '#6c757d',
  //   textAlign: 'center',
  //   flexShrink: 1, // Allows the subtitle to shrink to fit within the available space
  //   marginLeft: 5, // Ensures space between title and subtitle
  // },
  google_login: {
    width: 300,
    height: 200,
    resizeMode: 'contain',
    marginBottom: 15,
  },
  centered: {
    flex: 1,
    alignItems: 'center',
  },
  createJobButton: {
    width: 250,
    backgroundColor: '#FF5722',
    paddingVertical: 15,
    borderRadius: 5,
    alignItems: 'center',
  },
  createJobButtonText: {
    fontSize: 18,
    color: '#fff',
    fontWeight: 'bold',
  },

  buttonRowMobile: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
    marginHorizontal: 20,
    paddingHorizontal: 20,
  },
  buttonRowLarge: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 20,
    marginHorizontal: 20,
    paddingHorizontal: 20,
  },
  logoutButton: {
    backgroundColor: '#007BFF',
    paddingVertical: 15,
    paddingHorizontal: 30,
    borderRadius: 8,
    marginVertical: 10,
  },
  logoutButtonText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
  },
  exploreButton: {
    backgroundColor: '#FFD700',
    paddingVertical: 15,
    paddingHorizontal: 30,
    borderRadius: 8,
    marginTop:20
  },
  exploreButtonText: {
    color: '#1e1e1e',
    fontWeight: 'bold',
    fontSize: 20,
  },
  assessmentButton: {
    backgroundColor: '#28a745',
    paddingVertical: 15,
    paddingHorizontal: 30,
    borderRadius: 8,
    marginVertical: 10,
  },
  assessmentButtonText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
  },
  loginActionText: {
    color: '#1e1e1e',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 15,
    paddingVertical: 15,
    paddingHorizontal: 10,
  },
  gridContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: 10,
  },
  listContainer: {
    flexDirection: 'column',
    padding: 10,
  },
  editIcon: {
    position: 'absolute',  // Allows positioning in relation to the card
    top: 10,               // Adjust this value to move it vertically
    right: 10,             // Adjust this value to move it horizontally
    zIndex: 1,             // Ensures the icon is above other card content
  },
  card: {
    width: isLargeScreen ? '45%' : "90%",
    backgroundColor: '#fff',
    padding: 20,
    marginVertical: 10,
    marginHorizontal: 10,
    borderRadius: 10,
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    position: 'relative',
  },
  cardTitle: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: 15,
    color: '#007BFF',
  },
  description: {
    color: '#333',
    fontSize: 18,
  },
  skillsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
  },
  skillChip: {
    backgroundColor: '#007BFF',
    color: '#fff',
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginVertical: 5,
    marginHorizontal: 5,
    borderRadius: 15,
    fontSize: 14,
  },
  experienceItem: {
    marginBottom: 15,
  },
  experienceTitle: {
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 5,
    color: '#007BFF',
  },
  editButton: {
    color: '#FF6F61',
    marginTop: 15,
    textAlign: 'right',
    fontSize: 18,
  },
  linkText: {
    color: '#FF6F61',
    textDecorationLine: 'underline',
    marginTop: 5,
  },
  reportContainer: {
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    margin: 20,
    elevation: 5,
  },
  reportTitle: {
    fontSize: 22,
    fontWeight: 'bold',
    color: '#007BFF',
    textAlign: 'center',
    marginBottom: 10,
  },
  reportDate: {
    fontSize: 16,
    color: '#6c757d',
    textAlign: 'center',
    marginBottom: 20,
  },
  sectionCard: {
    backgroundColor: '#f9f9f9',
    padding: 15,
    borderRadius: 10,
    marginVertical: 10,
    elevation: 3,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#007BFF',
    marginBottom: 10,
  },
  sectionContent: {
    fontSize: 16,
    color: '#333',
    marginBottom: 10,
  },
  subsection: {
    marginBottom: 20,
  },
  subsectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#28a745',
    marginBottom: 5,
  },
  point: {
    fontSize: 16,
    color: '#333',
    marginBottom: 5,
  },
  reportMessage: {
    backgroundColor: '#FF5722',
    color: '#ffffff',
    fontSize: 16,
    padding: 10,
    borderRadius: 5,
    textAlign: 'center',
    marginVertical: 10,
  },
  toggleButton: {
    backgroundColor: '#007BFF',
    paddingVertical: 15,
    paddingHorizontal: 30,
    borderRadius: 8,
    marginVertical: 10,
    alignItems: 'center',
    alignSelf: 'center',
  },
  toggleButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
  },
});