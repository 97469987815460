import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import CareerAssessment from './screens/CareerAssessment';
import ProfileScreen from './screens/ProfileScreen';
import ExploreCareersScreen from './screens/ExploreCareersScreen';
import CareerDetailScreen from './screens/CareerDetailScreen';
import CareerRoadMap from './CareerRoadmapStack';
import CareerRoadmpaStack from './CareerRoadmapStack';


const Stack = createStackNavigator();

export default function PostsStack() {
  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
        <Stack.Screen name="Profile" component={ProfileScreen} options={{ title: 'Profile' }} />
        <Stack.Screen name="Career Assessment" component={CareerAssessment} options={{ title: 'Career Assessment' }} />
        <Stack.Screen name="ExploreCareers" component={CareerRoadmpaStack} options={{ title: 'Explore Careers' }}/>
    </Stack.Navigator>
  );
}