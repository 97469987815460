import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import ExploreCareersScreen from './screens/ExploreCareersScreen';
import CareerDetailScreen from './screens/CareerDetailScreen';


const Stack = createStackNavigator();

export default function CareerRoadmpaStack() {
  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
        <Stack.Screen name="ExploreCareers" component={ExploreCareersScreen} options={{ title: 'Explore Careers' }}/>
        <Stack.Screen name="CareerDetail" component={CareerDetailScreen} options={{ title: 'Career Path Detail' }}/>
    </Stack.Navigator>
  );
}