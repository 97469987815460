import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Button, ScrollView, Image } from 'react-native';

export default function MCQScreen({ route, navigation }) {
  // Ensure that 'subject' is passed as part of the route parameters
  const { subject, questions, timeLimit } = route.params;
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timer, setTimer] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [userAnswers, setUserAnswers] = useState([]);

  useEffect(() => {
    let interval = null;
    if (timer !== null && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleNext = () => {
    setUserAnswers((prev) => [
      ...prev,
      { questionId: currentQuestionIndex, selected: selectedOption },
    ]);
    setSelectedOption(null);
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePrevious = () => {
    setSelectedOption(null);
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleFinish = () => {
    clearInterval(timer);
    setTimer(null);
    navigation.navigate('ResultScreen', { userAnswers, questions });
  };

  const handleStart = () => {
    setTimer(timeLimit);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const instructions = [
    "This test consists of multiple-choice questions.",
    `You have a total of ${questions.length} minutes to complete the test.`,
    "Each question carries 1 mark.",
    "There is no negative marking for wrong answers.",
    "Once you finish, you will see your score and the correct answers for the questions you got wrong.",
  ];
  const currentQuestion = questions[currentQuestionIndex];

  if (!currentQuestion) {
    return (
      <View style={styles.container}>
        <Text style={styles.noQuestions}>No questions available for this subject.</Text>
        <TouchableOpacity style={styles.backButton} onPress={() => navigation.goBack()}>
          <Text style={styles.backButtonText}>Back</Text>
        </TouchableOpacity>
      </View>
    );
  }

  return (
    <ScrollView style={styles.container}>
      {/* Header Section */}
      <View style={styles.header}>
        <Image source={require('../assets/hamtiv-logo.png')} style={styles.logoHeader} />
        <Text style={styles.headerTitle}>HamTiv</Text>
        <Text style={styles.headerSubtitle}>MCQ Test Series</Text>
      </View>

      {/* Instructions and Start Button */}
      {timer === null ? (
        <>
          <Text style={styles.instructions}>
            {instructions}
          </Text>
          <TouchableOpacity style={styles.startButton} onPress={handleStart}>
            <Text style={styles.startButtonText}>Start</Text>
          </TouchableOpacity>
        </>
      ) : (
        <>
          <View style={styles.timerContainer}>
            <Text style={styles.subjectTitle}>{subject || "Subject"}</Text>
            <Text style={styles.timer}>Time Left: {timer}s</Text>
          </View>

          <View style={styles.questionsGrid}>
            <View style={styles.questionContainer}>
              <Text style={styles.question}>{currentQuestion.questionText}</Text>
              {currentQuestion.options.map((option, index) => (
                <TouchableOpacity
                  key={index}
                  style={[
                    styles.option,
                    selectedOption === option && styles.selectedOption,
                  ]}
                  onPress={() => handleOptionSelect(option)}
                >
                  <Text>{option}</Text>
                </TouchableOpacity>
              ))}
            </View>
          </View>

          <View style={styles.buttonContainer}>
            {currentQuestionIndex > 0 && (
              <TouchableOpacity style={styles.navButton} onPress={handlePrevious}>
                <Text style={styles.navButtonText}>Previous</Text>
              </TouchableOpacity>
            )}
            {currentQuestionIndex < questions.length - 1 && (
              <TouchableOpacity style={styles.navButton} onPress={handleNext}>
                <Text style={styles.navButtonText}>Next</Text>
              </TouchableOpacity>
            )}
            {currentQuestionIndex === questions.length - 1 && (
              <TouchableOpacity style={styles.finishButton} onPress={handleFinish}>
                <Text style={styles.navButtonText}>Finish</Text>
              </TouchableOpacity>
            )}
          </View>
        </>
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  header: {
    backgroundColor: '#272727',
    padding: 20,
    alignItems: 'center',
  },
  logoHeader: {
    width: 150,
    height: 150,
    resizeMode: 'contain',
    marginBottom: 15,
  },
  headerTitle: {
    fontSize: 44,
    color: '#f9d342',
    fontWeight: 'bold',
  },
  headerSubtitle: {
    fontSize: 28,
    color: '#bdc3c7',
  },
  instructions: {
    fontSize: 18,
    color: '#555',
    padding: 15,
    textAlign: 'center',
    backgroundColor: '#f9f9f9',
    borderRadius: 8,
    marginBottom: 20,
  },
  startButton: {
    alignSelf: 'center',
    paddingVertical: 10,
    paddingHorizontal: 40,
    backgroundColor: '#FF6F61',
    borderRadius: 5,
    marginBottom: 20,
  },
  startButtonText: {
    color: '#fff',
    fontSize: 18,
  },
  timerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    marginBottom: 20,
  },
  subjectTitle: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  timer: {
    fontSize: 20,
    color: 'red',
  },
  questionsGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginVertical: 10,
  },
  questionContainer: {
    flex: 1,
    margin: 10,
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 8,
    elevation: 3,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
  },
  question: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  option: {
    padding: 15,
    marginBottom: 10,
    backgroundColor: '#f0f0f0',
    borderRadius: 5,
  },
  selectedOption: {
    backgroundColor: '#d0f0c0',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 20,
  },
  navButton: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: '#FF6F61',
    borderRadius: 5,
  },
  finishButton: {
    paddingVertical: 10,
    paddingHorizontal: 40,
    backgroundColor: '#FF6F61',
    borderRadius: 5,
  },
  navButtonText: {
    color: '#fff',
    fontSize: 16,
  },
  backButton: {
    alignSelf: 'center',
    marginTop: 30,
  },
  backButtonText: {
    color: '#FF6F61',
    fontSize: 16,
  },
  noQuestions: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 20,
  },
});
