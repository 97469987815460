// CareerRoadMap Class
export default class CareerRoadMap {
  constructor(title, description, roadmap, favoriteSubjects = [], favoriteHobbies = [], stream, degree, branch) {
    this.title = title;  // Career title like "Backend Developer"
    this.description = description;  // Career description
    this.roadmap = roadmap;  // Expecting an instance of Roadmap
    this.favoriteSubjects = favoriteSubjects;  // Array of favorite subjects
    this.favoriteHobbies = favoriteHobbies;  // Array of favorite hobbies
    this.stream = stream;  // The education stream (e.g., "Science Stream")
    this.degree = degree;  // The degree (e.g., "B.Tech/B.E.")
    this.branch = branch;  // The branch or specialization (e.g., "Computer Science and Engineering")
  }
}

// Roadmap Class with Short-Term, Medium-Term, and Long-Term Steps
export class Roadmap {
  constructor(shortTerm = [], mediumTerm = [], longTerm = []) {
    this.shortTerm = shortTerm;  // Array of StepDetails for short-term roadmap
    this.mediumTerm = mediumTerm;  // Array of StepDetails for medium-term roadmap
    this.longTerm = longTerm;  // Array of StepDetails for long-term roadmap
  }
}

// StepDetails Class for Roadmap Steps
export class StepDetails {
  constructor(sectionTitle, content) {
    this.sectionTitle = sectionTitle;  // Title of the section, like "Education", "Databases", etc.
    this.content = content;  // Content describing what needs to be learned or achieved
  }
}
