import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Dimensions, FlatList, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import CategoryDropdown from '../components/CategoryDropdown';
import { careerData } from '../models/careerPaths';
import { ScrollView } from 'react-native-gesture-handler';

const ExploreCareersScreen = () => {
    const navigation = useNavigation();
    const screenWidth = Dimensions.get('window').width;
    const [selectedStream, setSelectedStream] = useState('');
    const [selectedDegree, setSelectedDegree] = useState('');
    const [selectedBranch, setSelectedBranch] = useState('');
    const [filteredCareers, setFilteredCareers] = useState([]);

    // Filter the careers based on selected stream, degree, and branch
    useEffect(() => {
        const filterData = careerData.filter(
            (career) =>
                (!selectedStream || career.stream === selectedStream) &&
                (!selectedDegree || career.degree === selectedDegree) &&
                (!selectedBranch || career.branch === selectedBranch)
        );
        setFilteredCareers(filterData);
    }, [selectedStream, selectedDegree, selectedBranch]);

    const handleCareerClick = (career) => {
        navigation.navigate('CareerDetail', { career });
    };

    const renderCareerCard = ({ item }) => (
        <TouchableOpacity style={styles.card} onPress={() => handleCareerClick(item)}>
            <View style={styles.cardContent}>
                <Text style={styles.careerTitle}>{item.title}</Text>
                <Text style={styles.careerDescription}>{item.description.substring(0, 150)}...</Text>
            </View>
        </TouchableOpacity>
    );

    return (
        <ScrollView style={styles.container}>
            <View style={styles.header}>
                {/* Back Button with proper positioning and flex layout */}
                <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
                    <Text style={styles.backButtonText}>Back</Text>
                </TouchableOpacity>

                {/* Header Title */}
                <Text style={styles.headerTitle}>Explore Career Paths</Text>
            </View>

            <CategoryDropdown
                selectedStream={selectedStream}
                onStreamChange={setSelectedStream}
                selectedDegree={selectedDegree}
                onDegreeChange={setSelectedDegree}
                selectedBranch={selectedBranch}
                onBranchChange={setSelectedBranch}
            />

            {filteredCareers.length > 0 ? (
                <View style={screenWidth >= 768 ? styles.gridContainer : styles.verticalContainer}>
                    {filteredCareers.map((career, index) => (
                        <View
                            key={career.title}
                            style={screenWidth >= 768 ? styles.cardLarge : styles.card}
                        >
                            <TouchableOpacity onPress={() => handleCareerClick(career)}>
                                <Text style={styles.careerTitle}>{career.title}</Text>
                                <Text style={styles.careerDescription}>
                                    {career.description.substring(0, 150)}...
                                </Text>
                            </TouchableOpacity>
                        </View>
                    ))}
                </View>
            ) : (
                <Text style={styles.noResultsText}>No careers match your selection</Text>
            )}
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F1EEE6', // Light beige background
    },
    header: {
        paddingVertical: 20,
        backgroundColor: '#ffffff',
        borderBottomWidth: 1,
        borderBottomColor: '#dddddd',
        flexDirection: 'row', // Align items in a row
        alignItems: 'center',
        justifyContent: 'space-between', // Space between back button and title
        paddingHorizontal: 10,
    },
    backButton: {
        padding: 10,
        backgroundColor: '#007BFF',
        borderRadius: 5,
    },
    backButtonText: {
        color: '#ffffff',
        fontSize: 16,
        fontWeight: 'bold',
    },
    headerTitle: {
        fontSize: 32, // Smaller for better alignment
        fontWeight: 'bold',
        color: '#333333',
        flex: 1, // Take up available space to center text
        textAlign: 'center', // Center title
    },
    verticalContainer: {
        padding: 20,
        alignItems: 'center',
    },
    gridContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        paddingHorizontal: 20,
        paddingBottom: 20,
    },
    card: {
        backgroundColor: '#ffffff', // White card background
        borderRadius: 10,
        marginBottom: 20,
        padding: 20, // Padding inside the card
        width: '90%', // Full width for mobile screens
        maxWidth: 700,
        borderColor: '#000000', // Black border around the cards
        borderWidth: 2, // Stronger border for contrast
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 10,
        elevation: 4,
    },
    cardLarge: {
        backgroundColor: '#ffffff',
        borderRadius: 10,
        padding: 20,
        marginBottom: 30,
        width: '48%', // Two cards per row in grid
        borderColor: '#000000', // Black border for larger screens too
        borderWidth: 2,
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 10,
        elevation: 4,
    },
    cardContent: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    careerTitle: {
        fontSize: 26,
        fontWeight: 'bold',
        fontFamily: 'Poppins', // Modern font
        color: '#333333', // Dark text color for title
        marginBottom: 10,
        textAlign: 'center',
    },
    careerDescription: {
        fontSize: 18,
        fontWeight: '500',
        fontFamily: 'Roboto',
        color: '#666666', // Dark gray text color for description
        textAlign: 'center',
        marginBottom: 20,
        lineHeight: 22,
    },
    noResultsText: {
        fontSize: 18,
        color: '#999999', // Light gray text color for no results
        textAlign: 'center',
        marginTop: 20,
    },
});

export default ExploreCareersScreen;
