import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import PostsScreen from './screens/PostsScreen';
import HubsScreen from './screens/HubsScreen';
import PostDetailScreen from './screens/PostDetailScreen';
import CreatePostScreen from './screens/CreatePostScreen';


const Stack = createStackNavigator();

export default function PostsStack() {
  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
        <Stack.Screen name="Posts" component={PostsScreen} options={{ title: 'Posts' }} />
        <Stack.Screen name="Post Detail" component={PostDetailScreen} options={{ title: 'Posts Detail' }} />
        <Stack.Screen name="Hubs" component={HubsScreen} options={{ title: 'Hubs' }} />
        <Stack.Screen name="CreatePost" component={CreatePostScreen} options={{ title: 'CreatePost' }} />
    </Stack.Navigator>
  );
}