class ModelPost {
    constructor(
      postId,
      postContent,
      postAttachments = [],
      postAuthorId,
      postAuthorName,
      postAuthorPicture,
      postAuthorRole,
      postCreatedAt,
      postLikes = [],
      postResponses = [],
      postReports = [],
      postTags = []
    ) {
      this.postId = postId;
      this.postContent = postContent;
      this.postAttachments = postAttachments;
      this.postAuthorId = postAuthorId;
      this.postAuthorName = postAuthorName;
      this.postAuthorPicture = postAuthorPicture;
      this.postAuthorRole = postAuthorRole;
      this.postCreatedAt = postCreatedAt;
      this.postLikes = postLikes;
      this.postResponses = postResponses;
      this.postReports = postReports;
      this.postTags = postTags;
    }
  }
  
  export default ModelPost;
  