import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView, Image, Dimensions } from 'react-native';
import Topics from '../components/KotlinTopics';
import { useNavigation } from '@react-navigation/native';

const windowWidth = Dimensions.get('window').width;
const isLargeScreen = windowWidth >= 768;

export default function TopicsScreen({ route, navigation }) {
  const { selectedTopic } = route.params;

  const topicDetails = Topics[selectedTopic] || [];

  const handleTopicSelect = (title) => {
    navigation.navigate('TopicDetailScreen', { selectedTopic: selectedTopic, selectedTitle: title });
  };

  return (
    <ScrollView style={styles.container}>
      <View style={styles.header}>
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={styles.backButton}
        >
          <Text style={styles.backButtonText}>Back</Text>
        </TouchableOpacity>
        <Text style={styles.headerTitle}>{selectedTopic}</Text>
      </View>

      <View style={styles.topicsContainer}>
        {topicDetails.map((topic, index) => (
          <TouchableOpacity
            key={index}
            style={styles.topicCard}
            onPress={() => handleTopicSelect(topic.title)}
          >
            <Text style={styles.topicTitle}>{topic.title}</Text>
            <Text style={styles.topicDescription}>
              {topic.description}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F1EEE6', // Light background color
  },
  header: {
    width: '100%',
    paddingVertical: 15, // Vertical padding for spacing
    paddingHorizontal: 20, // Horizontal padding for space between back button and title
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  },
  backButton: {
    position: 'absolute',
    left: 10,  // Set the left to 0 so it takes full horizontal space
    paddingHorizontal: 20, // Increase padding to make button fully clickable
    paddingVertical: 10,
    backgroundColor: '#007BFF', // Blue background for back button
    borderRadius: 5,
    zIndex: 10,  // Ensure the back button is always on top
  },
  backButtonText: {
    color: '#FFFFFF', // White text for the back button
    fontSize: 16,
    fontWeight: 'bold',
  },
  headerTitle: {
    fontSize: isLargeScreen?24:20,
    fontWeight: 'bold',
    color: '#333',
    maxWidth: '80%',  // Ensure the title doesn't take too much space and interfere with the button
    textAlign: 'center',
  },
  topicsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center', // Center the cards
    marginTop: 20,
  },
  topicCard: {
    width: isLargeScreen ? '45%' : '90%', // 45% for large screens, 90% for mobile
    backgroundColor: '#FFFFFF', // White card background
    borderRadius: 10,
    margin: 10,
    padding: 15,
    alignItems: 'center',
    borderWidth: 2, // Black border
    borderColor: '#000000',
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 5,
    elevation: 3,
  },
  topicTitle: {
    fontSize: 22,
    fontWeight: 'bold',
    color: '#007BFF', // Blue text for the title
    textAlign: 'center',
    marginBottom: 10,
  },
  topicDescription: {
    fontSize: 18,
    color: '#666666', // Gray text for the description
    textAlign: 'center',
  },
  noResourcesText: {
    fontSize: 18,
    color: '#666666', // Gray text for no resources message
    textAlign: 'center',
    marginTop: 20,
  },
});
