export const generateMCQInstructions = (timeLimitInMinutes) => [
  `This test consists of multiple-choice questions.`,
  `You have a total of ${timeLimitInMinutes} minutes to complete the test.`,
  `Each question carries 1 mark.`,
  `There is no negative marking for wrong answers.`,
  `Once you finish, you will see your score and the correct answers for the questions you got wrong.`
];

export const MCQ_QUESTIONS = [
    {
        "id": 1,
        "questionText": "You are tasked with introducing Kotlin to a team that has been using Java for years. They are concerned about the learning curve and compatibility with existing Java code. What key points would you highlight to demonstrate that Kotlin can be integrated smoothly into their current Java-based projects?",
        "options": [
            "Kotlin is fully interoperable with Java, allowing you to call Kotlin code from Java and vice versa.",
            "Kotlin requires rewriting the entire codebase to start using it.",
            "Kotlin introduces significant runtime performance issues when used alongside Java.",
            "Kotlin code is compiled into bytecode that can run on the Java Virtual Machine (JVM)."
        ],
        "correctAnswer": [
            "Kotlin is fully interoperable with Java, allowing you to call Kotlin code from Java and vice versa.",
            "Kotlin code is compiled into bytecode that can run on the Java Virtual Machine (JVM)."
        ],
        "explanation": "Kotlin is designed to be fully interoperable with Java, which means you can gradually introduce Kotlin into an existing Java project. The ability to compile into JVM bytecode ensures that Kotlin can run seamlessly in environments already configured for Java.",
        "difficultyLevel": "Medium",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "subject": "Kotlin",
        "topic": "Introduction to Kotlin",
        "marks": 2,
        "timeLimit": 120,
        "isMultipleCorrect": true
    },
    {
        "id": 2,
        "questionText": "You have just installed the Kotlin plugin in your IDE and set up your first Kotlin project. The next step is to write a simple Kotlin program that prints 'Hello, World!'. Which of the following code snippets would you use?",
        "options": [
            "fun main(args: Array<String>) { println('Hello, World!') }",
            "void main() { System.out.println('Hello, World!'); }",
            "fun main() { println('Hello, World!') }",
            "public static void main(String[] args) { System.out.println('Hello, World!'); }"
        ],
        "correctAnswer": "fun main() { println('Hello, World!') }",
        "explanation": "In Kotlin, the main function is the entry point to a program. Unlike Java, you don't need to define a class or specify static methods. The correct syntax uses 'fun' to declare a function, and 'println' to output text to the console.",
        "difficultyLevel": "Easy",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "subject": "Kotlin",
        "topic": "Introduction to Kotlin",
        "marks": 1,
        "timeLimit": 60,
        "isMultipleCorrect": false
    },
    {
        "id": 3,
        "questionText": "Imagine you're migrating a legacy Java application to Kotlin. The application relies heavily on null references, leading to frequent NullPointerExceptions. How does Kotlin address this issue, and what steps would you take to prevent null-related crashes?",
        "options": [
            "Kotlin eliminates null references entirely, forcing you to use alternative programming patterns.",
            "Kotlin introduces nullable and non-nullable types, which help prevent null pointer exceptions.",
            "Kotlin automatically converts all null references to empty strings.",
            "Kotlin provides built-in null checks and throws exceptions when null values are encountered."
        ],
        "correctAnswer": "Kotlin introduces nullable and non-nullable types, which help prevent null pointer exceptions.",
        "explanation": "Kotlin's type system distinguishes between nullable and non-nullable types, reducing the risk of null pointer exceptions by forcing the programmer to explicitly handle null values.",
        "difficultyLevel": "Medium",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "subject": "Kotlin",
        "topic": "Introduction to Kotlin",
        "marks": 2,
        "timeLimit": 90,
        "isMultipleCorrect": false
    },
    {
        "id": 4,
        "questionText": "Your team has just started using Kotlin and is writing their first Kotlin program. They are familiar with Java but encounter an error when trying to assign null to a variable. What could be the reason, and how can they fix it?",
        "options": [
            "Kotlin variables are non-null by default, and you need to explicitly mark a variable as nullable using '?' to assign null.",
            "Kotlin does not support null values at all.",
            "They should use the keyword 'null' instead of 'NULL'.",
            "Kotlin requires special syntax for assigning null, using 'val variable = Nullable(null)'."
        ],
        "correctAnswer": "Kotlin variables are non-null by default, and you need to explicitly mark a variable as nullable using '?' to assign null.",
        "explanation": "In Kotlin, variables are non-null by default. If you need a variable to hold a null value, you must declare it with a nullable type using the '?' symbol (e.g., 'var name: String?').",
        "difficultyLevel": "Easy",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "subject": "Kotlin",
        "topic": "Introduction to Kotlin",
        "marks": 1,
        "timeLimit": 60,
        "isMultipleCorrect": false
    },
    {
        "id": 5,
        "questionText": "While setting up your Kotlin development environment, you encounter a scenario where you need to choose between using IntelliJ IDEA and Android Studio. What factors should you consider when making this choice?",
        "options": [
            "IntelliJ IDEA is better suited for mobile app development.",
            "Android Studio is specifically designed for Android development and provides tools optimized for this platform.",
            "Both IDEs are equally suited for Android development with no real differences.",
            "IntelliJ IDEA is only useful for Java development and does not support Kotlin."
        ],
        "correctAnswer": "Android Studio is specifically designed for Android development and provides tools optimized for this platform.",
        "explanation": "While both IntelliJ IDEA and Android Studio support Kotlin, Android Studio is specifically tailored for Android development, offering additional tools and features such as the Android Emulator, profilers, and layout designers.",
        "difficultyLevel": "Medium",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "subject": "Kotlin",
        "topic": "Introduction to Kotlin",
        "marks": 2,
        "timeLimit": 90,
        "isMultipleCorrect": false
    },
    {
        "id": 6,
        "questionText": "You are explaining to a new team member the difference between Kotlin and Java when it comes to verbosity. How would you demonstrate that Kotlin is more concise than Java using a simple example?",
        "options": [
            "Kotlin does not require semicolons to end statements.",
            "Kotlin uses 'val' and 'var' for variable declarations instead of 'int', 'String', etc.",
            "Kotlin allows you to write a complete class with properties, constructor, and methods in fewer lines compared to Java.",
            "All of the above."
        ],
        "correctAnswer": "All of the above.",
        "explanation": "Kotlin is designed to be more concise and expressive than Java. It removes the need for boilerplate code, such as semicolons, and uses simplified syntax for variable declarations and classes, making the code more readable and easier to write.",
        "difficultyLevel": "Easy",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "subject": "Kotlin",
        "topic": "Introduction to Kotlin",
        "marks": 1,
        "timeLimit": 60,
        "isMultipleCorrect": false
    },
    {
        "id": 7,
        "questionText": "You are tasked with setting up a Kotlin project for a new Android app. During setup, you have the option to choose the Kotlin Standard Library (stdlib). Which version of the Kotlin Standard Library would be most appropriate for your project, considering backward compatibility and performance?",
        "options": [
            "kotlin-stdlib-jdk7 for projects targeting Java 7 and below.",
            "kotlin-stdlib-jdk8 for projects targeting Java 8 and taking advantage of new language features.",
            "kotlin-stdlib-common for projects that need to run on multiple platforms.",
            "All of the above, depending on the specific requirements of the project."
        ],
        "correctAnswer": "All of the above, depending on the specific requirements of the project.",
        "explanation": "Kotlin provides different versions of the standard library depending on the Java version you're targeting or whether you need cross-platform compatibility. The choice depends on your project's specific requirements.",
        "difficultyLevel": "Medium",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "subject": "Kotlin",
        "topic": "Introduction to Kotlin",
        "marks": 2,
        "timeLimit": 120,
        "isMultipleCorrect": false
    },
    {
        "id": 8,
        "questionText": "As part of introducing Kotlin into your development workflow, you're exploring its syntax for declaring variables. Which of the following statements best describes Kotlin's approach to variable declaration and its benefits?",
        "options": [
            "'val' is used for immutable variables, and 'var' is used for mutable variables, which helps prevent unintended changes to values.",
            "'val' and 'var' are interchangeable and can be used based on personal preference.",
            "All variables in Kotlin must be declared as 'var' to ensure flexibility.",
            "Kotlin does not differentiate between mutable and immutable variables."
        ],
        "correctAnswer": "'val' is used for immutable variables, and 'var' is used for mutable variables, which helps prevent unintended changes to values.",
        "explanation": "Kotlin distinguishes between immutable ('val') and mutable ('var') variables. This distinction helps developers avoid unintended modifications to variables, thereby reducing bugs and making the code more predictable.",
        "difficultyLevel": "Easy",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "subject": "Kotlin",
        "topic": "Introduction to Kotlin",
        "marks": 1,
        "timeLimit": 60,
        "isMultipleCorrect": false
    },
    {
        "id": 9,
        "questionText": "When setting up the Kotlin development environment, you have the option to use different build systems. What advantages does Gradle offer over Maven when building Kotlin projects?",
        "options": [
            "Gradle provides better support for incremental builds, which can significantly reduce build times.",
            "Gradle has native support for Kotlin DSL, making the build scripts easier to write and maintain.",
            "Gradle allows for more flexible and scalable build configurations, especially for complex multi-module projects.",
            "All of the above."
        ],
        "correctAnswer": "All of the above.",
        "explanation": "Gradle offers several advantages over Maven, including better incremental build support, native Kotlin DSL support, and greater flexibility in configuring complex projects, making it a preferred choice for many Kotlin-based Android projects.",
        "difficultyLevel": "Medium",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "subject": "Kotlin",
        "topic": "Introduction to Kotlin",
        "marks": 2,
        "timeLimit": 120,
        "isMultipleCorrect": false
    },
    {
        "id": 10,
        "questionText": "You are comparing Kotlin and Java for a new Android project. One of your goals is to reduce boilerplate code and improve code readability. Which Kotlin features specifically address these concerns?",
        "options": [
            "Type inference, which reduces the need to explicitly declare variable types.",
            "Data classes, which automatically generate common methods like equals(), hashCode(), and toString().",
            "Extension functions, which allow you to add functionality to existing classes without modifying their source code.",
            "All of the above."
        ],
        "correctAnswer": "All of the above.",
        "explanation": "Kotlin introduces several features that reduce boilerplate code and enhance readability, including type inference, data classes, and extension functions. These features simplify code and make it more expressive, improving overall productivity.",
        "difficultyLevel": "Medium",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "subject": "Kotlin",
        "topic": "Introduction to Kotlin",
        "marks": 2,
        "timeLimit": 120,
        "isMultipleCorrect": false
    },
    {
        "id": 11,
        "questionText": "You are reviewing a colleague's Kotlin code and notice the file names don't seem to follow any particular convention. What naming conventions should they follow for Kotlin files to ensure consistency and readability in a project?",
        "options": [
            "Kotlin file names should match the name of the class or object defined within the file.",
            "File names should always be in lowercase and separated by underscores.",
            "There are no specific naming conventions for Kotlin files; any naming scheme is acceptable.",
            "Kotlin file names must always end with 'Kt'."
        ],
        "correctAnswer": "Kotlin file names should match the name of the class or object defined within the file.",
        "explanation": "For clarity and consistency, Kotlin file names typically match the name of the main class, object, or interface defined in the file. This helps in easily locating and identifying files, especially in larger projects.",
        "difficultyLevel": "Medium",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "subject": "Kotlin",
        "topic": "Building the Foundation - Basic Syntax and Structure",
        "marks": 2,
        "timeLimit": 120,
        "isMultipleCorrect": false
    },
    {
        "id": 12,
        "questionText": "A team member asks you about the structure of a basic Kotlin program. How would you describe the general structure and what essential components it should include?",
        "options": [
            "A basic Kotlin program must include at least one class and a main function.",
            "Kotlin programs are composed of packages, classes, and functions but do not require any specific structure.",
            "A Kotlin program does not require a main function if it's used as a library.",
            "A basic Kotlin program can consist of just a top-level function without any class."
        ],
        "correctAnswer": "A basic Kotlin program can consist of just a top-level function without any class.",
        "explanation": "Unlike Java, Kotlin does not require a class to define a function. A basic Kotlin program can be as simple as a single top-level function (e.g., 'fun main()') which serves as the entry point of the application.",
        "difficultyLevel": "Easy",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "subject": "Kotlin",
        "topic": "Building the Foundation - Basic Syntax and Structure",
        "marks": 1,
        "timeLimit": 60,
        "isMultipleCorrect": false
    },
    {
        "id": 13,
        "questionText": "During a code review, you notice that your team uses different styles of comments within the Kotlin codebase. Which comment types are supported in Kotlin, and what is the recommended use for each?",
        "options": [
            "Kotlin supports single-line comments using // and multi-line comments using /* */.",
            "Only single-line comments using // are supported in Kotlin.",
            "Kotlin supports comments only in block format /* */.",
            "Kotlin uses # for comments, similar to Python."
        ],
        "correctAnswer": "Kotlin supports single-line comments using // and multi-line comments using /* */.",
        "explanation": "Kotlin supports both single-line comments (//) for brief annotations and multi-line comments (/* */) for more detailed explanations or documentation. These comments help in making the code more readable and maintainable.",
        "difficultyLevel": "Easy",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "subject": "Kotlin",
        "topic": "Building the Foundation - Basic Syntax and Structure",
        "marks": 1,
        "timeLimit": 60,
        "isMultipleCorrect": false
    },
    {
        "id": 14,
        "questionText": "You are documenting a Kotlin library you've developed for your team. What is the best way to add documentation comments in Kotlin, and how do they differ from regular comments?",
        "options": [
            "Use /** */ for documentation comments that generate API docs, and // or /* */ for regular comments.",
            "Use // for documentation comments and /* */ for regular comments.",
            "Kotlin does not support documentation comments.",
            "Use /* */ for both documentation and regular comments, as there is no difference."
        ],
        "correctAnswer": "Use /** */ for documentation comments that generate API docs, and // or /* */ for regular comments.",
        "explanation": "In Kotlin, documentation comments are written using /** */. These comments are processed by tools like KDoc to generate API documentation. Regular comments (// and /* */) are intended for internal notes and clarifications within the code.",
        "difficultyLevel": "Medium",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "subject": "Kotlin",
        "topic": "Building the Foundation - Basic Syntax and Structure",
        "marks": 2,
        "timeLimit": 90,
        "isMultipleCorrect": false
    },
    {
        "id": 15,
        "questionText": "You are asked to create a Kotlin file that contains multiple top-level functions and properties. What is the correct way to structure and organize this Kotlin file?",
        "options": [
            "You can freely define multiple top-level functions and properties in a single Kotlin file without needing a class or object.",
            "Each top-level function or property must be placed inside a class or object.",
            "Top-level functions are not allowed; everything must be inside a class.",
            "Kotlin requires each file to contain only one top-level function."
        ],
        "correctAnswer": "You can freely define multiple top-level functions and properties in a single Kotlin file without needing a class or object.",
        "explanation": "Kotlin allows you to define multiple top-level functions and properties directly in a file without the need for wrapping them inside a class or object, making the code more modular and reusable.",
        "difficultyLevel": "Medium",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "subject": "Kotlin",
        "topic": "Building the Foundation - Basic Syntax and Structure",
        "marks": 2,
        "timeLimit": 120,
        "isMultipleCorrect": false
    },
    {
        "id": 16,
        "questionText": "In a Kotlin project, you want to ensure that the source files are well-organized and follow best practices. What is the recommended way to name Kotlin files, and how does it impact the project structure?",
        "options": [
            "Name the files based on the main class or object they contain, and avoid using generic names.",
            "Always name the file starting with an underscore (_) to ensure it appears at the top of the directory.",
            "Kotlin file names should always be in uppercase to distinguish them from other files.",
            "File names should be kept as short as possible, even if they are not descriptive."
        ],
        "correctAnswer": "Name the files based on the main class or object they contain, and avoid using generic names.",
        "explanation": "Naming Kotlin files based on the main class or object they contain helps in maintaining a clear and organized project structure, making it easier to navigate and understand the codebase.",
        "difficultyLevel": "Easy",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "subject": "Kotlin",
        "topic": "Building the Foundation - Basic Syntax and Structure",
        "marks": 1,
        "timeLimit": 60,
        "isMultipleCorrect": false
    },
    {
        "id": 17,
        "questionText": "While learning Kotlin, you encounter the term 'main function.' What is the significance of the 'main' function in a Kotlin program, and how is it different from the main function in Java?",
        "options": [
            "The 'main' function in Kotlin does not require an array of strings as a parameter and can be defined with or without it.",
            "The 'main' function in Kotlin must always include the 'public static void' keywords.",
            "The 'main' function in Kotlin can only be used within classes, similar to Java.",
            "There is no significant difference; the main function in Kotlin is identical to Java's main function."
        ],
        "correctAnswer": "The 'main' function in Kotlin does not require an array of strings as a parameter and can be defined with or without it.",
        "explanation": "In Kotlin, the 'main' function can be defined with or without the 'Array<String>' parameter, providing flexibility. Unlike Java, it doesn't need to be wrapped inside a class and doesn't require the 'public static void' keywords.",
        "difficultyLevel": "Easy",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "subject": "Kotlin",
        "topic": "Building the Foundation - Basic Syntax and Structure",
        "marks": 1,
        "timeLimit": 60,
        "isMultipleCorrect": false
    },
    {
        "id": 18,
        "questionText": "You need to add some code comments to explain a particularly complex function in Kotlin. Which of the following best practices should you follow when writing comments?",
        "options": [
            "Comments should be clear and concise, explaining why the code does something, not just what it does.",
            "Use comments to describe every line of code in detail, ensuring nothing is left unexplained.",
            "Avoid using comments altogether, as they clutter the code and are unnecessary.",
            "Write comments in a way that only experienced developers can understand them."
        ],
        "correctAnswer": "Comments should be clear and concise, explaining why the code does something, not just what it does.",
        "explanation": "Effective comments in code should provide insight into the reasoning behind the code, helping others (and your future self) understand the purpose and intent rather than simply describing the functionality.",
        "difficultyLevel": "Medium",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "subject": "Kotlin",
        "topic": "Building the Foundation - Basic Syntax and Structure",
        "marks": 2,
        "timeLimit": 120,
        "isMultipleCorrect": false
    },
    {
        "id": 19,
        "questionText": "In a Kotlin file, you want to include some important notes and documentation that can be extracted and displayed in the generated API documentation. What should you use?",
        "options": [
            "Use /** */ to create KDoc comments that can be extracted by documentation tools.",
            "Use // to create single-line comments for documentation purposes.",
            "Use /* */ for all types of comments, as Kotlin does not differentiate.",
            "Use HTML tags directly in the code, which will be parsed by documentation generators."
        ],
        "correctAnswer": "Use /** */ to create KDoc comments that can be extracted by documentation tools.",
        "explanation": "Kotlin uses KDoc comments (/** */) to generate API documentation, allowing you to include detailed explanations, parameter descriptions, and code examples that can be processed by documentation tools.",
        "difficultyLevel": "Medium",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "subject": "Kotlin",
        "topic": "Building the Foundation - Basic Syntax and Structure",
        "marks": 2,
        "timeLimit": 120,
        "isMultipleCorrect": false
    },
    {
        "id": 20,
        "questionText": "When organizing your Kotlin code, what is the purpose of using packages, and how should they be structured for optimal project organization?",
        "options": [
            "Packages are used to group related classes and functions together, following a hierarchical naming structure that reflects the project structure.",
            "Packages are only used to prevent name conflicts in large projects and do not impact project organization.",
            "It is best to avoid using packages in Kotlin to keep the project structure flat and simple.",
            "Packages should be named arbitrarily, as their structure does not impact code organization."
        ],
        "correctAnswer": "Packages are used to group related classes and functions together, following a hierarchical naming structure that reflects the project structure.",
        "explanation": "In Kotlin, packages help organize code by grouping related classes and functions together. A well-structured package hierarchy mirrors the project organization, making it easier to navigate and maintain the codebase.",
        "difficultyLevel": "Medium",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "subject": "Kotlin",
        "topic": "Building the Foundation - Basic Syntax and Structure",
        "marks": 2,
        "timeLimit": 120,
        "isMultipleCorrect": false
    },
    {
        "id": 21,
        "questionText": "You are designing a feature where a user's birthdate will never change once set. Which variable declaration would best suit this requirement?",
        "options": [
            "A) var birthdate: String",
            "B) val birthdate: String",
            "C) lateinit var birthdate: String",
            "D) const val birthdate: String"
        ],
        "correctAnswer": "B) val birthdate: String",
        "explanation": "Since the birthdate should not change, 'val' is the appropriate choice as it declares an immutable variable.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "Gathering Magical Resources - Mastering Variables and Data Types",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 22,
        "questionText": "You need to initialize a variable at a later stage in the code after some data is fetched from a server. Which of the following declarations would you use?",
        "options": [
            "A) var serverData: String",
            "B) val serverData: String",
            "C) lateinit var serverData: String",
            "D) lazy val serverData: String"
        ],
        "correctAnswer": "C) lateinit var serverData: String",
        "explanation": "'lateinit' allows a non-nullable variable to be initialized later, making it suitable for this scenario.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "Gathering Magical Resources - Mastering Variables and Data Types",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 23,
        "questionText": "Which Kotlin keyword would you use to define a constant value that will be known at compile-time and never change?",
        "options": [
            "A) var",
            "B) val",
            "C) const",
            "D) lateinit"
        ],
        "correctAnswer": "C) const",
        "explanation": "'const' is used for compile-time constants, which are known and set during compilation and do not change.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "Gathering Magical Resources - Mastering Variables and Data Types",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 24,
        "questionText": "In which scenario is lazy initialization most beneficial?",
        "options": [
            "A) When the variable must be initialized immediately with a default value.",
            "B) When the variable's value will be known at compile-time.",
            "C) When the variable's initialization is resource-intensive and may not be needed immediately.",
            "D) When the variable is required to change frequently during runtime."
        ],
        "correctAnswer": "C) When the variable's initialization is resource-intensive and may not be needed immediately.",
        "explanation": "'lazy' is best used when initialization is resource-intensive and should be delayed until the value is actually needed.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "Gathering Magical Resources - Mastering Variables and Data Types",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 25,
        "questionText": "Which of the following is an example of a destructuring declaration in Kotlin?",
        "options": [
            "A) val name = \"John\"",
            "B) val (firstName, lastName) = Pair(\"John\", \"Doe\")",
            "C) var age = 25",
            "D) lateinit var nickname: String"
        ],
        "correctAnswer": "B) val (firstName, lastName) = Pair(\"John\", \"Doe\")",
        "explanation": "Destructuring declarations allow you to unpack multiple values from a single object, as shown in the example.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "Gathering Magical Resources - Mastering Variables and Data Types",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 26,
        "questionText": "Which basic data type would you use in Kotlin to represent a true/false value?",
        "options": [
            "A) Int",
            "B) Double",
            "C) Boolean",
            "D) Char"
        ],
        "correctAnswer": "C) Boolean",
        "explanation": "The 'Boolean' data type in Kotlin is used to represent true/false values.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "Gathering Magical Resources - Mastering Variables and Data Types",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 27,
        "questionText": "What happens when you attempt to assign a null value to a non-nullable variable in Kotlin?",
        "options": [
            "A) The variable will accept the null value.",
            "B) The variable will be initialized with a default value.",
            "C) A compilation error will occur.",
            "D) The variable will automatically be converted to a nullable type."
        ],
        "correctAnswer": "C) A compilation error will occur.",
        "explanation": "In Kotlin, assigning a null value to a non-nullable variable results in a compilation error to prevent NullPointerExceptions.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "Gathering Magical Resources - Mastering Variables and Data Types",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 28,
        "questionText": "Which of the following Kotlin variable declarations uses type inference correctly?",
        "options": [
            "A) val number: Int = 10",
            "B) val number = 10",
            "C) var name: String = \"Alice\"",
            "D) const val PI = 3.14159"
        ],
        "correctAnswer": "B) val number = 10",
        "explanation": "In Kotlin, type inference allows the compiler to automatically determine the type of 'number' based on the assigned value.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "Gathering Magical Resources - Mastering Variables and Data Types",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 29,
        "questionText": "When should you use the 'Double' data type instead of 'Float' in Kotlin?",
        "options": [
            "A) When you need to store whole numbers.",
            "B) When you need to store a single character.",
            "C) When you require more precision in floating-point numbers.",
            "D) When you need to store true/false values."
        ],
        "correctAnswer": "C) When you require more precision in floating-point numbers.",
        "explanation": "'Double' provides more precision compared to 'Float', making it suitable for calculations that require higher accuracy.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "Gathering Magical Resources - Mastering Variables and Data Types",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 30,
        "questionText": "Which of the following correctly demonstrates a nullable type in Kotlin?",
        "options": [
            "A) var name: String = null",
            "B) var name: String? = null",
            "C) lateinit var name: String = null",
            "D) val name: String = null"
        ],
        "correctAnswer": "B) var name: String? = null",
        "explanation": "Using 'String?' allows the variable 'name' to hold a null value, indicating that it can be null.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "Gathering Magical Resources - Mastering Variables and Data Types",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 31,
        "questionText": "You need a variable that stores a string and should only be initialized once the user inputs data. Which declaration is most appropriate?",
        "options": [
            "A) val userInput: String",
            "B) lateinit var userInput: String",
            "C) const val userInput: String",
            "D) var userInput: String?"
        ],
        "correctAnswer": "B) lateinit var userInput: String",
        "explanation": "'lateinit' is suitable for variables that will be initialized later, such as after user input.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "Gathering Magical Resources - Mastering Variables and Data Types",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 32,
        "questionText": "Why would you use a 'lazy' delegate for a variable in Kotlin?",
        "options": [
            "A) To ensure the variable is initialized at the time of declaration.",
            "B) To avoid initializing the variable until it is first accessed.",
            "C) To declare a constant variable.",
            "D) To initialize a nullable variable."
        ],
        "correctAnswer": "B) To avoid initializing the variable until it is first accessed.",
        "explanation": "'lazy' is used to delay the initialization of a variable until it is first accessed, optimizing resource usage.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "Gathering Magical Resources - Mastering Variables and Data Types",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 33,
        "questionText": "Which of the following Kotlin declarations is an example of using a type inference?",
        "options": [
            "A) val count = 42",
            "B) var isActive: Boolean = true",
            "C) const val PI: Double = 3.14159",
            "D) lateinit var username: String"
        ],
        "correctAnswer": "A) val count = 42",
        "explanation": "Kotlin automatically infers the type of 'count' as 'Int' based on the assigned value, demonstrating type inference.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "Gathering Magical Resources - Mastering Variables and Data Types",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 34,
        "questionText": "How would you define a constant representing the maximum number of retries allowed in a Kotlin application?",
        "options": [
            "A) var maxRetries = 3",
            "B) val maxRetries = 3",
            "C) const val MAX_RETRIES = 3",
            "D) lazy val maxRetries = 3"
        ],
        "correctAnswer": "C) const val MAX_RETRIES = 3",
        "explanation": "'const val' is used for compile-time constants, making it the appropriate choice for defining a constant like 'MAX_RETRIES'.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "Gathering Magical Resources - Mastering Variables and Data Types",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 35,
        "questionText": "You are developing a Kotlin application where you need to ensure that a variable 'nickname' can be null. How would you declare it?",
        "options": [
            "A) val nickname: String = null",
            "B) var nickname: String = null",
            "C) var nickname: String? = null",
            "D) lateinit var nickname: String = null"
        ],
        "correctAnswer": "C) var nickname: String? = null",
        "explanation": "In Kotlin, adding a '?' to the type allows the variable to hold a null value, making 'String?' the correct choice.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "Gathering Magical Resources - Mastering Variables and Data Types",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 36,
        "questionText": "You are implementing a feature where you need to calculate the total price of items in a cart by multiplying the quantity with the price of each item. Which operator would you use?",
        "options": [
            "A) +",
            "B) -",
            "C) *",
            "D) /"
        ],
        "correctAnswer": "C) *",
        "explanation": "The '*' operator is used for multiplication in Kotlin, making it suitable for calculating the total price.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Arsenal of Operators - Mastering Operations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 37,
        "questionText": "You need to increment a counter variable in a loop. Which operator would you use to increase its value by 1?",
        "options": [
            "A) ++",
            "B) --",
            "C) +=",
            "D) -="
        ],
        "correctAnswer": "A) ++",
        "explanation": "The '++' operator increments the value of a variable by 1, which is useful in loops for counters.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Arsenal of Operators - Mastering Operations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 38,
        "questionText": "In a condition, you need to check if a user's age is both greater than 18 and less than 60. Which logical operator would you use?",
        "options": [
            "A) ||",
            "B) &&",
            "C) !",
            "D) &"
        ],
        "correctAnswer": "B) &&",
        "explanation": "The '&&' operator is used for logical AND operations, ensuring both conditions are true.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Arsenal of Operators - Mastering Operations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 39,
        "questionText": "You have a scenario where you need to negate a Boolean value, flipping it from true to false or vice versa. Which operator would you use?",
        "options": [
            "A) &&",
            "B) ||",
            "C) !",
            "D) ?:"
        ],
        "correctAnswer": "C) !",
        "explanation": "The '!' operator negates a Boolean value, flipping true to false and false to true.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Arsenal of Operators - Mastering Operations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 40,
        "questionText": "Which of the following operators is used to compare two values for equality in Kotlin?",
        "options": [
            "A) ==",
            "B) =",
            "C) !=",
            "D) ==="
        ],
        "correctAnswer": "A) ==",
        "explanation": "The '==' operator checks if two values are equal, making it the correct choice for comparison.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Arsenal of Operators - Mastering Operations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 41,
        "questionText": "You're performing a bitwise operation where you need to shift the bits of a number to the left by 2 positions. Which operator would you use?",
        "options": [
            "A) >>>",
            "B) <<",
            "C) >>",
            "D) &"
        ],
        "correctAnswer": "B) <<",
        "explanation": "The '<<' operator shifts the bits of a number to the left by the specified number of positions.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Arsenal of Operators - Mastering Operations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 42,
        "questionText": "In Kotlin, which operator can be overloaded to change its behavior for custom classes?",
        "options": [
            "A) +",
            "B) &",
            "C) =",
            "D) !"
        ],
        "correctAnswer": "A) +",
        "explanation": "In Kotlin, the '+' operator can be overloaded to define custom behavior when used with instances of a custom class.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Arsenal of Operators - Mastering Operations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 43,
        "questionText": "Which operator is used to assign a value to a variable in Kotlin?",
        "options": [
            "A) ==",
            "B) =",
            "C) :=",
            "D) =>"
        ],
        "correctAnswer": "B) =",
        "explanation": "The '=' operator is used to assign values to variables in Kotlin.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Arsenal of Operators - Mastering Operations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 44,
        "questionText": "How would you check if a number is both positive and even in Kotlin using logical operators?",
        "options": [
            "A) if (number > 0 && number % 2 == 0)",
            "B) if (number > 0 || number % 2 == 0)",
            "C) if (!(number > 0 && number % 2 == 0))",
            "D) if (number > 0 && number % 2 != 0)"
        ],
        "correctAnswer": "A) if (number > 0 && number % 2 == 0)",
        "explanation": "The '&&' operator checks if both conditions are true, making it appropriate for ensuring the number is positive and even.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Arsenal of Operators - Mastering Operations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 45,
        "questionText": "You're working on a feature that requires you to compare the memory addresses of two objects in Kotlin. Which operator would you use?",
        "options": [
            "A) ==",
            "B) ===",
            "C) =",
            "D) !="
        ],
        "correctAnswer": "B) ===",
        "explanation": "The '===' operator compares the memory addresses of two objects to determine if they refer to the same instance.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Arsenal of Operators - Mastering Operations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 46,
        "questionText": "Which operator would you use in Kotlin to add two numbers and assign the result to the first number?",
        "options": [
            "A) +",
            "B) ++",
            "C) +=",
            "D) -="
        ],
        "correctAnswer": "C) +=",
        "explanation": "The '+=' operator adds the right-hand value to the left-hand variable and assigns the result to the left-hand variable.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Arsenal of Operators - Mastering Operations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 47,
        "questionText": "You need to check if a number is not equal to 10 in Kotlin. Which operator will you use?",
        "options": [
            "A) ==",
            "B) !=",
            "C) !==",
            "D) =="
        ],
        "correctAnswer": "B) !=",
        "explanation": "The '!=' operator checks if two values are not equal, making it suitable for this condition.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Arsenal of Operators - Mastering Operations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 48,
        "questionText": "In Kotlin, how would you subtract 5 from a variable 'x' and store the result in 'x'?",
        "options": [
            "A) x =- 5",
            "B) x - 5 = x",
            "C) x -= 5",
            "D) x = -5"
        ],
        "correctAnswer": "C) x -= 5",
        "explanation": "The '-=' operator subtracts the right-hand value from the left-hand variable and assigns the result to the left-hand variable.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Arsenal of Operators - Mastering Operations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 49,
        "questionText": "Which operator would you use to invert the bits of a binary number in Kotlin?",
        "options": [
            "A) ~",
            "B) !",
            "C) -",
            "D) ++"
        ],
        "correctAnswer": "A) ~",
        "explanation": "The '~' operator inverts the bits of a binary number, flipping all 0s to 1s and all 1s to 0s.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Arsenal of Operators - Mastering Operations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 50,
        "questionText": "You want to check if a value is within a certain range in Kotlin. Which operator would be useful in this scenario?",
        "options": [
            "A) &&",
            "B) ||",
            "C) in",
            "D) is"
        ],
        "correctAnswer": "C) in",
        "explanation": "The 'in' operator checks if a value belongs to a specified range or collection, making it ideal for range checks.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Arsenal of Operators - Mastering Operations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 51,
        "questionText": "You need to execute a block of code only if a certain condition is true. Which control flow statement would you use?",
        "options": [
            "A) for",
            "B) while",
            "C) if",
            "D) when"
        ],
        "correctAnswer": "C) if",
        "explanation": "The 'if' statement is used to execute a block of code only if a specified condition is true.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Maze of Decisions - Mastering Control Flow in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 52,
        "questionText": "Which statement would you use in Kotlin to choose between multiple conditions and execute the corresponding block of code?",
        "options": [
            "A) if-else",
            "B) switch",
            "C) when",
            "D) try-catch"
        ],
        "correctAnswer": "C) when",
        "explanation": "The 'when' statement in Kotlin is used to choose between multiple conditions, similar to a switch-case in other languages, but more powerful and flexible.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Maze of Decisions - Mastering Control Flow in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 53,
        "questionText": "You need to iterate over a collection of items in Kotlin. Which loop would you typically use?",
        "options": [
            "A) for",
            "B) while",
            "C) do-while",
            "D) when"
        ],
        "correctAnswer": "A) for",
        "explanation": "The 'for' loop is commonly used to iterate over collections or ranges in Kotlin.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Maze of Decisions - Mastering Control Flow in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 54,
        "questionText": "Which loop structure in Kotlin is guaranteed to execute the block of code at least once?",
        "options": [
            "A) for",
            "B) while",
            "C) do-while",
            "D) when"
        ],
        "correctAnswer": "C) do-while",
        "explanation": "The 'do-while' loop executes the block of code once before checking the condition, ensuring the block runs at least once.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Maze of Decisions - Mastering Control Flow in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 55,
        "questionText": "You need to iterate from 1 to 10 in Kotlin. Which of the following range expressions would you use?",
        "options": [
            "A) 1..10",
            "B) 1 until 10",
            "C) 1 downTo 10",
            "D) 1 until 11"
        ],
        "correctAnswer": "A) 1..10",
        "explanation": "The '1..10' expression creates a range from 1 to 10, inclusive, which can be used in loops.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Maze of Decisions - Mastering Control Flow in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 56,
        "questionText": "Which operator would you use in Kotlin to reverse the iteration direction from 10 down to 1?",
        "options": [
            "A) ..",
            "B) until",
            "C) downTo",
            "D) step"
        ],
        "correctAnswer": "C) downTo",
        "explanation": "The 'downTo' operator is used to create a range that iterates in reverse order, from a higher number down to a lower one.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Maze of Decisions - Mastering Control Flow in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 57,
        "questionText": "You want to skip the current iteration of a loop in Kotlin if a certain condition is met. Which keyword would you use?",
        "options": [
            "A) break",
            "B) continue",
            "C) return",
            "D) skip"
        ],
        "correctAnswer": "B) continue",
        "explanation": "The 'continue' keyword skips the current iteration of a loop and moves to the next iteration.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Maze of Decisions - Mastering Control Flow in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 58,
        "questionText": "In a nested loop scenario, you want to exit both the inner and outer loops when a condition is met. Which technique would you use in Kotlin?",
        "options": [
            "A) Use a single break",
            "B) Use a labeled break",
            "C) Use return",
            "D) Use continue"
        ],
        "correctAnswer": "B) Use a labeled break",
        "explanation": "A labeled break allows you to break out of an outer loop from within an inner loop in Kotlin.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Maze of Decisions - Mastering Control Flow in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 59,
        "questionText": "You need to return a value from a lambda expression in Kotlin before the lambda completes its execution. Which technique would you use?",
        "options": [
            "A) Use a labeled return",
            "B) Use an unlabeled return",
            "C) Use a break statement",
            "D) Use continue statement"
        ],
        "correctAnswer": "A) Use a labeled return",
        "explanation": "A labeled return is used to return from a lambda expression before it finishes executing, useful in inline functions.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Maze of Decisions - Mastering Control Flow in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 60,
        "questionText": "Which statement would you use to exit a loop immediately in Kotlin?",
        "options": [
            "A) break",
            "B) continue",
            "C) return",
            "D) exit"
        ],
        "correctAnswer": "A) break",
        "explanation": "The 'break' statement is used to exit a loop immediately, stopping further iterations.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Maze of Decisions - Mastering Control Flow in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 61,
        "questionText": "You want to iterate over a range with a specific step value in Kotlin, such as counting by twos. Which keyword would you use?",
        "options": [
            "A) for",
            "B) step",
            "C) until",
            "D) downTo"
        ],
        "correctAnswer": "B) step",
        "explanation": "The 'step' keyword is used in Kotlin to define the increment between each value in a range iteration.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Maze of Decisions - Mastering Control Flow in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 62,
        "questionText": "Which Kotlin keyword is used to create an infinite loop that will only stop based on a break condition?",
        "options": [
            "A) for",
            "B) while",
            "C) do-while",
            "D) loop"
        ],
        "correctAnswer": "B) while",
        "explanation": "A 'while' loop can create an infinite loop if the condition is always true, stopping only when a break condition is met.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Maze of Decisions - Mastering Control Flow in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 63,
        "questionText": "Which control flow statement in Kotlin is used to handle multiple possible values of a single expression?",
        "options": [
            "A) if-else",
            "B) when",
            "C) switch",
            "D) try-catch"
        ],
        "correctAnswer": "B) when",
        "explanation": "The 'when' statement handles multiple possible values of an expression, allowing more concise and readable code than multiple 'if-else' statements.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Maze of Decisions - Mastering Control Flow in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 64,
        "questionText": "You want to iterate over a list of names and print each name in Kotlin. Which control flow statement would you use?",
        "options": [
            "A) for",
            "B) while",
            "C) do-while",
            "D) if-else"
        ],
        "correctAnswer": "A) for",
        "explanation": "A 'for' loop is ideal for iterating over collections such as lists, making it easy to access and print each element.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Maze of Decisions - Mastering Control Flow in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 65,
        "questionText": "Which statement would you use in Kotlin to exit a function early and return a value?",
        "options": [
            "A) break",
            "B) continue",
            "C) return",
            "D) exit"
        ],
        "correctAnswer": "C) return",
        "explanation": "The 'return' statement is used to exit a function early and optionally return a value to the caller.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Maze of Decisions - Mastering Control Flow in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 66,
        "questionText": "You need to check multiple conditions in a sequence where only one condition will execute its block if true. Which statement would you use?",
        "options": [
            "A) if-else",
            "B) when",
            "C) switch",
            "D) try-catch"
        ],
        "correctAnswer": "A) if-else",
        "explanation": "The 'if-else' statement is used to check multiple conditions in sequence, executing the block of the first true condition.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Maze of Decisions - Mastering Control Flow in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 67,
        "questionText": "In a nested loop scenario, you want to continue the outer loop when a condition in the inner loop is met. Which Kotlin feature would you use?",
        "options": [
            "A) continue",
            "B) break",
            "C) labeled continue",
            "D) return"
        ],
        "correctAnswer": "C) labeled continue",
        "explanation": "A labeled continue allows you to skip the remaining part of the current iteration of the outer loop from within an inner loop in Kotlin.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Maze of Decisions - Mastering Control Flow in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 68,
        "questionText": "You need to perform an action for each element in a range, but the loop should terminate if a specific value is found. Which statement combination would be appropriate?",
        "options": [
            "A) for and return",
            "B) for and break",
            "C) while and continue",
            "D) while and return"
        ],
        "correctAnswer": "B) for and break",
        "explanation": "Using 'for' to iterate over the range and 'break' to exit the loop if the specific value is found is the correct approach.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Maze of Decisions - Mastering Control Flow in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 69,
        "questionText": "You want to iterate over a list of numbers, but only even numbers should be processed. Which Kotlin technique would help?",
        "options": [
            "A) for with a condition",
            "B) filter and for",
            "C) while with a condition",
            "D) step with a for loop"
        ],
        "correctAnswer": "B) filter and for",
        "explanation": "Using 'filter' to select only even numbers and then iterating over them with a 'for' loop is the optimal approach.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Maze of Decisions - Mastering Control Flow in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 70,
        "questionText": "Which Kotlin loop allows you to iterate indefinitely until a break condition is explicitly provided within the loop body?",
        "options": [
            "A) for",
            "B) while",
            "C) do-while",
            "D) infinite"
        ],
        "correctAnswer": "B) while",
        "explanation": "A 'while' loop continues indefinitely as long as the condition is true, and it requires a break condition within the loop to terminate.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Maze of Decisions - Mastering Control Flow in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 71,
        "questionText": "What is the correct syntax to define a simple function in Kotlin that prints 'Hello, World!'?",
        "options": [
            "A) fun helloWorld() { print(\"Hello, World!\") }",
            "B) def helloWorld() { println(\"Hello, World!\") }",
            "C) function helloWorld() { println(\"Hello, World!\") }",
            "D) void helloWorld() { println(\"Hello, World!\") }"
        ],
        "correctAnswer": "A) fun helloWorld() { print(\"Hello, World!\") }",
        "explanation": "In Kotlin, the 'fun' keyword is used to define a function, followed by the function name and the function body within curly braces.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 72,
        "questionText": "How do you specify a return type for a function in Kotlin?",
        "options": [
            "A) fun add(a: Int, b: Int) -> Int",
            "B) fun add(a: Int, b: Int): Int",
            "C) fun add(a: Int, b: Int) returns Int",
            "D) fun add(a: Int, b: Int): return Int"
        ],
        "correctAnswer": "B) fun add(a: Int, b: Int): Int",
        "explanation": "In Kotlin, the return type of a function is specified after the parameter list, following a colon.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 73,
        "questionText": "Which of the following functions uses default arguments?",
        "options": [
            "A) fun greet(name: String = \"Guest\") { println(\"Hello, $name\") }",
            "B) fun greet(name: String) { println(\"Hello, $name\") }",
            "C) fun greet(name: String): String { return \"Hello, $name\" }",
            "D) fun greet(name: String): Unit { println(\"Hello, $name\") }"
        ],
        "correctAnswer": "A) fun greet(name: String = \"Guest\") { println(\"Hello, $name\") }",
        "explanation": "In Kotlin, you can provide a default value for function parameters, which will be used if no argument is passed.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 74,
        "questionText": "How can you call a function with named arguments in Kotlin?",
        "options": [
            "A) greet(name = \"Alice\", age = 30)",
            "B) greet(Alice, 30)",
            "C) greet(name -> \"Alice\", age -> 30)",
            "D) greet(30, \"Alice\")"
        ],
        "correctAnswer": "A) greet(name = \"Alice\", age = 30)",
        "explanation": "Named arguments allow you to specify which parameter each argument belongs to, making the function call more readable.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 75,
        "questionText": "What is a single-expression function in Kotlin?",
        "options": [
            "A) A function that has a single parameter",
            "B) A function that only returns a single value",
            "C) A function that consists of a single expression and returns the result",
            "D) A function that is declared in a single line"
        ],
        "correctAnswer": "C) A function that consists of a single expression and returns the result",
        "explanation": "Single-expression functions in Kotlin consist of a single expression and return its result. The return type is inferred if not explicitly specified.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 76,
        "questionText": "How do you declare a higher-order function in Kotlin?",
        "options": [
            "A) fun operate(a: Int, b: Int): Int",
            "B) fun operate(a: Int, b: Int, operation: (Int, Int) -> Int): Int",
            "C) fun operate(a: Int, b: Int): (Int, Int) -> Int",
            "D) fun operate(a: Int, b: Int, operation: Int): (Int, Int) -> Int"
        ],
        "correctAnswer": "B) fun operate(a: Int, b: Int, operation: (Int, Int) -> Int): Int",
        "explanation": "A higher-order function is one that takes another function as a parameter or returns a function as a result.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 77,
        "questionText": "What is a lambda expression in Kotlin?",
        "options": [
            "A) A named function that can be passed as an argument",
            "B) A small anonymous function that can be passed as an argument",
            "C) A function that is always inline",
            "D) A recursive function"
        ],
        "correctAnswer": "B) A small anonymous function that can be passed as an argument",
        "explanation": "Lambda expressions in Kotlin are small, anonymous functions that can be passed as arguments to higher-order functions or stored in variables.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 78,
        "questionText": "Which keyword is used to declare an inline function in Kotlin?",
        "options": [
            "A) inline",
            "B) fun",
            "C) inlineFun",
            "D) function"
        ],
        "correctAnswer": "A) inline",
        "explanation": "The 'inline' keyword is used to declare inline functions in Kotlin, which are functions that are expanded at the call site, reducing the overhead of function calls.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 79,
        "questionText": "What is tail recursion in Kotlin?",
        "options": [
            "A) A type of recursion where the recursive call is the last operation in the function",
            "B) A recursion that is automatically optimized by the Kotlin compiler",
            "C) A function that calls itself multiple times",
            "D) A recursive function that uses less memory"
        ],
        "correctAnswer": "A) A type of recursion where the recursive call is the last operation in the function",
        "explanation": "Tail recursion is a special form of recursion where the recursive call is the last operation in the function. Kotlin can optimize tail-recursive functions to avoid stack overflow errors.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
    },
    {
        "id": 80,
        "questionText": "Which of the following statements correctly uses a lambda expression?",
        "options": [
            "A) val sum = { a, b -> a + b }",
            "B) val sum = (a, b) => a + b",
            "C) val sum = (a, b) -> { a + b }",
            "D) val sum = (a, b) -> return a + b"
        ],
        "correctAnswer": "A) val sum = { a, b -> a + b }",
        "explanation": "In Kotlin, lambda expressions are defined using curly braces, with parameters followed by the '->' symbol and the expression.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 81,
        "questionText": "How can you call an inline function in Kotlin?",
        "options": [
            "A) Just like any other function",
            "B) Using the 'inline' keyword at the call site",
            "C) By passing it as an argument to another function",
            "D) You cannot call an inline function directly"
        ],
        "correctAnswer": "A) Just like any other function",
        "explanation": "Inline functions are called just like any other function in Kotlin. The difference is that their code is expanded at the call site, reducing function call overhead.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 82,
        "questionText": "What is the result of the following function call? 'sum(3, 5)' if 'fun sum(a: Int, b: Int) = a + b'?",
        "options": [
            "A) 35",
            "B) 8",
            "C) 15",
            "D) 53"
        ],
        "correctAnswer": "B) 8",
        "explanation": "The function 'sum' adds two integers, so 'sum(3, 5)' results in 8.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 83,
        "questionText": "Which of the following is an example of a higher-order function?",
        "options": [
            "A) fun multiply(a: Int, b: Int): Int",
            "B) fun processString(str: String, process: (String) -> String): String",
            "C) fun getFullName(firstName: String, lastName: String): String",
            "D) fun showWelcomeMessage(message: String): Unit"
        ],
        "correctAnswer": "B) fun processString(str: String, process: (String) -> String): String",
        "explanation": "A higher-order function is one that takes another function as a parameter or returns a function as a result.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 84,
        "questionText": "What is the primary benefit of using named arguments in Kotlin?",
        "options": [
            "A) It reduces the number of parameters required",
            "B) It increases the function's readability and flexibility",
            "C) It allows the function to return multiple values",
            "D) It enables the use of inline functions"
        ],
        "correctAnswer": "B) It increases the function's readability and flexibility",
        "explanation": "Named arguments in Kotlin increase the readability of function calls by allowing you to specify which parameter each argument corresponds to, regardless of the order.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 85,
        "questionText": "Which Kotlin function type represents a function that takes two Int parameters and returns an Int?",
        "options": [
            "A) (Int, Int) -> Unit",
            "B) (Int) -> Int",
            "C) (Int, Int) -> Int",
            "D) Int -> (Int, Int)"
        ],
        "correctAnswer": "C) (Int, Int) -> Int",
        "explanation": "In Kotlin, '(Int, Int) -> Int' represents a function that takes two Int parameters and returns an Int.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 86,
        "questionText": "Which of the following functions is tail-recursive?",
        "options": [
            "A) fun factorial(n: Int): Int { return n * factorial(n - 1) }",
            "B) fun factorial(n: Int): Int { return if (n <= 1) 1 else n * factorial(n - 1) }",
            "C) tailrec fun factorial(n: Int, acc: Int = 1): Int { return if (n <= 1) acc else factorial(n - 1, n * acc) }",
            "D) fun factorial(n: Int): Int { return n * (n - 1) }"
        ],
        "correctAnswer": "C) tailrec fun factorial(n: Int, acc: Int = 1): Int { return if (n <= 1) acc else factorial(n - 1, n * acc) }",
        "explanation": "The 'tailrec' keyword in Kotlin indicates that the function is tail-recursive, meaning the recursive call is the last operation in the function.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
    },
    {
        "id": 87,
        "questionText": "In Kotlin, how do you define a function that takes a lambda as a parameter?",
        "options": [
            "A) fun execute(lambda: () -> Unit)",
            "B) fun execute(lambda: () => Unit)",
            "C) fun execute(lambda: () -> Void)",
            "D) fun execute(lambda: Unit -> Void)"
        ],
        "correctAnswer": "A) fun execute(lambda: () -> Unit)",
        "explanation": "In Kotlin, '() -> Unit' represents a lambda that takes no parameters and returns no value (Unit).",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 88,
        "questionText": "Which of the following best describes the purpose of inline functions in Kotlin?",
        "options": [
            "A) To make functions easier to read",
            "B) To reduce the overhead of function calls",
            "C) To ensure that functions are executed only once",
            "D) To automatically infer return types"
        ],
        "correctAnswer": "B) To reduce the overhead of function calls",
        "explanation": "Inline functions in Kotlin are used to reduce the overhead of function calls by expanding the function's code at the call site.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 89,
        "questionText": "Which of the following Kotlin functions is equivalent to the following expression: 'a: Int = 5; b: Int = 10; return a + b'?",
        "options": [
            "A) fun sum(a: Int = 5, b: Int = 10): Int = a + b",
            "B) fun sum(a: Int = 5, b: Int = 10): Int { return a + b }",
            "C) fun sum(a: Int = 5, b: Int = 10) = a + b",
            "D) All of the above"
        ],
        "correctAnswer": "D) All of the above",
        "explanation": "All three function declarations are valid and will return the sum of 5 and 10, resulting in 15.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": true
    },
    {
        "id": 90,
        "questionText": "Which statement is true about higher-order functions in Kotlin?",
        "options": [
            "A) They can only take functions as parameters but cannot return functions",
            "B) They can only return functions but cannot take functions as parameters",
            "C) They can neither take nor return functions",
            "D) They can both take functions as parameters and return functions"
        ],
        "correctAnswer": "D) They can both take functions as parameters and return functions",
        "explanation": "Higher-order functions in Kotlin can both take functions as parameters and return functions as their result, providing great flexibility in code.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
    },
    {
        "id": 91,
        "questionText": "What does the following Kotlin code snippet do? 'fun String.repeat(n: Int): String { return this.repeat(n) }'",
        "options": [
            "A) Repeats the String n times and returns the concatenated result",
            "B) Repeats the String n times and prints the result",
            "C) Repeats the last character of the String n times",
            "D) Repeats the String in reverse order n times"
        ],
        "correctAnswer": "A) Repeats the String n times and returns the concatenated result",
        "explanation": "This function repeats the string 'n' times and returns the concatenated result. 'this' refers to the String on which the function is called.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 92,
        "questionText": "Which of the following is a valid Kotlin function that uses a lambda expression?",
        "options": [
            "A) fun applyOperation(a: Int, operation: (Int) -> Int): Int = operation(a)",
            "B) fun applyOperation(a: Int, operation: (Int) => Int): Int = operation(a)",
            "C) fun applyOperation(a: Int, operation: (Int) -> Int): Int { return operation(a) }",
            "D) Both A and C"
        ],
        "correctAnswer": "D) Both A and C",
        "explanation": "Both options A and C define a valid Kotlin function that takes a lambda expression as a parameter and applies it to the input.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": true
    },
    {
        "id": 93,
        "questionText": "What is the return type of the following function? 'fun getGreeting(): String = \"Hello, World!\"'",
        "options": [
            "A) Unit",
            "B) String",
            "C) Void",
            "D) None of the above"
        ],
        "correctAnswer": "B) String",
        "explanation": "The return type of the function is explicitly specified as 'String', and the function returns the string 'Hello, World!'.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 94,
        "questionText": "Which of the following code snippets defines an inline function in Kotlin?",
        "options": [
            "A) inline fun calculate(a: Int, b: Int): Int { return a + b }",
            "B) fun calculate(a: Int, b: Int): Int = a + b",
            "C) fun calculate(a: Int, b: Int): Int { return a + b }",
            "D) inline fun calculate(a: Int, b: Int): Int = a + b"
        ],
        "correctAnswer": "D) inline fun calculate(a: Int, b: Int): Int = a + b",
        "explanation": "Option D defines an inline function using the 'inline' keyword. This reduces function call overhead by expanding the function at the call site.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 95,
        "questionText": "What is the purpose of the 'tailrec' keyword in Kotlin?",
        "options": [
            "A) To mark a function as recursive",
            "B) To mark a recursive function as tail-recursive for optimization",
            "C) To prevent a function from being inline",
            "D) To optimize the return type of a function"
        ],
        "correctAnswer": "B) To mark a recursive function as tail-recursive for optimization",
        "explanation": "The 'tailrec' keyword is used in Kotlin to optimize tail-recursive functions by transforming them into loops, avoiding potential stack overflow errors.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
    },
    {
        "id": 96,
        "questionText": "Which of the following functions returns the square of an integer using a single-expression function?",
        "options": [
            "A) fun square(x: Int): Int = x * x",
            "B) fun square(x: Int): Int { return x * x }",
            "C) fun square(x: Int): Int => x * x",
            "D) fun square(x: Int) = x * x"
        ],
        "correctAnswer": "A) fun square(x: Int): Int = x * x",
        "explanation": "Option A defines a single-expression function that returns the square of an integer. The return type is inferred from the expression.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 97,
        "questionText": "In Kotlin, what is the correct way to define a function that takes a lambda expression as an argument and applies it to an integer?",
        "options": [
            "A) fun applyToInt(n: Int, f: (Int) -> Int): Int = f(n)",
            "B) fun applyToInt(n: Int, f: (Int) => Int): Int = f(n)",
            "C) fun applyToInt(n: Int, f: Int => Int): Int = f(n)",
            "D) fun applyToInt(n: Int, f: (Int) -> Int): Int { return f(n) }"
        ],
        "correctAnswer": "A) fun applyToInt(n: Int, f: (Int) -> Int): Int = f(n)",
        "explanation": "Option A defines a function that takes an integer and a lambda expression as parameters, applies the lambda to the integer, and returns the result.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 98,
        "questionText": "Which of the following best describes a lambda expression in Kotlin?",
        "options": [
            "A) A function that returns Unit",
            "B) A function with multiple return types",
            "C) A small anonymous function that can be passed as an argument",
            "D) A function that uses tail recursion"
        ],
        "correctAnswer": "C) A small anonymous function that can be passed as an argument",
        "explanation": "Lambda expressions in Kotlin are small anonymous functions that can be passed as arguments to other functions or stored in variables.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 99,
        "questionText": "Which of the following is an example of a tail-recursive function in Kotlin?",
        "options": [
            "A) tailrec fun factorial(n: Int, acc: Int = 1): Int { return if (n <= 1) acc else factorial(n - 1, n * acc) }",
            "B) tailrec fun factorial(n: Int): Int { return n * factorial(n - 1) }",
            "C) fun factorial(n: Int, acc: Int = 1): Int { return if (n <= 1) acc else factorial(n - 1, n * acc) }",
            "D) fun factorial(n: Int): Int { return n * (n - 1) }"
        ],
        "correctAnswer": "A) tailrec fun factorial(n: Int, acc: Int = 1): Int { return if (n <= 1) acc else factorial(n - 1, n * acc) }",
        "explanation": "Option A is a tail-recursive function because the recursive call is the last operation in the function, and the function is marked with the 'tailrec' keyword for optimization.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
    },
    {
        "id": 100,
        "questionText": "What does the 'inline' keyword do when used in a Kotlin function?",
        "options": [
            "A) It prevents the function from being called recursively",
            "B) It optimizes the function by expanding its code at the call site",
            "C) It forces the function to return a value",
            "D) It marks the function as deprecated"
        ],
        "correctAnswer": "B) It optimizes the function by expanding its code at the call site",
        "explanation": "The 'inline' keyword in Kotlin optimizes a function by expanding its code directly at the call site, reducing the overhead of function calls.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 101,
        "questionText": "Which of the following is an advantage of using higher-order functions in Kotlin?",
        "options": [
            "A) They make the code more concise and reusable",
            "B) They automatically improve the performance of the application",
            "C) They enforce strict type safety",
            "D) They eliminate the need for recursion"
        ],
        "correctAnswer": "A) They make the code more concise and reusable",
        "explanation": "Higher-order functions in Kotlin allow for more concise and reusable code by enabling functions to take other functions as parameters or return them as results.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 102,
        "questionText": "How do you declare a Kotlin function with a default parameter value?",
        "options": [
            "A) fun greet(name: String = \"Guest\")",
            "B) fun greet(name: String?): String",
            "C) fun greet(name: String = \"Guest\"): String",
            "D) Both A and C"
        ],
        "correctAnswer": "D) Both A and C",
        "explanation": "In Kotlin, functions can have default parameter values, making them optional during function calls. Options A and C are both valid ways to declare such a function.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": true
    },
    {
        "id": 103,
        "questionText": "Which keyword in Kotlin is used to declare a tail-recursive function?",
        "options": [
            "A) inline",
            "B) tailrec",
            "C) recursive",
            "D) recur"
        ],
        "correctAnswer": "B) tailrec",
        "explanation": "The 'tailrec' keyword is used in Kotlin to declare a tail-recursive function, allowing the compiler to optimize it into a loop.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
    },
    {
        "id": 104,
        "questionText": "What is the correct way to define an inline function that multiplies two integers in Kotlin?",
        "options": [
            "A) inline fun multiply(a: Int, b: Int): Int = a * b",
            "B) fun multiply(a: Int, b: Int): Int { return a * b }",
            "C) inline fun multiply(a: Int, b: Int) = a * b",
            "D) Both A and C"
        ],
        "correctAnswer": "D) Both A and C",
        "explanation": "Options A and C define an inline function that multiplies two integers. The 'inline' keyword helps optimize the function by expanding it at the call site.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": true
    },
    {
        "id": 105,
        "questionText": "Which of the following best describes the purpose of a lambda expression in Kotlin?",
        "options": [
            "A) To create a small, anonymous function that can be passed as an argument",
            "B) To define a recursive function",
            "C) To optimize the performance of loops",
            "D) To return multiple values from a function"
        ],
        "correctAnswer": "A) To create a small, anonymous function that can be passed as an argument",
        "explanation": "Lambda expressions in Kotlin are small, anonymous functions that can be passed as arguments to other functions or stored in variables.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Spellbook of Functions - Crafting Powerful Incantations in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 106,
        "questionText": "What is the correct way to declare a class in Kotlin?",
        "options": [
            "A) class MyClass {}",
            "B) MyClass class {}",
            "C) class MyClass()",
            "D) MyClass() class"
        ],
        "correctAnswer": "A) class MyClass {}",
        "explanation": "In Kotlin, classes are declared using the 'class' keyword followed by the class name and curly braces.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 107,
        "questionText": "Which of the following is true about primary constructors in Kotlin?",
        "options": [
            "A) A class can have multiple primary constructors.",
            "B) The primary constructor is defined in the class header.",
            "C) Primary constructors cannot take parameters.",
            "D) Primary constructors must be defined inside the class body."
        ],
        "correctAnswer": "B) The primary constructor is defined in the class header.",
        "explanation": "In Kotlin, the primary constructor is defined in the class header and is the main constructor for initializing a class.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 108,
        "questionText": "How do you declare a property in Kotlin with a default getter and setter?",
        "options": [
            "A) var name: String = \"Mayank\"",
            "B) val name: String by lazy {}",
            "C) lateinit var name: String",
            "D) const val name: String = \"Mayank\""
        ],
        "correctAnswer": "A) var name: String = \"Mayank\"",
        "explanation": "Option A correctly declares a mutable property with a default getter and setter.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 109,
        "questionText": "Which of the following is an example of a secondary constructor in Kotlin?",
        "options": [
            "A) constructor(name: String) : this()",
            "B) fun MyClass(name: String): MyClass {}",
            "C) init { name = \"Mayank\" }",
            "D) fun MyClass() { this.name = name }"
        ],
        "correctAnswer": "A) constructor(name: String) : this()",
        "explanation": "A secondary constructor in Kotlin is defined with the 'constructor' keyword and can delegate to the primary constructor using 'this()'.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 110,
        "questionText": "What is the main difference between 'val' and 'var' properties in a Kotlin class?",
        "options": [
            "A) 'val' properties are mutable, while 'var' properties are immutable.",
            "B) 'val' properties are initialized once, while 'var' properties can be reassigned.",
            "C) 'val' properties cannot have getters, while 'var' properties can.",
            "D) There is no difference between 'val' and 'var'."
        ],
        "correctAnswer": "B) 'val' properties are initialized once, while 'var' properties can be reassigned.",
        "explanation": "In Kotlin, 'val' properties are immutable and can only be assigned once, while 'var' properties are mutable and can be reassigned.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 111,
        "questionText": "How do you define an abstract class in Kotlin?",
        "options": [
            "A) abstract class MyClass",
            "B) class MyClass: Abstract",
            "C) class MyClass abstract",
            "D) abstract class MyClass {}"
        ],
        "correctAnswer": "D) abstract class MyClass {}",
        "explanation": "Option D is correct because 'abstract class' is the correct syntax for defining an abstract class in Kotlin.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 112,
        "questionText": "Which of the following best describes a data class in Kotlin?",
        "options": [
            "A) A class that contains only data properties",
            "B) A class that automatically generates equals, hashCode, and toString methods",
            "C) A class that must be abstract",
            "D) A class that cannot inherit from another class"
        ],
        "correctAnswer": "B) A class that automatically generates equals, hashCode, and toString methods",
        "explanation": "Data classes in Kotlin automatically generate methods like equals, hashCode, and toString, making them ideal for holding data.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 113,
        "questionText": "In Kotlin, what is the purpose of the 'companion object'?",
        "options": [
            "A) To create multiple instances of an object",
            "B) To define static members of a class",
            "C) To provide a secondary constructor",
            "D) To allow inheritance in Kotlin"
        ],
        "correctAnswer": "B) To define static members of a class",
        "explanation": "A companion object in Kotlin is used to define static members that belong to the class itself, rather than to instances of the class.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 114,
        "questionText": "Which of the following keywords is used to inherit from a superclass in Kotlin?",
        "options": [
            "A) extend",
            "B) inherit",
            "C) open",
            "D) super"
        ],
        "correctAnswer": "C) open",
        "explanation": "The 'open' keyword in Kotlin allows a class to be inherited from, which is necessary because classes are final by default.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 115,
        "questionText": "What is a sealed class in Kotlin?",
        "options": [
            "A) A class that is final and cannot be inherited",
            "B) A class that restricts its subclasses to a defined set",
            "C) A class that can be instantiated multiple times",
            "D) A class that contains only abstract methods"
        ],
        "correctAnswer": "B) A class that restricts its subclasses to a defined set",
        "explanation": "A sealed class in Kotlin restricts which classes can inherit from it, providing more control over inheritance hierarchies.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
    },
    {
        "id": 116,
        "questionText": "Which keyword in Kotlin is used to declare a method that does not belong to any object?",
        "options": [
            "A) static",
            "B) companion",
            "C) top-level",
            "D) object"
        ],
        "correctAnswer": "C) top-level",
        "explanation": "In Kotlin, a top-level function is defined outside of any class or object, making it accessible without instantiating a class.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 117,
        "questionText": "In Kotlin, what is the difference between 'nested classes' and 'inner classes'?",
        "options": [
            "A) Nested classes can access outer class members, while inner classes cannot.",
            "B) Inner classes can access outer class members, while nested classes cannot.",
            "C) There is no difference between nested and inner classes in Kotlin.",
            "D) Inner classes are declared with the 'open' keyword, while nested classes are not."
        ],
        "correctAnswer": "B) Inner classes can access outer class members, while nested classes cannot.",
        "explanation": "In Kotlin, an inner class is declared with the 'inner' keyword and has access to the outer class's members. A nested class does not.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
    },
    {
        "id": 118,
        "questionText": "Which of the following statements about Kotlin's object declarations is true?",
        "options": [
            "A) Object declarations create a new instance every time they are called.",
            "B) Object declarations cannot contain methods.",
            "C) Object declarations create a singleton.",
            "D) Object declarations are equivalent to Java classes."
        ],
        "correctAnswer": "C) Object declarations create a singleton.",
        "explanation": "In Kotlin, object declarations are used to create singletons, meaning only one instance of the object exists.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 119,
        "questionText": "How do you create a default parameter for a function in a Kotlin class?",
        "options": [
            "A) fun greet(name: String = \"Mayank\") { println(name) }",
            "B) fun greet(name: String) { name = \"Mayank\" }",
            "C) fun greet(name) { println(\"Mayank\") }",
            "D) fun greet(name: String? = null) { println(name) }"
        ],
        "correctAnswer": "A) fun greet(name: String = \"Mayank\") { println(name) }",
        "explanation": "In Kotlin, default parameter values are provided directly in the function signature, as shown in option A.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 120,
        "questionText": "What does the 'open' keyword do in Kotlin?",
        "options": [
            "A) It allows a class to be inherited from.",
            "B) It makes a class abstract.",
            "C) It makes a class final.",
            "D) It creates a singleton object."
        ],
        "correctAnswer": "A) It allows a class to be inherited from.",
        "explanation": "In Kotlin, classes are final by default. The 'open' keyword is used to make a class inheritable.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 121,
        "questionText": "Which of the following best describes the use of a 'lateinit' variable in Kotlin?",
        "options": [
            "A) It is used for immutable variables that are initialized later.",
            "B) It is used for mutable variables that are initialized later.",
            "C) It is used for variables that are constants.",
            "D) It is used for creating singletons."
        ],
        "correctAnswer": "B) It is used for mutable variables that are initialized later.",
        "explanation": "'lateinit' is used in Kotlin for mutable variables that will be initialized later but must be initialized before being accessed.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 122,
        "questionText": "Which statement about Kotlin interfaces is true?",
        "options": [
            "A) Interfaces cannot contain properties.",
            "B) Interfaces can contain both abstract methods and concrete methods.",
            "C) Interfaces must be implemented by abstract classes.",
            "D) Interfaces are equivalent to abstract classes."
        ],
        "correctAnswer": "B) Interfaces can contain both abstract methods and concrete methods.",
        "explanation": "Kotlin interfaces can contain both abstract methods, which must be implemented by the subclass, and concrete methods with a default implementation.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 123,
        "questionText": "In Kotlin, how can you ensure that a method in a superclass is overridden in a subclass?",
        "options": [
            "A) By using the 'final' keyword in the superclass method",
            "B) By using the 'override' keyword in the subclass method",
            "C) By using the 'open' keyword in the subclass method",
            "D) By using the 'abstract' keyword in the superclass"
        ],
        "correctAnswer": "B) By using the 'override' keyword in the subclass method",
        "explanation": "The 'override' keyword in Kotlin is used to indicate that a method in the subclass is intended to override a method in the superclass.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 124,
        "questionText": "Which of the following is true about the 'this' keyword in Kotlin?",
        "options": [
            "A) It refers to the superclass instance.",
            "B) It refers to the outer class in nested classes.",
            "C) It refers to the current instance of the class.",
            "D) It can be used to call the primary constructor."
        ],
        "correctAnswer": "C) It refers to the current instance of the class.",
        "explanation": "In Kotlin, 'this' refers to the current instance of the class and is used to access its members and methods.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 125,
        "questionText": "How do you declare a companion object in Kotlin?",
        "options": [
            "A) companion object MyObject",
            "B) object MyObject",
            "C) companion object {}",
            "D) object companion {}"
        ],
        "correctAnswer": "C) companion object {}",
        "explanation": "A companion object in Kotlin is declared with the 'companion object' keyword, allowing the creation of class-level properties and methods.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 126,
        "questionText": "What is the primary purpose of using 'init' blocks in Kotlin classes?",
        "options": [
            "A) To declare variables",
            "B) To initialize properties during object creation",
            "C) To handle exceptions",
            "D) To define secondary constructors"
        ],
        "correctAnswer": "B) To initialize properties during object creation",
        "explanation": "'init' blocks in Kotlin are used to initialize class properties and perform any setup required during object creation.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 127,
        "questionText": "Which of the following is a correct way to define a method in a Kotlin class?",
        "options": [
            "A) fun calculateSum(a: Int, b: Int) -> Int",
            "B) def calculateSum(a: Int, b: Int): Int",
            "C) function calculateSum(a: Int, b: Int): Int",
            "D) fun calculateSum(a: Int, b: Int): Int"
        ],
        "correctAnswer": "D) fun calculateSum(a: Int, b: Int): Int",
        "explanation": "In Kotlin, methods (functions) in a class are defined using the 'fun' keyword followed by the function name, parameters, and return type.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 128,
        "questionText": "What is a primary constructor in Kotlin, and how is it different from a secondary constructor?",
        "options": [
            "A) A primary constructor is defined inside the class body, while a secondary constructor is defined in the class header.",
            "B) A primary constructor can only initialize properties, while a secondary constructor can include additional logic.",
            "C) A primary constructor is defined in the class header, while a secondary constructor is defined inside the class body.",
            "D) There is no difference between a primary and a secondary constructor in Kotlin."
        ],
        "correctAnswer": "C) A primary constructor is defined in the class header, while a secondary constructor is defined inside the class body.",
        "explanation": "In Kotlin, the primary constructor is defined in the class header and is used for initializing class properties, while secondary constructors are defined inside the class body and can include additional logic.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 129,
        "questionText": "Which of the following correctly describes a nested class in Kotlin?",
        "options": [
            "A) A nested class is declared with the 'inner' keyword.",
            "B) A nested class cannot access the outer class's members.",
            "C) A nested class can only be declared within an interface.",
            "D) A nested class is equivalent to a top-level class."
        ],
        "correctAnswer": "B) A nested class cannot access the outer class's members.",
        "explanation": "In Kotlin, a nested class is declared within another class but does not have access to the members of the outer class unless it is declared as an inner class using the 'inner' keyword.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
    },
    {
        "id": 130,
        "questionText": "How do you declare an inner class in Kotlin that can access the outer class's properties and methods?",
        "options": [
            "A) class InnerClass",
            "B) inner class InnerClass",
            "C) open class InnerClass",
            "D) sealed class InnerClass"
        ],
        "correctAnswer": "B) inner class InnerClass",
        "explanation": "In Kotlin, an inner class is declared with the 'inner' keyword, allowing it to access the properties and methods of the outer class.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 131,
        "questionText": "Which of the following statements about sealed classes in Kotlin is true?",
        "options": [
            "A) Sealed classes can be instantiated directly.",
            "B) Sealed classes must have exactly one subclass.",
            "C) Sealed classes restrict which classes can inherit from them.",
            "D) Sealed classes cannot have abstract methods."
        ],
        "correctAnswer": "C) Sealed classes restrict which classes can inherit from them.",
        "explanation": "Sealed classes in Kotlin restrict which classes can inherit from them, providing a way to represent a restricted hierarchy of classes.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
    },
    {
        "id": 132,
        "questionText": "What is the correct way to define a method in a Kotlin interface?",
        "options": [
            "A) fun calculate(a: Int, b: Int): Int",
            "B) abstract fun calculate(a: Int, b: Int): Int",
            "C) def calculate(a: Int, b: Int): Int",
            "D) None of the above"
        ],
        "correctAnswer": "A) fun calculate(a: Int, b: Int): Int",
        "explanation": "In Kotlin, interface methods are declared using the 'fun' keyword without the need for the 'abstract' keyword, even though they are abstract by default.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 133,
        "questionText": "How do you declare an abstract property in an abstract class in Kotlin?",
        "options": [
            "A) abstract val name: String",
            "B) val abstract name: String",
            "C) var abstract name: String",
            "D) abstract name: String"
        ],
        "correctAnswer": "A) abstract val name: String",
        "explanation": "In Kotlin, abstract properties in an abstract class are declared with the 'abstract' keyword followed by 'val' or 'var'.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 134,
        "questionText": "What is the purpose of the 'object' keyword in Kotlin?",
        "options": [
            "A) To create a new instance of a class",
            "B) To declare a singleton",
            "C) To declare an interface",
            "D) To create an abstract class"
        ],
        "correctAnswer": "B) To declare a singleton",
        "explanation": "The 'object' keyword in Kotlin is used to declare a singleton, which ensures that only one instance of the object is created.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 135,
        "questionText": "Which of the following correctly demonstrates inheritance in Kotlin?",
        "options": [
            "A) class Dog: Animal()",
            "B) class Dog extends Animal()",
            "C) class Dog inherits Animal()",
            "D) class Dog open Animal()"
        ],
        "correctAnswer": "A) class Dog: Animal()",
        "explanation": "In Kotlin, the correct way to demonstrate inheritance is by using the ':' symbol followed by the superclass name.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 136,
        "questionText": "What is the difference between 'var' and 'val' in Kotlin when used in a class?",
        "options": [
            "A) 'var' is immutable, 'val' is mutable.",
            "B) 'var' is mutable, 'val' is immutable.",
            "C) There is no difference.",
            "D) 'var' is used for class properties, 'val' is used for function parameters."
        ],
        "correctAnswer": "B) 'var' is mutable, 'val' is immutable.",
        "explanation": "'var' is used to declare mutable properties in Kotlin, while 'val' is used to declare immutable properties.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 137,
        "questionText": "How do you define a method that must be overridden by subclasses in Kotlin?",
        "options": [
            "A) Use the 'abstract' keyword.",
            "B) Use the 'override' keyword.",
            "C) Use the 'open' keyword.",
            "D) Use the 'final' keyword."
        ],
        "correctAnswer": "A) Use the 'abstract' keyword.",
        "explanation": "In Kotlin, methods that must be overridden by subclasses are defined with the 'abstract' keyword.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 138,
        "questionText": "What is a companion object used for in Kotlin?",
        "options": [
            "A) To create a singleton instance of a class.",
            "B) To provide a way to implement static methods in a class.",
            "C) To create multiple instances of a class.",
            "D) To define constructors."
        ],
        "correctAnswer": "B) To provide a way to implement static methods in a class.",
        "explanation": "A companion object in Kotlin is used to implement static methods and properties within a class.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 139,
        "questionText": "Which of the following best describes the purpose of 'init' blocks in Kotlin?",
        "options": [
            "A) They are used to declare properties.",
            "B) They are used to initialize the class during object creation.",
            "C) They are used to override methods.",
            "D) They are used to define secondary constructors."
        ],
        "correctAnswer": "B) They are used to initialize the class during object creation.",
        "explanation": "'init' blocks in Kotlin are used to initialize properties and perform additional setup during the creation of an instance of a class.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 140,
        "questionText": "How do you define a data class in Kotlin?",
        "options": [
            "A) data class User(name: String, age: Int)",
            "B) class data User(name: String, age: Int)",
            "C) class User(data: String, age: Int)",
            "D) data User(name: String, age: Int)"
        ],
        "correctAnswer": "A) data class User(name: String, age: Int)",
        "explanation": "In Kotlin, data classes are defined using the 'data class' keyword followed by the class name and properties.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Epic of Mayank: Mastering Kotlin's Classes and Objects",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
    },
    {
        "id": 141,
        "questionText": "Which of the following correctly demonstrates a lazy property delegate in Kotlin?",
        "options": [
          "A) val lazyValue by lazy { expensiveComputation() }",
          "B) var lazyValue = lazy { expensiveComputation() }",
          "C) val lazyValue: Lazy = { expensiveComputation() }",
          "D) lazy val lazyValue = expensiveComputation()"
        ],
        "correctAnswer": "A) val lazyValue by lazy { expensiveComputation() }",
        "explanation": "The correct way to declare a lazy property in Kotlin is using 'by lazy', which ensures the property is only initialized when first accessed.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Magical World of Companion Objects and Delegation in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 142,
        "questionText": "What is the purpose of the 'observable' property delegate in Kotlin?",
        "options": [
          "A) To delay the initialization of a property.",
          "B) To notify listeners of changes to a property.",
          "C) To veto changes to a property under certain conditions.",
          "D) To cache the result of a property."
        ],
        "correctAnswer": "B) To notify listeners of changes to a property.",
        "explanation": "The 'observable' property delegate in Kotlin allows you to monitor and react to changes in a property’s value.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Magical World of Companion Objects and Delegation in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 143,
        "questionText": "How do you implement a custom property delegate in Kotlin?",
        "options": [
          "A) By implementing the `Delegates` interface.",
          "B) By creating a class with `getValue` and `setValue` operator functions.",
          "C) By overriding the `propertyDelegate` function.",
          "D) By using the `@Delegate` annotation."
        ],
        "correctAnswer": "B) By creating a class with `getValue` and `setValue` operator functions.",
        "explanation": "Custom property delegates in Kotlin are implemented by defining a class with `getValue` and `setValue` operator functions.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Magical World of Companion Objects and Delegation in CodeLand",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 144,
        "questionText": "Which property delegate would you use to prevent changes to a property unless they meet certain conditions?",
        "options": [
          "A) lazy",
          "B) observable",
          "C) vetoable",
          "D) synchronized"
        ],
        "correctAnswer": "C) vetoable",
        "explanation": "The 'vetoable' property delegate allows you to control whether a property's value can be changed based on specific conditions.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Magical World of Companion Objects and Delegation in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 145,
        "questionText": "What does the 'by' keyword indicate when used in property delegation in Kotlin?",
        "options": [
          "A) The property is inherited from another class.",
          "B) The property is delegated to another object.",
          "C) The property is lazily initialized.",
          "D) The property is overridden by a subclass."
        ],
        "correctAnswer": "B) The property is delegated to another object.",
        "explanation": "In Kotlin, the 'by' keyword is used to indicate that a property is delegated to another object, which handles its getter and setter logic.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Magical World of Companion Objects and Delegation in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 146,
        "questionText": "Which of the following correctly demonstrates using the 'by' keyword for interface delegation?",
        "options": [
          "A) class MyClass(delegate: MyInterface) : MyInterface by delegate",
          "B) class MyClass by MyInterface(delegate: MyInterface)",
          "C) class MyClass(delegate: MyInterface) by MyInterface",
          "D) class MyClass : MyInterface(delegate: MyInterface) by delegate"
        ],
        "correctAnswer": "A) class MyClass(delegate: MyInterface) : MyInterface by delegate",
        "explanation": "Interface delegation in Kotlin is implemented using the 'by' keyword after the class declaration, indicating that the interface methods are delegated to the provided object.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Magical World of Companion Objects and Delegation in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 147,
        "questionText": "In the context of Kotlin delegation, what is the primary purpose of a 'lazy' property?",
        "options": [
          "A) To initialize a property in a background thread.",
          "B) To initialize a property only when it is first accessed.",
          "C) To prevent a property from being modified.",
          "D) To cache the value of a property for future accesses."
        ],
        "correctAnswer": "B) To initialize a property only when it is first accessed.",
        "explanation": "The 'lazy' property delegate initializes the property only when it is first accessed, which can save resources if the property is never used.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Magical World of Companion Objects and Delegation in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 148,
        "questionText": "Which delegate would you use to automatically notify observers when a property changes?",
        "options": [
          "A) lazy",
          "B) observable",
          "C) vetoable",
          "D) by"
        ],
        "correctAnswer": "B) observable",
        "explanation": "The 'observable' delegate allows you to define a property that notifies observers when its value changes.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Magical World of Companion Objects and Delegation in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 149,
        "questionText": "How does Kotlin ensure that a property delegated by 'vetoable' can only be modified under certain conditions?",
        "options": [
          "A) It uses a lambda expression to check the condition before assigning a new value.",
          "B) It automatically rejects any changes that do not meet the specified conditions.",
          "C) It locks the property, preventing any changes.",
          "D) It initializes the property only when a certain condition is met."
        ],
        "correctAnswer": "A) It uses a lambda expression to check the condition before assigning a new value.",
        "explanation": "The 'vetoable' delegate in Kotlin takes a lambda expression that checks whether a new value can be assigned, allowing you to enforce specific conditions.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Magical World of Companion Objects and Delegation in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 150,
        "questionText": "What is the output of the following Kotlin code?\n\n```kotlin\nval myValue by lazy {\n    println(\"Initializing\")\n    \"Hello\"\n}\n\nfun main() {\n    println(myValue)\n    println(myValue)\n}\n```",
        "options": [
          "A) Initializing\nHello\nInitializing\nHello",
          "B) Initializing\nHello\nHello",
          "C) Hello\nHello",
          "D) Initializing\nInitializing\nHello\nHello"
        ],
        "correctAnswer": "B) Initializing\nHello\nHello",
        "explanation": "The 'lazy' delegate initializes the property only when it's first accessed. The 'Initializing' message is printed only once, followed by the cached value of 'Hello'.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Magical World of Companion Objects and Delegation in CodeLand",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 151,
        "questionText": "Which of the following statements about 'interface delegation' in Kotlin is correct?",
        "options": [
          "A) Interface delegation can only be used with abstract classes.",
          "B) Interface delegation allows an interface's implementation to be provided by a delegate object.",
          "C) Interface delegation is used to inherit multiple interfaces in Kotlin.",
          "D) Interface delegation is deprecated and should not be used."
        ],
        "correctAnswer": "B) Interface delegation allows an interface's implementation to be provided by a delegate object.",
        "explanation": "Interface delegation in Kotlin allows one class to delegate the implementation of an interface to another class, promoting code reuse and reducing boilerplate.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Magical World of Companion Objects and Delegation in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 152,
        "questionText": "When would you prefer to use 'lateinit' over 'lazy' in Kotlin?",
        "options": [
          "A) When you want to initialize a non-nullable property lazily.",
          "B) When you need a property to be initialized before it is first accessed.",
          "C) When you want to ensure a property is only initialized once.",
          "D) When the property is declared in the global scope."
        ],
        "correctAnswer": "B) When you need a property to be initialized before it is first accessed.",
        "explanation": "'lateinit' is preferred when you need to initialize a non-nullable property before its first access, typically in a constructor or an initialization block.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Magical World of Companion Objects and Delegation in CodeLand",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 153,
        "questionText": "Which scenario is best suited for using the 'observable' delegate in Kotlin?",
        "options": [
          "A) Caching the result of a computation for future use.",
          "B) Monitoring changes to a property and performing actions when it changes.",
          "C) Deferring the initialization of a property until it is first accessed.",
          "D) Preventing a property from being modified unless certain conditions are met."
        ],
        "correctAnswer": "B) Monitoring changes to a property and performing actions when it changes.",
        "explanation": "The 'observable' delegate in Kotlin is used when you want to monitor changes to a property and perform certain actions (like logging) whenever the property is updated.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Magical World of Companion Objects and Delegation in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 154,
        "questionText": "What is the purpose of the 'getValue' and 'setValue' operator functions in custom property delegates?",
        "options": [
          "A) To define how the property's value is read and written.",
          "B) To lock the property against any changes.",
          "C) To cache the property's value for future accesses.",
          "D) To provide default values for properties."
        ],
        "correctAnswer": "A) To define how the property's value is read and written.",
        "explanation": "The 'getValue' and 'setValue' operator functions are essential parts of creating custom property delegates in Kotlin, determining how the property's value is accessed and modified.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Magical World of Companion Objects and Delegation in CodeLand",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 155,
        "questionText": "How does Kotlin's interface delegation help in reducing boilerplate code?",
        "options": [
          "A) By automatically generating code to implement all abstract methods.",
          "B) By delegating method calls to another class, reducing the need to write redundant implementations.",
          "C) By eliminating the need for interfaces altogether.",
          "D) By allowing multiple inheritance, thereby reducing the amount of code."
        ],
        "correctAnswer": "B) By delegating method calls to another class, reducing the need to write redundant implementations.",
        "explanation": "Kotlin's interface delegation allows a class to delegate the implementation of an interface to another class, thus reducing the need to write the same method implementations repeatedly.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Magical World of Companion Objects and Delegation in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 156,
        "questionText": "What does the following Kotlin code demonstrate?\n\n```kotlin\nclass MyService {\n    var count: Int by observable(0) { prop, old, new ->\n        println(\"$old -> $new\")\n    }\n}\n```",
        "options": [
          "A) Lazy initialization of a property.",
          "B) Custom getter and setter logic for a property.",
          "C) Observable property delegation.",
          "D) Interface delegation."
        ],
        "correctAnswer": "C) Observable property delegation.",
        "explanation": "The code demonstrates the use of the 'observable' property delegate in Kotlin, which triggers a callback whenever the property value changes.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Magical World of Companion Objects and Delegation in CodeLand",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 157,
        "questionText": "Which of the following correctly shows a class implementing an interface using delegation?",
        "options": [
          "A) `class MyClass : MyInterface by MyDelegate()`",
          "B) `class MyClass : MyDelegate() by MyInterface`",
          "C) `class MyClass(MyInterface: MyDelegate())`",
          "D) `class MyClass by MyDelegate() : MyInterface`"
        ],
        "correctAnswer": "A) `class MyClass : MyInterface by MyDelegate()`",
        "explanation": "In Kotlin, interface delegation is achieved by using the 'by' keyword followed by the delegate instance after the interface implementation.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Magical World of Companion Objects and Delegation in CodeLand",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 158,
        "questionText": "What advantage does using a 'lazy' delegate provide when working with properties in Kotlin?",
        "options": [
          "A) Ensures that the property is immutable.",
          "B) Automatically notifies observers when the property changes.",
          "C) Delays property initialization until it is actually needed.",
          "D) Prevents the property from being modified."
        ],
        "correctAnswer": "C) Delays property initialization until it is actually needed.",
        "explanation": "The 'lazy' delegate in Kotlin is particularly useful for deferring the initialization of a property until the first time it is accessed, which can optimize resource usage.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Magical World of Companion Objects and Delegation in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 159,
        "questionText": "How does Kotlin handle multiple delegates in a single class?",
        "options": [
          "A) Kotlin does not allow multiple delegates in a single class.",
          "B) Each delegate is handled independently, and you can combine multiple interfaces using different delegates.",
          "C) You need to explicitly specify which delegate to use for each method.",
          "D) Kotlin automatically merges the behavior of all delegates."
        ],
        "correctAnswer": "B) Each delegate is handled independently, and you can combine multiple interfaces using different delegates.",
        "explanation": "Kotlin allows a class to use multiple delegates by delegating different interfaces to different delegate objects, thereby combining their behaviors.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Magical World of Companion Objects and Delegation in CodeLand",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 160,
        "questionText": "Which of the following Kotlin features is used to implement property delegation?",
        "options": [
          "A) `operator` keyword",
          "B) `by` keyword",
          "C) `lazy` keyword",
          "D) `lateinit` keyword"
        ],
        "correctAnswer": "B) `by` keyword",
        "explanation": "In Kotlin, the 'by' keyword is used to implement property delegation, allowing properties to be delegated to another object that handles their getter and setter logic.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Magical World of Companion Objects and Delegation in CodeLand",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 161,
        "questionText": "Which of the following statements best describes Kotlin Coroutines?",
        "options": [
          "A) Coroutines are similar to threads but are more lightweight.",
          "B) Coroutines can only be used for network operations.",
          "C) Coroutines are an alternative to Java's Executors.",
          "D) Coroutines are only useful for Android development."
        ],
        "correctAnswer": "A) Coroutines are similar to threads but are more lightweight.",
        "explanation": "Coroutines in Kotlin are lightweight and allow for asynchronous programming, similar to threads but with less overhead.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 162,
        "questionText": "What is the output of the following code?\n\n```kotlin\nimport kotlinx.coroutines.*\n\nfun main() = runBlocking {\n    launch {\n        delay(1000L)\n        println(\"World!\")\n    }\n    println(\"Hello,\")\n}\n```",
        "options": [
          "A) Hello, World!",
          "B) World! Hello,",
          "C) Hello,",
          "D) No output"
        ],
        "correctAnswer": "A) Hello, World!",
        "explanation": "The 'launch' coroutine is delayed by 1000 milliseconds, allowing 'Hello,' to print first, followed by 'World!'.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 163,
        "questionText": "Which function is used to cancel a coroutine in Kotlin?",
        "options": [
          "A) stop()",
          "B) cancel()",
          "C) terminate()",
          "D) finish()"
        ],
        "correctAnswer": "B) cancel()",
        "explanation": "The 'cancel()' function is used to cancel an active coroutine in Kotlin.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 164,
        "questionText": "What happens when a coroutine is cancelled using the 'cancel()' function?",
        "options": [
          "A) The coroutine stops immediately.",
          "B) The coroutine completes the current task before stopping.",
          "C) The coroutine continues to run until all tasks are completed.",
          "D) The coroutine throws an exception and stops immediately."
        ],
        "correctAnswer": "B) The coroutine completes the current task before stopping.",
        "explanation": "When a coroutine is cancelled, it tries to complete its current task before it stops. If the task checks for cancellation, it will stop immediately.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 165,
        "questionText": "Which keyword is used in Kotlin to declare a suspending function?",
        "options": [
          "A) suspend",
          "B) async",
          "C) await",
          "D) coroutine"
        ],
        "correctAnswer": "A) suspend",
        "explanation": "The 'suspend' keyword is used in Kotlin to declare a suspending function, which can be paused and resumed at a later time.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 166,
        "questionText": "What is the purpose of the 'withContext' function in Kotlin coroutines?",
        "options": [
          "A) To change the coroutine's context or dispatcher.",
          "B) To start a new coroutine.",
          "C) To cancel a coroutine.",
          "D) To block the main thread until the coroutine completes."
        ],
        "correctAnswer": "A) To change the coroutine's context or dispatcher.",
        "explanation": "The 'withContext' function in Kotlin is used to change the context or dispatcher of a coroutine, allowing it to switch to a different thread pool or execution context.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 167,
        "questionText": "In Kotlin, how do you create a channel for communication between coroutines?",
        "options": [
          "A) Using the 'flow' function",
          "B) Using the 'channel' function",
          "C) Using the 'broadcast' function",
          "D) Using the 'produce' function"
        ],
        "correctAnswer": "B) Using the 'channel' function",
        "explanation": "The 'channel' function is used in Kotlin to create a communication channel between coroutines, allowing them to send and receive data asynchronously.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 168,
        "questionText": "Which of the following statements about 'timeout' in Kotlin coroutines is correct?",
        "options": [
          "A) The 'timeout' function is used to block the main thread.",
          "B) The 'timeout' function is used to cancel a coroutine after a specified delay.",
          "C) The 'timeout' function is only used with network operations.",
          "D) The 'timeout' function prevents a coroutine from being cancelled."
        ],
        "correctAnswer": "B) The 'timeout' function is used to cancel a coroutine after a specified delay.",
        "explanation": "The 'timeout' function in Kotlin is used to cancel a coroutine if it takes longer than the specified time to complete.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 169,
        "questionText": "What is the purpose of the 'launch' function in Kotlin coroutines?",
        "options": [
          "A) To start a new coroutine without blocking the current thread.",
          "B) To block the main thread until the coroutine completes.",
          "C) To cancel an ongoing coroutine.",
          "D) To create a channel for communication between coroutines."
        ],
        "correctAnswer": "A) To start a new coroutine without blocking the current thread.",
        "explanation": "The 'launch' function is used to start a new coroutine that runs concurrently with the rest of the code without blocking the current thread.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 170,
        "questionText": "How does Kotlin handle coroutine exceptions by default?",
        "options": [
          "A) Coroutine exceptions are ignored.",
          "B) Coroutine exceptions are propagated to the parent coroutine.",
          "C) Coroutine exceptions are logged and the coroutine continues running.",
          "D) Coroutine exceptions cause the entire application to crash."
        ],
        "correctAnswer": "B) Coroutine exceptions are propagated to the parent coroutine.",
        "explanation": "By default, coroutine exceptions are propagated to the parent coroutine, which can then handle the exception.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 171,
        "questionText": "Which dispatcher is optimized for offloading blocking IO tasks in Kotlin coroutines?",
        "options": [
          "A) Dispatchers.Default",
          "B) Dispatchers.Main",
          "C) Dispatchers.IO",
          "D) Dispatchers.Unconfined"
        ],
        "correctAnswer": "C) Dispatchers.IO",
        "explanation": "Dispatchers.IO is optimized for offloading blocking IO tasks such as reading from disk or network calls.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 172,
        "questionText": "What is the key difference between 'launch' and 'async' in Kotlin coroutines?",
        "options": [
          "A) 'launch' returns a Deferred object, while 'async' does not.",
          "B) 'launch' is used for coroutines without results, while 'async' is used for coroutines that return a result.",
          "C) 'launch' blocks the current thread, while 'async' does not.",
          "D) 'launch' is only used for UI operations, while 'async' is used for background tasks."
        ],
        "correctAnswer": "B) 'launch' is used for coroutines without results, while 'async' is used for coroutines that return a result.",
        "explanation": "'launch' starts a coroutine that does not return a result, while 'async' starts a coroutine that returns a Deferred object, which can be used to get the result later.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 173,
        "questionText": "Which of the following is NOT a feature of Kotlin coroutines?",
        "options": [
          "A) Lightweight concurrency",
          "B) Structured concurrency",
          "C) Memory safety",
          "D) Guaranteed real-time execution"
        ],
        "correctAnswer": "D) Guaranteed real-time execution",
        "explanation": "Kotlin coroutines provide lightweight concurrency, structured concurrency, and memory safety, but they do not guarantee real-time execution.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 174,
        "questionText": "How does the 'SupervisorJob' differ from a regular 'Job' in Kotlin coroutines?",
        "options": [
          "A) 'SupervisorJob' cancels all child coroutines if any child fails.",
          "B) 'SupervisorJob' allows child coroutines to fail independently without affecting others.",
          "C) 'SupervisorJob' blocks the main thread until all coroutines complete.",
          "D) 'SupervisorJob' is used only in Android development."
        ],
        "correctAnswer": "B) 'SupervisorJob' allows child coroutines to fail independently without affecting others.",
        "explanation": "'SupervisorJob' allows each child coroutine to fail independently without affecting the execution of other coroutines.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 175,
        "questionText": "Which function is used to handle coroutine exceptions in a structured way?",
        "options": [
          "A) try-catch block",
          "B) withTimeout",
          "C) CoroutineExceptionHandler",
          "D) withContext"
        ],
        "correctAnswer": "C) CoroutineExceptionHandler",
        "explanation": "The 'CoroutineExceptionHandler' is used to handle exceptions in coroutines in a structured manner, allowing for centralized exception handling.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 176,
        "questionText": "What is the main purpose of 'Shared Mutable State' in Kotlin coroutines?",
        "options": [
          "A) To allow multiple coroutines to access and modify the same variable concurrently.",
          "B) To prevent coroutines from accessing shared data.",
          "C) To enforce immutability in coroutines.",
          "D) To isolate each coroutine’s state from others."
        ],
        "correctAnswer": "A) To allow multiple coroutines to access and modify the same variable concurrently.",
        "explanation": "'Shared Mutable State' allows multiple coroutines to access and modify the same variable, requiring careful synchronization to avoid race conditions.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 177,
        "questionText": "How does the 'delay' function work in Kotlin coroutines?",
        "options": [
          "A) It blocks the main thread for the specified time.",
          "B) It pauses the coroutine without blocking the thread.",
          "C) It cancels the coroutine after the specified time.",
          "D) It suspends the coroutine and all child coroutines."
        ],
        "correctAnswer": "B) It pauses the coroutine without blocking the thread.",
        "explanation": "The 'delay' function in Kotlin coroutines pauses the coroutine for a specified time without blocking the underlying thread.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 178,
        "questionText": "In Kotlin coroutines, what is the 'yield' function used for?",
        "options": [
          "A) To block the current thread.",
          "B) To cancel a coroutine.",
          "C) To release the thread and give other coroutines a chance to run.",
          "D) To ensure the coroutine runs on the main thread."
        ],
        "correctAnswer": "C) To release the thread and give other coroutines a chance to run.",
        "explanation": "The 'yield' function in Kotlin coroutines releases the thread, allowing other coroutines to run and ensuring fair execution.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 179,
        "questionText": "What does the 'Job' interface represent in Kotlin coroutines?",
        "options": [
          "A) A running coroutine instance.",
          "B) A thread in a coroutine context.",
          "C) A blocking task.",
          "D) A network operation."
        ],
        "correctAnswer": "A) A running coroutine instance.",
        "explanation": "The 'Job' interface represents a running coroutine instance and allows you to control its lifecycle, such as cancelling or joining the coroutine.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 180,
        "questionText": "Which function allows you to await the result of an 'async' coroutine?",
        "options": [
          "A) wait()",
          "B) await()",
          "C) get()",
          "D) join()"
        ],
        "correctAnswer": "B) await()",
        "explanation": "The 'await()' function allows you to wait for the result of an 'async' coroutine and retrieve its value once it completes.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 181,
        "questionText": "In Kotlin, what is the function of the 'runBlocking' block?",
        "options": [
          "A) To start a new coroutine.",
          "B) To block the main thread and wait for the coroutine inside to complete.",
          "C) To cancel all coroutines in the application.",
          "D) To create a new coroutine context."
        ],
        "correctAnswer": "B) To block the main thread and wait for the coroutine inside to complete.",
        "explanation": "The 'runBlocking' block is used to block the main thread and wait for all the coroutines inside it to complete before proceeding.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 182,
        "questionText": "Which of the following is a core principle of structured concurrency in Kotlin?",
        "options": [
          "A) Coroutines can be started and stopped independently.",
          "B) Coroutines must be bound to a scope that defines their lifetime.",
          "C) Coroutines automatically manage memory allocation.",
          "D) Coroutines run sequentially, one after the other."
        ],
        "correctAnswer": "B) Coroutines must be bound to a scope that defines their lifetime.",
        "explanation": "Structured concurrency in Kotlin ensures that coroutines are bound to a scope, which defines their lifetime and helps prevent memory leaks.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 183,
        "questionText": "What is the role of 'Dispatchers.Main' in Kotlin coroutines?",
        "options": [
          "A) To run coroutines on the main UI thread.",
          "B) To run coroutines on a background thread.",
          "C) To cancel a coroutine.",
          "D) To manage coroutine lifecycle."
        ],
        "correctAnswer": "A) To run coroutines on the main UI thread.",
        "explanation": "'Dispatchers.Main' is used in Kotlin coroutines to run coroutines on the main UI thread, typically for UI updates.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 184,
        "questionText": "Which function is used to start a coroutine that returns a result?",
        "options": [
          "A) launch()",
          "B) async()",
          "C) withContext()",
          "D) runBlocking()"
        ],
        "correctAnswer": "B) async()",
        "explanation": "The 'async()' function is used to start a coroutine that returns a result, encapsulated in a Deferred object.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 185,
        "questionText": "Which coroutine function allows for easy combination of multiple suspending functions?",
        "options": [
          "A) async()",
          "B) launch()",
          "C) runBlocking()",
          "D) coroutineScope()"
        ],
        "correctAnswer": "D) coroutineScope()",
        "explanation": "The 'coroutineScope()' function is used to combine multiple suspending functions, ensuring that all coroutines are completed before the scope ends.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 186,
        "questionText": "What is the function of the 'flow' builder in Kotlin?",
        "options": [
          "A) To create a channel for communication.",
          "B) To handle exceptions in coroutines.",
          "C) To build a sequence of values emitted asynchronously.",
          "D) To combine multiple coroutine contexts."
        ],
        "correctAnswer": "C) To build a sequence of values emitted asynchronously.",
        "explanation": "The 'flow' builder in Kotlin is used to create a sequence of values emitted asynchronously, which can be collected by a Flow collector.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 187,
        "questionText": "In Kotlin coroutines, what is a 'cold' Flow?",
        "options": [
          "A) A Flow that starts emitting values immediately upon creation.",
          "B) A Flow that only emits values when a collector is attached.",
          "C) A Flow that runs in a background thread.",
          "D) A Flow that stops emitting values when a coroutine is cancelled."
        ],
        "correctAnswer": "B) A Flow that only emits values when a collector is attached.",
        "explanation": "A 'cold' Flow in Kotlin is a flow that only starts emitting values when a collector is attached, ensuring that resources are used efficiently.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 188,
        "questionText": "What is the role of the 'channel' function in Kotlin?",
        "options": [
          "A) To manage coroutine contexts.",
          "B) To handle coroutine exceptions.",
          "C) To create a communication channel between coroutines.",
          "D) To control coroutine lifecycle."
        ],
        "correctAnswer": "C) To create a communication channel between coroutines.",
        "explanation": "The 'channel' function in Kotlin is used to create a communication channel between coroutines, allowing them to send and receive data asynchronously.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 189,
        "questionText": "Which of the following statements is true about 'Flow' in Kotlin?",
        "options": [
          "A) Flow is always executed on the main thread.",
          "B) Flow is synchronous by default.",
          "C) Flow can be used to handle streams of data asynchronously.",
          "D) Flow replaces all use cases of coroutines."
        ],
        "correctAnswer": "C) Flow can be used to handle streams of data asynchronously.",
        "explanation": "Flow in Kotlin is used to handle streams of data asynchronously, making it suitable for processing sequences of data over time.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 190,
        "questionText": "How do you handle exceptions in a Flow?",
        "options": [
          "A) Using a try-catch block within the Flow builder.",
          "B) Using the 'catch' operator.",
          "C) Using the 'finally' block after the Flow collection.",
          "D) Exceptions cannot be handled in Flow."
        ],
        "correctAnswer": "B) Using the 'catch' operator.",
        "explanation": "In Kotlin Flow, exceptions can be handled using the 'catch' operator, which allows you to handle errors within the Flow.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 191,
        "questionText": "What is the purpose of the 'collect' function in Kotlin Flow?",
        "options": [
          "A) To start a new Flow.",
          "B) To transform the Flow data.",
          "C) To consume and process the emitted values from a Flow.",
          "D) To cancel a Flow."
        ],
        "correctAnswer": "C) To consume and process the emitted values from a Flow.",
        "explanation": "The 'collect' function is used in Kotlin Flow to consume and process the emitted values from a Flow, similar to how you would iterate over a collection.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 192,
        "questionText": "How does the 'buffer' operator affect a Flow in Kotlin?",
        "options": [
          "A) It cancels the Flow if it emits too many values.",
          "B) It buffers the values emitted by a Flow to improve performance.",
          "C) It delays the emission of values from a Flow.",
          "D) It transforms the values emitted by a Flow."
        ],
        "correctAnswer": "B) It buffers the values emitted by a Flow to improve performance.",
        "explanation": "The 'buffer' operator in Kotlin Flow is used to buffer the emitted values, allowing for improved performance when processing large or fast-emitting flows.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 193,
        "questionText": "Which of the following is true about the 'shareIn' operator in Kotlin Flow?",
        "options": [
          "A) It shares a cold Flow as a hot Flow.",
          "B) It combines multiple Flows into one.",
          "C) It buffers Flow emissions for later use.",
          "D) It is used for error handling in Flows."
        ],
        "correctAnswer": "A) It shares a cold Flow as a hot Flow.",
        "explanation": "The 'shareIn' operator in Kotlin Flow allows you to share a cold Flow as a hot Flow, which is useful for broadcasting events to multiple collectors.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 194,
        "questionText": "Which of the following is NOT a use case for Kotlin's 'stateFlow'?",
        "options": [
          "A) Managing UI state in Android apps.",
          "B) Handling one-time events.",
          "C) Emitting a sequence of values over time.",
          "D) Maintaining a shared state across multiple components."
        ],
        "correctAnswer": "B) Handling one-time events.",
        "explanation": "'stateFlow' in Kotlin is used to manage and share state across multiple components, but it is not intended for handling one-time events.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 195,
        "questionText": "How does 'conflate' improve performance in Kotlin Flow?",
        "options": [
          "A) It cancels the Flow if the values are emitted too quickly.",
          "B) It skips intermediate values to keep up with a fast-emitting Flow.",
          "C) It buffers the values emitted by the Flow.",
          "D) It delays the emission of values by the Flow."
        ],
        "correctAnswer": "B) It skips intermediate values to keep up with a fast-emitting Flow.",
        "explanation": "The 'conflate' operator in Kotlin Flow skips intermediate values to keep up with a fast-emitting Flow, which can improve performance by reducing the processing load.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 196,
        "questionText": "Which of the following functions is used to delay a Flow emission in Kotlin?",
        "options": [
          "A) delay()",
          "B) buffer()",
          "C) debounce()",
          "D) collect()"
        ],
        "correctAnswer": "C) debounce()",
        "explanation": "The 'debounce()' function in Kotlin Flow delays the emission of values from a Flow, typically to handle rapid bursts of events, such as user input.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 197,
        "questionText": "Which of the following is a key difference between 'broadcast' and 'channel' in Kotlin?",
        "options": [
          "A) 'broadcast' is used for single-shot communication, while 'channel' is for ongoing communication.",
          "B) 'broadcast' allows multiple receivers to listen, while 'channel' allows only one.",
          "C) 'broadcast' is synchronous, while 'channel' is asynchronous.",
          "D) 'broadcast' is for handling UI events, while 'channel' is for background processing."
        ],
        "correctAnswer": "B) 'broadcast' allows multiple receivers to listen, while 'channel' allows only one.",
        "explanation": "In Kotlin, 'broadcast' allows multiple receivers to listen to the same data stream, while 'channel' allows only one receiver at a time.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 198,
        "questionText": "Which function in Kotlin coroutines ensures that a coroutine is not cancelled while performing a critical task?",
        "options": [
          "A) runBlocking",
          "B) withContext",
          "C) withTimeout",
          "D) withContext(NonCancellable)"
        ],
        "correctAnswer": "D) withContext(NonCancellable)",
        "explanation": "The 'withContext(NonCancellable)' function in Kotlin ensures that a coroutine is not cancelled while performing a critical task, allowing the operation to complete even if the parent coroutine is cancelled.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 199,
        "questionText": "What is the primary use of the 'launch' coroutine builder in Kotlin?",
        "options": [
          "A) To create a coroutine that returns a Deferred result.",
          "B) To create a coroutine that runs in parallel with others and does not return a result.",
          "C) To cancel all coroutines in a scope.",
          "D) To block the current thread until the coroutine completes."
        ],
        "correctAnswer": "B) To create a coroutine that runs in parallel with others and does not return a result.",
        "explanation": "The 'launch' coroutine builder in Kotlin is primarily used to create a coroutine that runs in parallel with other coroutines and does not return a result, making it suitable for launching background jobs.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 200,
        "questionText": "Which of the following is a characteristic of 'cold' Flows in Kotlin?",
        "options": [
          "A) They start emitting values as soon as they are created.",
          "B) They require explicit cancellation to stop.",
          "C) They emit values only when collected.",
          "D) They run on the main thread by default."
        ],
        "correctAnswer": "C) They emit values only when collected.",
        "explanation": "In Kotlin, 'cold' Flows emit values only when they are collected, meaning that they do not start emitting values as soon as they are created, making them resource-efficient.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Realm of Coroutines",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 201,
        "questionText": "Which of the following is true about collections in Kotlin?",
        "options": [
          "A) Collections in Kotlin are always mutable.",
          "B) Kotlin provides both mutable and immutable collections.",
          "C) Kotlin does not support collections natively.",
          "D) All collections in Kotlin are immutable."
        ],
        "correctAnswer": "B) Kotlin provides both mutable and immutable collections.",
        "explanation": "Kotlin provides both mutable and immutable collections, allowing developers to choose the right type based on the use case.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 202,
        "questionText": "How do you create an immutable list in Kotlin?",
        "options": [
          "A) val list = listOf(1, 2, 3)",
          "B) val list = mutableListOf(1, 2, 3)",
          "C) val list = arrayListOf(1, 2, 3)",
          "D) val list = linkedListOf(1, 2, 3)"
        ],
        "correctAnswer": "A) val list = listOf(1, 2, 3)",
        "explanation": "The 'listOf' function creates an immutable list in Kotlin, meaning the elements cannot be changed after the list is created.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 203,
        "questionText": "Which Kotlin collection would you use if you need a collection that does not allow duplicate elements?",
        "options": [
          "A) List",
          "B) Set",
          "C) Map",
          "D) Array"
        ],
        "correctAnswer": "B) Set",
        "explanation": "A 'Set' in Kotlin is a collection that does not allow duplicate elements, ensuring that all elements are unique.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 204,
        "questionText": "What is the primary difference between a mutable and immutable collection in Kotlin?",
        "options": [
          "A) Mutable collections can change their size and elements, while immutable collections cannot.",
          "B) Immutable collections can only increase in size.",
          "C) Mutable collections cannot be traversed.",
          "D) Immutable collections support lazy evaluation."
        ],
        "correctAnswer": "A) Mutable collections can change their size and elements, while immutable collections cannot.",
        "explanation": "The primary difference is that mutable collections in Kotlin can have their size and elements changed, while immutable collections are fixed after creation.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 205,
        "questionText": "Which function would you use to transform a list of strings into their lengths in Kotlin?",
        "options": [
          "A) filter()",
          "B) map()",
          "C) reduce()",
          "D) fold()"
        ],
        "correctAnswer": "B) map()",
        "explanation": "The 'map()' function in Kotlin is used to transform each element in a collection, such as converting a list of strings into a list of their lengths.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 206,
        "questionText": "Which Kotlin function can you use to combine the elements of a collection into a single result?",
        "options": [
          "A) map()",
          "B) filter()",
          "C) reduce()",
          "D) flatMap()"
        ],
        "correctAnswer": "C) reduce()",
        "explanation": "The 'reduce()' function in Kotlin combines all elements of a collection into a single result by applying a binary operation.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 207,
        "questionText": "What is the main advantage of using 'flatMap' over 'map' in Kotlin?",
        "options": [
          "A) 'flatMap' is faster than 'map'.",
          "B) 'flatMap' can handle nested collections more effectively.",
          "C) 'flatMap' is used for filtering collections.",
          "D) 'flatMap' allows for element modification."
        ],
        "correctAnswer": "B) 'flatMap' can handle nested collections more effectively.",
        "explanation": "'flatMap' is used to flatten nested collections, making it more effective for handling collections within collections, unlike 'map' which transforms elements directly.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 208,
        "questionText": "In Kotlin, which function is used to filter out elements from a collection that do not match a given condition?",
        "options": [
          "A) map()",
          "B) filter()",
          "C) reduce()",
          "D) fold()"
        ],
        "correctAnswer": "B) filter()",
        "explanation": "The 'filter()' function in Kotlin is used to select elements from a collection that satisfy a given condition, filtering out the rest.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 209,
        "questionText": "Which of the following is true about 'fold' in Kotlin?",
        "options": [
          "A) 'fold' combines elements starting from the first to the last.",
          "B) 'fold' combines elements starting from the last to the first.",
          "C) 'fold' is used for filtering collections.",
          "D) 'fold' is used to flatten nested collections."
        ],
        "correctAnswer": "A) 'fold' combines elements starting from the first to the last.",
        "explanation": "'fold' in Kotlin combines elements in a collection by applying a function from the first element to the last, using an initial accumulator value.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 210,
        "questionText": "How does Kotlin handle lazy evaluation in collections?",
        "options": [
          "A) Lazy evaluation is not supported in Kotlin collections.",
          "B) Collections are evaluated lazily by default.",
          "C) Sequences are used for lazy evaluation in Kotlin.",
          "D) Lazy evaluation is only applicable to maps."
        ],
        "correctAnswer": "C) Sequences are used for lazy evaluation in Kotlin.",
        "explanation": "In Kotlin, sequences provide lazy evaluation, meaning elements are computed on-demand as they are accessed rather than all at once.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 211,
        "questionText": "Which Kotlin function would you use to avoid concurrent modification issues when iterating over a shared mutable collection?",
        "options": [
          "A) withContext()",
          "B) synchronized()",
          "C) atomic()",
          "D) runBlocking()"
        ],
        "correctAnswer": "B) synchronized()",
        "explanation": "The 'synchronized()' function in Kotlin can be used to prevent concurrent modification issues by ensuring that only one thread accesses the collection at a time.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 212,
        "questionText": "What is the difference between a 'List' and a 'Map' in Kotlin?",
        "options": [
          "A) A List holds key-value pairs, while a Map holds values only.",
          "B) A List holds values, while a Map holds key-value pairs.",
          "C) Both List and Map hold key-value pairs.",
          "D) Both List and Map hold values only."
        ],
        "correctAnswer": "B) A List holds values, while a Map holds key-value pairs.",
        "explanation": "In Kotlin, a List is a collection that holds values, while a Map is a collection that holds key-value pairs, allowing for quick lookups.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 213,
        "questionText": "Which Kotlin collection would you use to store a unique set of elements that are sorted by natural order?",
        "options": [
          "A) List",
          "B) Set",
          "C) SortedSet",
          "D) Map"
        ],
        "correctAnswer": "C) SortedSet",
        "explanation": "A 'SortedSet' in Kotlin is a Set that maintains its elements in natural order, ensuring uniqueness and sorted order.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 214,
        "questionText": "How does Kotlin handle null safety in collections?",
        "options": [
          "A) Collections in Kotlin cannot contain null values.",
          "B) Kotlin uses special null-safe collections to handle null values.",
          "C) Kotlin provides nullable collections that can hold null values, ensuring safe handling.",
          "D) Null safety is not enforced in Kotlin collections."
        ],
        "correctAnswer": "C) Kotlin provides nullable collections that can hold null values, ensuring safe handling.",
        "explanation": "Kotlin allows collections to be nullable, meaning they can hold null values, and ensures that operations on these collections are safe, avoiding null pointer exceptions.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 215,
        "questionText": "What is the purpose of the 'associateBy' function in Kotlin?",
        "options": [
          "A) To associate a value with its corresponding key in a map.",
          "B) To create a list of key-value pairs from a collection.",
          "C) To map each element of a collection to a key in a map.",
          "D) To sort elements by a given criterion."
        ],
        "correctAnswer": "C) To map each element of a collection to a key in a map.",
        "explanation": "'associateBy' in Kotlin is used to create a map from a collection, where each element is mapped to a key based on a specified function.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 216,
        "questionText": "Which function would you use to remove null values from a list in Kotlin?",
        "options": [
          "A) filterNotNull()",
          "B) filter()",
          "C) mapNotNull()",
          "D) reduceNotNull()"
        ],
        "correctAnswer": "A) filterNotNull()",
        "explanation": "'filterNotNull()' is a Kotlin function that filters out null values from a collection, returning only non-null elements.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 217,
        "questionText": "How does Kotlin's 'toList()' function behave when called on a collection?",
        "options": [
          "A) It converts the collection to a mutable list.",
          "B) It converts the collection to an immutable list.",
          "C) It duplicates the collection.",
          "D) It sorts the collection before converting it."
        ],
        "correctAnswer": "B) It converts the collection to an immutable list.",
        "explanation": "The 'toList()' function in Kotlin converts a collection to an immutable list, meaning the resulting list cannot be modified.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 218,
        "questionText": "Which function in Kotlin combines elements from two collections into pairs?",
        "options": [
          "A) merge()",
          "B) combine()",
          "C) zip()",
          "D) pair()"
        ],
        "correctAnswer": "C) zip()",
        "explanation": "The 'zip()' function in Kotlin combines elements from two collections into pairs, producing a list of pairs.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 219,
        "questionText": "Which of the following is true about 'groupBy' in Kotlin?",
        "options": [
          "A) It groups elements into lists based on a key selector function.",
          "B) It sorts the elements before grouping.",
          "C) It removes duplicates before grouping.",
          "D) It flattens nested collections before grouping."
        ],
        "correctAnswer": "A) It groups elements into lists based on a key selector function.",
        "explanation": "The 'groupBy' function in Kotlin groups elements from a collection into lists based on the result of a key selector function, returning a map where each key is associated with a list of elements.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 220,
        "questionText": "How do you create a mutable map in Kotlin?",
        "options": [
          "A) val map = mapOf(1 to 'A', 2 to 'B')",
          "B) val map = mutableMapOf(1 to 'A', 2 to 'B')",
          "C) val map = hashMapOf(1 to 'A', 2 to 'B')",
          "D) val map = sortedMapOf(1 to 'A', 2 to 'B')"
        ],
        "correctAnswer": "B) val map = mutableMapOf(1 to 'A', 2 to 'B')",
        "explanation": "The 'mutableMapOf' function creates a mutable map in Kotlin, allowing you to add, remove, or change entries after the map is created.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 221,
        "questionText": "What is the result of calling 'sum()' on a list of integers in Kotlin?",
        "options": [
          "A) It concatenates all the integers as strings.",
          "B) It calculates the sum of all integers in the list.",
          "C) It multiplies all integers in the list.",
          "D) It returns the maximum value in the list."
        ],
        "correctAnswer": "B) It calculates the sum of all integers in the list.",
        "explanation": "The 'sum()' function in Kotlin calculates and returns the sum of all integers in a list.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 222,
        "questionText": "How do you create an empty list in Kotlin?",
        "options": [
          "A) val list = emptyList<Int>()",
          "B) val list = listOf()",
          "C) val list = emptyArrayList()",
          "D) val list = emptyMutableList()"
        ],
        "correctAnswer": "A) val list = emptyList<Int>()",
        "explanation": "'emptyList()' creates an empty immutable list in Kotlin, which cannot be modified after creation.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 223,
        "questionText": "Which function would you use to find the maximum value in a list of integers in Kotlin?",
        "options": [
          "A) max()",
          "B) maxOrNull()",
          "C) maximum()",
          "D) findMax()"
        ],
        "correctAnswer": "B) maxOrNull()",
        "explanation": "'maxOrNull()' returns the maximum value from a list of integers, or null if the list is empty.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 224,
        "questionText": "In Kotlin, how do you create a range from 1 to 10?",
        "options": [
          "A) val range = 1..10",
          "B) val range = rangeOf(1, 10)",
          "C) val range = 1 to 10",
          "D) val range = 1->10"
        ],
        "correctAnswer": "A) val range = 1..10",
        "explanation": "The '..' operator in Kotlin creates a range from 1 to 10, inclusive.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 225,
        "questionText": "How can you find the first element in a list that matches a given predicate in Kotlin?",
        "options": [
          "A) first()",
          "B) find()",
          "C) findFirst()",
          "D) firstOrNull()"
        ],
        "correctAnswer": "B) find()",
        "explanation": "'find()' in Kotlin returns the first element that matches a given predicate or null if no element matches.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 226,
        "questionText": "What is the purpose of 'filterIndexed' in Kotlin?",
        "options": [
          "A) To filter elements based on their index.",
          "B) To filter elements based on their value.",
          "C) To filter elements based on both index and value.",
          "D) To filter elements based on a given key."
        ],
        "correctAnswer": "C) To filter elements based on both index and value.",
        "explanation": "'filterIndexed()' in Kotlin filters elements in a list based on both their index and value, allowing for complex filtering logic.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 227,
        "questionText": "Which function in Kotlin creates a single list from multiple collections?",
        "options": [
          "A) merge()",
          "B) combine()",
          "C) flatten()",
          "D) zip()"
        ],
        "correctAnswer": "C) flatten()",
        "explanation": "'flatten()' in Kotlin creates a single list from multiple collections, effectively flattening nested lists into a single list.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 228,
        "questionText": "Which Kotlin collection function would you use to get a list of values from a list of key-value pairs?",
        "options": [
          "A) mapValues()",
          "B) toList()",
          "C) values()",
          "D) map()"
        ],
        "correctAnswer": "D) map()",
        "explanation": "'map()' in Kotlin can be used to transform a list of key-value pairs into a list of values by applying a transformation function.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 229,
        "questionText": "How does Kotlin handle mutable and immutable maps?",
        "options": [
          "A) Maps are mutable by default.",
          "B) Maps are immutable by default.",
          "C) Kotlin does not support immutable maps.",
          "D) Mutable and immutable maps are treated the same."
        ],
        "correctAnswer": "B) Maps are immutable by default.",
        "explanation": "In Kotlin, maps are immutable by default, meaning they cannot be modified after creation. Mutable maps can be explicitly created using 'mutableMapOf()'.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 230,
        "questionText": "What does the 'associateWith' function do in Kotlin?",
        "options": [
          "A) It associates each element with a key.",
          "B) It associates each element with its index.",
          "C) It creates a map by associating each element with a value generated by the given function.",
          "D) It sorts the elements before associating them."
        ],
        "correctAnswer": "C) It creates a map by associating each element with a value generated by the given function.",
        "explanation": "'associateWith()' in Kotlin creates a map where each element in the collection is associated with a value generated by a given function.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Treasure Trove of Collections",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 231,
        "questionText": "Which of the following is the correct way to use string templates in Kotlin?",
        "options": [
          "A) val greeting = 'Hello, name!'",
          "B) val greeting = \"Hello, \$name!\"",
          "C) val greeting = 'Hello, \${name}!'",
          "D) val greeting = \"Hello, {name}!\""
        ],
        "correctAnswer": "B) val greeting = \"Hello, \$name!\"",
        "explanation": "In Kotlin, string templates are used by prefixing the variable name with a dollar sign ($). For more complex expressions, curly braces can be used.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Scrolls of String Handling",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 232,
        "questionText": "How do you access the length of a string in Kotlin?",
        "options": [
          "A) length()",
          "B) getLength()",
          "C) size()",
          "D) length"
        ],
        "correctAnswer": "D) length",
        "explanation": "In Kotlin, the length property is used to get the number of characters in a string.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Scrolls of String Handling",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 233,
        "questionText": "Which method is used to convert a string to lowercase in Kotlin?",
        "options": [
          "A) toLower()",
          "B) lower()",
          "C) toLowerCase()",
          "D) convertToLowerCase()"
        ],
        "correctAnswer": "C) toLowerCase()",
        "explanation": "The 'toLowerCase()' method converts all characters in a string to lowercase.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Scrolls of String Handling",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 234,
        "questionText": "What is the output of the following code?\n\nval name = \"John\"\nval message = \"Hello, \${name.toUpperCase()}!\"\nprintln(message)",
        "options": [
          "A) Hello, John!",
          "B) Hello, \$name!",
          "C) Hello, JOHN!",
          "D) Error: Invalid syntax"
        ],
        "correctAnswer": "C) Hello, JOHN!",
        "explanation": "String templates allow you to include expressions within the curly braces. In this case, the toUpperCase() method is applied to the name variable.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Scrolls of String Handling",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 235,
        "questionText": "How do you create a multiline string in Kotlin?",
        "options": [
          "A) Using triple quotes \"\"\"",
          "B) Using two double quotes \"\"",
          "C) Using a backslash at the end of each line",
          "D) Using a plus sign (+) to concatenate lines"
        ],
        "correctAnswer": "A) Using triple quotes \"\"\"",
        "explanation": "In Kotlin, multiline strings are created using triple quotes (\"\"\"). This allows you to include line breaks and other formatting directly in the string.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Scrolls of String Handling",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 236,
        "questionText": "Which of the following is a valid regular expression in Kotlin?",
        "options": [
          "A) val regex = \"[a-z]+\"",
          "B) val regex = Regex(\"[a-z]+\")",
          "C) val regex = regexOf(\"[a-z]+\")",
          "D) val regex = /[a-z]+/"
        ],
        "correctAnswer": "B) val regex = Regex(\"[a-z]+\")",
        "explanation": "In Kotlin, the Regex class is used to create regular expressions. The string pattern is passed as a parameter to the Regex constructor.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Scrolls of String Handling",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 237,
        "questionText": "How do you check if a string contains only digits in Kotlin?",
        "options": [
          "A) str.isDigits()",
          "B) str.all { it.isDigit() }",
          "C) str.containsDigits()",
          "D) str.matches(\"[0-9]+\")"
        ],
        "correctAnswer": "B) str.all { it.isDigit() }",
        "explanation": "The 'all' function checks if all characters in the string match the given predicate, which in this case is 'isDigit()'.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Scrolls of String Handling",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 238,
        "questionText": "What will be the result of the following code?\n\nval text = \"Kotlin is fun!\"\nval result = text.replace(\"fun\", \"awesome\")\nprintln(result)",
        "options": [
          "A) Kotlin is awesome!",
          "B) Kotlin is fun!",
          "C) Kotlin is !",
          "D) Kotlin is replace"
        ],
        "correctAnswer": "A) Kotlin is awesome!",
        "explanation": "The replace() function in Kotlin replaces occurrences of a substring with another string.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Scrolls of String Handling",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 239,
        "questionText": "How do you split a string by spaces in Kotlin?",
        "options": [
          "A) str.split(\" \")",
          "B) str.splitBy(\" \")",
          "C) str.split(\"spaces\")",
          "D) str.split(Regex(\"\\s+\"))"
        ],
        "correctAnswer": "D) str.split(Regex(\"\\s+\"))",
        "explanation": "The split() function can take a regular expression, and '\\s+' matches one or more whitespace characters, effectively splitting the string by spaces.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Scrolls of String Handling",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 240,
        "questionText": "What does the 'trim' function do in Kotlin?",
        "options": [
          "A) It removes all spaces from the string.",
          "B) It removes all occurrences of a specified character from the string.",
          "C) It removes whitespace from both ends of the string.",
          "D) It replaces multiple spaces with a single space."
        ],
        "correctAnswer": "C) It removes whitespace from both ends of the string.",
        "explanation": "The trim() function removes any leading or trailing whitespace from the string.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Scrolls of String Handling",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 241,
        "questionText": "How do you convert a string to an integer in Kotlin?",
        "options": [
          "A) str.toInt()",
          "B) str.convertToInt()",
          "C) str.asInt()",
          "D) str.parseInt()"
        ],
        "correctAnswer": "A) str.toInt()",
        "explanation": "The toInt() function converts a string to an integer, assuming the string is a valid representation of a number.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Scrolls of String Handling",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 242,
        "questionText": "Which function would you use to repeat a string in Kotlin?",
        "options": [
          "A) repeat()",
          "B) replicate()",
          "C) repeatString()",
          "D) cloneString()"
        ],
        "correctAnswer": "A) repeat()",
        "explanation": "The repeat() function in Kotlin repeats the string a specified number of times.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Scrolls of String Handling",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 243,
        "questionText": "What will the following code print?\n\nval text = \"Hello, Kotlin!\"\nprintln(text.substring(7, 13))",
        "options": [
          "A) Kotlin!",
          "B) Hello,",
          "C) K",
          "D) otlin!"
        ],
        "correctAnswer": "D) otlin!",
        "explanation": "The substring() function extracts a portion of the string starting from the 7th character up to, but not including, the 13th character.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Scrolls of String Handling",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 244,
        "questionText": "How can you check if a string is null or empty in Kotlin?",
        "options": [
          "A) str.isNullOrEmpty()",
          "B) str.isEmpty()",
          "C) str == null || str.isEmpty()",
          "D) str.isNullOrEmpty() || str.isBlank()"
        ],
        "correctAnswer": "A) str.isNullOrEmpty()",
        "explanation": "The isNullOrEmpty() function in Kotlin checks whether a string is null or empty, returning true if either condition is met.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Scrolls of String Handling",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 245,
        "questionText": "What is the output of the following code?\n\nval text = \"Hello, World!\"\nprintln(text.drop(7))",
        "options": [
          "A) World!",
          "B) Hello,",
          "C) ello, World!",
          "D) Hello,"
        ],
        "correctAnswer": "A) World!",
        "explanation": "The drop() function in Kotlin removes the first 'n' characters from the string. In this case, the first 7 characters are removed.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Scrolls of String Handling",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 246,
        "questionText": "Which method is used to match a string against a regular expression in Kotlin?",
        "options": [
          "A) str.match()",
          "B) str.matches()",
          "C) str.test()",
          "D) str.regexMatch()"
        ],
        "correctAnswer": "B) str.matches()",
        "explanation": "The matches() function checks if the entire string matches the given regular expression.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Scrolls of String Handling",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 247,
        "questionText": "Which of the following is a correct way to use multiline strings in Kotlin?",
        "options": [
          "A) val text = \"This is a multiline\nstring\"",
          "B) val text = '''This is a multiline\nstring'''",
          "C) val text = \"\"\"This is a multiline\nstring\"\"\"",
          "D) val text = \"\"\"This is a multiline string\"\"\""
        ],
        "correctAnswer": "C) val text = \"\"\"This is a multiline\nstring\"\"\"",
        "explanation": "In Kotlin, multiline strings are created using triple quotes. Line breaks and other formatting are preserved inside the string.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Scrolls of String Handling",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 248,
        "questionText": "How can you safely handle a nullable string before performing operations on it?",
        "options": [
          "A) Use the '?' operator",
          "B) Use 'let' function with safe call operator",
          "C) Use 'forceUnwrap()' method",
          "D) Convert the string to non-nullable"
        ],
        "correctAnswer": "B) Use 'let' function with safe call operator",
        "explanation": "The 'let' function along with the safe call operator (?.) is used to execute a block of code only if the string is not null.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Scrolls of String Handling",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 249,
        "questionText": "What will be the output of the following code?\n\nval regex = Regex(\"\\\\d+\")\nval result = regex.find(\"There are 123 apples\")?.value\nprintln(result)",
        "options": [
          "A) 123",
          "B) There are 123 apples",
          "C) apples",
          "D) 3"
        ],
        "correctAnswer": "A) 123",
        "explanation": "The Regex 'find' function searches for the first match in the string. The '\\d+' pattern matches one or more digits.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Scrolls of String Handling",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 250,
        "questionText": "Which method would you use to replace all occurrences of a substring with another string in Kotlin?",
        "options": [
          "A) replaceAll()",
          "B) replaceEach()",
          "C) replace()",
          "D) replaceFirst()"
        ],
        "correctAnswer": "C) replace()",
        "explanation": "The replace() method is used to replace all occurrences of a specified substring with another string in Kotlin.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Enchanted Scrolls of String Handling",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 251,
        "questionText": "What is the purpose of a try-catch block in Kotlin?",
        "options": [
          "A) To execute code regardless of whether an exception is thrown or not.",
          "B) To handle exceptions that occur during the execution of a block of code.",
          "C) To declare variables that may cause exceptions.",
          "D) To ensure that code runs before the program starts."
        ],
        "correctAnswer": "B) To handle exceptions that occur during the execution of a block of code.",
        "explanation": "A try-catch block is used to handle exceptions in Kotlin by catching and managing the exceptions thrown within the try block.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Trial by Fire: Mastering Exception Handling",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 252,
        "questionText": "What will happen if an exception is thrown in a try block but not caught in a catch block?",
        "options": [
          "A) The program will terminate immediately.",
          "B) The exception will be ignored, and the program will continue.",
          "C) The exception will be propagated up the call stack.",
          "D) The program will enter an infinite loop."
        ],
        "correctAnswer": "C) The exception will be propagated up the call stack.",
        "explanation": "If an exception is not caught in the catch block, it is propagated up the call stack until it is handled or causes the program to terminate.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Trial by Fire: Mastering Exception Handling",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 253,
        "questionText": "Which of the following is a correct way to throw an exception in Kotlin?",
        "options": [
          "A) throw new Exception(\"Error message\")",
          "B) throw Exception(\"Error message\")",
          "C) raise Exception(\"Error message\")",
          "D) Exception(\"Error message\").throw()"
        ],
        "correctAnswer": "B) throw Exception(\"Error message\")",
        "explanation": "In Kotlin, exceptions are thrown using the throw keyword followed by an instance of an Exception class.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Trial by Fire: Mastering Exception Handling",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 254,
        "questionText": "How can you create a custom exception in Kotlin?",
        "options": [
          "A) By extending the Throwable class.",
          "B) By implementing the Exception interface.",
          "C) By creating a class that extends Exception or RuntimeException.",
          "D) By overriding the throw keyword."
        ],
        "correctAnswer": "C) By creating a class that extends Exception or RuntimeException.",
        "explanation": "Custom exceptions in Kotlin are created by extending the Exception class or its subclasses, such as RuntimeException.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Trial by Fire: Mastering Exception Handling",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 255,
        "questionText": "What is the use of the finally block in Kotlin?",
        "options": [
          "A) To catch and handle exceptions.",
          "B) To execute code after a try-catch block, regardless of whether an exception occurred.",
          "C) To stop the propagation of an exception.",
          "D) To handle multiple exceptions."
        ],
        "correctAnswer": "B) To execute code after a try-catch block, regardless of whether an exception occurred.",
        "explanation": "The finally block in Kotlin is used to execute code after a try-catch block, regardless of whether an exception was thrown or caught.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Trial by Fire: Mastering Exception Handling",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 256,
        "questionText": "Which exception is thrown when you try to access an element outside the bounds of an array in Kotlin?",
        "options": [
          "A) IndexOutOfBoundsException",
          "B) ArrayIndexOutOfBoundsException",
          "C) NoSuchElementException",
          "D) IllegalArgumentException"
        ],
        "correctAnswer": "B) ArrayIndexOutOfBoundsException",
        "explanation": "ArrayIndexOutOfBoundsException is thrown when an attempt is made to access an array element with an index that is outside its bounds.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Trial by Fire: Mastering Exception Handling",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 257,
        "questionText": "What does the Nothing type represent in Kotlin?",
        "options": [
          "A) A type that represents a successful operation.",
          "B) A type that represents a value of null.",
          "C) A type that indicates a function never returns a value.",
          "D) A type that represents a function returning an empty value."
        ],
        "correctAnswer": "C) A type that indicates a function never returns a value.",
        "explanation": "The Nothing type in Kotlin represents a function that never returns a value. It is often used in functions that always throw exceptions.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Trial by Fire: Mastering Exception Handling",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 258,
        "questionText": "Which statement is true about the Nothing type in Kotlin?",
        "options": [
          "A) It can be used as a return type for functions that return a value.",
          "B) It is used to represent a null value.",
          "C) It indicates that a function will not return normally, such as a function that throws an exception.",
          "D) It can be used as a data type for variables."
        ],
        "correctAnswer": "C) It indicates that a function will not return normally, such as a function that throws an exception.",
        "explanation": "Nothing is a special type in Kotlin used to indicate that a function will not return normally, such as when it always throws an exception or enters an infinite loop.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Trial by Fire: Mastering Exception Handling",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 259,
        "questionText": "What is the output of the following code?\n\nfun divide(a: Int, b: Int): Int {\n    return if (b != 0) a / b else throw IllegalArgumentException(\"Division by zero\")\n}\n\nfun main() {\n    try {\n        println(divide(10, 0))\n    } catch (e: Exception) {\n        println(e.message)\n    }\n}",
        "options": [
          "A) 0",
          "B) Division by zero",
          "C) 10",
          "D) IllegalArgumentException"
        ],
        "correctAnswer": "B) Division by zero",
        "explanation": "The code throws an IllegalArgumentException with the message 'Division by zero' because the divisor is zero.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Trial by Fire: Mastering Exception Handling",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 260,
        "questionText": "What will be the result of the following code?\n\nfun checkAge(age: Int) {\n    if (age < 18) throw IllegalArgumentException(\"Underage\")\n}\n\nfun main() {\n    try {\n        checkAge(16)\n        println(\"Access granted\")\n    } catch (e: IllegalArgumentException) {\n        println(e.message)\n    }\n}",
        "options": [
          "A) Access granted",
          "B) IllegalArgumentException: Underage",
          "C) 16",
          "D) No output"
        ],
        "correctAnswer": "B) IllegalArgumentException: Underage",
        "explanation": "Since the age passed is less than 18, the code throws an IllegalArgumentException with the message 'Underage', which is caught and printed.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Trial by Fire: Mastering Exception Handling",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 261,
        "questionText": "When should you use the throw keyword in Kotlin?",
        "options": [
          "A) When you want to return a value from a function.",
          "B) When you need to explicitly terminate a loop.",
          "C) When you want to stop the execution of a function and signal an error condition.",
          "D) When you want to declare a new variable."
        ],
        "correctAnswer": "C) When you want to stop the execution of a function and signal an error condition.",
        "explanation": "The throw keyword is used to stop the execution of a function and signal an error condition by throwing an exception.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Trial by Fire: Mastering Exception Handling",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 262,
        "questionText": "How do you create a custom exception that requires a message in Kotlin?",
        "options": [
          "A) class CustomException : Exception",
          "B) class CustomException(message: String) : RuntimeException(message)",
          "C) class CustomException : Throwable",
          "D) class CustomException(message: String) : Throwable(message)"
        ],
        "correctAnswer": "B) class CustomException(message: String) : RuntimeException(message)",
        "explanation": "You create a custom exception by extending RuntimeException and passing a message to the constructor.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Trial by Fire: Mastering Exception Handling",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 263,
        "questionText": "What happens if you use a return statement inside a try block?",
        "options": [
          "A) The program will crash.",
          "B) The finally block will still execute.",
          "C) The catch block will be skipped.",
          "D) The exception will be thrown again."
        ],
        "correctAnswer": "B) The finally block will still execute.",
        "explanation": "Even if a return statement is used inside a try block, the finally block will still execute.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Trial by Fire: Mastering Exception Handling",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 264,
        "questionText": "In Kotlin, which exception is thrown when a function does not return a value as expected?",
        "options": [
          "A) IllegalArgumentException",
          "B) IllegalStateException",
          "C) NoSuchElementException",
          "D) NoSuchMethodException"
        ],
        "correctAnswer": "B) IllegalStateException",
        "explanation": "IllegalStateException is typically thrown when a method is invoked at an illegal or inappropriate time, often when a function does not return a value as expected.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Trial by Fire: Mastering Exception Handling",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 265,
        "questionText": "Which block of code will always execute after a try-catch block?",
        "options": [
          "A) try",
          "B) catch",
          "C) finally",
          "D) return"
        ],
        "correctAnswer": "C) finally",
        "explanation": "The finally block will always execute after a try-catch block, regardless of whether an exception was thrown or not.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Trial by Fire: Mastering Exception Handling",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 266,
        "questionText": "Which of the following is true about exception handling in Kotlin?",
        "options": [
          "A) All exceptions must be caught or declared to be thrown.",
          "B) You can only throw checked exceptions.",
          "C) Kotlin does not have checked exceptions.",
          "D) Exceptions are not allowed in Kotlin."
        ],
        "correctAnswer": "C) Kotlin does not have checked exceptions.",
        "explanation": "Kotlin does not have checked exceptions, meaning you are not required to catch or declare exceptions.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Trial by Fire: Mastering Exception Handling",
        "marks": 5,
        "timeLimit": "1 minute",
        "isMultipleCorrect": false
      },
      {
        "id": 267,
        "questionText": "What will happen if an exception is thrown inside the catch block?",
        "options": [
          "A) The program will terminate immediately.",
          "B) The exception will be caught by another catch block, if available.",
          "C) The exception will propagate up the call stack.",
          "D) The program will ignore the exception."
        ],
        "correctAnswer": "C) The exception will propagate up the call stack.",
        "explanation": "If an exception is thrown inside the catch block, it will propagate up the call stack until it is caught by another catch block or causes the program to terminate.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Trial by Fire: Mastering Exception Handling",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 268,
        "questionText": "Which exception is thrown when trying to access a null reference in Kotlin?",
        "options": [
          "A) NullReferenceException",
          "B) NullPointerException",
          "C) IllegalArgumentException",
          "D) IllegalStateException"
        ],
        "correctAnswer": "B) NullPointerException",
        "explanation": "NullPointerException is thrown when an application attempts to use an object reference that has the null value.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Trial by Fire: Mastering Exception Handling",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 269,
        "questionText": "What is the recommended way to handle exceptions in Kotlin for functions that are expected to fail occasionally?",
        "options": [
          "A) Use the try-catch block to catch the exception and handle it.",
          "B) Ignore the exception and continue the execution.",
          "C) Use a recursive function to retry the operation.",
          "D) Use a global exception handler to catch all exceptions."
        ],
        "correctAnswer": "A) Use the try-catch block to catch the exception and handle it.",
        "explanation": "The recommended approach is to use a try-catch block to catch the exception and handle it, especially for functions that are expected to fail occasionally.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Trial by Fire: Mastering Exception Handling",
        "marks": 5,
        "timeLimit": "2 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 270,
        "questionText": "What happens if you declare a function with a return type of Nothing?",
        "options": [
          "A) The function will return a null value.",
          "B) The function can return any type.",
          "C) The function is guaranteed not to return normally and will either throw an exception or never terminate.",
          "D) The function will return void."
        ],
        "correctAnswer": "C) The function is guaranteed not to return normally and will either throw an exception or never terminate.",
        "explanation": "A function with a return type of Nothing indicates that it will not return normally, either because it always throws an exception or it never terminates (e.g., an infinite loop).",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Trial by Fire: Mastering Exception Handling",
        "marks": 5,
        "timeLimit": "3 minutes",
        "isMultipleCorrect": false
      },
      {
        "id": 271,
        "questionText": "What is the primary purpose of annotations in Kotlin?",
        "options": [
          "To add metadata to the code",
          "To handle null safety",
          "To enforce type checking",
          "To manage memory allocation"
        ],
        "correctAnswer": "To add metadata to the code",
        "explanation": "Annotations in Kotlin provide metadata about the code, which can be used by the compiler, tools, or even at runtime to influence the program's behavior.",
        "difficultyLevel": "Easy",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 1,
        "timeLimit": "60 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 272,
        "questionText": "Which built-in annotation in Kotlin allows you to make a method static in Java?",
        "options": [
          "@JvmField",
          "@JvmOverloads",
          "@JvmStatic",
          "@JvmName"
        ],
        "correctAnswer": "@JvmStatic",
        "explanation": "The @JvmStatic annotation in Kotlin makes a method in a companion object or object available as a static method in Java.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 1,
        "timeLimit": "60 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 273,
        "questionText": "Which annotation would you use to generate overloads for a Kotlin function that has default parameters?",
        "options": [
          "@JvmStatic",
          "@JvmOverloads",
          "@JvmField",
          "@JvmName"
        ],
        "correctAnswer": "@JvmOverloads",
        "explanation": "The @JvmOverloads annotation generates multiple overloads for a Kotlin function, making it easier to call the function from Java code.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 1,
        "timeLimit": "60 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 274,
        "questionText": "What does the @JvmField annotation do in Kotlin?",
        "options": [
          "It makes a Kotlin property visible as a public field in Java.",
          "It creates a static field in the Java bytecode.",
          "It generates a toString method.",
          "It makes a Kotlin function accessible from Java."
        ],
        "correctAnswer": "It makes a Kotlin property visible as a public field in Java.",
        "explanation": "@JvmField exposes a Kotlin property as a public field in Java, bypassing Kotlin's getter/setter generation.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 1,
        "timeLimit": "60 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 275,
        "questionText": "How can you create a custom annotation in Kotlin?",
        "options": [
          "By using the @Custom annotation.",
          "By defining a class with @interface keyword.",
          "By defining a class with @annotation keyword.",
          "By using the annotation class keyword."
        ],
        "correctAnswer": "By using the annotation class keyword.",
        "explanation": "In Kotlin, custom annotations are created using the annotation class keyword.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 1,
        "timeLimit": "60 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 276,
        "questionText": "What is the retention policy of an annotation in Kotlin, and how do you specify it?",
        "options": [
          "Retention policy determines how long annotations are retained. You specify it with the @Retention annotation.",
          "Retention policy determines annotation inheritance. You specify it with the @Inherited annotation.",
          "Retention policy controls annotation visibility. You specify it with the @Visible annotation.",
          "Retention policy defines annotation usage. You specify it with the @Usage annotation."
        ],
        "correctAnswer": "Retention policy determines how long annotations are retained. You specify it with the @Retention annotation.",
        "explanation": "The retention policy determines whether an annotation is available at runtime, compile-time, or source-level only. It's specified using the @Retention annotation.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 1,
        "timeLimit": "60 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 277,
        "questionText": "How do you inspect the annotations of a class at runtime using Kotlin reflection?",
        "options": [
          "Using the declaredAnnotations property of KClass.",
          "Using the getAnnotations() method of Class.",
          "Using the getAnnotation() method of Object.",
          "Using the annotations property of KFunction."
        ],
        "correctAnswer": "Using the declaredAnnotations property of KClass.",
        "explanation": "Kotlin reflection allows inspection of class annotations using the declaredAnnotations property of KClass.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 2,
        "timeLimit": "90 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 278,
        "questionText": "Which of the following options is used to define the target of an annotation in Kotlin?",
        "options": [
          "@Target",
          "@Retention",
          "@JvmField",
          "@AnnotationTarget"
        ],
        "correctAnswer": "@Target",
        "explanation": "The @Target annotation is used to specify the possible kinds of elements which an annotation can be applied to, such as functions, properties, or classes.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 1,
        "timeLimit": "60 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 279,
        "questionText": "What is the purpose of the @KotlinModule annotation?",
        "options": [
          "To mark a Kotlin module for reflection purposes.",
          "To define a Kotlin module in a multi-module project.",
          "To integrate Kotlin with Java in a mixed project.",
          "To provide metadata for module-related reflection."
        ],
        "correctAnswer": "To mark a Kotlin module for reflection purposes.",
        "explanation": "The @KotlinModule annotation is used to mark a module for reflection purposes, helping the Kotlin compiler understand how to treat the module in the reflection process.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 2,
        "timeLimit": "90 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 280,
        "questionText": "How does @JvmName annotation affect a Kotlin function or property?",
        "options": [
          "It renames the Kotlin function or property in the generated Java bytecode.",
          "It exposes the Kotlin function or property to Java as a static method.",
          "It converts the Kotlin function or property to a lambda in Java.",
          "It converts the Kotlin function to an inline function in Java."
        ],
        "correctAnswer": "It renames the Kotlin function or property in the generated Java bytecode.",
        "explanation": "The @JvmName annotation allows you to specify a different name for a Kotlin function or property when it is accessed from Java code, which is helpful when dealing with naming conflicts or to follow Java naming conventions.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 1,
        "timeLimit": "60 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 281,
        "questionText": "Which Kotlin reflection class is used to represent a function at runtime?",
        "options": [
          "KFunction",
          "KCallable",
          "KClass",
          "KProperty"
        ],
        "correctAnswer": "KFunction",
        "explanation": "KFunction is a Kotlin reflection class used to represent a function at runtime, providing access to the function's parameters, name, and return type.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 1,
        "timeLimit": "60 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 282,
        "questionText": "What is the role of KClass in Kotlin's reflection API?",
        "options": [
          "It represents a Kotlin class at runtime.",
          "It represents a function at runtime.",
          "It represents a property at runtime.",
          "It represents an annotation at runtime."
        ],
        "correctAnswer": "It represents a Kotlin class at runtime.",
        "explanation": "KClass in Kotlin's reflection API represents a Kotlin class at runtime, allowing access to the class's metadata, such as its functions, properties, and annotations.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 1,
        "timeLimit": "60 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 283,
        "questionText": "Which function in Kotlin reflection would you use to get a list of all declared properties of a class?",
        "options": [
          "declaredMemberProperties",
          "declaredMemberFunctions",
          "getProperties()",
          "getDeclaredProperties()"
        ],
        "correctAnswer": "declaredMemberProperties",
        "explanation": "The declaredMemberProperties function is used to get a list of all properties declared in a Kotlin class using reflection.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 1,
        "timeLimit": "60 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 284,
        "questionText": "How do you check if a class has a specific annotation in Kotlin?",
        "options": [
          "Using hasAnnotation<Annotation>() function",
          "Using has<Annotation>() function",
          "Using isAnnotationPresent() method",
          "Using checkAnnotation<Annotation>() function"
        ],
        "correctAnswer": "Using hasAnnotation<Annotation>() function",
        "explanation": "You can check if a class has a specific annotation in Kotlin by using the hasAnnotation<Annotation>() function provided by the reflection API.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 2,
        "timeLimit": "90 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 285,
        "questionText": "In Kotlin, what is the purpose of the @Target annotation?",
        "options": [
          "To specify the elements a custom annotation can be applied to",
          "To control the visibility of an annotation",
          "To define the retention policy of an annotation",
          "To specify the name of an annotation in Java"
        ],
        "correctAnswer": "To specify the elements a custom annotation can be applied to",
        "explanation": "The @Target annotation in Kotlin is used to specify the kinds of elements (such as functions, properties, classes) to which an annotation can be applied.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 1,
        "timeLimit": "60 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 286,
        "questionText": "Which retention policy ensures that an annotation is available at runtime in Kotlin?",
        "options": [
          "RUNTIME",
          "SOURCE",
          "CLASS",
          "COMPILE"
        ],
        "correctAnswer": "RUNTIME",
        "explanation": "In Kotlin, the RUNTIME retention policy ensures that an annotation is available during runtime, allowing it to be accessed via reflection.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 1,
        "timeLimit": "60 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 287,
        "questionText": "What is the primary purpose of the @Repeatable annotation in Kotlin?",
        "options": [
          "To allow an annotation to be applied multiple times to the same element",
          "To repeat the execution of the annotated function",
          "To duplicate the functionality of an annotation",
          "To allow an annotation to be inherited"
        ],
        "correctAnswer": "To allow an annotation to be applied multiple times to the same element",
        "explanation": "The @Repeatable annotation allows an annotation to be applied multiple times to the same element in Kotlin, such as applying the same annotation to different methods in a class.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 2,
        "timeLimit": "90 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 288,
        "questionText": "How do you apply multiple annotations to a single element in Kotlin?",
        "options": [
          "By using the @Repeatable annotation",
          "By placing annotations on separate lines",
          "By using the @Multi annotation",
          "By using the @Combined annotation"
        ],
        "correctAnswer": "By placing annotations on separate lines",
        "explanation": "In Kotlin, multiple annotations can be applied to a single element by placing each annotation on a separate line, or on the same line separated by a space.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 1,
        "timeLimit": "60 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 289,
        "questionText": "Which annotation is used to specify that an annotation should be inherited by subclasses in Kotlin?",
        "options": [
          "@Inherited",
          "@Repeatable",
          "@Retention",
          "@Target"
        ],
        "correctAnswer": "@Inherited",
        "explanation": "The @Inherited annotation in Kotlin specifies that an annotation is inherited by subclasses, allowing the annotation to be applied to both the superclass and its subclasses.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 2,
        "timeLimit": "90 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 290,
        "questionText": "What is the purpose of the @JvmSynthetic annotation in Kotlin?",
        "options": [
          "To hide a Kotlin function from Java",
          "To convert a Kotlin function into a synthetic method in Java",
          "To expose a Kotlin property as a synthetic field in Java",
          "To generate synthetic classes in Java"
        ],
        "correctAnswer": "To hide a Kotlin function from Java",
        "explanation": "The @JvmSynthetic annotation in Kotlin is used to hide a Kotlin function or property from being accessed from Java code, essentially making it invisible to Java while still available in Kotlin.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 2,
        "timeLimit": "90 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 291,
        "questionText": "How does Kotlin's reflection API help in accessing the value of an annotated property?",
        "options": [
          "Using the getAnnotation() method",
          "Using the getValue() method",
          "Using the declaredMemberProperties",
          "Using the annotations property"
        ],
        "correctAnswer": "Using the declaredMemberProperties",
        "explanation": "In Kotlin, the reflection API allows you to access the value of an annotated property using the declaredMemberProperties property of a KClass.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 2,
        "timeLimit": "90 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 292,
        "questionText": "Which annotation in Kotlin is used to specify a new name for a function or property in the generated Java bytecode?",
        "options": [
          "@JvmName",
          "@JvmStatic",
          "@JvmOverloads",
          "@JvmField"
        ],
        "correctAnswer": "@JvmName",
        "explanation": "The @JvmName annotation in Kotlin is used to specify a different name for a Kotlin function or property in the generated Java bytecode, helping resolve naming conflicts or follow Java naming conventions.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 1,
        "timeLimit": "60 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 293,
        "questionText": "How can you retrieve all the annotations present on a Kotlin function using reflection?",
        "options": [
          "Using the annotations property of KFunction",
          "Using the getAnnotations() method",
          "Using the declaredAnnotations property",
          "Using the getDeclaredAnnotations() method"
        ],
        "correctAnswer": "Using the annotations property of KFunction",
        "explanation": "In Kotlin, you can retrieve all the annotations present on a function using the annotations property of the KFunction interface.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 2,
        "timeLimit": "90 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 294,
        "questionText": "What does the @Retention annotation control in Kotlin?",
        "options": [
          "It controls how long an annotation is retained.",
          "It controls where an annotation can be applied.",
          "It controls the visibility of an annotation.",
          "It controls the inheritance of an annotation."
        ],
        "correctAnswer": "It controls how long an annotation is retained.",
        "explanation": "The @Retention annotation in Kotlin controls how long an annotation is retained (e.g., SOURCE, BINARY, RUNTIME), determining whether it's available at runtime or only during compile-time.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 1,
        "timeLimit": "60 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 295,
        "questionText": "Which annotation would you use to specify that a Kotlin property should be exposed as a public field in Java?",
        "options": [
          "@JvmField",
          "@JvmStatic",
          "@JvmName",
          "@JvmOverloads"
        ],
        "correctAnswer": "@JvmField",
        "explanation": "The @JvmField annotation in Kotlin is used to expose a Kotlin property as a public field in Java, bypassing the default getter/setter generation.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 1,
        "timeLimit": "60 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 296,
        "questionText": "Which reflection class in Kotlin represents a callable function or property?",
        "options": [
          "KCallable",
          "KFunction",
          "KClass",
          "KProperty"
        ],
        "correctAnswer": "KCallable",
        "explanation": "KCallable is a Kotlin reflection class that represents a callable function or property, providing access to the parameters, return type, and annotations associated with the callable element.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 2,
        "timeLimit": "90 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 297,
        "questionText": "What is the difference between KFunction and KCallable in Kotlin reflection?",
        "options": [
          "KFunction represents a function, while KCallable represents a callable element (function or property).",
          "KCallable represents a function, while KFunction represents a class.",
          "KFunction is used for properties, while KCallable is used for functions.",
          "There is no difference; they are interchangeable."
        ],
        "correctAnswer": "KFunction represents a function, while KCallable represents a callable element (function or property).",
        "explanation": "In Kotlin reflection, KFunction specifically represents a function, while KCallable is a more general interface representing any callable element, including both functions and properties.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 2,
        "timeLimit": "90 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 298,
        "questionText": "How does the @JvmOverloads annotation help in Kotlin-Java interoperability?",
        "options": [
          "It generates overloads for a Kotlin function, making it easier to call from Java.",
          "It makes a Kotlin function static in Java.",
          "It changes the return type of a Kotlin function to void in Java.",
          "It adds default parameters to Java methods."
        ],
        "correctAnswer": "It generates overloads for a Kotlin function, making it easier to call from Java.",
        "explanation": "The @JvmOverloads annotation in Kotlin generates multiple overloads for a function that has default parameters, allowing it to be called from Java with or without those parameters.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 1,
        "timeLimit": "60 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 299,
        "questionText": "Which of the following reflection classes is used to represent a property in Kotlin?",
        "options": [
          "KProperty",
          "KFunction",
          "KCallable",
          "KClass"
        ],
        "correctAnswer": "KProperty",
        "explanation": "In Kotlin's reflection API, KProperty is used to represent a property, providing access to its name, type, and annotations.",
        "difficultyLevel": "Medium",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 1,
        "timeLimit": "60 seconds",
        "isMultipleCorrect": false
      },
      {
        "id": 300,
        "questionText": "What does the @JvmSynthetic annotation do when applied to a Kotlin function?",
        "options": [
          "It hides the function from Java, making it inaccessible.",
          "It generates synthetic Java code for the function.",
          "It converts the function into a Java lambda.",
          "It adds default parameters to the function."
        ],
        "correctAnswer": "It hides the function from Java, making it inaccessible.",
        "explanation": "The @JvmSynthetic annotation in Kotlin hides the annotated function or property from Java code, making it inaccessible while still being available in Kotlin.",
        "difficultyLevel": "Hard",
        "subject": "Kotlin",
        "category": "Engineering",
        "subcategory": "CS Engineering",
        "careerPath": "Mobile App Development",
        "subCareerPath": "Native Development",
        "topic": "The Hall of Mirrors: Mastering Annotations and Reflection",
        "marks": 2,
        "timeLimit": "90 seconds",
        "isMultipleCorrect": false
      }
]